import {
  StackDivider,
  StackProps,
  ThemingProps,
  VStack,
  useStyleConfig,
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

export type Props = StackProps & ThemingProps<'BlockList'>;

const BlockList: React.FC<PropsWithChildren<Props>> = ({
  children,
  variant,
  sx,
  ...stackProps
}: Props) => {
  const style = useStyleConfig('BlockList', { variant });

  return (
    <VStack
      alignItems="stretch"
      spacing={0}
      role="list"
      sx={{ ...style, ...sx }}
      divider={<StackDivider />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...stackProps}
    >
      {children}
    </VStack>
  );
};

export default BlockList;
