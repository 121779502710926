import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  WithFieldValue,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';
import Catch from '../../../components/Catch';
import ExpertTierField from '../../../components/ExpertTierField';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PhoneNumberField from '../../../components/PhoneNumberField';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import TextField from '../../../components/TextField';
import { useUserRef } from '../../../components/UserRefContext';
import AddUserIcon from '../../../icons/AddUserIcon';
import Spinner from '../../../icons/Spinner';
import {
  ExpertDoc,
  ExpertStatus,
  ExpertTier,
  useExpertsCollectionRef,
} from '../../../types/Expert';
import { useMembersCollectionRef } from '../../../types/Member';
import { SkillDoc } from '../../../types/Skill';

export interface FormFields {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  customerUrl: string;
  skillRefs: DocumentReference<SkillDoc>[];
  tier: ExpertTier;
}

const schema = yup.object().shape({
  email: yup.string().email().label('Expert email').required(),
  phoneNumber: yup.string().label('Expert phone'),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  customerUrl: yup.string().url().label('RMS profile'),
  skillRefs: yup.array().min(1).label('Skills').required(),
  tier: yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ]).required(),
});

const CreateExpertScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const userRef = useUserRef();

  const navigate = useNavigate();

  const { trackEvent } = useIntercom();

  const expertsCollectionRef = useExpertsCollectionRef();

  const membersCollectionRef = useMembersCollectionRef(organizationRef);
  const memberRef = useMemo(
    () => doc(membersCollectionRef, userRef.id),
    [membersCollectionRef, userRef.id],
  );

  const handleFormSubmit = useCallback(async ({
    email,
    firstName,
    lastName,
    phoneNumber,
    customerUrl,
    skillRefs,
    tier,
  }: FormFields) => {
    const expertRef = doc(expertsCollectionRef);

    const requestData: WithFieldValue<ExpertDoc> = {
      email,
      firstName,
      lastName,
      organizationRef,
      skillRefs,
      tier,
      createdAt: serverTimestamp(),
      assigneeRefs: [memberRef],
      rating: 0.5,
      status: ExpertStatus.PAUSED,
      _v: 3,
    };

    if (phoneNumber !== '') {
      requestData.phoneNumber = phoneNumber;
    }

    if (customerUrl !== '') {
      requestData.customerUrl = customerUrl;
    }

    await setDoc(
      expertRef,
      requestData,
    );

    trackEvent('clarwis-expert-created', requestData);

    navigate(`../${expertRef.id}`);
  }, [expertsCollectionRef, memberRef, navigate, organizationRef, trackEvent]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        customerUrl: '',
        skillRefs: [],
        tier: ExpertTier.LIEUTENANT,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
            data-intercom-target="OrganizationCreateExpertPage"
          >
            <GridItem py={4} borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Heading>
                  New expert
                </Heading>
              </Container>
            </GridItem>

            <GridItem py={4} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={4}>
                  <HStack spacing={4} alignItems="start" className="expert-name">
                    <TextField isRequired label="First name" name="firstName" placeholder="John" data-intercom-target="OrganizationCreateExpertFormFirstName" />
                    <TextField isRequired label="Last name" name="lastName" placeholder="Doe" data-intercom-target="OrganizationCreateExpertFormLastName" />
                  </HStack>

                  <TextField isRequired label="Expert email" name="email" type="email" placeholder="expert@email.com" data-intercom-target="OrganizationCreateExpertFormEmail" />

                  <HStack spacing={4} alignItems="start">
                    <PhoneNumberField label="Expert phone (optional)" name="phoneNumber" />
                    <TextField label="RMS profile (optional)" name="customerUrl" type="url" placeholder="https://example.com/profile/123" />
                  </HStack>

                  <ExpertTierField
                    isRequired
                    label="Rank"
                    name="tier"
                  />

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                    isRequired
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Button
                  width="100%"
                  variant="solid"
                  type="submit"
                  leftIcon={<AddUserIcon />}
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Processing..."
                  className="cart-checkout"
                  data-intercom-target="OrganizationCreateExpertFormSubmit"
                >
                  Invite expert
                </Button>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const CreateExpertScreenCatchFallback: React.FC = () => null;
export const CreateExpertScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CreateExpertScreen: React.FC = () => (
  <Catch fallback={<CreateExpertScreenCatchFallback />}>
    <Suspense fallback={<CreateExpertScreenSuspenseFallback />}>
      <CreateExpertScreenMain />
    </Suspense>
  </Catch>
);

export default CreateExpertScreen;
