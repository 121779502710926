import {
  Alert,
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useAnalytics, useAuth } from 'reactfire';
import * as yup from 'yup';
import Loader from '../../components/Loader';
import TextField from '../../components/TextField';
import Spinner from '../../icons/Spinner';

export interface FormFields {
  email: string;
}

const authSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const EmailRedirect: React.FC = () => {
  const auth = useAuth();
  const analytics = useAnalytics();

  const [error, setError] = useState<string | null>(null);

  const handleSignIn = useCallback(async (email: string) => {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
      return;
    }

    try {
      await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      logEvent(analytics, 'login', { method: 'email' });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  }, [auth, setError, analytics]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const emailForSignIn = window.localStorage.getItem('emailForSignIn');
    if (emailForSignIn) {
      setLoading(true);
      handleSignIn(emailForSignIn).then(() => { setLoading(false); });
    }
  }, [handleSignIn]);

  const handleConfirmEmailClick = useCallback(async ({ email }: FormFields) => {
    if (email) {
      await handleSignIn(email);
    }
  }, [handleSignIn]);

  const [validateAll, setValidateAll] = useState(false);

  if (loading) {
    return (
      <Loader />
    );
  }

  return (
    <Container maxW="container.sm">
      <VStack alignItems="stretch" spacing={5} py={3}>
        <Box py={1}>
          <Heading>
            Email confirmation
          </Heading>
        </Box>

        <Text>
          It appears that you have started authentication process in
          another browser. For security reasons confirm your email
          address once again please.
        </Text>

        <Formik<FormFields>
          initialValues={{
            email: '',
          }}
          onSubmit={handleConfirmEmailClick}
          validationSchema={authSchema}
          validateOnChange={validateAll}
          validateOnBlur={validateAll}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form
              noValidate
              onSubmit={(...props) => {
                setValidateAll(true);
                return handleSubmit(...props);
              }}
            >
              <VStack alignItems="stretch" spacing={5}>
                <TextField
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Enter your email address"
                />

                {error ? (
                  <Alert colorScheme="red">
                    {error}
                  </Alert>
                ) : null}

                <Button
                  size="lg"
                  width="100%"
                  type="submit"
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Signing you in..."
                >
                  Sign in
                </Button>
              </VStack>
            </form>
          )}
        </Formik>
      </VStack>
    </Container>
  );
};

export default EmailRedirect;
