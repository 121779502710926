import { Button, HStack, VStack } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import Catch from '../../components/Catch';
import SkillsSelectorField from '../../components/SkillsSelectorField';
import { useUserRef } from '../../components/UserRefContext';
import useUserRequestSkills from '../../functions/useUserRequestSkills';
import Spinner from '../../icons/Spinner';
import { SkillDoc } from '../../types/Skill';
import { useUserInterviewerProfileDocRef } from '../../types/UserInterviewerProfile';

export interface FormFields {
  claimedSkillRefs: DocumentReference<SkillDoc>[];
}

const schema = yup.object().shape({
  claimedSkillRefs: yup.array(yup.mixed().required()).label('Skills').min(1).required(),
});

const ClaimedSkillsFormMain: React.FC = () => {
  const userRef = useUserRef();
  const userInterviewerProfileRef = useUserInterviewerProfileDocRef(userRef);
  const { data: userInterviewerProfileSnap } = useFirestoreDoc(userInterviewerProfileRef);

  const userInterviewerProfile = useMemo(
    () => userInterviewerProfileSnap.data(),
    [userInterviewerProfileSnap],
  );

  const [validateAll, setValidateAll] = useState(false);

  const requestSkills = useUserRequestSkills();
  const onSubmit = useCallback(async ({ claimedSkillRefs }: FormFields) => {
    await requestSkills({ skillIds: claimedSkillRefs.map((ref) => ref.id) });
  }, [requestSkills]);

  if (!userInterviewerProfile) {
    return null;
  }

  return (
    <Formik<FormFields>
      initialValues={{
        claimedSkillRefs: userInterviewerProfile.claimedSkillRefs || [],
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={4} alignItems="stretch" textAlign="left">
            <SkillsSelectorField name="claimedSkillRefs" label="Claimed skills" />

            <HStack justifyContent="right">
              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Saving..."
              >
                Add
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export const ClaimedSkillsFormCatchFallback: React.FC = () => null;
export const ClaimedSkillsFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ClaimedSkillsForm: React.FC = () => (
  <Catch fallback={<ClaimedSkillsFormCatchFallback />}>
    <Suspense fallback={<ClaimedSkillsFormSuspenseFallback />}>
      <ClaimedSkillsFormMain />
    </Suspense>
  </Catch>
);

export default ClaimedSkillsForm;
