import React, { Suspense } from 'react';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import RoomProvider, { RoomContext } from '../../../components/RoomProvider';
import CallRoom from './CallRoom';
import Lobby from './Lobby';

const VisitorScreenMain: React.FC = () => (
  <RoomProvider>
    <RoomContext.Consumer>
      {(context) => (
        context?.room ? (
          <CallRoom />
        ) : (
          <Lobby />
        )
      )}
    </RoomContext.Consumer>
  </RoomProvider>
);

export const VisitorScreenCatchFallback: React.FC = () => null;
export const VisitorScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const VisitorScreen: React.FC = (props) => (
  <Catch fallback={<VisitorScreenCatchFallback />}>
    <Suspense fallback={<VisitorScreenSuspenseFallback />}>
      <VisitorScreenMain {...props} />
    </Suspense>
  </Catch>
);

export default VisitorScreen;
