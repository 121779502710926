import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import InterviewRefProvider from '../../components/InterviewRefContext';
import { useInterviewDocRef } from '../../types/Interview';
import PlaybackScreen from './PlaybackScreen';

const PlaybackPage: React.FC = () => {
  const { update } = useIntercom();
  useEffect(() => {
    update({
      hideDefaultLauncher: true,
    });

    return () => {
      update({
        hideDefaultLauncher: false,
      });
    };
  }, [update]);

  const { interviewId } = useParams<{ interviewId: string; }>();

  if (!interviewId) {
    throw new Error();
  }

  const interviewRef = useInterviewDocRef(interviewId);

  return (
    <InterviewRefProvider interviewRef={interviewRef}>
      <PlaybackScreen />
    </InterviewRefProvider>
  );
};

export default PlaybackPage;
