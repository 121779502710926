import {
  Button,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { } from 'react';
import PaddingBlock from '../../components/PaddingBlock';

export type Props = {
  onPrevious: () => unknown;
  onNext: () => unknown;
};

const LiftoffScreen: React.FC<Props> = ({
  onPrevious,
  onNext,
}) => (
  <PaddingBlock>
    <VStack alignItems="stretch" spacing={3}>
      <Heading fontSize="xl" textAlign="center">
        Congratulations!
      </Heading>

      <Text textAlign="center">
        You&apos;ve successfully completed our wizard, and you&apos;re now ready to dive into the
        heart of our platform. Head over to your dashboard where you can seamlessly add more
        candidates and experts, taking full advantage of our streamlined interviewing process. Your
        journey to smarter, more efficient hiring has just begun, and we&apos;re excited to be part
        of it every step of the way. Welcome to a new era of technical interviewing excellence!
      </Text>

      <Button onClick={onNext}>
        Go to your dashboard
      </Button>
    </VStack>
  </PaddingBlock>
);

export default LiftoffScreen;
