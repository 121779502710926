import { BoxProps, useToken } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import useCandidateStatusColor from '../hooks/useCandidateStatusColor';
import { CandidateDoc } from '../types/Candidate';
import BlockListItem from './BlockListItem';
import CandidateInfo, { CandidateInfoSuspenseFallback } from './CandidateInfo';
import Catch from './Catch';

export type Props = {
  candidateSnap: QueryDocumentSnapshot<CandidateDoc>;
} & BoxProps;

const CandidateRowMain: React.FC<Props> = ({ candidateSnap, ...boxProps }: Props) => {
  const { candidateId: activeCandidateId } = useParams<{ candidateId: string }>();
  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  const statusColor = useCandidateStatusColor(candidateSnap);
  const [styledColor] = useToken('colors', [statusColor]);

  const navigate = useNavigate();
  const onClick = useCallback(
    () => navigate(candidateSnap.id),
    [candidateSnap.id, navigate],
  );

  return (
    <BlockListItem
      data-intercom-target="CandidateRow"
      data-candidate-status={candidate.status}
      boxShadow={`inset 4px 0px ${styledColor}`}
      onClick={onClick}
      aria-pressed={candidateSnap.id === activeCandidateId}
      height="68px"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <CandidateInfo candidateSnap={candidateSnap} />
    </BlockListItem>
  );
};

export const CandidateRowSuspenseFallback: React.FC = () => (
  <BlockListItem height="68px">
    <CandidateInfoSuspenseFallback />
  </BlockListItem>
);

export const CandidateRowCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateRow: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateRowCatchFallback />}>
    <Suspense fallback={<CandidateRowSuspenseFallback />}>
      <CandidateRowMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateRow;
