import React, { useContext } from 'react';

interface Playback {
  interviewToPlaybackTime: (a: number) => number;
  playbackToInterviewTime: (a: number) => number;
  playbackLength: number;
  playbackTime: number;
  seek: (playbackTime: number) => void;
  onVideoWaiting: () => void;
  onVideoPlaying: () => void;
  isBuffering: boolean;
  isPlaying: boolean;
  play: () => void;
  pause: () => void;
}

const PlaybackContext = React.createContext<Playback>({
  interviewToPlaybackTime: () => 0,
  playbackToInterviewTime: () => 0,
  playbackLength: 0,
  playbackTime: 0,
  seek: () => { },
  onVideoWaiting: () => { },
  onVideoPlaying: () => { },
  isBuffering: false,
  isPlaying: false,
  play: () => { },
  pause: () => { },
});

export const usePlayback = () => useContext(PlaybackContext);

export default PlaybackContext.Provider;
