import { Td, Text, Tr } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import Catch from '../../../components/Catch';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useTimezone from '../../../hooks/useTimezone';
import {
  OrganizationBalanceTransactionDoc,
  OrganizationBalanceTransactionType,
} from '../../../types/OrganizationBalanceTransaction';
import CandidateCell from './CandidateCell';
import InterviewCell from './InterviewCell';

export interface Props {
  transactionSnap: QueryDocumentSnapshot<OrganizationBalanceTransactionDoc>;
  totalTierCQuantity: number;
}

const TransactionRowMain: React.FC<Props> = ({ transactionSnap, totalTierCQuantity }) => {
  const timezone = useTimezone();
  const transaction = useMemo(() => transactionSnap.data(), [transactionSnap]);

  const currentTime = useCurrentTime(1000 * 60);
  const timestamp = useMemo(
    () => moment(transaction.timestamp.toDate()).tz(timezone).calendar(currentTime),
    [transaction.timestamp, timezone, currentTime],
  );

  switch (transaction.type) {
    case OrganizationBalanceTransactionType.TRIAL_CREDIT:
      return (
        <Tr overflow="auto" flexGrow={1} flexShrink={1}>
          <Td>
            {timestamp}
          </Td>
          <Td>
            Trial
          </Td>
          <Td isNumeric>
            (
            <Text as="span" color="green">
              +
              {transaction.tierCQuantity}
            </Text>
            )
            {' '}
            {totalTierCQuantity}
          </Td>
          <Td colSpan={2} />
        </Tr>
      );
    case OrganizationBalanceTransactionType.STRIPE_INVOICE:
      return (
        <Tr overflow="auto" flexGrow={1} flexShrink={1}>
          <Td>
            {timestamp}
          </Td>
          <Td>
            Paid
          </Td>
          <Td isNumeric>
            (
            <Text as="span" color="green">
              +
              {transaction.tierCQuantity}
            </Text>
            )
            {' '}
            {totalTierCQuantity}
          </Td>
          <Td colSpan={2}>
            {transaction.invoiceId}
          </Td>
        </Tr>
      );
    case OrganizationBalanceTransactionType.INTERVIEW_CREATED:
      return (
        <Tr overflow="auto" flexGrow={1} flexShrink={1}>
          <Td>
            {timestamp}
          </Td>
          <Td>
            Spent
          </Td>
          <Td isNumeric>
            (
            <Text as="span" color="red">
              {transaction.tierCQuantity}
            </Text>
            )
            {' '}
            {totalTierCQuantity}
          </Td>
          <Td>
            <CandidateCell transactionSnap={transactionSnap} />
          </Td>
          <Td>
            <InterviewCell transactionSnap={transactionSnap} />
          </Td>
        </Tr>
      );
    case OrganizationBalanceTransactionType.INTERVIEW_CANCELED:
    case OrganizationBalanceTransactionType.REFUND:
      return (
        <Tr overflow="auto" flexGrow={1} flexShrink={1}>
          <Td>
            {timestamp}
          </Td>
          <Td>
            Refunded
          </Td>
          <Td isNumeric>
            (
            <Text as="span" color="green">
              +
              {transaction.tierCQuantity}
            </Text>
            )
            {' '}
            {totalTierCQuantity}
          </Td>
          <Td>
            <CandidateCell transactionSnap={transactionSnap} />
          </Td>
          <Td>
            <InterviewCell transactionSnap={transactionSnap} />
          </Td>
        </Tr>
      );
    default:
      return null;
  }
};

export const TransactionRowCatchFallback: React.FC = () => null;
export const TransactionRowSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const TransactionRow: React.FC<Props> = (props) => (
  <Catch fallback={<TransactionRowCatchFallback />}>
    <Suspense fallback={<TransactionRowSuspenseFallback />}>
      <TransactionRowMain {...props} />
    </Suspense>
  </Catch>
);

export default TransactionRow;
