import { logEvent } from 'firebase/analytics';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAnalytics } from 'reactfire';

const PageViewLogger: React.FC = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_path: location.pathname });
  }, [location.pathname, analytics]);

  return null;
};

export default PageViewLogger;
