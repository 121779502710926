import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { OrganizationDoc, isOrganizationRef } from './Organization';

export enum OrganizationPlatformTier {
  MOON = 'MOON',
  MARS = 'MARS',
  TITAN = 'TITAN',
  KEPLER = 'KEPLER',
}

export type OrganizationCustomerProfileDoc = {
  platformTier?: OrganizationPlatformTier;
  platformSubscriptionItem?: string;
  stripeCustomerId?: string;
  isEnabled?: boolean;
};

export const isOrganizationCustomerProfileDoc = (
  data?: DocumentData,
): data is OrganizationCustomerProfileDoc => true;

export const isOrganizationCustomerProfileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationCustomerProfileDoc> => ref.id === 'customerProfile' && ref.parent.id === 'private' && ref.parent.parent !== null && isOrganizationRef(ref.parent.parent);

export const isOrganizationInternalSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
OrganizationCustomerProfileDoc
> => isOrganizationCustomerProfileRef(snap.ref);

export const useOrganizationCustomerProfileDocRef = (organizationRef: DocumentReference<OrganizationDoc>) => useMemo(() => doc(collection(organizationRef, 'private'), 'customerProfile') as DocumentReference<OrganizationCustomerProfileDoc>, [organizationRef]);
