import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

type Props = BoxProps;

const Logo: React.FC<Props> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box as="svg" boxSize={9} viewBox="0 0 36 36" fill="none" {...props}>
    <path d="M33.0776 17.0267C33.7296 17.5917 33.889 18.5398 33.4576 19.287L26.8436 30.7428C26.4123 31.4898 25.5116 31.8259 24.6964 31.5439L13.2356 27.5796C12.8261 27.438 12.4814 27.1534 12.2649 26.778L7.53584 18.581C7.31964 18.2062 7.24565 17.7662 7.32741 17.3413L9.50804 6.01045C9.71519 4.93409 10.8281 4.2912 11.864 4.64951L23.0725 8.52651C23.2893 8.60148 23.4897 8.71717 23.663 8.86738L33.0776 17.0267Z" fill="#2F92A8" />
    <path d="M28.6872 17.5502C28.7467 17.8596 28.724 18.1793 28.6212 18.4772L25.1031 28.6779C24.8861 29.3069 24.3399 29.765 23.6826 29.8689L11.0997 31.8589C10.3638 31.9753 9.6322 31.6263 9.25966 30.981L2.52098 19.3093C2.08968 18.5622 2.24897 17.6143 2.90074 17.0492L12.3374 8.86813C12.5112 8.71745 12.7123 8.60148 12.9298 8.5265L24.116 4.66944C25.1515 4.31237 26.2633 4.95529 26.4703 6.03095L28.6872 17.5502Z" fill="#7A37AD" />
    <path d="M18.5208 23.9188C20.6028 23.9188 22.4244 22.8941 23.4003 21.2839L21.1558 19.9827C20.6678 20.861 19.6756 21.3815 18.5208 21.3815C16.504 21.3815 15.2028 20.0315 15.2028 17.9984C15.2028 15.9652 16.504 14.6152 18.5208 14.6152C19.6756 14.6152 20.6515 15.1357 21.1558 16.014L23.4003 14.7128C22.4082 13.1026 20.5865 12.0779 18.5208 12.0779C15.0889 12.0779 12.6003 14.6478 12.6003 17.9984C12.6003 21.349 15.0889 23.9188 18.5208 23.9188Z" fill="white" />
  </Box>
);

export default Logo;
