import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import NavLogo from '../../components/NavLogo';
import NavUser from '../../components/NavUser';
import OrganizationMenu from './OrganizationMenu';

const MainLayout: React.FC = () => (
  <Grid
    gridTemplateColumns="auto 1fr"
    gridTemplateRows="1fr"
    height="100%"
    width="100%"
  >
    <GridItem
      py={3}
      pl={5}
      boxSizing="border-box"
      borderBottomWidth="none"
      borderBottomStyle="solid"
    >
      <Grid
        gridTemplateColumns="1fr"
        gridTemplateRows="36px 1fr 36px"
        gap={5}
        height="100%"
        width="100%"
        alignItems="center"
        alignContent="center"
      >
        <GridItem>
          <NavLogo />
        </GridItem>

        <GridItem>
          <OrganizationMenu />
        </GridItem>

        <GridItem>
          <NavUser />
        </GridItem>
      </Grid>
    </GridItem>

    <GridItem minH={0}>
      <Outlet />
    </GridItem>
  </Grid>
);

export default MainLayout;
