import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc } from './Organization';

export enum WebhookEventType {
  SCORE_CREATED = 'score.created',
}

export type WebhookDoc = {
  url: string;
  secret: string;
  eventTypes: WebhookEventType[];
  isActive: boolean;
  organizationRef?: DocumentReference<OrganizationDoc>;
};

export const isWebhookDoc = (
  data?: DocumentData,
): data is WebhookDoc => true;

export const isWebhookRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<WebhookDoc> => ref.parent.id === 'webhooks';

export const isWebhookSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<WebhookDoc> => isWebhookRef(snap.ref);

export const useWebhooksCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'webhooks') as CollectionReference<WebhookDoc>, [firestore]);
};

export const useWebhookDocRef = (docId: string) => {
  const collectionRef = useWebhooksCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
