import {
  Alert,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import { updateEmail } from 'firebase/auth';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { useUser } from 'reactfire';
import EditIcon from '../icons/EditIcon';
import PaddingBlock from './PaddingBlock';
import TextField from './TextField';

export interface FormFields {
  email: string;
}

export type EmailAuthMethodProps = {
  label?: string;
};

const EmailAuthMethod: React.FC<EmailAuthMethodProps> = ({
  label = 'Email',
}) => {
  const { data: user } = useUser();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = useCallback(() => setDialogOpen(true), [setDialogOpen]);
  const handleDialogClose = useCallback(() => setDialogOpen(false), [setDialogOpen]);

  const [email, setEmail] = useState<string | null>(
    user?.email || null,
  );

  const [error, setError] = useState<string | null>(null);

  const handleEmailSave = useCallback(async (fields: FormFields) => {
    if (user) {
      try {
        await updateEmail(user, fields.email);
        setEmail(fields.email);
        setDialogOpen(false);
        setError(null);
      } catch (err) {
        if (err instanceof FirebaseError) {
          setError(err.message);
        }
      }
    }
  }, [user, setEmail, setDialogOpen, setError]);

  return (
    <>
      <FormControl>
        <FormLabel>
          {label}
        </FormLabel>

        <PaddingBlock>
          <Flex alignItems="center">
            <Text>{email}</Text>
            <Spacer />
            <Button leftIcon={<EditIcon />} variant="outline" onClick={handleDialogOpen}>
              Change
            </Button>
          </Flex>
        </PaddingBlock>
      </FormControl>

      <Formik<FormFields>
        initialValues={{
          email: email || '',
        }}
        onSubmit={handleEmailSave}
      >
        {({ handleSubmit, values }) => (
          <Modal isOpen={dialogOpen} onClose={handleDialogClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                Change email
              </ModalHeader>

              <ModalCloseButton />

              <ModalBody>
                <form onSubmit={handleSubmit}>
                  <VStack alignItems="stretch">
                    <TextField name="email" type="email" label="New email" />

                    {error ? (
                      <Alert colorScheme="red">
                        {error}
                      </Alert>
                    ) : null}
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={handleDialogClose}>
                  Close
                </Button>

                <Button onClick={() => handleEmailSave(values)}>
                  Change
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Formik>
    </>
  );
};

EmailAuthMethod.defaultProps = {
  label: undefined,
};

export default EmailAuthMethod;
