import {
  Grid,
  GridItem,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import CallContent from '../../../../components/CallContent';
import CallSchedule from '../../../../components/CallSchedule';
import CallScore from '../../../../components/CallScore';
import GhostParticipants from '../../../../components/GhostParticipants';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import LeaveButton from '../../../../components/LeaveButton';
import LocalParticipantMedia from '../../../../components/LocalParticipantMedia';
import ParticipantMedia from '../../../../components/ParticipantMedia';
import { useUserRef } from '../../../../components/UserRefContext';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const CallRoom: React.FC = () => {
  const userRef = useUserRef();

  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return (
    <Grid
      px={5}
      py={3}
      templateColumns="auto 336px"
      templateRows="1fr auto auto"
      templateAreas={' "content media" "score media" "schedule media" '}
      columnGap={5}
      rowGap={3}
      h="100%"
    >
      <GridItem gridArea="content" overflow="auto">
        <CallContent />
      </GridItem>

      <GridItem
        gridArea="score"
        background="cf.bgBlackAlpha6"
        borderRadius="xl"
        padding="8px"
      >
        <CallScore />
      </GridItem>

      <GridItem
        gridArea="media"
        overflow="auto"
        background="cf.bgBlackAlpha6"
        borderRadius="xl"
        padding="8px"
      >
        <VStack spacing={3} alignItems="stretch">
          <LocalParticipantMedia />

          {interview.intervieweeRef ? (
            <ParticipantMedia
              userRef={interview.intervieweeRef}
            />
          ) : null}

          {interview.interviewerRefs.map((interviewerRef) => {
            if (userRef.id === interviewerRef.id) {
              return null;
            }

            return (
              <ParticipantMedia
                key={interviewerRef.id}
                userRef={interviewerRef}
              />
            );
          })}

          <GhostParticipants />
        </VStack>
      </GridItem>

      <GridItem
        gridArea="schedule"
        background="cf.bgBlackAlpha6"
        borderRadius="xl"
        padding="8px"
      >
        <HStack spacing={5}>
          <CallSchedule flexGrow={1} />
          <LeaveButton />
        </HStack>
      </GridItem>
    </Grid>
  );
};

export default CallRoom;
