import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { LocalDataTrack } from 'twilio-video';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';

export type Result = {
  track: LocalDataTrack | null;
};

const LocalDataContext = React.createContext<Result>({ track: null });

export const useLocalData = (): Result => useContext(LocalDataContext);

const LocalDataProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const role = useInterviewRole();
  const name = useMemo<string>(() => {
    switch (role) {
      case InterviewRole.INTERVIEWEE: return 'interviewee-data';
      case InterviewRole.INTERVIEWER: return 'interviewer-data';
      default: return 'visitor-data';
    }
  }, [role]);

  const localDataTrack = useMemo<LocalDataTrack>(
    () => new LocalDataTrack({ name }),
    [name],
  );

  const res = useMemo(() => ({
    track: localDataTrack,
  }), [localDataTrack]);

  return (
    <LocalDataContext.Provider value={res}>
      {children}
    </LocalDataContext.Provider>
  );
};

export default LocalDataProvider;
