import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc } from './Organization';

export enum DeelLegalEntityType {
  INDIVIDUAL = 'individual',
  COMPANY = 'company',
}

export type DeelLegalEntityDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  name: string;
  type: DeelLegalEntityType;
};

export const isDeelLegalEntityDoc = (
  data?: DocumentData,
): data is DeelLegalEntityDoc => true;

export const isDeelLegalEntityRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelLegalEntityDoc> => ref.parent.id === 'deelLegalEntities';

export const isDeelLegalEntitySnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelLegalEntityDoc> => isDeelLegalEntityRef(snap.ref);

export const useDeelLegalEntitiesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'deelLegalEntities') as CollectionReference<DeelLegalEntityDoc>, [firestore]);
};

export const useDeelLegalEntityDocRef = (docId: string) => {
  const collectionRef = useDeelLegalEntitiesCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
