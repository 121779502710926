import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
} from '@chakra-ui/react';
import { useField } from 'formik';
import moment from 'moment';
import React, {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useUserRef } from '../../../components/UserRefContext';
import { useUserTimezoneDocRef } from '../../../types/UserTimezone';

export type Props = BoxProps & {
  name: string;
  label: string;
  isRequired?: boolean;
};

const StartField: React.FC<Props> = ({
  name,
  label,
  isRequired,
  ...boxProps
}) => {
  const [field, meta, helpers] = useField<number>(name);

  const userRef = useUserRef();

  const userTimezoneRef = useUserTimezoneDocRef(userRef);
  const { data: userTimezoneSnap } = useFirestoreDoc(userTimezoneRef);
  const userTimezone = useMemo(() => userTimezoneSnap.data(), [userTimezoneSnap]);

  const [timezone, setTimezone] = useState(userTimezone?.timezone ?? 'UTC');

  const dayValue = useMemo(
    () => moment(field.value).tz(timezone).format('YYYY-MM-DD'),
    [field.value, timezone],
  );

  const timeValue = useMemo(
    () => moment(field.value).tz(timezone).format('HH:mm:ss'),
    [field.value, timezone],
  );

  const timezoneOptions = useMemo(
    () => moment.tz.names(),
    [],
  );

  const dayOptions = useMemo(
    () => {
      const results: { key: string, option: string }[] = [];
      const start = moment().tz(timezone).startOf('day');
      for (let i = 0; i < 28; i += 1) {
        const current = moment(start).add(i, 'days');
        results.push({
          key: current.format('YYYY-MM-DD'),
          option: current.format('LL'),
        });
      }
      return results;
    },
    [timezone],
  );

  const timeOptions = useMemo(
    () => {
      const results: { key: string, option: string }[] = [];
      const start = moment().tz(timezone).startOf('year');
      for (let i = 0; i < 96; i += 1) {
        const current = moment(start).add(15 * i, 'minutes');
        results.push({
          key: current.format('HH:mm:ss'),
          option: current.format('LT'),
        });
      }
      return results;
    },
    [timezone],
  );

  const handleTimezoneChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setTimezone(e.target.value);
    },
    [],
  );

  const handleDayChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helpers.setValue(moment.tz(`${e.target.value}T${timeValue}`, timezone).toDate().getTime());
    },
    [helpers, timeValue, timezone],
  );

  const handleTimeChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helpers.setValue(moment.tz(`${dayValue}T${e.target.value}`, timezone).toDate().getTime());
    },
    [helpers, dayValue, timezone],
  );

  return (
    <FormControl
      isInvalid={!!meta.error}
      isRequired={isRequired}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <FormLabel flexGrow={1}>
        {label}
      </FormLabel>

      <HStack>
        <Select value={dayValue} onChange={handleDayChange}>
          {dayOptions.map(({ key, option }) => (
            <option value={key} key={key}>{option}</option>
          ))}
        </Select>

        <Select value={timeValue} onChange={handleTimeChange}>
          {timeOptions.map(({ key, option }) => (
            <option value={key} key={key}>{option}</option>
          ))}
        </Select>

        <Select value={timezone} onChange={handleTimezoneChange}>
          {timezoneOptions.map((tz) => (
            <option value={tz} key={tz}>{tz}</option>
          ))}
        </Select>
      </HStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

StartField.defaultProps = {
  isRequired: undefined,
};

export default StartField;
