import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const FilterIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <rect x="3" y="4" width="14" height="2" fill="currentColor" />
    <rect x="5" y="9" width="10" height="2" fill="currentColor" />
    <rect x="7" y="14" width="6" height="2" fill="currentColor" />
  </Icon>
);

export default FilterIcon;
