import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import IntercomUpdate from '../components/IntercomUpdate';
import NavigateWithNext from '../components/NavigateWithNext';
import PageViewLogger from '../components/PageViewLogger';
import AuthLayout from '../pages/AuthLayout';
import CheckAuthLayout from '../pages/CheckAuthLayout';
import CheckNoAuthLayout from '../pages/CheckNoAuthLayout';
import CheckOrganizationLayout from '../pages/CheckOrganizationLayout';
import CheckSubscriptionLayout from '../pages/CheckSubscriptionLayout';
import CheckUserLayout from '../pages/CheckUserLayout';
import CheckUserScheduleLayout from '../pages/CheckUserScheduleLayout';
import CheckUserSensitiveLayout from '../pages/CheckUserSensitiveLayout';
import EmptyPage from '../pages/EmptyPage';
import Error404Page from '../pages/Error404Page';
import InterviewPage from '../pages/InterviewPage';
import LoginPage from '../pages/LoginPage';
import MainLayout from '../pages/MainLayout';
import OrganizationBalancePage from '../pages/OrganizationBalancePage';
import OrganizationBillingPage from '../pages/OrganizationBillingPage';
import OrganizationCandidateAcceptInvitationPage from '../pages/OrganizationCandidateAcceptInvitationPage';
import OrganizationCandidateCreatePage from '../pages/OrganizationCandidateCreatePage';
import OrganizationCandidateDetailsPage from '../pages/OrganizationCandidateDetailsPage';
import OrganizationCandidateEditPage from '../pages/OrganizationCandidateEditPage';
import OrganizationCandidateInterviewCreatePage from '../pages/OrganizationCandidateInterviewCreatePage';
import OrganizationCandidatesLayout from '../pages/OrganizationCandidatesLayout';
import OrganizationCreatePage from '../pages/OrganizationCreatePage';
import OrganizationDeelAuthPage from '../pages/OrganizationDeelAuthPage';
import OrganizationDeelRedirectPage from '../pages/OrganizationDeelRedirectPage';
import OrganizationExpertAcceptInvitationPage from '../pages/OrganizationExpertAcceptInvitationPage';
import OrganizationExpertCreatePage from '../pages/OrganizationExpertCreatePage';
import OrganizationExpertDetailsPage from '../pages/OrganizationExpertDetailsPage';
import OrganizationExpertEditPage from '../pages/OrganizationExpertEditPage';
import OrganizationExpertsLayout from '../pages/OrganizationExpertsLayout';
import OrganizationInterviewDetailsPage from '../pages/OrganizationInterviewDetailsPage';
import OrganizationInterviewsLayout from '../pages/OrganizationInterviewsLayout';
import OrganizationLayout from '../pages/OrganizationLayout';
import OrganizationMemberAcceptInvitationPage from '../pages/OrganizationMemberAcceptInvitationPage';
import OrganizationSettingsPage from '../pages/OrganizationSettingsPage';
import OrganizationSignUpPage from '../pages/OrganizationSignUpPage';
import OrganizationSkillCreatePage from '../pages/OrganizationSkillCreatePage';
import OrganizationSkillDetailsPage from '../pages/OrganizationSkillDetailsPage';
import OrganizationSkillEditPage from '../pages/OrganizationSkillEditPage';
import OrganizationSkillsLayout from '../pages/OrganizationSkillsLayout';
import OrganizationSkillSubscriptionPage from '../pages/OrganizationSkillSubscriptionPage';
import OrganizationsPage from '../pages/OrganizationsPage';
import OrganizationStageCreatePage from '../pages/OrganizationStageCreatePage';
import OrganizationStageDetailsPage from '../pages/OrganizationStageDetailsPage';
import OrganizationStageEditPage from '../pages/OrganizationStageEditPage';
import OrganizationStagesLayout from '../pages/OrganizationStagesLayout';
import OrganizationStageSubscriptionPage from '../pages/OrganizationStageSubscriptionPage';
import OrganizationSubscribePage from '../pages/OrganizationSubscribePage';
import OrganizationSubscribeRedirectPage from '../pages/OrganizationSubscribeRedirectPage';
import OrganizationSubscribeStartPage from '../pages/OrganizationSubscribeStartPage';
import PlaybackPage from '../pages/PlaybackPage';
import ScorePage from '../pages/ScorePage';
import UserInterviewDetailsPage from '../pages/UserInterviewDetailsPage';
import UserInterviewsLayout from '../pages/UserInterviewsLayout';
import UserMicrosoftAuthPage from '../pages/UserMicrosoftAuthPage';
import UserMicrosoftRedirectPage from '../pages/UserMicrosoftRedirectPage';
import UserProfilePage from '../pages/UserProfilePage';
import UserSettingsPage from '../pages/UserSettingsPage';
import UserSignUpPage from '../pages/UserSignUpPage';
import { OrganizationPlatformTier } from '../types/OrganizationCustomerProfile';
import LoadOrganization from './LoadOrganization';

const Navigation: React.FC = () => (
  <BrowserRouter>
    <IntercomUpdate />
    <PageViewLogger />

    <Routes>
      <Route element={<CheckAuthLayout fallback={<NavigateWithNext to="login" />} />}>
        <Route element={<AuthLayout />}>
          <Route path="signup" element={<UserSignUpPage />} />
          <Route path="signup-customer" element={<OrganizationSignUpPage />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route element={<CheckUserLayout fallback={<NavigateWithNext to="/signup" />} />}>
            <Route element={<CheckUserSensitiveLayout fallback={<NavigateWithNext to="/signup" />} />}>
              <Route
                path="/"
                element={(
                  <LoadOrganization>
                    {(organizationSnap) => (organizationSnap ? (
                      <Navigate to={`organizations/${organizationSnap.id}`} />
                    ) : (
                      <Navigate to="interviews" />
                    ))}
                  </LoadOrganization>
                )}
              />
              <Route path="settings" element={<UserSettingsPage />} />
              <Route path="profile" element={<UserProfilePage />} />
              <Route path="organizations/:organizationId/memberInvitations/:memberInvitationId/accept" element={<OrganizationMemberAcceptInvitationPage />} />

              <Route element={<CheckUserScheduleLayout fallback={<NavigateWithNext to="signup" />} />}>
                <Route path="interviews" element={<UserInterviewsLayout />}>
                  <Route index element={<EmptyPage />} />
                  <Route path=":interviewId" element={<UserInterviewDetailsPage />} />
                </Route>
                <Route path="candidates/:candidateId/invitations/:invitationId/accept" element={<OrganizationCandidateAcceptInvitationPage />} />
                <Route path="experts/:expertId/invitations/:invitationId/accept" element={<OrganizationExpertAcceptInvitationPage />} />
                {/* <Route path="interviewer">
                  <Route path="profile" element={<InterviewerProfilePage />} />
                </Route> */}
              </Route>
            </Route>
          </Route>

          <Route element={<CheckUserLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
            <Route element={<CheckUserSensitiveLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
              <Route element={<CheckOrganizationLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
                <Route path="organizations">
                  <Route index element={<OrganizationsPage />} />
                  <Route path="new" element={<OrganizationCreatePage />} />
                  <Route path=":organizationId" element={<OrganizationLayout />}>
                    <Route path="subscribe" element={<OrganizationSubscribePage />} />
                    <Route element={<CheckSubscriptionLayout fallback={<NavigateWithNext to="subscribe" />} min={OrganizationPlatformTier.MOON} />}>
                      <Route index element={<Navigate to="candidates" replace />} />
                      <Route path="settings" element={<OrganizationSettingsPage />} />
                      <Route path="billing" element={<OrganizationBillingPage />} />
                      <Route path="balance" element={<OrganizationBalancePage />} />
                      <Route path="interviews" element={<OrganizationInterviewsLayout />}>
                        <Route index element={<EmptyPage />} />
                        <Route path=":interviewId" element={<OrganizationInterviewDetailsPage />} />
                      </Route>
                      <Route path="stages" element={<OrganizationStagesLayout />}>
                        <Route index element={<EmptyPage />} />
                        <Route
                          element={(
                            <CheckSubscriptionLayout
                              fallback={<OrganizationStageSubscriptionPage />}
                              min={OrganizationPlatformTier.MARS}
                            />
                          )}
                        >
                          <Route path="new" element={<OrganizationStageCreatePage />} />
                          <Route path=":stageId" element={<OrganizationStageDetailsPage />} />
                          <Route path=":stageId/edit" element={<OrganizationStageEditPage />} />
                        </Route>
                      </Route>
                      <Route path="candidates" element={<OrganizationCandidatesLayout />}>
                        <Route index element={<EmptyPage />} />
                        <Route path="new" element={<OrganizationCandidateCreatePage />} />
                        <Route path=":candidateId" element={<OrganizationCandidateDetailsPage />} />
                        <Route path=":candidateId/edit" element={<OrganizationCandidateEditPage />} />
                        <Route path=":candidateId/interviews/new" element={<OrganizationCandidateInterviewCreatePage />} />
                      </Route>
                      <Route path="experts" element={<OrganizationExpertsLayout />}>
                        <Route index element={<EmptyPage />} />
                        <Route path="new" element={<OrganizationExpertCreatePage />} />
                        <Route path=":expertId" element={<OrganizationExpertDetailsPage />} />
                        <Route path=":expertId/edit" element={<OrganizationExpertEditPage />} />
                      </Route>
                      <Route path="skills" element={<OrganizationSkillsLayout />}>
                        <Route index element={<EmptyPage />} />
                        <Route
                          element={(
                            <CheckSubscriptionLayout
                              fallback={<OrganizationSkillSubscriptionPage />}
                              min={OrganizationPlatformTier.MARS}
                            />
                          )}
                        >
                          <Route path="new" element={<OrganizationSkillCreatePage />} />
                        </Route>
                        <Route path=":skillId" element={<OrganizationSkillDetailsPage />} />
                        <Route path=":skillId/edit" element={<OrganizationSkillEditPage />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<CheckUserLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
          <Route path="call/:interviewId" element={<InterviewPage />} />
        </Route>

        <Route element={<CheckUserLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
          <Route element={<CheckUserSensitiveLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
            <Route element={<CheckOrganizationLayout fallback={<NavigateWithNext to="signup-customer" />} />}>
              <Route path="organizations/:organizationId/subscribe-start" element={<OrganizationSubscribeStartPage />} />
              <Route path="organizations/:organizationId/subscribe-redirect" element={<OrganizationSubscribeRedirectPage />} />
              <Route path="organizations/:organizationId/deel-auth" element={<OrganizationDeelAuthPage />} />
              <Route path="organizations/:organizationId/interviews/:interviewId/playback" element={<PlaybackPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="deel-redirect" element={<OrganizationDeelRedirectPage />} />
        <Route path="ms-auth" element={<UserMicrosoftAuthPage />} />
        <Route path="ms-redirect" element={<UserMicrosoftRedirectPage />} />
      </Route>

      <Route element={<CheckNoAuthLayout />}>
        <Route element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Route>

      <Route path="scores/:scoreId" element={<ScorePage />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  </BrowserRouter>
);

export default Navigation;
