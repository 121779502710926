import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  candidateId: string;
  expertIds: string[];
  skillIds: string[];
  start: number;
};

export type ResponseData = string;

const useInterviewCreate = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'interview-create'),
    [functions],
  );
};

export default useInterviewCreate;
