import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { InterviewDoc, InterviewStatus } from '../types/Interview';

const useInterviewStatusColor = (interviewSnap: QueryDocumentSnapshot<InterviewDoc>) => useMemo(
  () => {
    const interview = interviewSnap.data();

    switch (interview.status) {
      case InterviewStatus.CREATED:
        return 'cf.status.created';
      case InterviewStatus.STARTED:
        return 'cf.status.started';
      case InterviewStatus.ENDED:
        return 'cf.status.ended';
      case InterviewStatus.CANCELED:
      default:
        return 'cf.status.canceled';
    }
  },
  [interviewSnap],
);

export default useInterviewStatusColor;
