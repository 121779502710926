import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import CallLobbyJoinButton from '../../../../components/CallLobbyJoinButton';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const CallActionMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return (
    <CallLobbyJoinButton
      isDisabled={interview.status === 'ENDED'}
    />
  );
};

const CallActionCatchFallback: React.FC = () => null;
const CallActionSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallAction: React.FC = () => (
  <Catch fallback={<CallActionCatchFallback />}>
    <Suspense fallback={<CallActionSuspenseFallback />}>
      <CallActionMain />
    </Suspense>
  </Catch>
);

export default CallAction;
