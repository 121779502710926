import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  code: string;
  state: string;
};

export type ResponseData = void;

const useDeelGetAccessToken = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'deel-getaccesstoken'),
    [functions],
  );
};

export default useDeelGetAccessToken;
