import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { ExpertDoc, ExpertStatus } from '../types/Expert';

const useExpertStatusColor = (expertSnap: QueryDocumentSnapshot<ExpertDoc>) => useMemo(
  () => {
    const expert = expertSnap.data();

    if (!expert.userRef && expert.status === ExpertStatus.PAUSED) {
      return 'cf.status.empty';
    }

    switch (expert.status) {
      case ExpertStatus.ACTIVE:
        return 'cf.status.started';
      case ExpertStatus.PAUSED:
        return 'cf.status.canceled';
      case ExpertStatus.ARCHIVED:
      default:
        return 'cf.status.archived';
    }
  },
  [expertSnap],
);

export default useExpertStatusColor;
