import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const VideoOnIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 6C1 4.34315 2.34314 3 4 3H11C12.418 3 13.6062 3.98382 13.9194 5.30612L16.3675 4.49006C17.6626 4.05837 19 5.02231 19 6.38743V13.6126C19 14.9777 17.6626 15.9416 16.3675 15.5099L13.9687 14.7103C13.7956 15.8886 12.9147 17 11.5714 17H3.42857C1.92434 17 1 15.6063 1 14.2857V6ZM12 6C12 5.44772 11.5523 5 11 5H4C3.44772 5 3 5.44772 3 6V14.2857C3 14.5374 3.0855 14.7363 3.18664 14.8577C3.286 14.9769 3.37587 15 3.42857 15H11.5714C11.6241 15 11.714 14.9769 11.8134 14.8577C11.9145 14.7363 12 14.5374 12 14.2857V6ZM17 6.38743L14 7.38743L14 12.6126L17 13.6126V6.38743Z" fill="currentColor" />
  </Icon>
);

export default VideoOnIcon;
