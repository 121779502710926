import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  email: string;
  url: string;
};

export type ResponseData = void;

const useAuthSendSignInLinkToEmail = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'auth-sendsigninlinktoemail'),
    [functions],
  );
};

export default useAuthSendSignInLinkToEmail;
