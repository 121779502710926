import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import useMinPlatformTier from '../../hooks/useMinPlatformTier';
import { OrganizationPlatformTier } from '../../types/OrganizationCustomerProfile';

export type CheckOrganizationProps = {
  min: OrganizationPlatformTier;
  fallback: React.ReactElement;
};

const CheckSubscriptionLayoutMain: React.FC<CheckOrganizationProps> = ({ fallback, min }) => {
  const available = useMinPlatformTier(min);

  if (!available) {
    return fallback;
  }

  return (
    <Outlet />
  );
};

export const CheckSubscriptionLayoutCatchFallback: React.FC = () => null;
export const CheckSubscriptionLayoutSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CheckSubscriptionLayout: React.FC<CheckOrganizationProps> = (props) => (
  <Catch fallback={<CheckSubscriptionLayoutCatchFallback />}>
    <Suspense fallback={<CheckSubscriptionLayoutSuspenseFallback />}>
      <CheckSubscriptionLayoutMain {...props} />
    </Suspense>
  </Catch>
);

export default CheckSubscriptionLayout;
