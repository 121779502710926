import {
  Box,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirestoreDoc } from 'reactfire';
import { SkillDoc } from '../types/Skill';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export interface Timeline {
  skillRef: DocumentReference<SkillDoc>;
  duration: number;
  total: number;
  complete: number;
}

export type Props = Timeline;

const CallScheduleItem: React.FC<Props> = ({
  skillRef,
  duration,
  total,
  complete,
}) => {
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);
  const { t } = useTranslation();

  const formatMinutes = useCallback(
    (v: number) => new Intl.NumberFormat('en-US', {
      style: 'unit',
      unitDisplay: 'short',
      unit: 'minute',
      maximumFractionDigits: 1,
    }).format(v),
    [],
  );

  return (
    <Box>
      <VStack
        alignItems="stretch"
        spacing={0}
        overflow="visible"
      >
        <HStack spacing={1}>
          <Text
            fontSize="sm"
            lineHeight="short"
            fontWeight="semibold"
            whiteSpace="nowrap"
          >
            {skill.name}
          </Text>

          <Text
            fontSize="sm"
            lineHeight="short"
            color="cf.cntSecondary"
            whiteSpace="nowrap"
          >
            (
            {formatMinutes(duration)}
            )
          </Text>
        </HStack>

        <Text
          fontSize="xs"
          lineHeight="shorter"
          color="cf.cntSecondary"
          whiteSpace="nowrap"
        >
          {t('interviewPage.schedule.skillTimeline.questions', { count: total, current: complete })}
        </Text>
      </VStack>
    </Box>
  );
};

export default CallScheduleItem;
