import { HStack, IconButton, Text } from '@chakra-ui/react';
import { QueryDocumentSnapshot, deleteDoc, doc } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc, useUser } from 'reactfire';
import Catch from '../../components/Catch';
import UserAvatar from '../../components/UserAvatar';
import TrashIcon from '../../icons/TrashIcon';
import { MemberDoc } from '../../types/Member';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import { useUsersCollectionRef } from '../../types/User';

export type Props = {
  memberSnap: QueryDocumentSnapshot<MemberDoc>;
};

const MemberItemMain: React.FC<Props> = ({ memberSnap }) => {
  const usersCollectionRef = useUsersCollectionRef();

  const userRef = useMemo(
    () => doc(usersCollectionRef, memberSnap.id),
    [memberSnap.id, usersCollectionRef],
  );

  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const { data: authUser } = useUser();

  const handleRemoveMemberClick = useCallback(
    async () => {
      if (userSnap.id === authUser?.uid) {
        // can't remove self
        return;
      }

      await deleteDoc(memberSnap.ref);
    },
    [authUser?.uid, memberSnap.ref, userSnap.id],
  );

  const user = useMemo(() => userSnap.data(), [userSnap]);

  return (
    <HStack>
      <UserAvatar userRef={userRef} size="xs" />
      <Text variant="labelMedium" flexGrow={1}>
        {user.firstName}
        {' '}
        {user.lastName}

        {authUser?.uid === userSnap.id ? (
          <Text as="span" color="cf.cntSecondary" fontStyle="italic">
            {' '}
            (you)
          </Text>
        ) : null}
      </Text>

      <IconButton
        aria-label="Remove"
        colorScheme="negative"
        icon={<TrashIcon />}
        isDisabled={authUser?.uid === userSnap.id}
        onClick={handleRemoveMemberClick}
      />
    </HStack>
  );
};

export const MemberItemCatchFallback: React.FC = () => null;
export const MemberItemSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const MemberItem: React.FC<Props> = (props) => (
  <Catch fallback={<MemberItemCatchFallback />}>
    <Suspense fallback={<MemberItemSuspenseFallback />}>
      <MemberItemMain {...props} />
    </Suspense>
  </Catch>
);

export default MemberItem;
