import { useMemo } from 'react';

const usePluralized = (count: number, one: string, other: string): string => useMemo(() => {
  switch (new Intl.PluralRules('en-US').select(count)) {
    case 'one':
      return `${count} ${one}`;
    case 'other':
    default:
      return `${count} ${other}`;
  }
}, [count, one, other]);

export default usePluralized;
