import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { AvatarDoc } from './Avatar';

export type UserDoc = {
  firstName: string;
  lastName: string;
  avatarRef?: DocumentReference<AvatarDoc>;
  createdAt: Timestamp;
};

export const isUserDoc = (
  data?: DocumentData,
): data is UserDoc => true;

export const isUserRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserDoc> => ref.parent.id === 'users';

export const isUserSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<UserDoc> => isUserRef(snap.ref);

export const useUsersCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'users') as CollectionReference<UserDoc>, [firestore]);
};

export const useUserDocRef = (docId: string) => {
  const collectionRef = useUsersCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
