import { IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import Spinner from '../icons/Spinner';
import VideoOffIcon from '../icons/VideoOffIcon';
import VideoOnIcon from '../icons/VideoOnIcon';
import { useLocalCamera } from './LocalCameraProvider';

export interface Props {
  localCameraTrack: LocalVideoTrack;
}

const LocalCameraButton: React.FC = () => {
  const {
    track, on, off, loading,
  } = useLocalCamera();

  const handleClick = useCallback(() => {
    if (track) {
      off();
    } else {
      on();
    }
  }, [on, off, track]);

  return (
    <IconButton
      aria-label="Video"
      h="44px"
      w="44px"
      isRound
      colorScheme="blackAlpha"
      icon={track ? <VideoOnIcon /> : <VideoOffIcon />}
      onClick={handleClick}
      isLoading={loading}
      spinner={<Spinner />}
    />
  );
};

export default LocalCameraButton;
