import { Button, ButtonGroup, VStack } from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Easing } from '../../../helpers/ease';

export type Props = {
  name: string;
};

const EasingField: React.FC<Props> = ({ name }) => {
  const [field, , helper] = useField<Easing>({ name });

  const isInActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_CIRC,
      Easing.EASE_IN_CUBIC,
      Easing.EASE_IN_EXPO,
      Easing.EASE_IN_QUAD,
      Easing.EASE_IN_QUART,
      Easing.EASE_IN_QUINT,
      Easing.EASE_IN_SINE,
    ], field.value),
    [field.value],
  );

  const isInOutActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_OUT_CIRC,
      Easing.EASE_IN_OUT_CUBIC,
      Easing.EASE_IN_OUT_EXPO,
      Easing.EASE_IN_OUT_QUAD,
      Easing.EASE_IN_OUT_QUART,
      Easing.EASE_IN_OUT_QUINT,
      Easing.EASE_IN_OUT_SINE,
      Easing.LINEAR,
    ], field.value),
    [field.value],
  );

  const isOutActive = useMemo(
    () => _.includes([
      Easing.EASE_OUT_CIRC,
      Easing.EASE_OUT_CUBIC,
      Easing.EASE_OUT_EXPO,
      Easing.EASE_OUT_QUAD,
      Easing.EASE_OUT_QUART,
      Easing.EASE_OUT_QUINT,
      Easing.EASE_OUT_SINE,
    ], field.value),
    [field.value],
  );

  const isCircActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_CIRC,
      Easing.EASE_IN_OUT_CIRC,
      Easing.EASE_OUT_CIRC,
    ], field.value),
    [field.value],
  );

  const isCubicActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_CUBIC,
      Easing.EASE_IN_OUT_CUBIC,
      Easing.EASE_OUT_CUBIC,
    ], field.value),
    [field.value],
  );

  const isExpoActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_EXPO,
      Easing.EASE_IN_OUT_EXPO,
      Easing.EASE_OUT_EXPO,
    ], field.value),
    [field.value],
  );

  const isQuadActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_QUAD,
      Easing.EASE_IN_OUT_QUAD,
      Easing.EASE_OUT_QUAD,
    ], field.value),
    [field.value],
  );

  const isQuartActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_QUART,
      Easing.EASE_IN_OUT_QUART,
      Easing.EASE_OUT_QUART,
    ], field.value),
    [field.value],
  );

  const isQuintActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_QUINT,
      Easing.EASE_IN_OUT_QUINT,
      Easing.EASE_OUT_QUINT,
    ], field.value),
    [field.value],
  );

  const isSineActive = useMemo(
    () => _.includes([
      Easing.EASE_IN_SINE,
      Easing.EASE_IN_OUT_SINE,
      Easing.EASE_OUT_SINE,
    ], field.value),
    [field.value],
  );

  const isLinearActive = useMemo(
    () => _.includes([
      Easing.LINEAR,
    ], field.value),
    [field.value],
  );

  const handleInClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_OUT_CIRC,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_CIRC);
      }

      if (_.includes([
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_OUT_CUBIC,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_CUBIC);
      }

      if (_.includes([
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_OUT_EXPO,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_EXPO);
      }

      if (_.includes([
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_OUT_QUAD,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_QUAD);
      }

      if (_.includes([
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_OUT_QUART,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_QUART);
      }

      if (_.includes([
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_OUT_QUINT,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_QUINT);
      }

      if (_.includes([
        Easing.EASE_IN_SINE,
        Easing.EASE_IN_OUT_SINE,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_SINE);
      }
    },
    [field.value, helper],
  );

  const handleInOutClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_OUT_CIRC,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_CIRC);
      }

      if (_.includes([
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_OUT_CUBIC,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_CUBIC);
      }

      if (_.includes([
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_OUT_EXPO,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_EXPO);
      }

      if (_.includes([
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_OUT_QUAD,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_QUAD);
      }

      if (_.includes([
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_OUT_QUART,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_QUART);
      }

      if (_.includes([
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_OUT_QUINT,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_QUINT);
      }

      if (_.includes([
        Easing.EASE_IN_SINE,
        Easing.EASE_IN_OUT_SINE,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_SINE);
      }
    },
    [field.value, helper],
  );

  const handleOutClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_OUT_CIRC,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_CIRC);
      }

      if (_.includes([
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_OUT_CUBIC,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_CUBIC);
      }

      if (_.includes([
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_OUT_EXPO,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_EXPO);
      }

      if (_.includes([
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_OUT_QUAD,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_QUAD);
      }

      if (_.includes([
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_OUT_QUART,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_QUART);
      }

      if (_.includes([
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_OUT_QUINT,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_QUINT);
      }

      if (_.includes([
        Easing.EASE_IN_SINE,
        Easing.EASE_IN_OUT_SINE,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_SINE);
      }
    },
    [field.value, helper],
  );

  const handleCircClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_CIRC);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_CIRC);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_CIRC);
      }
    },
    [field.value, helper],
  );

  const handleCubicClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_CUBIC);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_CUBIC);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_CUBIC);
      }
    },
    [field.value, helper],
  );

  const handleExpoClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_EXPO);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_EXPO);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_EXPO);
      }
    },
    [field.value, helper],
  );

  const handleQuadClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_QUAD);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_QUAD);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_QUAD);
      }
    },
    [field.value, helper],
  );

  const handleQuartClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_QUART);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_QUART);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_QUART);
      }
    },
    [field.value, helper],
  );

  const handleQuintClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_QUINT);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_QUINT);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_QUINT);
      }
    },
    [field.value, helper],
  );

  const handleSineClick = useCallback(
    () => {
      if (_.includes([
        Easing.EASE_IN_CIRC,
        Easing.EASE_IN_CUBIC,
        Easing.EASE_IN_EXPO,
        Easing.EASE_IN_QUAD,
        Easing.EASE_IN_QUART,
        Easing.EASE_IN_QUINT,
        Easing.EASE_IN_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_SINE);
      }

      if (_.includes([
        Easing.EASE_IN_OUT_CIRC,
        Easing.EASE_IN_OUT_CUBIC,
        Easing.EASE_IN_OUT_EXPO,
        Easing.EASE_IN_OUT_QUAD,
        Easing.EASE_IN_OUT_QUART,
        Easing.EASE_IN_OUT_QUINT,
        Easing.EASE_IN_OUT_SINE,
        Easing.LINEAR,
      ], field.value)) {
        helper.setValue(Easing.EASE_IN_OUT_SINE);
      }

      if (_.includes([
        Easing.EASE_OUT_CIRC,
        Easing.EASE_OUT_CUBIC,
        Easing.EASE_OUT_EXPO,
        Easing.EASE_OUT_QUAD,
        Easing.EASE_OUT_QUART,
        Easing.EASE_OUT_QUINT,
        Easing.EASE_OUT_SINE,
      ], field.value)) {
        helper.setValue(Easing.EASE_OUT_SINE);
      }
    },
    [field.value, helper],
  );

  const handleLinearClick = useCallback(
    () => {
      helper.setValue(Easing.LINEAR);
    },
    [helper],
  );

  return (
    <VStack spacing={1} alignItems="stretch">

      <ButtonGroup isAttached variant="outline">
        <Button isActive={isInActive} onClick={handleInClick}>
          IN
        </Button>

        <Button isActive={isInOutActive} onClick={handleInOutClick}>
          IN_OUT
        </Button>

        <Button isActive={isOutActive} onClick={handleOutClick}>
          OUT
        </Button>
      </ButtonGroup>

      <ButtonGroup isAttached variant="outline">
        <Button isActive={isLinearActive} onClick={handleLinearClick}>
          LINEAR
        </Button>

        <Button isActive={isSineActive} onClick={handleSineClick}>
          SINE
        </Button>

        <Button isActive={isQuadActive} onClick={handleQuadClick}>
          QUAD
        </Button>

        <Button isActive={isCubicActive} onClick={handleCubicClick}>
          CUBIC
        </Button>

        <Button isActive={isCircActive} onClick={handleCircClick}>
          CIRC
        </Button>

        <Button isActive={isQuartActive} onClick={handleQuartClick}>
          QUART
        </Button>

        <Button isActive={isQuintActive} onClick={handleQuintClick}>
          QUINT
        </Button>

        <Button isActive={isExpoActive} onClick={handleExpoClick}>
          EXPO
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

export default EasingField;
