import {
  AvatarBadge,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import MemberAvatar from './MemberAvatar';
import PaddingBlock from './PaddingBlock';

const CallLobbyIntervieweeInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  if (!interview.intervieweeRef) {
    throw new Error();
  }

  const { data: intervieweeSnap } = useFirestoreDoc(interview.intervieweeRef);

  if (!intervieweeSnap.exists()) {
    throw new SnapNotFoundError(intervieweeSnap);
  }

  const interviewee = useMemo(() => intervieweeSnap.data(), [intervieweeSnap]);

  const intervieweeIsConnected = useMemo(
    () => _.some(interview.connectedUserRefs, (ref) => ref.id === interview.intervieweeRef?.id),
    [interview.connectedUserRefs, interview.intervieweeRef?.id],
  );

  return (
    <PaddingBlock>
      <HStack spacing={3} alignItems="center">
        <MemberAvatar
          userRef={interview.intervieweeRef}
        >
          {intervieweeIsConnected ? (<AvatarBadge />) : null}
        </MemberAvatar>

        <VStack spacing={1} alignItems="stretch" flexGrow={1}>
          <Text
            fontWeight="medium"
            fontSize="sm"
            lineHeight="shorter"
            color="cf.cntTertiary"
            pt="1px"
            pb="3px"
          >
            Interviewee
          </Text>

          <Text variant="labelMedium">
            {interviewee.firstName}
            {' '}
            {interviewee.lastName}
          </Text>
        </VStack>
      </HStack>
    </PaddingBlock>
  );
};

const CallLobbyIntervieweeInfoCatchFallback: React.FC = () => null;
const CallLobbyIntervieweeInfoSuspenseFallback: React.FC = () => (
  <PaddingBlock>
    <HStack spacing={3} alignItems="center">
      <SkeletonCircle size="36px" />

      <VStack spacing={0} alignItems="stretch">
        <Skeleton h="20px" w="100px" pt="1px" />
        <Skeleton h="24px" w="140px" pt="1px" pb="2px" />
      </VStack>
    </HStack>
  </PaddingBlock>
);

/* eslint-disable react/jsx-props-no-spreading */
const CallLobbyIntervieweeInfo: React.FC = () => (
  <Catch fallback={<CallLobbyIntervieweeInfoCatchFallback />}>
    <Suspense fallback={<CallLobbyIntervieweeInfoSuspenseFallback />}>
      <CallLobbyIntervieweeInfoMain />
    </Suspense>
  </Catch>
);

export default CallLobbyIntervieweeInfo;
