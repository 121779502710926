import { AspectRatio, Box, Center } from '@chakra-ui/react';
import {
  DocumentReference,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import { useCandidateRef } from '../../../../components/CandidateRefProvider';
import Catch from '../../../../components/Catch';
import ScoreSpiderChart from '../../../../components/ScoreSpiderChart';
import { useScoresCollectionRef } from '../../../../types/Score';
import { SkillDoc } from '../../../../types/Skill';

export type Props = {
  skillRefs: DocumentReference<SkillDoc>[];
};

const ScoreChartItemMain: React.FC<Props> = ({ skillRefs }) => {
  const candidateRef = useCandidateRef();
  const scoresCollectionRef = useScoresCollectionRef();

  const { data: scoresSnap } = useFirestoreCollection(
    query(
      scoresCollectionRef,
      where('candidateRef', '==', candidateRef),
      orderBy('score', 'desc'),
    ),
  );

  const items: Array<{ skillRef: DocumentReference<SkillDoc>; score?: number; }> = useMemo(
    () => skillRefs.map(
      (skillRef) => ({
        skillRef,
        score: scoresSnap.docs.reduce<number | undefined>(
          (memo, scoreSnap) => {
            const score = scoreSnap.data();
            if (score.skillRef.path === skillRef.path) {
              if (memo === undefined || score.score > memo) {
                return score.score;
              }
            }

            return memo;
          },
          undefined,
        ),
      }),
    ),
    [scoresSnap.docs, skillRefs],
  );

  if (items.length <= 2) {
    return null;
  }

  return (
    <Center>
      <AspectRatio
        borderRadius="100%"
        background="cf.bgPrimary"
        boxShadow="inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)"
        h="100%"
        w="100%"
        maxH="360px"
        maxW="360px"
        minH="100px"
        minW="100px"
        ratio={1}
      >
        <Box p={1}>
          <ScoreSpiderChart
            items={items}
            h="100%"
            w="100%"
          />
        </Box>
      </AspectRatio>
    </Center>
  );
};

export const ScoreChartItemSuspenseFallback: React.FC = () => null;

export const ScoreChartItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ScoreChartItem: React.FC<Props> = (props) => (
  <Catch fallback={<ScoreChartItemCatchFallback />}>
    <Suspense fallback={<ScoreChartItemSuspenseFallback />}>
      <ScoreChartItemMain {...props} />
    </Suspense>
  </Catch>
);

export default ScoreChartItem;
