import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { SkillDoc } from '../../types/Skill';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import Catch from '../Catch';
import AssetImage from './AssetImage';

export type SkillLogoProps = Omit<React.SVGProps<SVGImageElement>, 'href'> & {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillLogoMain: React.FC<SkillLogoProps> = ({ skillRef, ...imageProps }) => {
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  if (!skill.logoRef) {
    return null;
  }

  return (
    <AssetImage
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
      assetRef={skill.logoRef}
    />
  );
};

export const SkillLogoCatchFallback: React.FC = () => null;
export const SkillLogoSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillLogo: React.FC<SkillLogoProps> = (props) => (
  <Catch fallback={<SkillLogoCatchFallback />}>
    <Suspense fallback={<SkillLogoSuspenseFallback />}>
      <SkillLogoMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillLogo;
