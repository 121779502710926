import { Container } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import SkillInfo, { SkillInfoSuspenseFallback } from '../../../components/SkillInfo';
import { useSkillRef } from '../../../components/SkillRefContext';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const HeaderMain: React.FC = () => {
  const skillRef = useSkillRef();

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  return (
    <Container py={2}>
      <SkillInfo skillSnap={skillSnap} />
    </Container>
  );
};

export const HeaderSuspenseFallback: React.FC = () => (
  <Container py={2}>
    <SkillInfoSuspenseFallback />
  </Container>
);

const HeaderCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Header: React.FC = () => (
  <Catch fallback={<HeaderCatchFallback />}>
    <Suspense fallback={<HeaderSuspenseFallback />}>
      <HeaderMain />
    </Suspense>
  </Catch>
);

export default Header;
