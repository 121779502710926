import { Text, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../../../components/BlockList';
import Catch from '../../../../components/Catch';
import { useSkillRef } from '../../../../components/SkillRefContext';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';
import SkillItem, { SkillItemSuspenseFallback } from './SkillItem';

const SkillsMain: React.FC = () => {
  const skillRef = useSkillRef();

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  if (!skill.dependsOnRefs?.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Skills">
      <Text
        pt="1px"
        pb="3px"
        color="cf.cntTertiary"
        fontSize="sm"
        lineHeight="short"
        fontWeight="medium"
      >
        Depends on
        {' '}
        &middot;
        {' '}
        {skill.dependsOnRefs.length}
      </Text>

      <BlockList variant="outline">
        {skill.dependsOnRefs.map((dependsOnRef) => (
          <SkillItem key={dependsOnRef.id} skillRef={dependsOnRef} />
        ))}
      </BlockList>
    </VStack>
  );
};

export const SkillsSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Depends on
    </Text>

    <BlockList variant="outline">
      <SkillItemSuspenseFallback />
      <SkillItemSuspenseFallback />
      <SkillItemSuspenseFallback />
    </BlockList>
  </VStack>
);

const SkillsCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Skills: React.FC = () => (
  <Catch fallback={<SkillsCatchFallback />}>
    <Suspense fallback={<SkillsSuspenseFallback />}>
      <SkillsMain />
    </Suspense>
  </Catch>
);

export default Skills;
