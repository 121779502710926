import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { StageDoc } from '../types/Stage';

const StageRefContext = React
  .createContext<DocumentReference<StageDoc> | undefined>(undefined);

export const useStageRef = (): DocumentReference<StageDoc> => {
  const stageRef = useContext(StageRefContext);
  if (!stageRef) {
    throw new Error('useStageRef needs to be wrapped with StageRefProvider');
  }
  return stageRef;
};

export type Props = {
  stageRef: DocumentReference<StageDoc>;
};

const StageRefProvider: React.FC<PropsWithChildren<Props>> = ({
  stageRef,
  children,
}) => (
  <StageRefContext.Provider value={stageRef} key={stageRef.path}>
    {children}
  </StageRefContext.Provider>
);

export default StageRefProvider;
