function shallowEqualArrays(arrA: unknown[], arrB: unknown[]): boolean {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  const len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i += 1) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
}

interface PromiseCache {
  promise?: Promise<void>;
  fn: () => Promise<unknown>;
  inputs: Array<unknown>;
  error?: unknown;
  response?: unknown;
}

const promiseCaches: PromiseCache[] = [];

const usePromise = <T, I extends Array<unknown>>(
  fn: (...inputs: I) => Promise<T>,
  inputs: I,
): T => {
  for (const promiseCache of promiseCaches) {
    if (shallowEqualArrays(inputs, promiseCache.inputs) && fn === promiseCache.fn) {
      // If an error occurred,
      if (Object.prototype.hasOwnProperty.call(promiseCache, 'error')) {
        throw promiseCache.error;
      }

      // If a response was successful,
      if (Object.prototype.hasOwnProperty.call(promiseCache, 'response')) {
        return promiseCache.response as T;
      }
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw promiseCache.promise;
    }
  }

  // The request is new or has changed.
  const promiseCache: PromiseCache = {
    fn,
    promise:
      // Make the promise request.
      fn(...inputs)
        .then((response: unknown) => {
          promiseCache.response = response;
        })
        .catch((e: unknown) => {
          promiseCache.error = e;
        }),
    inputs,
  };
  promiseCaches.push(promiseCache);

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw promiseCache.promise;
};

export default usePromise;
