import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import {
  DocumentReference,
  arrayRemove,
  doc,
  setDoc,
} from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import { useExpertRef } from '../../../components/ExpertRefContext';
import UserAvatar from '../../../components/UserAvatar';
import { MemberDoc } from '../../../types/Member';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { useUsersCollectionRef } from '../../../types/User';

export type Props = {
  assigneeRef: DocumentReference<MemberDoc>;
};

const AssigneeTagMain: React.FC<Props> = ({ assigneeRef }) => {
  const expertRef = useExpertRef();
  const usersCollectionRef = useUsersCollectionRef();

  const userRef = useMemo(
    () => doc(usersCollectionRef, assigneeRef.id),
    [assigneeRef.id, usersCollectionRef],
  );

  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const userDoc = useMemo(() => userSnap.data(), [userSnap]);

  const onClick = useCallback(() => {
    setDoc(expertRef, {
      assigneeRefs: arrayRemove(assigneeRef),
    }, { merge: true });
  }, [assigneeRef, expertRef]);

  return (
    <Tag
      color="cf.cntPrimary"
      boxShadow="inset 0 0 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)"
      variant="outline"
      size="lg"
      h={9}
    >
      <UserAvatar
        userRef={userRef}
        size="xs"
        boxSize={6}
        name={`${userDoc.firstName} ${userDoc.lastName}`}
        ml={-1}
        mr={2}
      />

      <TagLabel>
        {userDoc.firstName}
        {' '}
        {userDoc.lastName}
      </TagLabel>

      <TagCloseButton onClick={onClick} />
    </Tag>
  );
};

export const AssigneeTagSuspenseFallback: React.FC = () => null;

const AssigneeTagCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AssigneeTag: React.FC<Props> = (props) => (
  <Catch fallback={<AssigneeTagCatchFallback />}>
    <Suspense fallback={<AssigneeTagSuspenseFallback />}>
      <AssigneeTagMain {...props} />
    </Suspense>
  </Catch>
);

export default AssigneeTag;
