import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const BriefcaseIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2C6.89543 2 6 2.89543 6 4V6H2H1V7V9V15C1 16.6569 2.34315 18 4 18H16C17.6569 18 19 16.6569 19 15V9V7V6H18H14V4C14 2.89543 13.1046 2 12 2H8ZM12 6H8V4H12V6ZM3 15V11.8293C3.31278 11.9398 3.64936 12 4 12H8V13H12V12H16C16.3506 12 16.6872 11.9398 17 11.8293V15C17 15.5523 16.5523 16 16 16H4C3.44772 16 3 15.5523 3 15ZM17 8V9C17 9.55228 16.5523 10 16 10H4C3.44772 10 3 9.55228 3 9V8H17Z" fill="currentColor" />
  </Icon>
);

export default BriefcaseIcon;
