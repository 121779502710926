import {
  Box,
  Container,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import {
  PartialWithFieldValue,
  arrayUnion,
  deleteField,
  doc,
  documentId,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useFirestoreCollection, useFirestoreDoc, useUser } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import OrganizationRefProvider from '../../components/OrganizationRefContext';
import UserRefProvider from '../../components/UserRefContext';
import CheckIcon from '../../icons/CheckIcon';
import AuthError from '../../types/AuthError';
import { getMemberOrganizationRef, useMembersCollectionGroupRef } from '../../types/Member';
import { OrganizationDoc, useOrganizationsCollectionRef } from '../../types/Organization';
import { UserDoc, useUsersCollectionRef } from '../../types/User';
import { timeSlots, useUserScheduleDocRef } from '../../types/UserSchedule';
import { UserSensitiveDoc, useUserSensitiveDocRef } from '../../types/UserSensitive';
import AvailabilityForm, { FormFields as AvailabilityFormFields } from './AvailabilityForm';
import BriefingScreen from './BriefingScreen';
import CandidatesScreen from './CandidatesScreen';
import ExpertsScreen from './ExpertsScreen';
import LiftoffScreen from './LiftoffScreen';
import MissionScreen from './MissionScreen';
import OrganizationForm, { FormFields as OrganizationFormFields } from './OrganizationForm';
import ProfileForm, { FormFields as ProfileFormFields } from './ProfileForm';

declare global {
  interface Window { lintrk: (event: string, options?: Record<string, unknown>) => void; }
}

const OrganizationSignUpPageMain: React.FC = () => {
  const { data: user } = useUser();

  if (!user) {
    throw new AuthError();
  }

  const { trackEvent } = useIntercom();

  const usersCollectionRef = useUsersCollectionRef();
  const userRef = useMemo(
    () => doc(usersCollectionRef, user.uid),
    [usersCollectionRef, user.uid],
  );
  const userSensitiveRef = useUserSensitiveDocRef(userRef);
  const userScheduleRef = useUserScheduleDocRef(userRef);

  const { data: userSnap } = useFirestoreDoc(userRef);
  const { data: userSensitiveSnap } = useFirestoreDoc(userSensitiveRef);
  const { data: userAvailabilitySnap } = useFirestoreDoc(userScheduleRef);

  const membersCollectionGroupRef = useMembersCollectionGroupRef();

  const { data: membersSnap } = useFirestoreCollection(
    query(
      membersCollectionGroupRef,
      where('uid', '==', userRef.id),
    ),
  );

  const organizationIds = useMemo(
    () => membersSnap.docs.map(
      (memberSnap) => getMemberOrganizationRef(memberSnap.ref).id,
    ),
    [membersSnap.docs],
  );

  const organizationsCollectionRef = useOrganizationsCollectionRef();

  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      organizationsCollectionRef,
      where(documentId(), 'in', organizationIds),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  const organizationSnap = useMemo(() => _.first(organizationsSnap.docs), [organizationsSnap.docs]);

  const { nextStep, prevStep, activeStep } = useSteps({ initialStep: 0 });

  const profileFormInitialValues = useMemo<ProfileFormFields>(
    () => {
      const parts = _.compact((user.displayName || '').split(' '));
      const firstName = _.dropRight(parts).join(' ');
      const lastName = _.last(parts) || '';

      const res: ProfileFormFields = {
        firstName,
        lastName,
        avatarRef: null,
        concent: false,
        location: null,
        phoneNumber: '',
      };

      const userData = userSnap.data();

      if (userSnap.exists() && userData) {
        res.firstName = userData.firstName;
        res.lastName = userData.lastName;
        res.avatarRef = userData.avatarRef || null;
        res.concent = true;
      }

      const userSensitiveData = userSensitiveSnap.data();

      if (userSensitiveSnap.exists() && userSensitiveData) {
        res.location = userSensitiveData.location || null;
        res.phoneNumber = userSensitiveData.phoneNumber || '';
      }

      return res;
    },
    [user.displayName, userSnap, userSensitiveSnap],
  );

  const [briefingComplete, setBriefingComplete] = useState(false);
  const handleBriefingNext = useCallback(
    () => {
      setBriefingComplete(true);
      nextStep();
    },
    [nextStep],
  );

  const [profileComplete, setProfileComplete] = useState(false);
  const handleProfileFormSubmit = useCallback(async ({
    avatarRef,
    firstName,
    lastName,
    location,
    phoneNumber,
  }: ProfileFormFields) => {
    const userData: PartialWithFieldValue<UserDoc> = {
      firstName,
      lastName,
    };

    if (avatarRef) {
      userData.avatarRef = avatarRef;
    }

    if (!userSnap) {
      userData.createdAt = serverTimestamp();
    }

    await setDoc(userRef, userData, { merge: true });

    if (!location) {
      return;
    }

    const userSensitiveData: PartialWithFieldValue<UserSensitiveDoc> = {
      location,
      phoneNumber,
      languages: arrayUnion('en'),
    };

    await setDoc(userSensitiveRef, userSensitiveData, { merge: true });

    trackEvent('clarwis-user-created', {
      firstName,
      lastName,
    });

    setProfileComplete(true);

    nextStep();
  }, [nextStep, trackEvent, userRef, userSnap, userSensitiveRef]);

  const availabilityFormInitialValues = useMemo<AvailabilityFormFields>(
    () => {
      const userAvailabilityData = userAvailabilitySnap.data();

      if (userSensitiveSnap.exists() && userAvailabilityData) {
        return { schedule: userAvailabilityData };
      }

      return {
        schedule: {
          su: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          mo: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          tu: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          we: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          th: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          fr: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
          sa: _.slice(
            timeSlots,
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
            _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
          ),
        },
      };
    },
    [userAvailabilitySnap, userSensitiveSnap],
  );

  const [availabilityComplete, setAvailabilityComplete] = useState(false);
  const handleAvailabilityFormSubmit = useCallback(async ({
    schedule,
  }: AvailabilityFormFields) => {
    await setDoc(userScheduleRef, schedule);
    trackEvent('clarwis-user-availability-created');
    setAvailabilityComplete(true);
    nextStep();
  }, [nextStep, trackEvent, userScheduleRef]);

  const organizationFormInitialValues = useMemo<OrganizationFormFields>(
    () => {
      const organizationData = organizationSnap?.data();

      if (organizationSnap?.exists() && organizationData) {
        return {
          name: organizationData.name || '',
          email: organizationData.email || '',
          logoRef: organizationData.logoRef || null,
        };
      }

      return {
        name: '',
        email: '',
        logoRef: null,
      };
    },
    [organizationSnap],
  );

  const [organizationComplete, setOrganizationComplete] = useState(false);
  const handleOrganizationFormSubmit = useCallback(async ({
    name,
    email,
    logoRef,
  }: OrganizationFormFields) => {
    const organizationData: PartialWithFieldValue<OrganizationDoc> = {
      name,
      email,
    };

    if (logoRef) {
      organizationData.logoRef = logoRef;
    } else {
      organizationData.logoRef = deleteField();
    }

    if (!organizationSnap) {
      organizationData.createdAt = serverTimestamp();
    }

    const organizationRef = organizationSnap
      ? organizationSnap.ref
      : doc(organizationsCollectionRef);

    await setDoc(organizationRef, organizationData, { merge: true });

    if (!organizationSnap) {
      trackEvent('clarwis-organization-created', {
        name,
      });
    }

    setOrganizationComplete(true);

    nextStep();
  }, [organizationsCollectionRef, nextStep, organizationSnap, trackEvent]);

  const { search } = useLocation();
  const next = useMemo(() => new URLSearchParams(search).get('next'), [search]);
  const navigate = useNavigate();

  const [expertsComplete, setExpertsComplete] = useState(false);
  const handleExpertsNext = useCallback(
    () => {
      setExpertsComplete(true);
      nextStep();
    },
    [nextStep],
  );

  const [candidatesComplete, setCandidatesComplete] = useState(false);
  const handleCandidatesNext = useCallback(
    () => {
      setCandidatesComplete(true);
      nextStep();
    },
    [nextStep],
  );

  const [missionComplete, setMissionComplete] = useState(false);
  const handleMissionNext = useCallback(
    () => {
      setMissionComplete(true);
      nextStep();
    },
    [nextStep],
  );

  const handleDoneClick = useCallback(
    () => {
      if (organizationSnap) {
        navigate(next || `/organizations/${organizationSnap.id}`);
      }
    },
    [navigate, next, organizationSnap],
  );

  return (
    <UserRefProvider userRef={userRef}>
      <Container maxW="container.sm">
        <VStack alignItems="stretch" spacing={5} py={3}>
          <Box py={1}>
            <Heading>
              Onboarding
            </Heading>
          </Box>

          <Steps
            activeStep={activeStep}
            colorScheme="accent"
            orientation="vertical"
            checkIcon={CheckIcon}
          >
            <Step
              label="Briefing"
              description="Getting ready for the mission"
              isCompletedStep={briefingComplete}
            >
              <BriefingScreen
                onNext={handleBriefingNext}
              />
            </Step>

            <Step
              label="Profile"
              description="Let's get to know you"
              isCompletedStep={profileComplete}
            >
              <ProfileForm
                initialValues={profileFormInitialValues}
                onSubmit={handleProfileFormSubmit}
                onPrevious={prevStep}
              />
            </Step>

            <Step
              label="Availability"
              description="For interview scheduling"
              isCompletedStep={availabilityComplete}
            >
              <AvailabilityForm
                initialValues={availabilityFormInitialValues}
                onSubmit={handleAvailabilityFormSubmit}
                onPrevious={prevStep}
              />
            </Step>

            <Step
              label="Organization"
              description="Public details of your company"
              isCompletedStep={organizationComplete}
            >
              <OrganizationForm
                initialValues={organizationFormInitialValues}
                onSubmit={handleOrganizationFormSubmit}
                onPrevious={prevStep}
              />
            </Step>

            <Step
              label="Experts"
              description="Who will be interviewing"
              isCompletedStep={expertsComplete}
            >
              {organizationSnap ? (
                <OrganizationRefProvider organizationRef={organizationSnap.ref}>
                  <ExpertsScreen
                    onNext={handleExpertsNext}
                    onPrevious={prevStep}
                  />
                </OrganizationRefProvider>
              ) : null}
            </Step>

            <Step
              label="Candidates"
              description="Who should be interviewed"
              isCompletedStep={candidatesComplete}
            >
              {organizationSnap ? (
                <OrganizationRefProvider organizationRef={organizationSnap.ref}>
                  <CandidatesScreen
                    onNext={handleCandidatesNext}
                    onPrevious={prevStep}
                  />
                </OrganizationRefProvider>
              ) : null}
            </Step>

            <Step
              label="Mission"
              description="Starting the mission"
              isCompletedStep={missionComplete}
            >
              {organizationSnap ? (
                <OrganizationRefProvider organizationRef={organizationSnap.ref}>
                  <MissionScreen
                    onNext={handleMissionNext}
                    onPrevious={prevStep}
                  />
                </OrganizationRefProvider>
              ) : null}
            </Step>

            <Step
              label="Liftoff"
              description="Starting the mission"
              isCompletedStep={false}
            >
              <LiftoffScreen
                onNext={handleDoneClick}
                onPrevious={prevStep}
              />
            </Step>
          </Steps>
        </VStack>
      </Container>
    </UserRefProvider>
  );
};

export const OrganizationSignUpPageCatchFallback: React.FC = () => null;
export const OrganizationSignUpPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationSignUpPage: React.FC = () => (
  <Catch fallback={<OrganizationSignUpPageCatchFallback />}>
    <Suspense fallback={<OrganizationSignUpPageSuspenseFallback />}>
      <OrganizationSignUpPageMain />
    </Suspense>
  </Catch>
);

export default OrganizationSignUpPage;
