import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SettingsIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.38197 1H11.618L12.7275 3.21894L12.8663 3.27643L15.2199 2.49191L17.5081 4.78016L16.7236 7.13373L16.7811 7.2725L19 8.38197V11.618L16.781 12.7275L16.7236 12.8663L17.5081 15.2198L15.2198 17.5081L12.8663 16.7236L12.7275 16.781L11.618 19H8.38197L7.27248 16.781L7.13366 16.7235L4.78012 17.508L2.49188 15.2198L3.2764 12.8662L3.21894 12.7275L1 11.618V8.38197L3.21895 7.27249L3.27643 7.13372L2.49192 4.78016L4.78016 2.49192L7.13372 3.27643L7.27249 3.21895L8.38197 1ZM9.61803 3L8.72751 4.78105L7.20943 5.40986L5.32034 4.78016L4.78016 5.32034L5.40986 7.20943L4.78105 8.72751L3 9.61803V10.382L4.78106 11.2725L5.40981 12.7905L4.78012 14.6796L5.3203 15.2198L7.20941 14.5901L8.72752 15.219L9.61803 17H10.382L11.2725 15.219L12.7906 14.5901L14.6797 15.2198L15.2198 14.6797L14.5901 12.7906L15.219 11.2725L17 10.382V9.61803L15.2189 8.7275L14.5902 7.2094L15.2199 5.32034L14.6797 4.78015L12.7906 5.40985L11.2725 4.78106L10.382 3H9.61803ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9ZM7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10Z" fill="currentColor" />
  </Icon>
);

export default SettingsIcon;
