import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

const DeelLogo: React.FC<BoxProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box as="svg" width="68px" height="24px" viewBox="0 0 68 24" fill="none" color="#15357A" {...props}>
    <path d="M55.748 23.5331C55.6475 23.5331 55.5659 23.4515 55.5659 23.3509V1.41023C55.5659 1.32395 55.6265 1.24951 55.7109 1.23192L59.514 0.440176C59.6271 0.416632 59.7333 0.502966 59.7333 0.618483V23.3509C59.7333 23.4515 59.6517 23.5331 59.5512 23.5331H55.748Z" fill="currentColor" />
    <path d="M7.94405 23.9972C6.4247 23.9972 5.06813 23.6257 3.87435 22.8826C2.68058 22.1395 1.73641 21.1232 1.04184 19.8337C0.347281 18.5443 0 17.08 0 15.4408C0 13.8017 0.347281 12.3483 1.04184 11.0807C1.73641 9.79121 2.68058 8.78586 3.87435 8.06463C5.06813 7.32155 6.4247 6.95001 7.94405 6.95001C9.15954 6.95001 10.2231 7.17949 11.1347 7.63845C11.8654 8.00634 12.4846 8.49358 12.9921 9.10017C13.1072 9.23774 13.3486 9.15993 13.3486 8.98056V1.0157C13.3486 0.929416 13.4092 0.854975 13.4936 0.83739L17.2967 0.0456443C17.4098 0.0221004 17.516 0.108434 17.516 0.223951V23.4217C17.516 23.5223 17.4344 23.6038 17.3339 23.6038H13.9543C13.8673 23.6038 13.7923 23.5421 13.7756 23.4567L13.4293 21.6881C13.3986 21.5317 13.1934 21.4853 13.0917 21.6079C12.6064 22.1934 11.9975 22.7166 11.2649 23.1776C10.4184 23.724 9.31147 23.9972 7.94405 23.9972ZM8.82311 20.3255C10.1688 20.3255 11.2649 19.8775 12.1114 18.9814C12.9796 18.0635 13.4137 16.8942 13.4137 15.4736C13.4137 14.053 12.9796 12.8947 12.1114 11.9986C11.2649 11.0807 10.1688 10.6217 8.82311 10.6217C7.4991 10.6217 6.40299 11.0697 5.53479 11.9658C4.66659 12.8619 4.23249 14.0202 4.23249 15.4408C4.23249 16.8614 4.66659 18.0307 5.53479 18.9486C6.40299 19.8665 7.4991 20.3255 8.82311 20.3255Z" fill="currentColor" />
    <path d="M27.6778 23.9998C26.05 23.9998 24.6066 23.6501 23.3477 22.9508C22.0888 22.2514 21.1012 21.2679 20.3849 20.0003C19.6687 18.7327 19.3105 17.2684 19.3105 15.6074C19.3105 13.9245 19.6578 12.4274 20.3524 11.1161C21.0687 9.80476 22.0454 8.78849 23.2826 8.06726C24.5415 7.32418 26.0174 6.95264 27.7104 6.95264C29.2949 6.95264 30.6949 7.30232 31.9103 8.0017C33.1258 8.70107 34.07 9.6627 34.7428 10.8866C35.4374 12.0886 35.7847 13.4328 35.7847 14.9189C35.7847 15.1593 35.7738 15.4107 35.7521 15.6729C35.7521 15.883 35.7452 16.1001 35.7312 16.3242C35.7253 16.4192 35.646 16.4925 35.5508 16.4925H23.6404C23.5349 16.4925 23.4512 16.582 23.4616 16.687C23.578 17.8623 24.0067 18.7918 24.7477 19.4758C25.5508 20.197 26.5166 20.5576 27.6453 20.5576C28.4918 20.5576 29.1972 20.3718 29.7615 20.0003C30.3137 19.6297 30.7309 19.162 31.0134 18.5974C31.0451 18.5338 31.1094 18.4923 31.1804 18.4923H35.0525C35.1742 18.4923 35.2617 18.6094 35.2235 18.725C34.9137 19.6618 34.428 20.524 33.7661 21.3116C33.0716 22.1421 32.2034 22.7978 31.1615 23.2786C30.1414 23.7594 28.9802 23.9998 27.6778 23.9998ZM27.7104 10.3621C26.6903 10.3621 25.7895 10.6571 25.0081 11.2472C24.2748 11.7805 23.79 12.5833 23.5537 13.6555C23.5293 13.7666 23.6154 13.8699 23.7292 13.8699H31.3587C31.4636 13.8699 31.5472 13.7813 31.5367 13.6769C31.4384 12.6961 31.0528 11.908 30.3801 11.3128C29.6639 10.679 28.774 10.3621 27.7104 10.3621Z" fill="currentColor" />
    <path d="M45.807 23.9998C44.1791 23.9998 42.7357 23.6501 41.4768 22.9508C40.2179 22.2514 39.2304 21.2679 38.5141 20.0003C37.7978 18.7327 37.4397 17.2684 37.4397 15.6074C37.4397 13.9245 37.787 12.4274 38.4815 11.1161C39.1978 9.80476 40.1745 8.78849 41.4117 8.06726C42.6706 7.32418 44.1466 6.95264 45.8396 6.95264C47.424 6.95264 48.824 7.30232 50.0395 8.0017C51.255 8.70107 52.1991 9.6627 52.872 10.8866C53.5666 12.0886 53.9138 13.4328 53.9138 14.9189C53.9138 15.1593 53.903 15.4107 53.8813 15.6729C53.8813 15.883 53.8743 16.1001 53.8604 16.3242C53.8545 16.4192 53.7752 16.4925 53.68 16.4925H41.7695C41.6641 16.4925 41.5804 16.582 41.5908 16.687C41.7072 17.8623 42.1359 18.7918 42.8768 19.4758C43.6799 20.197 44.6458 20.5576 45.7744 20.5576C46.6209 20.5576 47.3264 20.3718 47.8907 20.0003C48.4428 19.6297 48.8601 19.162 49.1425 18.5974C49.1743 18.5338 49.2385 18.4923 49.3096 18.4923H53.1817C53.3034 18.4923 53.3908 18.6094 53.3526 18.725C53.0429 19.6618 52.5571 20.524 51.8953 21.3116C51.2007 22.1421 50.3325 22.7978 49.2907 23.2786C48.2705 23.7594 47.1093 23.9998 45.807 23.9998ZM45.8396 10.3621C44.8194 10.3621 43.9187 10.6571 43.1373 11.2472C42.404 11.7805 41.9192 12.5833 41.6829 13.6555C41.6584 13.7666 41.7445 13.8699 41.8583 13.8699H49.4878C49.5927 13.8699 49.6763 13.7813 49.6659 13.6769C49.5675 12.6961 49.182 11.908 48.5093 11.3128C47.793 10.679 46.9031 10.3621 45.8396 10.3621Z" fill="currentColor" />
    <ellipse cx="65.1944" cy="20.7128" rx="2.80569" ry="2.82512" fill="currentColor" />
  </Box>
);

export default DeelLogo;
