import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { OrganizationDoc } from './Organization';
import { SendGridEventsParentDoc } from './SendGridEvent';

export type OrganizationMemberInvitationDoc = {
  accepted: boolean;
  email: string;
  firstName: string;
  lastName: string;
} & SendGridEventsParentDoc;

export const isOrganizationMemberInvitationDoc = (
  data?: DocumentData,
): data is OrganizationMemberInvitationDoc => true;

export const isOrganizationMemberInvitationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationMemberInvitationDoc> => ref.parent.id === 'memberInvitations';

export const isOrganizationMemberInvitationSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
OrganizationMemberInvitationDoc
> => isOrganizationMemberInvitationRef(snap.ref);

export const useOrganizationMemberInvitationsCollectionRef = (organizationRef: DocumentReference<OrganizationDoc>) => useMemo(() => collection(organizationRef, 'memberInvitations') as CollectionReference<OrganizationMemberInvitationDoc>, [organizationRef]);

export const useOrganizationMemberInvitationDocRef = (
  organizationRef: DocumentReference<OrganizationDoc>,
  docId: string,
) => {
  const collectionRef = useOrganizationMemberInvitationsCollectionRef(organizationRef);
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
