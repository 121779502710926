import { useEffect, useState } from 'react';
import { Participant, RemoteParticipant, Room } from 'twilio-video';

const getParticipants = (
  participants: Map<Participant.SID, RemoteParticipant> | undefined,
): RemoteParticipant[] => {
  if (!participants) {
    return [];
  }

  const res: RemoteParticipant[] = [];

  participants.forEach((participant) => res.push(participant));

  return res;
};

const useParticipants = (room: Room | null): RemoteParticipant[] => {
  const [participants, setParticipants] = useState<RemoteParticipant[]>(
    getParticipants(room?.participants),
  );

  useEffect(() => {
    const updateParticipants = () => {
      setParticipants(getParticipants(room?.participants));
    };

    updateParticipants();

    if (room) {
      room.on('dominantSpeakerChanged', updateParticipants);
      room.on('participantConnected', updateParticipants);
      room.on('participantDisconnected', updateParticipants);
      room.on('participantReconnected', updateParticipants);
      room.on('participantReconnecting', updateParticipants);
      room.on('trackDimensionsChanged', updateParticipants);
      room.on('trackDisabled', updateParticipants);
      room.on('trackEnabled', updateParticipants);
      room.on('trackMessage', updateParticipants);
      room.on('trackPublished', updateParticipants);
      room.on('trackPublishPriorityChanged', updateParticipants);
      room.on('trackStarted', updateParticipants);
      room.on('trackSubscribed', updateParticipants);
      room.on('trackSubscriptionFailed', updateParticipants);
      room.on('trackSwitchedOff', updateParticipants);
      room.on('trackSwitchedOn', updateParticipants);
      room.on('trackUnpublished', updateParticipants);
      room.on('trackUnsubscribed', updateParticipants);

      return () => {
        room.off('dominantSpeakerChanged', updateParticipants);
        room.off('participantConnected', updateParticipants);
        room.off('participantDisconnected', updateParticipants);
        room.off('participantReconnected', updateParticipants);
        room.off('participantReconnecting', updateParticipants);
        room.off('trackDimensionsChanged', updateParticipants);
        room.off('trackDisabled', updateParticipants);
        room.off('trackEnabled', updateParticipants);
        room.off('trackMessage', updateParticipants);
        room.off('trackPublished', updateParticipants);
        room.off('trackPublishPriorityChanged', updateParticipants);
        room.off('trackStarted', updateParticipants);
        room.off('trackSubscribed', updateParticipants);
        room.off('trackSubscriptionFailed', updateParticipants);
        room.off('trackSwitchedOff', updateParticipants);
        room.off('trackSwitchedOn', updateParticipants);
        room.off('trackUnpublished', updateParticipants);
        room.off('trackUnsubscribed', updateParticipants);
      };
    }

    return () => { };
  }, [room, setParticipants]);

  return participants;
};

export default useParticipants;
