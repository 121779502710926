import React from 'react';
import { useParams } from 'react-router';
import StageRefProvider from '../../components/StageRefProvider';
import { useStageDocRef } from '../../types/Stage';
import EditStageScreen from './EditStageScreen';

export type Params = {
  stageId: string;
  organizationId: string;
};

const OrganizationStageEditPage: React.FC = () => {
  const { stageId } = useParams<Params>();

  if (!stageId) {
    throw new Error();
  }

  const stageRef = useStageDocRef(stageId);

  return (
    <StageRefProvider stageRef={stageRef} key={stageRef.path}>
      <EditStageScreen />
    </StageRefProvider>
  );
};

export default OrganizationStageEditPage;
