import { QueryDocumentSnapshot } from 'firebase/firestore';
import moment, { Duration } from 'moment';
import React, { Suspense, useMemo } from 'react';
import useTimezone from '../../hooks/useTimezone';
import { TwilioCompositionDoc, TwilioCompositionStatus } from '../../types/TwilioComposition';
import Catch from '../Catch';

export interface Props {
  twilioCompositionSnap: QueryDocumentSnapshot<TwilioCompositionDoc>;
}

const CompositionMain: React.FC<Props> = ({ twilioCompositionSnap }: Props) => {
  const timezone = useTimezone();
  const twilioComposition = useMemo(() => twilioCompositionSnap.data(), [twilioCompositionSnap]);

  const duration = useMemo<Duration | null>(() => {
    if (!twilioComposition) {
      return null;
    }

    return moment.duration(
      twilioComposition.end.toMillis() - twilioComposition.start.toMillis(),
      'milliseconds',
    );
  }, [twilioComposition]);

  switch (twilioComposition.status) {
    case TwilioCompositionStatus.ENQUEUED:
      return (
        <>
          Enqueued
        </>
      );
    case TwilioCompositionStatus.PROCESSING:
      return (
        <>
          Processing
          {' '}
          {Math.round(twilioComposition.progress || 0 * 100)}
          %
        </>
      );
    case TwilioCompositionStatus.COMPLETED:
      return (
        <>
          Processing complete
        </>
      );
    case TwilioCompositionStatus.UPLOADING:
      return (
        <>
          Uploading
          {' '}
          {Math.round(twilioComposition.progress || 0 * 100)}
          %
        </>
      );
    default:
      return (
        <>
          {moment(twilioComposition.start.toDate()).tz(timezone).format('MMM M, LT')}
          {' '}
          &middot;
          {' '}
          {duration ? duration.humanize() : null}
        </>
      );
  }
};

export const CompositionCatchFallback: React.FC = () => null;
export const CompositionSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Composition: React.FC<Props> = (props) => (
  <Catch fallback={<CompositionCatchFallback />}>
    <Suspense fallback={<CompositionSuspenseFallback />}>
      <CompositionMain {...props} />
    </Suspense>
  </Catch>
);

export default Composition;
