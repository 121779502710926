import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { SkillDoc } from './Skill';
import { UserDoc, isUserRef } from './User';

export type UserAvailabilityDoc = {
  available: string[];
  skillRefs: DocumentReference<SkillDoc>[];
};

export const isUserAvailabilityDoc = (
  data: DocumentData,
): data is UserAvailabilityDoc => true;

export const isUserAvailabilityRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserAvailabilityDoc> => ref.id === 'availability' && ref.parent.id === 'private' && ref.parent.parent !== null && isUserRef(ref.parent.parent);

export const isUserAvailabilitySnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<UserAvailabilityDoc> => isUserAvailabilityRef(
  snap.ref,
);

export const useUserAvailabilityDocRef = (userRef: DocumentReference<UserDoc>) => useMemo(() => doc(collection(userRef, 'private'), 'availability') as DocumentReference<UserAvailabilityDoc>, [userRef]);
