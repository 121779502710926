import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  PartialWithFieldValue,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import AvatarField from '../../components/AvatarField';
import Catch from '../../components/Catch';
import TextField from '../../components/TextField';
import { useUserRef } from '../../components/UserRefContext';
import Spinner from '../../icons/Spinner';
import { AvatarDoc } from '../../types/Avatar';
import { UserDoc } from '../../types/User';

export type FormFields = {
  firstName: string;
  lastName: string;
  avatarRef: DocumentReference<AvatarDoc> | null;
};

const schema = yup.object().shape({
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  avatarRef: yup.mixed().label('Photo').nullable(),
});

const ProfileFormMain: React.FC = () => {
  const [validateAll, setValidateAll] = useState(false);

  const userRef = useUserRef();
  const { data: userSnap } = useFirestoreDoc(userRef);
  const user = useMemo(() => userSnap.data(), [userSnap]);

  const handleProfileFormSubmit = useCallback(async ({
    avatarRef,
    firstName,
    lastName,
  }: FormFields) => {
    const userData: PartialWithFieldValue<UserDoc> = {
      firstName,
      lastName,
    };

    if (avatarRef) {
      userData.avatarRef = avatarRef;
    }

    if (!userSnap) {
      userData.createdAt = serverTimestamp();
    }

    await setDoc(userRef, userData, { merge: true });
  }, [userRef, userSnap]);

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        avatarRef: user?.avatarRef || null,
      }}
      onSubmit={handleProfileFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <AvatarField name="avatarRef" label="Your photo" />

            <HStack spacing={4} alignItems="start">
              <TextField name="firstName" label="First name" placeholder="John" isRequired />
              <TextField name="lastName" label="Last name" placeholder="Doe" isRequired />
            </HStack>

            <Button
              type="submit"
              isLoading={isSubmitting}
              spinner={<Spinner />}
              loadingText="Saving..."
            >
              Save
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export const ProfileFormCatchFallback: React.FC = () => null;
export const ProfileFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ProfileForm: React.FC = () => (
  <Catch fallback={<ProfileFormCatchFallback />}>
    <Suspense fallback={<ProfileFormSuspenseFallback />}>
      <ProfileFormMain />
    </Suspense>
  </Catch>
);

export default ProfileForm;
