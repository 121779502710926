import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

export default function useDevices({
  audioTrack,
  videoTrack,
}: {
  audioTrack: LocalAudioTrack | null,
  videoTrack: LocalVideoTrack | null,
}): {
    audioInputDevices: MediaDeviceInfo[];
    audioOutputDevices: MediaDeviceInfo[];
    videoInputDevices: MediaDeviceInfo[];
  } {
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoInputDevices, setVideoInputDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    let mounted = true;

    async function updateDeviceList() {
      const res = await navigator.mediaDevices.enumerateDevices();

      if (mounted) {
        setAudioInputDevices(_.filter(res, { kind: 'audioinput' }));
        setAudioOutputDevices(_.filter(res, { kind: 'audiooutput' }));
        setVideoInputDevices(_.filter(res, { kind: 'videoinput' }));
      }
    }

    updateDeviceList();

    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList);

    return () => {
      mounted = false;
      navigator.mediaDevices.removeEventListener('devicechange', updateDeviceList);
    };
  }, [
    setAudioInputDevices,
    setAudioOutputDevices,
    setVideoInputDevices,
    audioTrack,
    videoTrack,
  ]);

  return { audioInputDevices, audioOutputDevices, videoInputDevices };
}
