import {
  Container,
  HStack,
  Heading,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PlusInCircleIcon from '../../../icons/PlusInCircleIcon';
import StagesGraph from './StagesList';

const StagesScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const navigate = useNavigate();

  const handleNewStageClick = useCallback(
    () => navigate(`/organizations/${organizationRef.id}/stages/new`),
    [navigate, organizationRef.id],
  );

  return (
    <VStack height="100%" spacing={0} alignItems="stretch" overflow="hidden">
      <Container>
        <VStack spacing={0} alignItems="stretch">
          <HStack py={3}>
            <Heading py="4px" flexGrow={1}>
              Pipeline stages
            </Heading>

            <IconButton
              data-intercom-target="OrganizationStageAdd"
              icon={<PlusInCircleIcon />}
              onClick={handleNewStageClick}
              aria-label="New stage"
            />
          </HStack>
        </VStack>
      </Container>

      <StagesGraph />
    </VStack>
  );
};

export const StagesScreenCatchFallback: React.FC = () => null;
export const StagesScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const StagesScreen: React.FC = () => (
  <Catch fallback={<StagesScreenCatchFallback />}>
    <Suspense fallback={<StagesScreenSuspenseFallback />}>
      <StagesScreenMain />
    </Suspense>
  </Catch>
);

export default StagesScreen;
