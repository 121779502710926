import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import React, { ReactNode, useCallback } from 'react';
import ChevronExternalAdmiralIcon from '../icons/ChevronExternalAdmiralIcon';
import ChevronExternalCaptainIcon from '../icons/ChevronExternalCaptainIcon';
import ChevronExternalCommanderIcon from '../icons/ChevronExternalCommanderIcon';
import ChevronExternalLieutenantIcon from '../icons/ChevronExternalLieutenantIcon';
import { ExpertTier } from '../types/Expert';

export type Props = {
  label: ReactNode;
  name: string;
  isRequired?: boolean;
};

const ExternalExpertTiersField: React.FC<Props> = ({ name, label, isRequired }) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = useCallback(
    (tier: ExpertTier, checked: boolean) => {
      if (checked) {
        helpers.setValue([...field.value, tier]);
      } else {
        helpers.setValue(_.filter(field.value, (t) => t !== tier));
      }
    },
    [field.value, helpers],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <VStack alignItems="stretch" spacing={1}>
        <Popover trigger="hover" isLazy openDelay={600} placement="left">
          <PopoverTrigger>
            <Box>
              <Switch
                size="sm"
                isChecked={_.includes(field.value, ExpertTier.LIEUTENANT)}
                onChange={(e) => handleChange(ExpertTier.LIEUTENANT, e.target.checked)}
                flexGrow={1}
              >
                <ChevronExternalLieutenantIcon mr={1} />
                Lieutenant
              </Switch>
            </Box>
          </PopoverTrigger>

          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <ChevronExternalLieutenantIcon mr={1} />
                Clarwis Lieutenant &middot; Tier 1 &middot; $60/h
              </PopoverHeader>
              <PopoverBody>
                Lieutenant tier represents experts with up to 5 years of experience with no
                leading experience
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>

        <Popover trigger="hover" isLazy openDelay={600} placement="left">
          <PopoverTrigger>
            <Box>
              <Switch
                size="sm"
                isChecked={_.includes(field.value, ExpertTier.COMMANDER)}
                onChange={(e) => handleChange(ExpertTier.COMMANDER, e.target.checked)}
                flexGrow={1}
              >
                <ChevronExternalCommanderIcon mr={1} />
                Commander
              </Switch>
            </Box>
          </PopoverTrigger>

          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <ChevronExternalCommanderIcon mr={1} />
                Clarwis Commander &middot; Tier 2 &middot; $120/h
              </PopoverHeader>
              <PopoverBody>
                Commander tier represents experts with up to 8 years of experience with
                leading experience
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>

        <Popover trigger="hover" isLazy openDelay={600} placement="left">
          <PopoverTrigger>
            <Box>
              <Switch
                size="sm"
                isChecked={_.includes(field.value, ExpertTier.CAPTAIN)}
                onChange={(e) => handleChange(ExpertTier.CAPTAIN, e.target.checked)}
                flexGrow={1}
              >
                <ChevronExternalCaptainIcon mr={1} />
                Captain
              </Switch>
            </Box>
          </PopoverTrigger>

          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <ChevronExternalCaptainIcon mr={1} />
                Clarwis Captain &middot; Tier 3 &middot; $240/h
              </PopoverHeader>
              <PopoverBody>
                Captain tier represents experts with more than 10 years of experience with wast
                leading experience
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>

        <Popover trigger="hover" isLazy openDelay={600} placement="left">
          <PopoverTrigger>
            <Box>
              <Switch
                size="sm"
                isChecked={_.includes(field.value, ExpertTier.ADMIRAL)}
                onChange={(e) => handleChange(ExpertTier.ADMIRAL, e.target.checked)}
                flexGrow={1}
              >
                <ChevronExternalAdmiralIcon mr={1} />
                Admiral
              </Switch>
            </Box>
          </PopoverTrigger>

          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader>
                <ChevronExternalAdmiralIcon mr={1} />
                Clarwis Admiral &middot; Tier 4 &middot; $480/h
              </PopoverHeader>
              <PopoverBody>
                Admiral tier represents C-level experts
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </VStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

ExternalExpertTiersField.defaultProps = {
  isRequired: false,
};

export default ExternalExpertTiersField;
