import {
  AvatarBadge,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../../components/BlockList';
import BlockListItem from '../../../components/BlockListItem';
import Catch from '../../../components/Catch';
import { useInterviewRef } from '../../../components/InterviewRefContext';
import MemberAvatar from '../../../components/MemberAvatar';
import PaddingBlock from '../../../components/PaddingBlock';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { UserDoc } from '../../../types/User';

export type Props = {
  interviewerRef: DocumentReference<UserDoc>
};

const InterviewerInfo: React.FC<Props> = ({ interviewerRef }) => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: interviewerSnap } = useFirestoreDoc(interviewerRef);

  if (!interviewerSnap.exists()) {
    throw new SnapNotFoundError(interviewerSnap);
  }

  const interviewer = useMemo(() => interviewerSnap.data(), [interviewerSnap]);

  const interviewerIsConnected = useMemo(
    () => _.some(interview.connectedUserRefs, (ref) => ref.id === interviewerRef.id),
    [interview.connectedUserRefs, interviewerRef.id],
  );

  return (
    <HStack spacing={3} alignItems="center">
      <MemberAvatar
        userRef={interviewerRef}
      >
        {interviewerIsConnected ? (<AvatarBadge />) : null}
      </MemberAvatar>

      <VStack spacing={1} alignItems="stretch" flexGrow={1}>
        <Text
          fontWeight="medium"
          fontSize="sm"
          lineHeight="shorter"
          color="cf.cntTertiary"
          pt="1px"
          pb="3px"
        >
          Interviewer
        </Text>

        <Text variant="labelMedium">
          {interviewer.firstName}
          {' '}
          {interviewer.lastName}
        </Text>
      </VStack>
    </HStack>
  );
};

const ParticipantsInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  if (!interview.intervieweeRef) {
    throw new Error();
  }

  const { data: intervieweeSnap } = useFirestoreDoc(interview.intervieweeRef);

  if (!intervieweeSnap.exists()) {
    throw new SnapNotFoundError(intervieweeSnap);
  }

  const interviewee = useMemo(() => intervieweeSnap.data(), [intervieweeSnap]);

  const intervieweeIsConnected = useMemo(
    () => _.some(interview.connectedUserRefs, (ref) => ref.id === interview.intervieweeRef?.id),
    [interview.connectedUserRefs, interview.intervieweeRef?.id],
  );

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Participants
        {' '}
        &middot;
        {' '}
        {interview.interviewerRefs.length + 1}
      </Text>

      <BlockList variant="outline">
        <BlockListItem>
          <HStack spacing={3} alignItems="center">
            <MemberAvatar
              userRef={interview.intervieweeRef}
            >
              {intervieweeIsConnected ? (<AvatarBadge />) : null}
            </MemberAvatar>

            <VStack spacing={1} alignItems="stretch" flexGrow={1}>
              <Text
                fontWeight="medium"
                fontSize="sm"
                lineHeight="shorter"
                color="cf.cntTertiary"
                pt="1px"
                pb="3px"
              >
                Interviewee
              </Text>

              <Text variant="labelMedium">
                {interviewee.firstName}
                {' '}
                {interviewee.lastName}
              </Text>
            </VStack>
          </HStack>
        </BlockListItem>

        {interview.interviewerRefs.map((interviewerRef) => (
          <BlockListItem key={interviewerRef.id}>
            <InterviewerInfo interviewerRef={interviewerRef} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

const ParticipantsInfoCatchFallback: React.FC = () => null;
const ParticipantsInfoSuspenseFallback: React.FC = () => (
  <PaddingBlock>
    <HStack spacing={3} alignItems="center">
      <SkeletonCircle size="36px" />

      <VStack spacing={0} alignItems="stretch">
        <Skeleton h="20px" w="100px" pt="1px" />
        <Skeleton h="24px" w="140px" pt="1px" pb="2px" />
      </VStack>
    </HStack>
  </PaddingBlock>
);

/* eslint-disable react/jsx-props-no-spreading */
const ParticipantsInfo: React.FC = () => (
  <Catch fallback={<ParticipantsInfoCatchFallback />}>
    <Suspense fallback={<ParticipantsInfoSuspenseFallback />}>
      <ParticipantsInfoMain />
    </Suspense>
  </Catch>
);

export default ParticipantsInfo;
