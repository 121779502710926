import { Box, BoxProps } from '@chakra-ui/react';
import { scaleLinear } from 'd3';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { usePlayback } from '../PlaybackScaleProvider';

export type Props = BoxProps & {
  start: number;
  end: number;
};

const Section: React.FC<Props> = ({
  start,
  end,
  ...props
}: Props) => {
  const { playbackTime, seek } = usePlayback();

  const passed = useMemo((): number => {
    if (playbackTime <= start) {
      return 0;
    } if (playbackTime >= end) {
      return 1;
    }
    const e = end - start;
    const c = playbackTime - start;
    return c / e;
  }, [playbackTime, end, start]);

  const bg = useMemo(
    () => {
      if (playbackTime < start) {
        return 'cf.status.created';
      }
      if (start <= playbackTime && playbackTime < end) {
        return 'cf.status.started';
      }
      return 'cf.status.ended';
    },
    [playbackTime, end, start],
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const f = scaleLinear()
        .domain([
          e.currentTarget.offsetLeft,
          e.currentTarget.offsetLeft + e.currentTarget.clientWidth,
        ])
        .range([start, end]);

      seek(f(e.clientX));
    },
    [end, seek, start],
  );

  return (
    <Box
      h="100%"
      w="100%"
      bg={bg}
      cursor="pointer"
      overflow="hidden"
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Box w={`${passed * 100}%`} h="100%" bg="cf.status.ended" />
    </Box>
  );
};

export default Section;
