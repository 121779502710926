import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertTitle,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  SkeletonCircle,
  Spacer,
  Tooltip,
  VStack,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { deleteField, setDoc } from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import AddUserIcon from '../../../icons/AddUserIcon';
import ArchiveIcon from '../../../icons/ArchiveIcon';
import CalendarIcon from '../../../icons/CalendarIcon';
import CheckIcon from '../../../icons/CheckIcon';
import EditIcon from '../../../icons/EditIcon';
import EndCallIcon from '../../../icons/EndCallIcon';
import MessageIcon from '../../../icons/MessageIcon';
import OtherPageIcon from '../../../icons/OtherPageIcon';
import Pause28Icon from '../../../icons/Pause28Icon';
import PlayIcon from '../../../icons/PlayIcon';
import Spinner from '../../../icons/Spinner';
import { CandidatePauseReason, CandidateStatus } from '../../../types/Candidate';
import { useMembersCollectionRef } from '../../../types/Member';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import AssigneeTag from './AssigneeInfo';
import AssigneeMenuItem from './AssigneeMenuItem';

const ContactInfoMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const candidateRef = useCandidateRef();
  const membersCollectionRef = useMembersCollectionRef(organizationRef);

  const { data: organizationSnap } = useFirestoreDoc(organizationRef);
  const { data: candidateSnap } = useFirestoreDoc(candidateRef);
  const { data: membersSnap } = useFirestoreCollection(membersCollectionRef);

  if (!organizationSnap.exists()) {
    throw new SnapNotFoundError(organizationSnap);
  }

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  const { hasCopied: hasCopiedEmail, onCopy: handleCopyEmailClick } = useClipboard(candidate.email || '');
  const { hasCopied: hasCopiedPhoneNumber, onCopy: handleCopyPhoneNumberClick } = useClipboard(candidate.phoneNumber || '');
  const { hasCopied: hasCopiedUrl, onCopy: handleCopyUrlClick } = useClipboard(candidate.customerUrl || '');

  const [isArchiving, setIsArchiving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const handleArchive = useCallback(async () => {
    try {
      setIsArchiving(true);
      await setDoc(candidateRef, {
        status: CandidateStatus.ARCHIVED,
      }, { merge: true });
      onClose();
    } finally {
      setIsArchiving(false);
    }
  }, [candidateRef, onClose]);

  const [isToggling, setIsToggling] = useState(false);

  const handlePause = useCallback(async () => {
    try {
      setIsToggling(true);
      if (candidate.status === CandidateStatus.ACTIVE) {
        await setDoc(candidateRef, {
          status: CandidateStatus.PAUSED,
          pauseReason: deleteField(),
        }, { merge: true });
      }
    } finally {
      setIsToggling(false);
    }
  }, [candidate.status, candidateRef]);

  const handleUnpause = useCallback(async () => {
    try {
      setIsToggling(true);
      if (
        candidate.status === CandidateStatus.PAUSED
        || candidate.status === CandidateStatus.FINALIZED
      ) {
        await setDoc(candidateRef, {
          status: CandidateStatus.ACTIVE,
        }, { merge: true });
      }
    } finally {
      setIsToggling(false);
    }
  }, [candidate.status, candidateRef]);

  const navigate = useNavigate();
  const handleEdit = useCallback(async () => {
    navigate('./edit');
  }, [navigate]);

  const handleAddInterview = useCallback(async () => {
    navigate('./interviews/new');
  }, [navigate]);

  return (
    <VStack alignItems="stretch">
      <HStack>
        {candidate.email ? (
          <Tooltip label="Copy email">
            <IconButton
              aria-label="Copy Email"
              icon={hasCopiedEmail ? <CheckIcon /> : <MessageIcon />}
              variant="outline"
              onClick={handleCopyEmailClick}
            />
          </Tooltip>
        ) : null}

        {candidate.phoneNumber ? (
          <Tooltip label="Copy phone number">
            <IconButton
              aria-label="Copy Phone number"
              icon={hasCopiedPhoneNumber ? <CheckIcon /> : <EndCallIcon />}
              variant="outline"
              onClick={handleCopyPhoneNumberClick}
            />
          </Tooltip>
        ) : null}

        {candidate.customerUrl ? (
          <Tooltip label="Copy URL">
            <IconButton
              aria-label="Copy Phone number"
              icon={hasCopiedUrl ? <CheckIcon /> : <OtherPageIcon />}
              variant="outline"
              onClick={handleCopyUrlClick}
            />
          </Tooltip>
        ) : null}

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Assign"
            icon={<AddUserIcon />}
            variant="outline"
          />
          <MenuList>
            {membersSnap.docs.map((memberSnap) => (
              <AssigneeMenuItem key={memberSnap.ref.path} assigneeRef={memberSnap.ref} />
            ))}
          </MenuList>
        </Menu>

        {(candidate.assigneeRefs ?? []).map((assigneeRef) => (
          <AssigneeTag key={assigneeRef.path} assigneeRef={assigneeRef} />
        ))}

        <Spacer />

        {candidate.userRef && candidate.status === CandidateStatus.ACTIVE ? (
          <Tooltip hasArrow label="Pause interview scheduler">
            <IconButton
              variant="outline"
              icon={<Pause28Icon boxSize={5} />}
              aria-label="Pause"
              onClick={handlePause}
              isLoading={isToggling}
              spinner={<Spinner />}
            />
          </Tooltip>
        ) : null}

        {candidate.userRef && (
          candidate.status === CandidateStatus.PAUSED
        || candidate.status === CandidateStatus.FINALIZED
        ) ? (
          <Tooltip hasArrow label="Resume interview scheduler">
            <IconButton
              variant="outline"
              icon={<PlayIcon />}
              aria-label="Unpause"
              onClick={handleUnpause}
              isLoading={isToggling}
              spinner={<Spinner />}
            />
          </Tooltip>
          ) : null}

        <Tooltip hasArrow label="Edit">
          <IconButton
            variant="outline"
            icon={<EditIcon />}
            aria-label="Edit"
            onClick={handleEdit}
          />
        </Tooltip>

        {candidate.status !== CandidateStatus.ARCHIVED ? (
          <Tooltip hasArrow label="Add interview manually">
            <IconButton
              variant="outline"
              icon={<CalendarIcon />}
              aria-label="Add interview manually"
              onClick={handleAddInterview}
            />
          </Tooltip>
        ) : null}

        {candidate.status !== CandidateStatus.ARCHIVED ? (
          <Tooltip hasArrow label="Archive candidate">
            <IconButton
              icon={<ArchiveIcon />}
              colorScheme="negative"
              aria-label="Archive"
              onClick={onOpen}
            />
          </Tooltip>
        ) : null}

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Archive candidate
              </AlertDialogHeader>

              <AlertDialogBody>
                Candidate would be hidden from your dashboard.
                We would also stop scheduling new interviews for this candidate.
              </AlertDialogBody>

              <AlertDialogFooter>
                <HStack>
                  <Button
                    variant="outline"
                    ref={cancelRef}
                    onClick={onClose}
                  >
                    No, keep
                  </Button>

                  <Button
                    colorScheme="negative"
                    isLoading={isArchiving}
                    spinner={<Spinner />}
                    loadingText="Archiving..."
                    onClick={handleArchive}
                  >
                    Yes, archive
                  </Button>
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </HStack>

      {candidate.status === CandidateStatus.PAUSED && candidate.pauseReason !== undefined ? (
        <Alert status="error" bg="#E02D43" borderRadius="md" color="#fff">
          <AlertTitle>
            Paused automatically:
          </AlertTitle>

          <AlertDescription>
            {candidate.pauseReason === CandidatePauseReason.INTERVIEW_MISSED_BY_CANDIDATE ? (
              <>
                Candidate missed the interview
              </>
            ) : null}

            {candidate.pauseReason === CandidatePauseReason.INTERVIEW_MISSED_BY_EXPERT ? (
              <>
                Expert missed the interview
              </>
            ) : null}

            {candidate.pauseReason === CandidatePauseReason.NO_MATCHING_EXPERT ? (
              <>
                No matching experts found
              </>
            ) : null}
          </AlertDescription>
        </Alert>
      ) : null}
    </VStack>
  );
};

export const ContactInfoSuspenseFallback: React.FC = () => (
  <VStack alignItems="stretch">
    <HStack>
      <SkeletonCircle boxSize={9} />
      <SkeletonCircle boxSize={9} />
      <SkeletonCircle boxSize={9} />
      <Spacer />
      <SkeletonCircle boxSize={9} />
    </HStack>
  </VStack>
);

const ContactInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ContactInfo: React.FC = () => (
  <Catch fallback={<ContactInfoCatchFallback />}>
    <Suspense fallback={<ContactInfoSuspenseFallback />}>
      <ContactInfoMain />
    </Suspense>
  </Catch>
);

export default ContactInfo;
