import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const UserIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 4C8.89543 4 8 4.89543 8 6C8 7.10457 8.89543 8 10 8C11.1046 8 12 7.10457 12 6C12 4.89543 11.1046 4 10 4ZM6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6ZM3 16C3 13.2386 5.23858 11 8 11H12C14.7614 11 17 13.2386 17 16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16ZM8 13C6.34315 13 5 14.3431 5 16H15C15 14.3431 13.6569 13 12 13H8Z" fill="currentColor" />
  </Icon>
);

export default UserIcon;
