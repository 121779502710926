import { IconButton } from '@chakra-ui/react';
import React from 'react';
import Pause28Icon from '../../../icons/Pause28Icon';
import Play28Icon from '../../../icons/Play28Icon';
import Spinner from '../../../icons/Spinner';
import { usePlayback } from '../PlaybackScaleProvider';

const Controls: React.FC = () => {
  const {
    isBuffering,
    isPlaying,
    pause,
    play,
  } = usePlayback();

  return isPlaying ? (
    <IconButton
      onClick={pause}
      icon={isBuffering ? <Spinner /> : <Pause28Icon />}
      aria-label="Pause"
      variant="outline"
      size="lg"
      borderRadius="full"
    />
  ) : (
    <IconButton
      onClick={play}
      icon={<Play28Icon />}
      aria-label="Play"
      variant="outline"
      size="lg"
      borderRadius="full"
    />
  );
};

export default Controls;
