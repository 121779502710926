import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc } from './Organization';

export type DeelTeamDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  name: string;
};

export const isDeelTeamDoc = (
  data?: DocumentData,
): data is DeelTeamDoc => true;

export const isDeelTeamRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelTeamDoc> => ref.parent.id === 'deelTeams';

export const isDeelTeamSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelTeamDoc> => isDeelTeamRef(snap.ref);

export const useDeelTeamsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'deelTeams') as CollectionReference<DeelTeamDoc>, [firestore]);
};

export const useDeelTeamDocRef = (docId: string) => {
  const collectionRef = useDeelTeamsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
