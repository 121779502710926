import {
  Box,
  Center,
  Container,
  Divider,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  getCountFromServer,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import CandidateRow, { CandidateRowSuspenseFallback } from '../../../components/CandidateRow';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import AddPosition64Icon from '../../../icons/AddPosition64Icon';
import Spinner from '../../../icons/Spinner';
import { CandidateStatus, useCandidatesCollectionRef } from '../../../types/Candidate';

const CandidateListMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const currentPage = useDeferredValue(page);
  const perPage = 20;

  const candidatesCollectionRef = useCandidatesCollectionRef();

  const { data: candidatesSnap } = useFirestoreCollection(query(
    candidatesCollectionRef,
    where('organizationRef', '==', organizationRef),
    where('status', 'in', [CandidateStatus.ACTIVE, CandidateStatus.PAUSED, CandidateStatus.FINALIZED]),
    orderBy('createdAt', 'desc'),
    limit(currentPage * perPage),
  ));

  useEffect(
    () => {
      getCountFromServer(
        query(
          candidatesCollectionRef,
          where('organizationRef', '==', organizationRef),
          where('status', 'in', [CandidateStatus.ACTIVE, CandidateStatus.PAUSED, CandidateStatus.FINALIZED]),
          orderBy('createdAt', 'desc'),
        ),
      ).then(
        (v) => setTotalPages(Math.ceil(v.data().count / perPage)),
      );
    },
    [candidatesSnap, candidatesCollectionRef, organizationRef],
  );

  const el = useRef<HTMLDivElement>(null);

  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrolledToTop(e.currentTarget.scrollTop <= 0);

    const scrollBottom = e.currentTarget.scrollHeight
      - e.currentTarget.scrollTop
      - e.currentTarget.clientHeight;

    if (scrollBottom <= 0) {
      if (totalPages > currentPage) {
        setPage(currentPage + 1);
      }
    }
  }, [currentPage, totalPages]);

  if (!candidatesSnap.docs.length) {
    return (
      <Center height="100%">
        <Container maxW="280px" px={0}>
          <VStack spacing={4}>
            <VStack spacing={2}>
              <Text color="cf.cntEmpty">
                <AddPosition64Icon />
              </Text>
              <Text variant="labelMedium" textAlign="center">
                No candidates yet
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Center>
    );
  }

  return (
    <>
      <ScaleFade in={!scrolledToTop}>
        <Container>
          <Divider />
        </Container>
      </ScaleFade>

      <Box ref={el} pb={3} height="100%" overflow="auto" onScroll={handleScroll}>
        <Container>
          <VStack alignItems="stretch" spacing={3}>
            <BlockList variant="outline">
              {candidatesSnap.docs.map((candidateSnap) => (
                <CandidateRow
                  key={candidateSnap.id}
                  candidateSnap={candidateSnap}
                />
              ))}
            </BlockList>

            {totalPages > currentPage ? (
              <Center>
                <Spinner color="cf.cntSecondary" />
              </Center>
            ) : null}
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export const CandidateListSuspenseFallback: React.FC = () => (
  <Box pb={3} height="100%" overflow="auto">
    <Container>
      <BlockList variant="outline">
        <CandidateRowSuspenseFallback />
        <CandidateRowSuspenseFallback />
        <CandidateRowSuspenseFallback />
        <CandidateRowSuspenseFallback />
        <CandidateRowSuspenseFallback />
      </BlockList>
    </Container>
  </Box>
);

export const CandidateListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateList: React.FC = () => (
  <Catch fallback={<CandidateListCatchFallback />}>
    <Suspense fallback={<CandidateListSuspenseFallback />}>
      <CandidateListMain />
    </Suspense>
  </Catch>
);

export default CandidateList;
