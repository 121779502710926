import { HStack, IconButton, Text } from '@chakra-ui/react';
import { QueryDocumentSnapshot, deleteDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useCallback, useMemo } from 'react';
import BlockListItem from '../../../../components/BlockListItem';
import Catch from '../../../../components/Catch';
import useTimezone from '../../../../hooks/useTimezone';
import TrashIcon from '../../../../icons/TrashIcon';
import { AvailabilityExceptionDoc } from '../../../../types/AvailabilityException';

export type Props = {
  availabilityExceptionSnap: QueryDocumentSnapshot<AvailabilityExceptionDoc>;
};

const AvailabilityExceptionRowMain: React.FC<Props> = ({ availabilityExceptionSnap }) => {
  const timezone = useTimezone();
  const availabilityException = useMemo(
    () => availabilityExceptionSnap.data(),
    [availabilityExceptionSnap],
  );

  const startsAt = useMemo(
    () => moment(availabilityException.startsAt.toDate()).tz(timezone).format('LT'),
    [availabilityException?.startsAt, timezone],
  );

  const endsAt = useMemo(
    () => moment(availabilityException.endsAt.toDate()).tz(timezone).format('LT'),
    [availabilityException?.endsAt, timezone],
  );

  const handleClick = useCallback(async () => {
    await deleteDoc(availabilityExceptionSnap.ref);
  }, [availabilityExceptionSnap.ref]);

  return (
    <BlockListItem
      backgroundImage="linear-gradient(45deg, #F9F9F9 8.33%, transparent 8.33%, transparent 50%, #F9F9F9 50%, #F9F9F9 58.33%, transparent 58.33%, transparent 100%)"
      backgroundSize="24px 24px"
    >
      <HStack>
        <Text variant="labelMedium" flexGrow={1}>
          Do not disturb:
          {' '}
          {startsAt}
          {' '}
          -
          {' '}
          {endsAt}
        </Text>

        <IconButton
          variant="ghost"
          aria-label="Remove"
          icon={<TrashIcon />}
          onClick={handleClick}
        />
      </HStack>
    </BlockListItem>
  );
};

export const AvailabilityExceptionRowCatchFallback: React.FC = () => null;
export const AvailabilityExceptionRowSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AvailabilityExceptionRow: React.FC<Props> = (props) => (
  <Catch fallback={<AvailabilityExceptionRowCatchFallback />}>
    <Suspense fallback={<AvailabilityExceptionRowSuspenseFallback />}>
      <AvailabilityExceptionRowMain {...props} />
    </Suspense>
  </Catch>
);

export default AvailabilityExceptionRow;
