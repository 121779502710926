import {
  AspectRatio,
  Box,
  FormControl,
  FormLabel,
  Select,
  VStack,
} from '@chakra-ui/react';
import React, { ChangeEvent, useCallback } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import Video from '../Video';

export interface Props {
  devices: MediaDeviceInfo[];
  videoTrack: LocalVideoTrack | null;
  onChange: (deviceId: string) => unknown;
  value: string;
}

const CameraSettings: React.FC<Props> = ({
  devices, videoTrack, onChange, value,
}: Props) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <VStack spacing={4} alignItems="stretch">
      <FormControl>
        <FormLabel>
          Camera
        </FormLabel>

        <Select onChange={handleChange} value={value}>
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </Select>
      </FormControl>

      {videoTrack ? (
        <AspectRatio maxW="192px" ratio={16 / 9}>
          <Box
            borderRadius="md"
            borderColor="cf.brdBlackAlpha12"
            borderWidth="1px"
          >
            <Video videoTrack={videoTrack} />
          </Box>
        </AspectRatio>
      ) : null}
    </VStack>
  );
};

export default CameraSettings;
