import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { InterviewDoc, isInterviewRef } from './Interview';

export enum InterviewTabStateTab {
  QUESTION = 'QUESTION',
  CODE_EDITOR = 'CODE_EDITOR',
  WHITEBOARD = 'WHITEBOARD',
}

export type InterviewTabStateDoc = {
  tab: InterviewTabStateTab;
  timestamp: Timestamp;
};

export const isInterviewTabStateDoc = (
  data?: DocumentData,
): data is InterviewTabStateDoc => true;

export const isInterviewTabStateRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewTabStateDoc> => ref.parent.id === 'tabStates' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

export const isInterviewTabStateSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewTabStateDoc
> => isInterviewTabStateRef(snap.ref);

export const useInterviewTabStatesCollectionRef = (interviewRef: DocumentReference<InterviewDoc>) => useMemo(() => collection(interviewRef, 'tabStates') as CollectionReference<InterviewTabStateDoc>, [interviewRef]);

export const useInterviewTabStateDocRef = (
  interviewRef: DocumentReference<InterviewDoc>,
  docId: string,
) => {
  const collectionRef = useInterviewTabStatesCollectionRef(interviewRef);
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
