import { Stripe, StripeConstructorOptions, loadStripe } from '@stripe/stripe-js';
import React, { PropsWithChildren, useContext, useMemo } from 'react';

const StripePromiseContext = React.createContext<Promise<Stripe | null> | undefined>(undefined);

export const useStripePromise = (): Promise<Stripe | null> => {
  const stripePromise = useContext(StripePromiseContext);

  if (stripePromise === undefined) {
    throw new Error('Use StripePromiseProvider');
  }

  return stripePromise;
};

export type StripePromiseProviderProps = {
  publishableKey: string;
  options?: StripeConstructorOptions;
};

export const StripePromiseProvider: React.FC<PropsWithChildren<StripePromiseProviderProps>> = ({
  children, publishableKey, options,
}) => {
  const stripePromise = useMemo(
    () => loadStripe(publishableKey, options),
    [publishableKey, options],
  );

  return (
    <StripePromiseContext.Provider value={stripePromise}>
      {children}
    </StripePromiseContext.Provider>
  );
};

StripePromiseProvider.defaultProps = {
  options: undefined,
};
