import _ from 'lodash';
import React, { Suspense, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useFirestoreDoc, useUser } from 'reactfire';
import Catch from '../../components/Catch';
import InterviewRefProvider from '../../components/InterviewRefContext';
import Loader from '../../components/Loader';
import AuthError from '../../types/AuthError';
import { useInterviewDocRef } from '../../types/Interview';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import IntervieweeScreen from './IntervieweeScreen';
import InterviewerScreen from './InterviewerScreen';
import VisitorScreen from './VisitorScreen';

const InterviewPageMain: React.FC = () => {
  const { update } = useIntercom();
  useEffect(() => {
    update({
      hideDefaultLauncher: true,
    });

    return () => {
      update({
        hideDefaultLauncher: false,
      });
    };
  }, [update]);

  const { interviewId } = useParams<{ interviewId: string; }>();

  if (!interviewId) {
    throw new Error();
  }

  const interviewRef = useInterviewDocRef(interviewId);

  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: user } = useUser();

  if (!user) {
    throw new AuthError();
  }

  if (interview.intervieweeRef?.id === user.uid) {
    return (
      <InterviewRefProvider interviewRef={interviewRef}>
        <IntervieweeScreen />
      </InterviewRefProvider>
    );
  }

  if (_.some(interview.interviewerRefs, (ref) => ref.id === user.uid)) {
    return (
      <InterviewRefProvider interviewRef={interviewRef}>
        <InterviewerScreen />
      </InterviewRefProvider>
    );
  }

  return (
    <InterviewRefProvider interviewRef={interviewRef}>
      <VisitorScreen />
    </InterviewRefProvider>
  );
};

const InterviewPageCatchFallback: React.FC = () => null;
const InterviewPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewPage: React.FC = (props) => (
  <Catch fallback={<InterviewPageCatchFallback />}>
    <Suspense fallback={<InterviewPageSuspenseFallback />}>
      <InterviewPageMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewPage;
