import { MenuItem } from '@chakra-ui/react';
import {
  DocumentReference,
  arrayUnion,
  doc,
  setDoc,
} from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import UserAvatar from '../../../components/UserAvatar';
import { MemberDoc } from '../../../types/Member';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { useUsersCollectionRef } from '../../../types/User';

export type Props = {
  assigneeRef: DocumentReference<MemberDoc>;
};

const AssigneeMenuItemMain: React.FC<Props> = ({ assigneeRef }) => {
  const candidateRef = useCandidateRef();
  const usersCollectionRef = useUsersCollectionRef();

  const userRef = useMemo(
    () => doc(usersCollectionRef, assigneeRef.id),
    [assigneeRef.id, usersCollectionRef],
  );

  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const userDoc = useMemo(() => userSnap.data(), [userSnap]);

  const onClick = useCallback(() => {
    setDoc(candidateRef, {
      assigneeRefs: arrayUnion(assigneeRef),
    }, { merge: true });
  }, [assigneeRef, candidateRef]);

  return (
    <MenuItem onClick={onClick}>
      <UserAvatar
        userRef={userRef}
        size="xs"
        name={`${userDoc.firstName} ${userDoc.lastName}`}
        mr={2}
      />

      <span>
        {userDoc.firstName}
        {' '}
        {userDoc.lastName}
      </span>
    </MenuItem>
  );
};

export const AssigneeMenuItemSuspenseFallback: React.FC = () => null;

const AssigneeMenuItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AssigneeMenuItem: React.FC<Props> = (props) => (
  <Catch fallback={<AssigneeMenuItemCatchFallback />}>
    <Suspense fallback={<AssigneeMenuItemSuspenseFallback />}>
      <AssigneeMenuItemMain {...props} />
    </Suspense>
  </Catch>
);

export default AssigneeMenuItem;
