import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const PlayIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.98574 3.27626C5.5965 2.91689 6.35182 2.90754 6.97129 3.25169L15.9713 8.25169C16.6062 8.60442 17 9.27367 17 10C17 10.7263 16.6062 11.3956 15.9713 11.7483L6.97129 16.7483C6.35182 17.0925 5.5965 17.0831 4.98574 16.7237C4.37499 16.3644 4 15.7086 4 15V5C4 4.29136 4.37499 3.63563 4.98574 3.27626Z" fill="currentColor" />
  </Icon>
);

export default PlayIcon;
