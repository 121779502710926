import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';
import { TimeSlot } from '../types/UserSchedule';
import PaddingBlock from './PaddingBlock';
import ScheduleWeekdayField from './ScheduleWeekdayField';

export type Props = {
  name: string;
};

const ScheduleField: React.FC<Props> = ({ name }) => {
  const [, meta] = useField<Record<string, TimeSlot[]>>(name);

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>
        Schedule
      </FormLabel>

      <PaddingBlock overflow="hidden">
        <Grid templateColumns="auto 1fr" alignItems="top" rowGap={3} columnGap={5}>
          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Sunday
          </Text>

          <ScheduleWeekdayField name="schedule.su" />

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Monday
          </Text>

          <ScheduleWeekdayField name="schedule.mo" />

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Tuesday
          </Text>

          <ScheduleWeekdayField name="schedule.tu" />

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Wednesday
          </Text>

          <ScheduleWeekdayField name="schedule.we" />

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Thursday
          </Text>

          <ScheduleWeekdayField name="schedule.th" />

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Friday
          </Text>

          <ScheduleWeekdayField name="schedule.fr" />

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <Text
            variant="labelMedium"
            lineHeight="44px"
            color="cf.cntSecondary"
          >
            Saturday
          </Text>

          <ScheduleWeekdayField name="schedule.sa" />
        </Grid>
      </PaddingBlock>

      <FormErrorMessage>
        {typeof meta.error === 'string' ? meta.error : null}
      </FormErrorMessage>
    </FormControl>
  );
};

export default ScheduleField;
