import {
  Button,
  HStack,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import CheckboxListField from '../../components/CheckboxListField';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import TextField from '../../components/TextField';
import useApiCreateWebhook from '../../functions/useApiCreateWebhook';
import Spinner from '../../icons/Spinner';
import { WebhookEventType } from '../../types/Webhook';

export interface FormFields {
  url: string;
  eventTypes: WebhookEventType[];
}

const schema = yup.object().shape({
  url: yup.string().label('URL').url().required(),
  eventTypes: yup.array().label('Event types').required(),
});

const WebhookCreateForm: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const [validateAll, setValidateAll] = useState(false);

  const createWebhook = useApiCreateWebhook();
  const toast = useToast();
  const onSubmit = useCallback(
    async ({
      url,
      eventTypes,
    }: FormFields) => {
      try {
        await createWebhook({
          url,
          eventTypes,
          organizationId: organizationRef.id,
        });
        toast({
          title: 'Webhook created',
          status: 'success',
        });
      } catch (err) {
        if (err instanceof Error) {
          toast({
            title: 'Failed to create webhook',
            status: 'error',
            description: err.message,
          });
        }
      }
    },
    [organizationRef.id, createWebhook, toast],
  );

  return (
    <Formik<FormFields>
      initialValues={{
        url: '',
        eventTypes: [],
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <TextField name="url" type="url" label="URL" placeholder="https://megacorp.com/webhook" />

            <CheckboxListField
              name="eventTypes"
              label="Event types"
              options={[{
                value: WebhookEventType.SCORE_CREATED,
                label: WebhookEventType.SCORE_CREATED,
              }]}
            />

            <HStack justifyContent="right">
              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Creating..."
              >
                Create
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export default WebhookCreateForm;
