import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ClockIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM9 5V10C9 10.3148 9.14819 10.6111 9.4 10.8L13.4 13.8L14.6 12.2L11 9.5V5H9Z" fill="currentColor" />
  </Icon>
);

export default ClockIcon;
