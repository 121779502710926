import {
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../../components/BlockList';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PaddingBlock from '../../../components/PaddingBlock';
import StageRow, {
  StageRowCatchFallback,
  StageRowSuspenseFallback,
} from '../../../components/StageRow';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const StageMain: React.FC = () => {
  const candidateRef = useCandidateRef();
  const { data: candidateSnap } = useFirestoreDoc(candidateRef);

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  if (!candidate.stageRef) {
    throw new Error('Candidate has no stage');
  }

  const organizationRef = useOrganizationRef();

  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/organizations/${organizationRef.id}/stages/${candidate.stageRef?.id}`);
  }, [organizationRef.id, candidate.stageRef?.id, navigate]);

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Stage
      </Text>

      <BlockList variant="outline">
        <Catch fallback={<StageRowCatchFallback />}>
          <Suspense fallback={<StageRowSuspenseFallback />}>
            <DocumentLoader reference={candidate.stageRef}>
              {(stageSnap) => (
                <StageRow
                  stageSnap={stageSnap}
                  onClick={handleClick}
                  key={stageSnap.ref.path}
                />
              )}
            </DocumentLoader>
          </Suspense>
        </Catch>
      </BlockList>
    </VStack>
  );
};

export const StageCatchFallback: React.FC = () => null;

export const StageSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Skeleton pt="1px" pb="3px" height="20px" width="140px" />

    <PaddingBlock>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <HStack spacing={3}>
          <SkeletonCircle size="44px" />

          <VStack spacing={1} alignItems="stretch">
            <Skeleton h="20px" w="140px" />
            <Skeleton h="20px" w="160px" />
          </VStack>
        </HStack>
      </Flex>
    </PaddingBlock>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const Stage: React.FC = () => (
  <Catch fallback={<StageCatchFallback />}>
    <Suspense fallback={<StageSuspenseFallback />}>
      <StageMain />
    </Suspense>
  </Catch>
);

export default Stage;
