import { Box } from '@chakra-ui/react';
import React from 'react';

export interface Props {
  isActive: boolean;
}

const Indicator: React.FC<Props> = ({ isActive }: Props) => (
  <Box h={2} bg={isActive ? 'cf.bgAccent' : 'cf.bgSecondary'} flexGrow={1} borderRadius="2px" />
);

export default Indicator;
