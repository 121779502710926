import { Skeleton, Text, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import { useInterviewRef } from '../../../components/InterviewRefContext';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import InterviewDetailsInterviewer, {
  InterviewDetailsInterviewerSuspenseFallback,
} from './InterviewDetailsInterviewer';

const InterviewDetailsInterviewersMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Experts
      </Text>

      <BlockList variant="outline">
        {interview.expertRefs.map((expertRef) => (
          <InterviewDetailsInterviewer key={expertRef.id} expertRef={expertRef} />
        ))}
      </BlockList>
    </VStack>
  );
};

export const InterviewDetailsInterviewersCatchFallback: React.FC = () => null;

export const InterviewDetailsInterviewersSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Skeleton pt="1px" pb="3px" height="20px" width="140px" />

    <BlockList variant="outline">
      <InterviewDetailsInterviewerSuspenseFallback />
      <InterviewDetailsInterviewerSuspenseFallback />
      <InterviewDetailsInterviewerSuspenseFallback />
    </BlockList>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsInterviewers: React.FC = () => (
  <Catch fallback={<InterviewDetailsInterviewersCatchFallback />}>
    <Suspense fallback={<InterviewDetailsInterviewersSuspenseFallback />}>
      <InterviewDetailsInterviewersMain />
    </Suspense>
  </Catch>
);

export default InterviewDetailsInterviewers;
