import { Center, Container, SimpleGrid } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import MissionKeplerCard from '../../components/MissionKeplerCard';
import MissionMarsCard from '../../components/MissionMarsCard';
import MissionMoonCard from '../../components/MissionMoonCard';
import MissionTitanCard from '../../components/MissionTitanCard';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import usePlatformTier from '../../hooks/usePlatformTier';

const OrganizationSubscribePage: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const { search } = useLocation();
  const next = useMemo(() => new URLSearchParams(search).get('next'), [search]);

  const platformTier = usePlatformTier();

  const navigate = useNavigate();
  useEffect(
    () => {
      if (platformTier) {
        navigate(next || `/organizations/${organizationRef.id}`);
      }
    },
    [navigate, next, organizationRef.id, platformTier],
  );

  return (
    <Container maxW="container.xl" h="100%" overflow="auto">
      <Center minH="100%">
        <SimpleGrid columns={4} columnGap={5}>
          <MissionMoonCard />
          <MissionMarsCard />
          <MissionTitanCard />
          <MissionKeplerCard />
        </SimpleGrid>
      </Center>
    </Container>
  );
};

export default OrganizationSubscribePage;
