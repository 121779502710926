import { Avatar, AvatarProps, CircularProgress } from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { FirebaseStorage, ref } from 'firebase/storage';
import React, { Suspense, useEffect, useState } from 'react';
import { useFirestoreDoc, useStorage } from 'reactfire';
import AlertInTriangleFilledIcon from '../icons/AlertInTriangleFilledIcon';
import UserIcon from '../icons/UserIcon';
import { AvatarDoc, AvatarResolutionMap } from '../types/Avatar';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';

type PixelRatio = keyof AvatarResolutionMap;

const gerUrls = (
  storage: FirebaseStorage,
  avatarSnap: QueryDocumentSnapshot<AvatarDoc>,
  size: StorageAvatarProps['size'],
  pixelRatio: PixelRatio,
): StorageAvatarProps['src'] => {
  const avatar = avatarSnap.data();

  if (size !== 'xs' && size !== 'sm' && size !== 'md' && size !== '2xl') {
    return undefined;
  }

  const pathMap = avatar[size as 'xs' | 'sm' | 'md' | '2xl'];

  if (!pathMap) {
    return undefined;
  }

  const path = pathMap[pixelRatio];

  if (!path) {
    return undefined;
  }

  const reference = ref(storage, path);

  return `https://storage.googleapis.com/${reference.bucket}/${reference.fullPath}`;
};

export type StorageAvatarStaticProps = AvatarProps;

export const StorageAvatarStatic: React.FC<StorageAvatarStaticProps> = ({
  ...avatarProps
}) => (
  <Avatar
    icon={<UserIcon />}
    bg="cf.bgPrimary"
    color="cf.cntAccent"
    _after={{
      content: '""',
      boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)',
      position: 'absolute',
      height: '100%',
      width: '100%',
      borderRadius: '100%',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...avatarProps}
  />
);

export type StorageAvatarMainProps = StorageAvatarStaticProps & {
  avatarRef: DocumentReference<AvatarDoc>;
};

export const StorageAvatarMain: React.FC<StorageAvatarMainProps> = ({
  avatarRef,
  size = 'md',
  ...avatarProps
}) => {
  const storage = useStorage();

  const { data: avatarSnap } = useFirestoreDoc(avatarRef);

  if (!avatarSnap.exists()) {
    throw new SnapNotFoundError(avatarSnap);
  }

  const [props, setProps] = useState<Pick<StorageAvatarProps, 'src' | 'srcSet'>>({});

  useEffect(
    () => {
      const urls = [
        gerUrls(storage, avatarSnap, size, '1x'),
        gerUrls(storage, avatarSnap, size, '1.5x'),
        gerUrls(storage, avatarSnap, size, '2x'),
        gerUrls(storage, avatarSnap, size, '3x'),
      ];

      setProps({
        src: urls[0],
        srcSet: [
          urls[0],
          `${urls[1]} 1.5x`,
          `${urls[2]} 2x`,
          `${urls[3]} 3x`,
        ].join(', '),
      });
    },
    [avatarSnap, size, storage],
  );

  return (
    <StorageAvatarStatic
      src={props.src}
      srcSet={props.srcSet}
      size={size}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
    />
  );
};

export const StorageAvatarSuspenseFallback: React.FC<StorageAvatarStaticProps> = (avatarProps) => (
  <StorageAvatarStatic
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...avatarProps}
    icon={<CircularProgress isIndeterminate size={5} color="cf.cntAccent" />}
  />
);

export const StorageAvatarCatchFallback: React.FC<StorageAvatarStaticProps> = (avatarProps) => (
  <StorageAvatarStatic
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...avatarProps}
    icon={<AlertInTriangleFilledIcon color="cf.cntNegative" />}
  />
);

export type StorageAvatarProps = StorageAvatarStaticProps & {
  avatarRef?: DocumentReference<AvatarDoc>;
};

/* eslint-disable react/jsx-props-no-spreading */
const StorageAvatar: React.FC<StorageAvatarProps> = ({ avatarRef, ...props }) => (
  avatarRef ? (
    <Catch fallback={<StorageAvatarCatchFallback {...props} />}>
      <Suspense fallback={<StorageAvatarSuspenseFallback {...props} />}>
        <StorageAvatarMain avatarRef={avatarRef} {...props} />
      </Suspense>
    </Catch>
  ) : (
    <StorageAvatarStatic {...props} />
  )
);

StorageAvatar.defaultProps = {
  avatarRef: undefined,
};

export default StorageAvatar;
