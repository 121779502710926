import { useEffect, useState } from 'react';

const useCurrentTime = (interval = 1000): Date => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [setCurrentTime, interval]);

  return currentTime;
};

export default useCurrentTime;
