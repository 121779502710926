import { IconButton } from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import useInterviewSetReview from '../../functions/useInterviewSetReview';
import Spinner from '../../icons/Spinner';
import Star28Icon from '../../icons/Star28Icon';
import StarFilled28Icon from '../../icons/StarFilled28Icon';
import { ReviewAspectType, useReviewsCollectionRef } from '../../types/Review';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import { useUserRef } from '../UserRefContext';

export type Props = {
  aspect: ReviewAspectType;
  value: number;
};

const StarMain: React.FC<Props> = ({ aspect, value }) => {
  const userRef = useUserRef();
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const reviewsCollectionRef = useReviewsCollectionRef();
  const { data: reviewsSnap } = useFirestoreCollection(
    query(
      reviewsCollectionRef,
      where('interviewRef', '==', interviewRef),
      where('userRef', '==', userRef),
      where('aspect', '==', aspect),
      orderBy('createdAt', 'desc'),
      limit(1),
    ),
  );

  const existingValue = useMemo<number | null>(
    () => (reviewsSnap.docs.length
      ? reviewsSnap.docs[0].data()?.value || null
      : null),
    [reviewsSnap.docs],
  );

  const setReview = useInterviewSetReview();
  const [isLoading, setLoading] = useState(false);
  const onClick = useCallback(async (v: number) => {
    setLoading(true);
    try {
      await setReview({
        aspect,
        value: v,
        interviewId: interviewRef.id,
      });
    } finally {
      setLoading(false);
    }
  }, [aspect, interviewRef.id, setReview]);

  return (
    <IconButton
      icon={
        existingValue !== null && (existingValue >= value)
          ? <StarFilled28Icon />
          : <Star28Icon />
      }
      aria-label={String(value * 4 + 1)}
      variant="ghost"
      borderRadius="full"
      onClick={() => onClick(value)}
      color="cf.status.created"
      isLoading={isLoading}
      spinner={<Spinner />}
    />
  );
};

export const StarCatchFallback: React.FC = () => null;

export const StarSuspenseFallback: React.FC = () => (
  <IconButton
    aria-label="Loading"
    variant="ghost"
    borderRadius="full"
    color="cf.status.created"
    isLoading
    spinner={<Spinner />}
  />
);

/* eslint-disable react/jsx-props-no-spreading */
const Star: React.FC<Props> = (props) => (
  <Catch fallback={<StarCatchFallback />}>
    <Suspense fallback={<StarSuspenseFallback />}>
      <StarMain {...props} />
    </Suspense>
  </Catch>
);

export default Star;
