import { Skeleton, Text } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { InterviewDoc } from '../../types/Interview';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import Catch from '../Catch';

export type Props = {
  interviewSnap: QueryDocumentSnapshot<InterviewDoc>;
};

const CustomerNameMain: React.FC<Props> = ({ interviewSnap }) => {
  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: customerSnap } = useFirestoreDoc(interview.customerRef);
  if (!customerSnap.exists()) {
    throw new SnapNotFoundError(customerSnap);
  }
  const customer = useMemo(() => customerSnap.data(), [customerSnap]);

  return (
    <Text
      variant="labelMedium"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {customer.name}
    </Text>
  );
};

export const CustomerNameCatchFallback: React.FC = () => (
  <Text
    variant="labelMedium"
    whiteSpace="nowrap"
    overflow="hidden"
    textOverflow="ellipsis"
    fontStyle="italic"
    color="cf.cntSecondary"
  >
    Unknown
  </Text>
);

export const CustomerNameSuspenseFallback: React.FC = () => (
  <Skeleton h="20px" w="70px" />
);

/* eslint-disable react/jsx-props-no-spreading */
const CustomerName: React.FC<Props> = (props) => (
  <Catch fallback={<CustomerNameCatchFallback />}>
    <Suspense fallback={<CustomerNameSuspenseFallback />}>
      <CustomerNameMain {...props} />
    </Suspense>
  </Catch>
);

export default CustomerName;
