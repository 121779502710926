import { HStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { LocalAudioTrack } from 'twilio-video';
import useAudioLevel from '../../../hooks/useAudioLevel';
import Indicator from './Indicator';

export interface Props {
  audioTrack: LocalAudioTrack | null;
}

const AudioLevel: React.FC<Props> = ({ audioTrack }: Props) => {
  const audioLevel = useAudioLevel(audioTrack);

  const audioLevelNormalized = useMemo(
    () => Math.round(
      Math.min(
        Math.max(
          (audioLevel || 0) - 3,
          0,
        ),
        6,
      ),
    ),
    [audioLevel],
  );

  return (
    <HStack spacing={1.5} alignItems="stretch">
      <Indicator isActive={audioLevelNormalized >= 1} />
      <Indicator isActive={audioLevelNormalized >= 2} />
      <Indicator isActive={audioLevelNormalized >= 3} />
      <Indicator isActive={audioLevelNormalized >= 4} />
      <Indicator isActive={audioLevelNormalized >= 5} />
      <Indicator isActive={audioLevelNormalized >= 6} />
    </HStack>
  );
};

export default AudioLevel;
