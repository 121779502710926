import {
  AvatarGroup,
  Box,
  BoxProps,
  GridItem,
  HStack,
  SimpleGrid,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, doc } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useCandidateStatusColor from '../hooks/useCandidateStatusColor';
import useCandidateStatusLabel from '../hooks/useCandidateStatusLabel';
import { CandidateDoc } from '../types/Candidate';
import { useCandidateSkillsStatusDocRef } from '../types/CandidateSkillsStatus';
import { useUsersCollectionRef } from '../types/User';
import CandidateTiersIcon from './CandidateTiersIcon';
import Catch from './Catch';
import MemberAvatar from './MemberAvatar';
import SkillLogosRow, { SkillLogosRowSuspenseFallback } from './SkillLogosRow';
import UserAvatar from './UserAvatar';

export type Props = {
  candidateSnap: QueryDocumentSnapshot<CandidateDoc>;
} & BoxProps;

const CandidateInfoMain: React.FC<Props> = ({ candidateSnap, ...boxProps }: Props) => {
  const candidateSkillsStatusDocRef = useCandidateSkillsStatusDocRef(candidateSnap.ref);
  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  const { data: candidateSkillsStatusSnap } = useFirestoreDoc(
    candidateSkillsStatusDocRef,
  );

  const candidateSkillsStatus = useMemo(
    () => candidateSkillsStatusSnap.data(),
    [candidateSkillsStatusSnap],
  );

  const statusColor = useCandidateStatusColor(candidateSnap);
  const statusLabel = useCandidateStatusLabel(candidateSnap);

  const usersCollectionRef = useUsersCollectionRef();

  return (
    <HStack spacing={3} flexGrow={1}>
      <AvatarGroup>
        <MemberAvatar userRef={candidate.userRef} />
      </AvatarGroup>

      <VStack alignItems="stretch" flexGrow={1} spacing={1} minW={0}>
        <HStack>
          <HStack spacing={1} flexGrow={1} minW={0}>
            <CandidateTiersIcon candidateSnap={candidateSnap} />

            <Text
              fontWeight="semibold"
              lineHeight="short"
              flexGrow={1}
              flexShrink={1}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {candidate.firstName}
              {' '}
              {candidate.lastName}
            </Text>
          </HStack>

          <Text variant="labelSmall" textAlign="right">
            <Text as="span" color={statusColor}>
              {statusLabel}
            </Text>
          </Text>

          {candidate.assigneeRefs?.length ? (
            <AvatarGroup size="xs" max={2}>
              {candidate.assigneeRefs.map((assigneeRef) => (
                <UserAvatar key={assigneeRef.id} userRef={doc(usersCollectionRef, assigneeRef.id)} size="xs" my="-4px" />
              ))}
            </AvatarGroup>
          ) : null}
        </HStack>

        <SimpleGrid columns={3} spacing={3}>
          <GridItem
            backgroundImage="linear-gradient(45deg, rgb(178,187,194,0.1) 25%, transparent 25%, transparent 50%, rgb(178,187,194,0.1) 50%, rgb(178,187,194,0.1) 75%, transparent 75%, transparent 100%)"
            backgroundSize="8px 8px"
            overflow="hidden"
          >
            <SkillLogosRow skillRefs={candidateSkillsStatus?.requestedSkillRefs ?? []} h={5} />
          </GridItem>

          <GridItem
            backgroundImage="linear-gradient(45deg, rgb(224,148,45,0.1) 25%, transparent 25%, transparent 50%, rgb(224,148,45,0.1) 50%, rgb(224,148,45,0.1) 75%, transparent 75%, transparent 100%)"
            backgroundSize="8px 8px"
            overflow="hidden"
          >
            <SkillLogosRow skillRefs={candidateSkillsStatus?.pendingSkillRefs ?? []} h={5} />
          </GridItem>

          <GridItem
            backgroundImage="linear-gradient(45deg, rgb(139,55,153,0.1) 25%, transparent 25%, transparent 50%, rgb(139,55,153,0.1) 50%, rgb(139,55,153,0.1) 75%, transparent 75%, transparent 100%)"
            backgroundSize="8px 8px"
            overflow="hidden"
          >
            <SkillLogosRow skillRefs={candidateSkillsStatus?.finalizedSkillRefs ?? []} h={5} />
          </GridItem>
        </SimpleGrid>
      </VStack>
    </HStack>
  );
};

export const CandidateInfoSuspenseFallback: React.FC = () => (
  <HStack spacing={3} flexGrow={1}>
    <SkeletonCircle boxSize="44px" />

    <VStack alignItems="stretch" flexGrow={1} spacing={1}>
      <HStack>
        <Box flexGrow={1}>
          <Skeleton h={5} w="140px" />
        </Box>

        <SkeletonCircle boxSize={5} my="-4px" />
      </HStack>

      <SimpleGrid columns={3} spacing={3}>
        <GridItem
          backgroundImage="linear-gradient(45deg, rgb(178,187,194,0.1) 25%, transparent 25%, transparent 50%, rgb(178,187,194,0.1) 50%, rgb(178,187,194,0.1) 75%, transparent 75%, transparent 100%)"
          backgroundSize="8px 8px"
        >
          <SkillLogosRowSuspenseFallback />
        </GridItem>

        <GridItem
          backgroundImage="linear-gradient(45deg, rgb(178,187,194,0.1) 25%, transparent 25%, transparent 50%, rgb(178,187,194,0.1) 50%, rgb(178,187,194,0.1) 75%, transparent 75%, transparent 100%)"
          backgroundSize="8px 8px"
        >
          <SkillLogosRowSuspenseFallback />
        </GridItem>

        <GridItem
          backgroundImage="linear-gradient(45deg, rgb(178,187,194,0.1) 25%, transparent 25%, transparent 50%, rgb(178,187,194,0.1) 50%, rgb(178,187,194,0.1) 75%, transparent 75%, transparent 100%)"
          backgroundSize="8px 8px"
        >
          <SkillLogosRowSuspenseFallback />
        </GridItem>
      </SimpleGrid>
    </VStack>
  </HStack>
);

export const CandidateInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateInfo: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateInfoCatchFallback />}>
    <Suspense fallback={<CandidateInfoSuspenseFallback />}>
      <CandidateInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateInfo;
