import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const EndCallIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 8.66497V12.7773L6 12.1773V10.9971C6 10.6179 6.21448 10.2713 6.55385 10.1021C8.8348 8.96505 11.1652 8.96505 13.4461 10.1021C13.7855 10.2713 14 10.6179 14 10.9971V12.1773L17 12.7773V8.66113C14.2325 7.52109 12.0955 6.9954 10.0019 6.99997C7.90753 7.00454 5.76999 7.53981 3 8.66497ZM9.99755 4.99997C12.6288 4.99423 15.2104 5.71085 18.3946 7.07822L19 7.3382V12.7773C19 14.0394 17.8454 14.986 16.6078 14.7384L13.6078 14.1384C12.6729 13.9515 12 13.1306 12 12.1773V11.645C10.6587 11.1174 9.3413 11.1174 8 11.645V12.1773C8 13.1306 7.32708 13.9515 6.39223 14.1384L3.39223 14.7384C2.15465 14.986 1 14.0394 1 12.7773V7.3341L1.61068 7.07598C4.7888 5.73267 7.36827 5.00571 9.99755 4.99997Z" fill="currentColor" />
  </Icon>
);

export default EndCallIcon;
