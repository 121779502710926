import easeInCirc from './easeInCirc';
import easeInCubic from './easeInCubic';
import easeInExpo from './easeInExpo';
import easeInOutCirc from './easeInOutCirc';
import easeInOutCubic from './easeInOutCubic';
import easeInOutExpo from './easeInOutExpo';
import easeInOutQuad from './easeInOutQuad';
import easeInOutQuart from './easeInOutQuart';
import easeInOutQuint from './easeInOutQuint';
import easeInOutSine from './easeInOutSine';
import easeInQuad from './easeInQuad';
import easeInQuart from './easeInQuart';
import easeInQuint from './easeInQuint';
import easeInSine from './easeInSine';
import easeOutCirc from './easeOutCirc';
import easeOutCubic from './easeOutCubic';
import easeOutExpo from './easeOutExpo';
import easeOutQuad from './easeOutQuad';
import easeOutQuart from './easeOutQuart';
import easeOutQuint from './easeOutQuint';
import easeOutSine from './easeOutSine';

export enum Easing {
  EASE_IN_CIRC,
  EASE_IN_CUBIC,
  EASE_IN_EXPO,
  EASE_IN_OUT_CIRC,
  EASE_IN_OUT_CUBIC,
  EASE_IN_OUT_EXPO,
  EASE_IN_OUT_QUAD,
  EASE_IN_OUT_QUART,
  EASE_IN_OUT_QUINT,
  EASE_IN_OUT_SINE,
  EASE_IN_QUAD,
  EASE_IN_QUART,
  EASE_IN_QUINT,
  EASE_IN_SINE,
  EASE_OUT_CIRC,
  EASE_OUT_CUBIC,
  EASE_OUT_EXPO,
  EASE_OUT_QUAD,
  EASE_OUT_QUART,
  EASE_OUT_QUINT,
  EASE_OUT_SINE,
  LINEAR,
}

export default function ease(x: number, easing: Easing): number {
  switch (easing) {
    case Easing.EASE_IN_SINE: return easeInSine(x);
    case Easing.EASE_OUT_SINE: return easeOutSine(x);
    case Easing.EASE_IN_OUT_SINE: return easeInOutSine(x);

    case Easing.EASE_IN_CUBIC: return easeInCubic(x);
    case Easing.EASE_OUT_CUBIC: return easeOutCubic(x);
    case Easing.EASE_IN_OUT_CUBIC: return easeInOutCubic(x);

    case Easing.EASE_IN_QUINT: return easeInQuint(x);
    case Easing.EASE_OUT_QUINT: return easeOutQuint(x);
    case Easing.EASE_IN_OUT_QUINT: return easeInOutQuint(x);

    case Easing.EASE_IN_CIRC: return easeInCirc(x);
    case Easing.EASE_OUT_CIRC: return easeOutCirc(x);
    case Easing.EASE_IN_OUT_CIRC: return easeInOutCirc(x);

    case Easing.EASE_IN_QUAD: return easeInQuad(x);
    case Easing.EASE_OUT_QUAD: return easeOutQuad(x);
    case Easing.EASE_IN_OUT_QUAD: return easeInOutQuad(x);

    case Easing.EASE_IN_QUART: return easeInQuart(x);
    case Easing.EASE_OUT_QUART: return easeOutQuart(x);
    case Easing.EASE_IN_OUT_QUART: return easeInOutQuart(x);

    case Easing.EASE_IN_EXPO: return easeInExpo(x);
    case Easing.EASE_OUT_EXPO: return easeOutExpo(x);
    case Easing.EASE_IN_OUT_EXPO: return easeInOutExpo(x);

    case Easing.LINEAR:
    default: return x;
  }
}
