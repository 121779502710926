import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

export interface Props extends TextareaProps {
  label: string;
  name: string;
}

const TextareaField: React.FC<Props> = ({
  label, name, isRequired, ...props
}: Props) => {
  const [field, meta] = useField(name);

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <Textarea
        isRequired={isRequired}
        onKeyUp={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default TextareaField;
