import { VStack } from '@chakra-ui/react';
import { DocumentReference, query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import { useGoogleCalendarsCollectionRef } from '../../types/GoogleCalendar';
import { GoogleCredentialsDoc } from '../../types/GoogleCredentials';
import Catch from '../Catch';
import { useUserRef } from '../UserRefContext';
import GoogleCalendar from './GoogleCalendar';

export interface Props {
  credentialsRef: DocumentReference<GoogleCredentialsDoc>;
}

const GoogleCalendarsMain: React.FC<Props> = ({ credentialsRef }) => {
  const userRef = useUserRef();
  const googleCalendarsCollectionRef = useGoogleCalendarsCollectionRef();
  const { data: googleCalendarsSnap } = useFirestoreCollection(
    query(
      googleCalendarsCollectionRef,
      where('userRef', '==', userRef),
      where('credentialsRef', '==', credentialsRef),
    ),
  );

  if (!googleCalendarsSnap.docs.length) {
    return null;
  }

  return (
    <VStack alignItems="stretch" spacing={2}>
      {googleCalendarsSnap.docs.map((googleCalendarSnap) => (
        <GoogleCalendar
          key={googleCalendarSnap.id}
          googleCalendarSnap={googleCalendarSnap}
        />
      ))}
    </VStack>
  );
};

export const GoogleCalendarsCatchFallback: React.FC = () => null;
export const GoogleCalendarsSuspenseFallback: React.FC = () => (<>Loading</>);

/* eslint-disable react/jsx-props-no-spreading */
const GoogleCalendars: React.FC<Props> = (props) => (
  <Catch fallback={<GoogleCalendarsCatchFallback />}>
    <Suspense fallback={<GoogleCalendarsSuspenseFallback />}>
      <GoogleCalendarsMain {...props} />
    </Suspense>
  </Catch>
);

export default GoogleCalendars;
