import { useToken } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router';
import BlockListItem from '../../../../components/BlockListItem';
import InterviewInfo from '../../../../components/InterviewInfo';
import useInterviewStatusColor from '../../../../hooks/useInterviewStatusColor';
import { InterviewDoc } from '../../../../types/Interview';

export type Props = {
  interviewSnap: QueryDocumentSnapshot<InterviewDoc>;
};

const InterviewRow: React.FC<Props> = ({ interviewSnap }) => {
  const statusColor = useInterviewStatusColor(interviewSnap);
  const [styledColor] = useToken('colors', [statusColor]);

  const navigate = useNavigate();

  const onClick = useCallback(
    () => navigate(`/interviews/${interviewSnap.id}`),
    [interviewSnap.id, navigate],
  );

  const match = useMatch(`/interviews/${interviewSnap.id}`);

  return (
    <BlockListItem
      onClick={onClick}
      boxShadow={`inset 4px 0px ${styledColor}`}
      aria-pressed={!!match}
    >
      <InterviewInfo interviewSnap={interviewSnap} />
    </BlockListItem>
  );
};

export default InterviewRow;
