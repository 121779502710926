import { DocumentData, DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import React, { ReactElement, ReactNode } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export type Props<T> = {
  reference: DocumentReference<T>;
  children: (snapshot: QueryDocumentSnapshot<T>) => ReactNode;
};

// eslint-disable-next-line react/function-component-definition
function DocumentLoader<T = DocumentData>({ reference, children }: Props<T>): ReactElement {
  const { data: snap } = useFirestoreDoc(reference);

  if (!snap.exists()) {
    throw new SnapNotFoundError(snap);
  }

  return (
    <>
      {children(snap)}
    </>
  );
}

export default DocumentLoader;
