import {
  CircularProgress,
  HStack,
  Skeleton,
  SkeletonCircle,
  StackProps,
  Text,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { ScoreDoc } from '../types/Score';
import Catch from './Catch';

export type Props = StackProps & ThemingProps<'ScoreShortInfo'> & {
  scoreSnap: QueryDocumentSnapshot<ScoreDoc>;
};

const ScoreShortInfoMain: React.FC<Props> = ({ scoreSnap, sx, ...props }) => {
  const score = useMemo(() => scoreSnap.data(), [scoreSnap]);

  const style = useStyleConfig('ScoreShortInfo', {});

  return (
    <HStack
      spacing={1}
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Text color="cf.cntSecondary" variant="labelMedium">
        {Math.round(score.score * 100)}
      </Text>

      <CircularProgress
        value={Math.round(score.score * 100)}
        color="cf.cntAccent"
        size={4}
        py={0.5}
      />
    </HStack>
  );
};

export const ScoreShortInfoCatchFallback: React.FC = () => null;

export const ScoreShortInfoSuspenseFallback: React.FC<StackProps & ThemingProps<'ScoreShortInfoSkeleton'>> = ({ sx, ...props }) => {
  const style = useStyleConfig('ScoreShortInfoSkeleton', {});

  return (
    <HStack
      spacing={2}
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Skeleton h={5} w="28px" />
      <SkeletonCircle boxSize={5} />
    </HStack>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const ScoreShortInfo: React.FC<Props> = (props) => (
  <Catch fallback={<ScoreShortInfoCatchFallback />}>
    <Suspense fallback={<ScoreShortInfoSuspenseFallback />}>
      <ScoreShortInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default ScoreShortInfo;
