import {
  Button,
  HStack,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import Catch from '../../components/Catch';
import MissionKeplerCard from '../../components/MissionKeplerCard';
import MissionMarsCard from '../../components/MissionMarsCard';
import MissionMoonCard from '../../components/MissionMoonCard';
import MissionTitanCard from '../../components/MissionTitanCard';
import PaddingBlock from '../../components/PaddingBlock';
import usePlatformTier from '../../hooks/usePlatformTier';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';

export type Props = {
  onPrevious: () => unknown;
  onNext: () => unknown;
};

const MissionScreenMain: React.FC<Props> = ({
  onPrevious,
  onNext,
}) => {
  const tier = usePlatformTier();

  return (
    <VStack spacing={8} alignItems="stretch" textAlign="left">
      <PaddingBlock>
        <VStack spacing={3} alignItems="stretch">
          <Text>
            In this final step, it&apos;s time to select the subscription plan that perfectly aligns
            with your organization&apos;s needs. We offer a range of plans tailored to cater to
            various requirements, from self-employed head hunters to large enterprises. You can
            begin your journey with our free plan, which includes essential features such as
            interview auto-scheduler, unlimited user accounts for your colleagues, calendar
            availability sync, built-in video calls, collaborative coding, a whiteboard for
            architectural thinking assessment, and our tailored knowledge base.
          </Text>

          <Text>
            For those looking to elevate their interviewing process further, we offer advanced plans
            with additional features like interview recording, interview ghosting, phone call
            reminders, and the ability to customize your knowledge base. This flexibility ensures
            that you can choose the plan that best suits your organization&apos;s unique needs,
            empowering you to streamline your technical interviewing process and make smarter hiring
            decisions. Let&apos;s find the perfect plan to help you achieve your hiring goals!
          </Text>
        </VStack>
      </PaddingBlock>

      <SimpleGrid columns={2} columnGap={5} rowGap={3}>
        <MissionMoonCard />
        <MissionMarsCard />
        <MissionTitanCard />
        <MissionKeplerCard />
      </SimpleGrid>

      <HStack justifyContent="right">
        <Button
          variant="outline"
          onClick={onPrevious}
          leftIcon={<ArrowUpIcon />}
        >
          Previous
        </Button>

        <Spacer />

        <Button
          onClick={onNext}
          leftIcon={<ArrowDownIcon />}
          isDisabled={!tier}
        >
          Next
        </Button>
      </HStack>
    </VStack>
  );
};

export const MissionScreenCatchFallback: React.FC = () => null;
export const MissionScreenSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const MissionScreen: React.FC<Props> = (props) => (
  <Catch fallback={<MissionScreenCatchFallback />}>
    <Suspense fallback={<MissionScreenSuspenseFallback />}>
      <MissionScreenMain {...props} />
    </Suspense>
  </Catch>
);

export default MissionScreen;
