import { HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import useCurrentTime from '../../../../hooks/useCurrentTime';
import useTimezone from '../../../../hooks/useTimezone';
import VideoOnIcon from '../../../../icons/VideoOnIcon';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const StartedAtMain: React.FC = () => {
  const timezone = useTimezone();
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const currentTime = useCurrentTime(1000 * 60);
  const when = useMemo(
    () => moment(interview.startedAt?.toDate())
      .tz(timezone)
      .calendar(currentTime, {
        sameDay: '[today at] LT',
        nextDay: '[tomorrow at] LT',
        nextWeek: 'dddd [at] LT',
        lastDay: '[yesterday at] LT',
        lastWeek: '[last] dddd [at] LT',
        sameElse: 'DD/MM/YYYY',
      }),
    [currentTime, interview.startedAt, timezone],
  );

  if (!interview.startedAt) {
    return null;
  }

  return (
    <HStack spacing={2}>
      <VideoOnIcon />
      <Text
        variant="subtitle"
        fontWeight="medium"
        flexGrow={1}
        flexShrink={1}
        whiteSpace="nowrap"
        minW={0}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        <>
          <Text as="span" color="cf.status.started">Started</Text>
          {' '}
          {when}
        </>
      </Text>
    </HStack>
  );
};

const StartedAtCatchFallback: React.FC = () => null;
const StartedAtSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StartedAt: React.FC = () => (
  <Catch fallback={<StartedAtCatchFallback />}>
    <Suspense fallback={<StartedAtSuspenseFallback />}>
      <StartedAtMain />
    </Suspense>
  </Catch>
);

export default StartedAt;
