import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { InterviewDoc } from './Interview';

export enum TwilioRoomStatus {
  CREATED = 'CREATED',
  ENDED = 'ENDED',
}

export type TwilioRoomDoc = {
  interviewRef: DocumentReference<InterviewDoc>;
  lastUpdate: Timestamp;
  status: TwilioRoomStatus;
  createdAt: Timestamp;
} & (
  | {
    status: TwilioRoomStatus.CREATED;
  }
  | {
    status: TwilioRoomStatus.ENDED;
    endedAt: Timestamp;
  }
);

export const isTwilioRoomDoc = (
  data: DocumentData,
): data is TwilioRoomDoc => true;

export const isTwilioRoomRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TwilioRoomDoc> => ref.parent.id === 'twilioRooms';

export const isTwilioRoomSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TwilioRoomDoc> => isTwilioRoomRef(snap.ref);

export const useTwilioRoomsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'twilioRooms') as CollectionReference<TwilioRoomDoc>, [firestore]);
};

export const useTwilioRoomDocRef = (docId: string) => {
  const collectionRef = useTwilioRoomsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
