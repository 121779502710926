import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CheckIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7071 6.70712L8.70711 14.7071C8.31658 15.0976 7.68342 15.0976 7.29289 14.7071L3.29289 10.7071L4.70711 9.29291L8 12.5858L15.2929 5.29291L16.7071 6.70712Z" fill="currentColor" />
  </Icon>
);

export default CheckIcon;
