import React, { Suspense } from 'react';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import LocalDataProvider from '../../../components/LocalDataProvider';
import LocalMediaInputProvider from '../../../components/LocalMediaInputProvider';
import RoomProvider, { RoomContext } from '../../../components/RoomProvider';
import CallRoom from './CallRoom';
import Lobby from './Lobby';

const IntervieweeScreenMain: React.FC = () => (
  <LocalMediaInputProvider>
    <LocalDataProvider>
      <RoomProvider>
        <RoomContext.Consumer>
          {(context) => (
            context?.room ? (
              <CallRoom />
            ) : (
              <Lobby />
            )
          )}
        </RoomContext.Consumer>
      </RoomProvider>
    </LocalDataProvider>
  </LocalMediaInputProvider>
);

export const IntervieweeScreenCatchFallback: React.FC = () => null;
export const IntervieweeScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const IntervieweeScreen: React.FC = () => (
  <Catch fallback={<IntervieweeScreenCatchFallback />}>
    <Suspense fallback={<IntervieweeScreenSuspenseFallback />}>
      <IntervieweeScreenMain />
    </Suspense>
  </Catch>
);

export default IntervieweeScreen;
