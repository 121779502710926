import { IconButton } from '@chakra-ui/react';
import React from 'react';
import SettingsIcon from '../icons/SettingsIcon';
import { useLocalMediaInputSettingsOpen } from './LocalMediaInputProvider';

const SettingsButton: React.FC = () => {
  const localMediaInputSettingsOpen = useLocalMediaInputSettingsOpen();

  return (
    <IconButton
      aria-label="Settings"
      h="44px"
      w="44px"
      borderRadius="100%"
      colorScheme="blackAlpha"
      icon={<SettingsIcon />}
      onClick={localMediaInputSettingsOpen}
    />
  );
};

export default SettingsButton;
