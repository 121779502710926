import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = { skillIds: string[]; };

export type ResponseData = void;

const useUserRequestSkills = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'user-requestskills'),
    [functions],
  );
};

export default useUserRequestSkills;
