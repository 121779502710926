import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { InterviewDoc } from './Interview';
import { OrganizationDoc } from './Organization';
import { TwilioRoomDoc } from './TwilioRoom';
import { UserDoc } from './User';

export enum TwilioCompositionStatus {
  ENQUEUED = 'ENQUEUED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
}

export type TwilioCompositionDoc = {
  interviewRef: DocumentReference<InterviewDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  expertRefs: DocumentReference<ExpertDoc>[];
  vendorRefs: DocumentReference<OrganizationDoc>[];
  intervieweeRef?: DocumentReference<UserDoc>;
  interviewerRefs: DocumentReference<UserDoc>[];
  twilioRoomRef: DocumentReference<TwilioRoomDoc>;
  lastUpdate: Timestamp;
  enqueuedAt?: Timestamp;
  completedAt?: Timestamp;
  uploadedAt?: Timestamp;
  ref?: string;
  status: TwilioCompositionStatus;
  progress?: number;
  start: Timestamp;
  end: Timestamp;
  _v: 1;
};

export const isTwilioCompositionDoc = (
  data: DocumentData,
): data is TwilioCompositionDoc => true;

export const isTwilioCompositionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TwilioCompositionDoc> => ref.parent.id === 'twilioCompositions';

export const isTwilioCompositionSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TwilioCompositionDoc> => isTwilioCompositionRef(snap.ref);

export const useTwilioCompositionsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'twilioCompositions') as CollectionReference<TwilioCompositionDoc>, [firestore]);
};

export const useTwilioCompositionDocRef = (docId: string) => {
  const collectionRef = useTwilioCompositionsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
