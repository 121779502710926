import React, { Suspense, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useCallableFunctionResponse } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';

const UserMicrosoftRedirectPageMain: React.FC = () => {
  const { search } = useLocation();
  const data = useMemo(
    () => {
      const query = new URLSearchParams(search);
      const state = query.get('state');
      const code = query.get('code');

      if (code === null || state === null) {
        throw new Error();
      }

      return {
        state,
        code,
      };
    },
    [search],
  );

  useCallableFunctionResponse<{ code: string; state: string; }, void>(
    'microsoft-calendar-addcredentials',
    { data },
  );

  window.close();

  return null;
};

const UserMicrosoftRedirectPageCatchFallback: React.FC = () => null;
const UserMicrosoftRedirectPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const UserMicrosoftRedirectPage: React.FC = () => (
  <Catch fallback={<UserMicrosoftRedirectPageCatchFallback />}>
    <Suspense fallback={<UserMicrosoftRedirectPageSuspenseFallback />}>
      <UserMicrosoftRedirectPageMain />
    </Suspense>
  </Catch>
);

export default UserMicrosoftRedirectPage;
