import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const LinkedInIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} color="rgb(0,119,183)" viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" {...props}>
    <g transform="matrix(0.052356,0,0,0.052356,0,0)">
      <path d="M347.445,0L34.555,0C15.471,0 0,15.471 0,34.555L0,347.444C0,366.529 15.471,382 34.555,382L347.444,382C366.529,382 382,366.529 382,347.444L382,34.555C382,15.471 366.529,0 347.445,0ZM118.207,329.844C118.207,335.398 113.705,339.9 108.151,339.9L65.345,339.9C59.791,339.9 55.289,335.398 55.289,329.844L55.289,150.403C55.289,144.849 59.791,140.347 65.345,140.347L108.151,140.347C113.705,140.347 118.207,144.849 118.207,150.403L118.207,329.844ZM86.748,123.432C64.289,123.432 46.082,105.225 46.082,82.766C46.082,60.307 64.289,42.1 86.748,42.1C109.207,42.1 127.414,60.307 127.414,82.766C127.414,105.225 109.208,123.432 86.748,123.432ZM341.91,330.654C341.91,335.76 337.77,339.9 332.664,339.9L286.73,339.9C281.624,339.9 277.484,335.76 277.484,330.654L277.484,246.486C277.484,233.93 281.167,191.465 244.671,191.465C216.362,191.465 210.62,220.531 209.467,233.575L209.467,330.654C209.467,335.76 205.328,339.9 200.221,339.9L155.795,339.9C150.689,339.9 146.549,335.76 146.549,330.654L146.549,149.593C146.549,144.487 150.689,140.347 155.795,140.347L200.221,140.347C205.327,140.347 209.467,144.487 209.467,149.593L209.467,165.248C219.964,149.495 235.564,137.336 268.779,137.336C342.331,137.336 341.91,206.052 341.91,243.808L341.91,330.654Z" fill="currentColor" fillRule="nonzero" />
    </g>
  </Icon>
);

export default LinkedInIcon;
