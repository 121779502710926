import { Monaco } from '@monaco-editor/react';
import { doc } from 'firebase/firestore';
import { editor as monacoEditor } from 'monaco-editor';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import { RemoteParticipant } from 'twilio-video';
import useParticipantDataTrack from '../../hooks/useParticipantDataTrack';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import { useUsersCollectionRef } from '../../types/User';
import Catch from '../Catch';
import {
  CodeEditorCursorPositionUpdateMessage,
  CodeEditorCursorSelectionUpdateMessage,
  DataMessage,
  MessageType,
} from './types';

export type Props = {
  participant: RemoteParticipant,
  monaco: Monaco;
  editor: monacoEditor.IStandaloneCodeEditor;
};

const ParticipantCursorMain: React.FC<Props> = ({
  participant,
  monaco,
  editor,
}) => {
  const usersCollectionRef = useUsersCollectionRef();
  const userRef = useMemo(
    () => doc(usersCollectionRef, participant.identity),
    [participant.identity, usersCollectionRef],
  );
  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const user = useMemo(() => userSnap.data(), [userSnap]);

  const participantDataTrack = useParticipantDataTrack(participant, 'data');

  const [
    cursorPosition,
    setCursorPosition,
  ] = useState<monacoEditor.IEditorDecorationsCollection | null>(null);

  const [
    cursorSelection,
    setCursorSelection,
  ] = useState<monacoEditor.IEditorDecorationsCollection | null>(null);

  useEffect(
    () => {
      const cp = editor.createDecorationsCollection();
      setCursorPosition(cp);

      const cs = editor.createDecorationsCollection();
      setCursorSelection(cs);

      return () => {
        cp.clear();
        cs.clear();
      };
    },
    [editor],
  );

  const handleCodeEditorCursorPositionUpdate = useCallback(
    (e: CodeEditorCursorPositionUpdateMessage) => {
      // console.log('CODE_EDITOR_CURSOR_POSITION_UPDATE', e.event);

      if (editor && monaco) {
        if (cursorPosition) {
          cursorPosition.set([
            {
              range: new monaco.Range(
                e.event.position.lineNumber,
                e.event.position.column,
                e.event.position.lineNumber,
                e.event.position.column,
              ),
              options: {
                afterContentClassName: 'intervieweeCodeEditorCursorPosition',
                hoverMessage: { value: `${user.firstName} ${user.lastName}` },
              },
            },
          ]);
        }
      }
    },
    [editor, monaco, cursorPosition, user.firstName, user.lastName],
  );

  const handleCodeEditorCursorSelectionUpdate = useCallback(
    (e: CodeEditorCursorSelectionUpdateMessage) => {
      // console.log('CODE_EDITOR_CURSOR_SELECTION_UPDATE', e.event);

      if (editor && monaco) {
        if (cursorSelection) {
          cursorSelection.set([
            {
              range: new monaco.Range(
                e.event.selection.selectionStartLineNumber,
                e.event.selection.selectionStartColumn,
                e.event.selection.positionLineNumber,
                e.event.selection.positionColumn,
              ),
              options: {
                inlineClassName: 'intervieweeCodeEditorCursorSelection',
                hoverMessage: { value: `${user.firstName} ${user.lastName}` },
              },
            },
          ]);
        }
      }
    },
    [editor, monaco, cursorSelection, user.firstName, user.lastName],
  );

  const processMessage = useCallback(
    (e: DataMessage) => {
      switch (e.type) {
        case MessageType.CODE_EDITOR_CURSOR_POSITION_UPDATE: {
          handleCodeEditorCursorPositionUpdate(e);
          break;
        }
        case MessageType.CODE_EDITOR_CURSOR_SELECTION_UPDATE: {
          handleCodeEditorCursorSelectionUpdate(e);
          break;
        }
      }
    },
    [
      handleCodeEditorCursorPositionUpdate,
      handleCodeEditorCursorSelectionUpdate,
    ],
  );

  useEffect(
    () => {
      if (participant && participantDataTrack) {
        const message = (v: string | ArrayBuffer) => {
          if (typeof v === 'string') {
            const e = JSON.parse(v) as DataMessage;

            processMessage(e);
          }
        };

        // console.log('add interviewee track');
        participantDataTrack.on('message', message);

        return () => {
          // console.log('remove interviewee track');
          participantDataTrack.off('message', message);
        };
      }

      return () => { };
    },
    [
      participant,
      participantDataTrack,
      processMessage,
    ],
  );

  return null;
};

const ParticipantCursorCatchFallback: React.FC = () => null;
const ParticipantCursorSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ParticipantCursor: React.FC<Props> = (props) => (
  <Catch fallback={<ParticipantCursorCatchFallback />}>
    <Suspense fallback={<ParticipantCursorSuspenseFallback />}>
      <ParticipantCursorMain {...props} />
    </Suspense>
  </Catch>
);

export default ParticipantCursor;
