import {
  HStack,
  IconButton,
  Tag,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
  useClipboard,
  useToast,
} from '@chakra-ui/react';
import { DocumentSnapshot } from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import Catch from '../../components/Catch';
import useApiRemoveWebhook from '../../functions/useApiRemoveWebhook';
import CheckIcon from '../../icons/CheckIcon';
import CopyIcon from '../../icons/CopyIcon';
import Spinner from '../../icons/Spinner';
import TrashIcon from '../../icons/TrashIcon';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import { WebhookDoc } from '../../types/Webhook';

export type Props = {
  webhookSnap: DocumentSnapshot<WebhookDoc>;
};

const WebhookItemMain: React.FC<Props> = ({ webhookSnap }) => {
  if (!webhookSnap.exists()) {
    throw new SnapNotFoundError(webhookSnap);
  }

  const webhook = useMemo(() => webhookSnap.data(), [webhookSnap]);

  const removeWebhook = useApiRemoveWebhook();
  const toast = useToast();

  const [isRemoving, setRemoving] = useState(false);
  const handleRemoveWebhookClick = useCallback(
    async () => {
      try {
        setRemoving(true);
        await removeWebhook({ webhookId: webhookSnap.id });

        toast({
          title: 'Webhook removed',
          status: 'success',
        });
      } catch (err) {
        if (err instanceof Error) {
          toast({
            title: 'Failed to removed webhook',
            status: 'error',
            description: err.message,
          });
        }
      } finally {
        setRemoving(false);
      }
    },
    [removeWebhook, toast, webhookSnap.id],
  );

  const { hasCopied, onCopy: handleCopyLinkClick } = useClipboard(webhook.secret);

  return (
    <HStack>
      <VStack flexGrow={1} flexShrink={1} alignItems="stretch" spacing={1}>
        <Text variant="labelMedium">
          {webhook.url}
        </Text>

        <Wrap spacing={1}>
          {webhook.eventTypes.map((eventType) => (
            <WrapItem key={eventType}>
              <Tag variant="outline" size="sm">{eventType}</Tag>
            </WrapItem>
          ))}
        </Wrap>
      </VStack>

      <Tooltip label="Copy secret">
        <IconButton
          aria-label="Copy secret"
          variant="outline"
          icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
          onClick={handleCopyLinkClick}
          flexShrink={0}
        />
      </Tooltip>

      <Tooltip label="Remove">
        <IconButton
          aria-label="Remove"
          colorScheme="negative"
          icon={<TrashIcon />}
          onClick={handleRemoveWebhookClick}
          flexShrink={0}
          isLoading={isRemoving}
          spinner={<Spinner />}
        />
      </Tooltip>
    </HStack>
  );
};

export const WebhookItemCatchFallback: React.FC = () => null;
export const WebhookItemSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const WebhookItem: React.FC<Props> = (props) => (
  <Catch fallback={<WebhookItemCatchFallback />}>
    <Suspense fallback={<WebhookItemSuspenseFallback />}>
      <WebhookItemMain {...props} />
    </Suspense>
  </Catch>
);

export default WebhookItem;
