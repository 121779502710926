import { SearchOptions } from '@algolia/client-search';
import { DocumentReference, doc } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useExpertsIndex } from '../../../components/AlgoliaOrganizationProvider';
import {
  CandidateRowCatchFallback,
  CandidateRowSuspenseFallback,
} from '../../../components/CandidateRow';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import ExpertRow from '../../../components/ExpertRow';
import { AlgoliaCandidateRecord } from '../../../types/AlgoliaRecords';
import { ExpertDoc, useExpertsCollectionRef } from '../../../types/Expert';

export interface Props {
  query: string;
  searchOptions: SearchOptions;
  visible: boolean;
  page: number;
  hitsPerPage: number;
  onNbPagesChange: (pages: number) => unknown;
}

const ExpertSearchPageMain: React.FC<Props> = ({
  query,
  searchOptions,
  visible,
  page,
  hitsPerPage,
  onNbPagesChange,
}) => {
  const expertsIndex = useExpertsIndex();

  const [expertRefs, setExpertRefs] = useState<Array<DocumentReference<ExpertDoc>>>();

  const expertsCollectionRef = useExpertsCollectionRef();

  useEffect(() => {
    if (!expertsIndex || !visible) {
      return;
    }

    expertsIndex.search<AlgoliaCandidateRecord>(query, { ...searchOptions, hitsPerPage, page })
      .then((res) => {
        setExpertRefs(res.hits.map(
          (hit) => doc(expertsCollectionRef, hit.objectID),
        ));
        onNbPagesChange(res.nbPages);
      })
      .catch(() => {
        /* do nothing */
      });
  }, [
    expertsIndex,
    expertsCollectionRef,
    hitsPerPage,
    onNbPagesChange,
    page,
    query,
    searchOptions,
    visible,
  ]);

  if (!expertsIndex || !expertRefs) {
    return (
      <ExpertSearchPageSuspenseFallback size={hitsPerPage} />
    );
  }

  return (
    <>
      {expertRefs.map((expertRef) => (
        <Catch key={expertRef.path} fallback={<CandidateRowCatchFallback />}>
          <Suspense fallback={<CandidateRowSuspenseFallback />}>
            <DocumentLoader reference={expertRef}>
              {(expertSnap) => (
                <ExpertRow expertSnap={expertSnap} />
              )}
            </DocumentLoader>
          </Suspense>
        </Catch>
      ))}
    </>
  );
};

export const ExpertSearchPageSuspenseFallback: React.FC<{ size: number }> = ({ size }) => (
  <>
    {_.range(size).map((key) => (
      <CandidateRowSuspenseFallback key={key} />
    ))}
  </>
);

export const ExpertSearchPageCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertSearchPage: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertSearchPageCatchFallback />}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <Suspense fallback={<ExpertSearchPageSuspenseFallback size={props.hitsPerPage} />}>
      <ExpertSearchPageMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertSearchPage;
