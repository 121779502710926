import * as Automerge from '@automerge/automerge';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useCallback, useEffect } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useParticipantDataTrack from '../../hooks/useParticipantDataTrack';
import { InterviewScheduleItemDoc } from '../../types/InterviewScheduleItem';
import Catch from '../Catch';

export type Props = {
  participant: RemoteParticipant;
  scheduleItemRef: DocumentReference<InterviewScheduleItemDoc>;
  automergeDoc: Automerge.Doc<{
    value: Automerge.Text;
    language: string;
  }>
  setAutomergeDoc: (v: Automerge.Doc<{
    value: Automerge.Text;
    language: string;
  }>) => unknown;
};

const ParticipantChangesMain: React.FC<Props> = ({
  participant,
  scheduleItemRef,
  automergeDoc,
  setAutomergeDoc,
}) => {
  const codeEditorTrack = useParticipantDataTrack(
    participant,
    `code-editor-${scheduleItemRef.id}`,
  );

  const onCodeEditorIncomingMessage = useCallback(
    (v: Uint8Array) => {
      const incomingAutomergeDoc = Automerge.load<{ value: Automerge.Text, language: string }>(
        new Uint8Array(v),
      );

      const newAutomergeDoc = Automerge.merge(automergeDoc, incomingAutomergeDoc);

      setAutomergeDoc(newAutomergeDoc);
    },
    [automergeDoc, setAutomergeDoc],
  );

  useEffect(
    () => {
      if (codeEditorTrack) {
        codeEditorTrack.on('message', onCodeEditorIncomingMessage);

        return () => {
          codeEditorTrack.off('message', onCodeEditorIncomingMessage);
        };
      }

      return () => { };
    },
    [codeEditorTrack, onCodeEditorIncomingMessage],
  );

  return null;
};

const ParticipantChangesCatchFallback: React.FC = () => null;
const ParticipantChangesSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ParticipantChanges: React.FC<Props> = (props) => (
  <Catch fallback={<ParticipantChangesCatchFallback />}>
    <Suspense fallback={<ParticipantChangesSuspenseFallback />}>
      <ParticipantChangesMain {...props} />
    </Suspense>
  </Catch>
);

export default ParticipantChanges;
