import _ from 'lodash';
import { OrganizationPlatformTier } from '../types/OrganizationCustomerProfile';
import usePlatformTier from './usePlatformTier';

const useMinPlatformTier = (minTier: OrganizationPlatformTier): boolean => {
  const platformTier = usePlatformTier();

  switch (minTier) {
    case OrganizationPlatformTier.MOON: {
      return _.includes([
        OrganizationPlatformTier.MOON,
        OrganizationPlatformTier.MARS,
        OrganizationPlatformTier.TITAN,
        OrganizationPlatformTier.KEPLER,
      ], platformTier);
    }
    case OrganizationPlatformTier.MARS: {
      return _.includes([
        OrganizationPlatformTier.MARS,
        OrganizationPlatformTier.TITAN,
        OrganizationPlatformTier.KEPLER,
      ], platformTier);
    }
    case OrganizationPlatformTier.TITAN: {
      return _.includes([
        OrganizationPlatformTier.TITAN,
        OrganizationPlatformTier.KEPLER,
      ], platformTier);
    }
    case OrganizationPlatformTier.KEPLER: {
      return _.includes([
        OrganizationPlatformTier.KEPLER,
      ], platformTier);
    }
    default: {
      return false;
    }
  }
};

export default useMinPlatformTier;
