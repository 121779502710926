import { Collaborator, ExcalidrawAPIRefValue } from '@excalidraw/excalidraw/types/types';
import { DocumentReference, doc, getDoc } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc, useStorage } from 'reactfire';
import { RemoteParticipant } from 'twilio-video';
import useParticipantDataTrack from '../../hooks/useParticipantDataTrack';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import { UserDoc, useUsersCollectionRef } from '../../types/User';
import Catch from '../Catch';
import {
  DataMessage,
  MessageType,
  WhiteboardElementUpdateMessage,
  WhiteboardFileUpdateMessage,
  WhiteboardPointerUpdateMessage,
  WhiteboardSelectionUpdateMessage,
} from './types';

const useCollaboratorData = (userRef: DocumentReference<UserDoc>): Collaborator => {
  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const user = useMemo(() => userSnap.data(), [userSnap]);

  const [avatarUrl, setAvatarUrl] = useState<string | undefined>();

  const storage = useStorage();

  useEffect(
    () => {
      if (user.avatarRef) {
        getDoc(user.avatarRef)
          .then((avatarSnap) => {
            if (avatarSnap.exists()) {
              const avatar = avatarSnap.data();

              if (avatar['2xl']?.['3x']) {
                const reference = ref(storage, avatar['2xl']['3x']);
                setAvatarUrl(`https://storage.googleapis.com/${reference.bucket}/${reference.fullPath}`);
              }
            }
          });
      }
    },
    [user.avatarRef, storage],
  );

  return {
    username: `${user.firstName} ${user.lastName}`,
    avatarUrl,
  };
};

export type Props = {
  participant: RemoteParticipant;
  excalidraw: ExcalidrawAPIRefValue | null;
};

const CallWhiteboardParticipantMain: React.FC<Props> = ({
  participant,
  excalidraw,
}) => {
  const participantDataTrack = useParticipantDataTrack(participant, 'data');

  const usersCollectionRef = useUsersCollectionRef();
  const userRef = useMemo(
    () => doc(usersCollectionRef, participant.identity),
    [participant, usersCollectionRef],
  );
  const collaboratorData = useCollaboratorData(userRef);
  useEffect(
    () => {
      if (excalidraw?.ready) {
        const { collaborators } = excalidraw.getAppState();

        const collaborator = collaborators.get(participant.identity);

        const nextCollaborator: Collaborator = {
          ...(collaborator || {}),
          ...collaboratorData,
        };

        if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
          collaborators.set(participant.identity, nextCollaborator);
          excalidraw.updateScene({ collaborators });
        }
      }
    },
    [
      collaboratorData,
      excalidraw,
      participant.identity,
    ],
  );

  const handleElementIncomingUpdate = useCallback(
    ({ element: incomingElement }: WhiteboardElementUpdateMessage) => {
      if (excalidraw?.ready) {
        const canvasElements = excalidraw.getSceneElementsIncludingDeleted();

        const canvasExisting = _.find(
          canvasElements,
          (canvasElement) => canvasElement.id === incomingElement.id,
        );

        if (
          !canvasExisting
          || canvasExisting.updated < incomingElement.updated
        ) {
          excalidraw.updateScene({
            elements: [
              ..._.filter(canvasElements, (element) => element.id !== incomingElement.id),
              incomingElement,
            ],
          });
        }
      }
    },
    [excalidraw],
  );

  const handleFileIncomingUpdate = useCallback(
    ({ file }: WhiteboardFileUpdateMessage) => {
      if (excalidraw?.ready) {
        excalidraw.addFiles([file]);
      }
    },
    [excalidraw],
  );

  const handlePointerIncomingUpdate = useCallback(
    ({ pointer, button }: WhiteboardPointerUpdateMessage) => {
      if (excalidraw?.ready) {
        const { collaborators } = excalidraw.getAppState();

        const collaborator = collaborators.get(participant.identity);

        const nextCollaborator: Collaborator = {
          ...(collaborator || {}),
          pointer,
          button,
        };

        if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
          collaborators.set(participant.identity, nextCollaborator);
          excalidraw.updateScene({ collaborators });
        }
      }
    },
    [excalidraw, participant.identity],
  );

  const handleSelectionIncomingUpdate = useCallback(
    ({ selectedElementIds }: WhiteboardSelectionUpdateMessage) => {
      if (excalidraw?.ready) {
        const { collaborators } = excalidraw.getAppState();

        const collaborator = collaborators.get(participant.identity);

        const nextCollaborator: Collaborator = {
          ...(collaborator || {}),
          selectedElementIds,
        };

        if (!collaborator || !_.isEqual(collaborator, nextCollaborator)) {
          collaborators.set(participant.identity, nextCollaborator);
          excalidraw.updateScene({ collaborators });
        }
      }
    },
    [excalidraw, participant.identity],
  );

  const processMessage = useCallback(
    (e: DataMessage) => {
      switch (e.type) {
        case MessageType.WHITEBOARD_ELEMENT_UPDATE: {
          handleElementIncomingUpdate(e);
          break;
        }
        case MessageType.WHITEBOARD_FILE_UPDATE: {
          handleFileIncomingUpdate(e);
          break;
        }
        case MessageType.WHITEBOARD_POINTER_UPDATE: {
          handlePointerIncomingUpdate(e);
          break;
        }
        case MessageType.WHITEBOARD_SELECTION_UPDATE: {
          handleSelectionIncomingUpdate(e);
          break;
        }
      }
    },
    [
      handleElementIncomingUpdate,
      handleFileIncomingUpdate,
      handlePointerIncomingUpdate,
      handleSelectionIncomingUpdate,
    ],
  );

  useEffect(
    () => {
      if (participant && participantDataTrack) {
        const message = (v: string | ArrayBuffer) => {
          if (typeof v === 'string') {
            const e = JSON.parse(v) as DataMessage;

            processMessage(e);
          }
        };

        participantDataTrack.on('message', message);

        return () => {
          participantDataTrack.off('message', message);
        };
      }

      return () => { };
    },
    [
      participant,
      participantDataTrack,
      processMessage,
    ],
  );

  return null;
};

const CallWhiteboardParticipantCatchFallback: React.FC = () => null;
const CallWhiteboardParticipantSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallWhiteboardParticipant: React.FC<Props> = (props) => (
  <Catch fallback={<CallWhiteboardParticipantCatchFallback />}>
    <Suspense fallback={<CallWhiteboardParticipantSuspenseFallback />}>
      <CallWhiteboardParticipantMain {...props} />
    </Suspense>
  </Catch>
);

export default CallWhiteboardParticipant;
