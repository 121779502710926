import { FormControl, FormLabel } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useCurrentTime from '../hooks/useCurrentTime';
import useTimezone from '../hooks/useTimezone';
import { UserDoc } from '../types/User';
import { useUserAvailabilityDocRef } from '../types/UserAvailability';
import Catch from './Catch';
import PaddingBlock from './PaddingBlock';

const getTimeSlotIdFromDate = (date: Date): string => moment(date).tz('UTC').format('YYYYMMDDTHHmm');

export type Props = {
  userRef: DocumentReference<UserDoc>;
};

const AvailabilityMain: React.FC<Props> = ({ userRef }) => {
  const userAvailabilityDocRef = useUserAvailabilityDocRef(userRef);
  const { data: availabilitySnap } = useFirestoreDoc(userAvailabilityDocRef);

  const availability = useMemo(
    () => availabilitySnap.data(),
    [availabilitySnap],
  );

  const timezone = useTimezone();

  const currentTime = useCurrentTime(1000 * 60);

  const grid = useMemo(() => {
    const TIME_SLOT_SIZE = 1000 * 60 * 15; // 15 minutes
    const PREVIOUS_MIDNIGHT = moment(currentTime)
      .tz(timezone)
      .startOf('day')
      .toDate()
      .getTime();
    const start = Math.ceil(PREVIOUS_MIDNIGHT / TIME_SLOT_SIZE);

    const res: { key: string, value: boolean }[][] = [];

    for (let y = 0; y < 14; y += 1) {
      res.push([]);
      for (let x = 0; x < 96; x += 1) {
        const ts = getTimeSlotIdFromDate(new Date((start + (y * 96) + x) * TIME_SLOT_SIZE));

        if (_.includes(availability?.available || [], ts)) {
          res[y][x] = { key: ts, value: true };
        } else {
          res[y][x] = { key: ts, value: false };
        }
      }
    }

    return res;
  }, [availability?.available, currentTime, timezone]);

  const defaultStyle = useMemo<React.SVGAttributes<SVGCircleElement>['style']>(() => ({
    transform: 'scale(1)',
    transformOrigin: 'center',
    transformBox: 'fill-box',
    transitionProperty: 'transform',
    transitionDuration: '1s',
    transitionTimingFunction: 'ease-in-out',
  }), []);

  if (!availability) {
    return null;
  }

  return (
    <FormControl>
      <FormLabel>
        Availability graph
      </FormLabel>

      <PaddingBlock>
        <svg viewBox={`0 0 ${24 * 4} ${14}`}>
          {grid.map((col, y) => (
            col.map((res, x) => (
              <circle
                key={res.key}
                cx={x + 0.5}
                cy={y + 0.5}
                r={0.375}
                fill="#358596"
                style={{
                  ...defaultStyle,
                  transform: res.value ? 'scale(1)' : 'scale(0.25)',
                }}
              />
            ))
          ))}
        </svg>
      </PaddingBlock>
    </FormControl>
  );
};

export const AvailabilityCatchFallback: React.FC = () => null;
export const AvailabilitySuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Availability: React.FC<Props> = (props) => (
  <Catch fallback={<AvailabilityCatchFallback />}>
    <Suspense fallback={<AvailabilitySuspenseFallback />}>
      <AvailabilityMain {...props} />
    </Suspense>
  </Catch>
);

export default Availability;
