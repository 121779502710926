import { Box, Container } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import InterviewInfo, { InterviewInfoSuspenseFallback } from '../../../components/InterviewInfo';
import { useInterviewRef } from '../../../components/InterviewRefContext';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const HeaderMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  return (
    <Container>
      <Box flexGrow={1} py={2}>
        <InterviewInfo interviewSnap={interviewSnap} />
      </Box>
    </Container>
  );
};

export const HeaderCatchFallback: React.FC = () => null;

export const HeaderSuspenseFallback: React.FC = () => (
  <Container>
    <Box flexGrow={1} py={2}>
      <InterviewInfoSuspenseFallback />
    </Box>
  </Container>
);

/* eslint-disable react/jsx-props-no-spreading */
const Header: React.FC = () => (
  <Catch fallback={<HeaderCatchFallback />}>
    <Suspense fallback={<HeaderSuspenseFallback />}>
      <HeaderMain />
    </Suspense>
  </Catch>
);

export default Header;
