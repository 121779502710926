import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CalendarIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path d="M10 12V14H14V12H10Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 4H12V2H14V4H18V15C18 16.6569 16.6569 18 15 18H5C3.34315 18 2 16.6569 2 15V4H6V2H8V4ZM6 6V7H8V6H12V7H14V6H16V15C16 15.5523 15.5523 16 15 16H5C4.44772 16 4 15.5523 4 15V6H6Z" fill="currentColor" />
  </Icon>
);

export default CalendarIcon;
