import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CopyIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4 4L4 9C4 9.55228 4.44772 10 5 10H6V12H5C3.34315 12 2 10.6569 2 9L2 2L12 2V6H10V4L4 4ZM8 8H18V15C18 16.6569 16.6569 18 15 18H11C9.34314 18 8 16.6569 8 15V8ZM10 10V15C10 15.5523 10.4477 16 11 16H15C15.5523 16 16 15.5523 16 15V10H10Z" fill="currentColor" />
  </Icon>
);

export default CopyIcon;
