import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { UserDoc } from './User';

export type MicrosoftCredentialsDoc = {
  email: string;
  userRef: DocumentReference<UserDoc>;
  credentials: {
    access_token: string;
    expires_in: number;
    ext_expires_in: number;
    id_token: string;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
  expiresAt: Timestamp;
};

export const isMicrosoftCredentialsDoc = (
  data?: DocumentData,
): data is MicrosoftCredentialsDoc => true;

export const isMicrosoftCredentialsRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<MicrosoftCredentialsDoc> => ref.parent.id === 'microsoftCredentials';

export const isMicrosoftCredentialsSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
MicrosoftCredentialsDoc
> => isMicrosoftCredentialsRef(snap.ref);

export const useMicrosoftCredentialsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'microsoftCredentials') as CollectionReference<MicrosoftCredentialsDoc>, [firestore]);
};

export const useMicrosoftCredentialDocRef = (docId: string) => {
  const collectionRef = useMicrosoftCredentialsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
