import { BoxProps } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router';
import BlockListItem from '../../../../components/BlockListItem';
import CandidateInfo, {
  CandidateInfoSuspenseFallback,
} from '../../../../components/CandidateInfo';
import Catch from '../../../../components/Catch';
import { CandidateDoc } from '../../../../types/Candidate';

export type Props = BoxProps & {
  candidateSnap: QueryDocumentSnapshot<CandidateDoc>;
};

const CandidateItemMain: React.FC<Props> = ({ candidateSnap }) => {
  const navigate = useNavigate();

  return (
    <BlockListItem
      data-intercom-target="CandidateRow"
      onClick={() => navigate(`../../../candidates/${candidateSnap.id}`)}
    >
      <CandidateInfo candidateSnap={candidateSnap} />
    </BlockListItem>
  );
};

export const CandidateItemSuspenseFallback: React.FC = () => (
  <BlockListItem>
    <CandidateInfoSuspenseFallback />
  </BlockListItem>
);

const CandidateItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateItem: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateItemCatchFallback />}>
    <Suspense fallback={<CandidateItemSuspenseFallback />}>
      <CandidateItemMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateItem;
