import { SearchOptions } from '@algolia/client-search';
import {
  Collapse,
  Container,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Switch,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRemoteConfigString } from 'reactfire';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PlusInCircleIcon from '../../../icons/PlusInCircleIcon';
import SearchIcon from '../../../icons/SearchIcon';
import SearchBar from './SearchBar';
import SkillList from './SkillList';
import SkillSearch from './SkillSearch';

const SkillsScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({});

  const [showExternal, setShowExternal] = useState(true);

  const navigate = useNavigate();

  const handleNewSkillClick = useCallback(
    () => navigate(`/organizations/${organizationRef.id}/skills/new`),
    [navigate, organizationRef.id],
  );

  const { data: clarwisOrganizationId } = useRemoteConfigString('clarwis_organization_id');

  const onSearchChange = useCallback((newQuery: string, newSearchOptions: SearchOptions) => {
    setQuery(newQuery);

    setSearchOptions({
      ...newSearchOptions,
      facetFilters: [
        showExternal ? [
          `organization.id: ${clarwisOrganizationId}`,
          `organization.id: ${organizationRef.id}`,
        ] : [
          `organization.id: ${organizationRef.id}`,
        ],
      ],
    });
  }, [clarwisOrganizationId, showExternal, organizationRef.id]);

  const handleFilterToggle = useCallback(() => {
    setFilterActive(!filterActive);
  }, [filterActive]);

  return (
    <VStack height="100%" spacing={0} alignItems="stretch" overflow="hidden">
      <Container>
        <VStack spacing={0} alignItems="stretch">
          <HStack py={3}>
            <Heading py="4px" flexGrow={1}>
              Skills
            </Heading>

            <HStack spacing={2}>
              <FormLabel htmlFor="includeExternal" m={0}>
                Show external
              </FormLabel>

              <Switch
                id="includeExternal"
                isChecked={showExternal}
                onChange={(e) => setShowExternal(e.target.checked)}
              />
            </HStack>

            <IconButton
              data-intercom-target="OrganizationSkillSearch"
              aria-label="Search"
              icon={<SearchIcon />}
              variant="ghost"
              isActive={filterActive}
              onClick={handleFilterToggle}
            />

            <IconButton
              data-intercom-target="OrganizationSkillAdd"
              icon={<PlusInCircleIcon />}
              onClick={handleNewSkillClick}
              aria-label="New skill"
            />
          </HStack>

          <Collapse in={filterActive} animateOpacity>
            <SearchBar onSearchOptionsChange={onSearchChange} />
          </Collapse>
        </VStack>
      </Container>

      {filterActive ? (
        <SkillSearch searchOptions={searchOptions} query={query} />
      ) : (
        <SkillList showExternal={showExternal} />
      )}
    </VStack>
  );
};

export const SkillsScreenCatchFallback: React.FC = () => null;
export const SkillsScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const SkillsScreen: React.FC = () => (
  <Catch fallback={<SkillsScreenCatchFallback />}>
    <Suspense fallback={<SkillsScreenSuspenseFallback />}>
      <SkillsScreenMain />
    </Suspense>
  </Catch>
);

export default SkillsScreen;
