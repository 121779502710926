import {
  Center,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  Timestamp,
  query,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import ScoreSpiderChart from '../../../components/ScoreSpiderChart';
import { useUserRef } from '../../../components/UserRefContext';
import useCurrentTime from '../../../hooks/useCurrentTime';
import { ScoreDoc, useScoresCollectionRef } from '../../../types/Score';
import { SkillDoc } from '../../../types/Skill';
import LegendRow from './LegendRow';

const ScoreboardScreenMain: React.FC = () => {
  const scoresCollectionRef = useScoresCollectionRef();
  const userRef = useUserRef();

  const currentTime = useCurrentTime(1000 * 60);

  const yearAgo = useMemo<number>(
    () => moment(currentTime)
      .tz('utc')
      .subtract(1, 'year')
      .startOf('day')
      .toDate()
      .getTime(),
    [currentTime],
  );

  const { data: scoresSnap } = useFirestoreCollection(
    query(
      scoresCollectionRef,
      where('userRef', '==', userRef),
      where('timestamp', '>=', Timestamp.fromMillis(yearAgo)),
    ),
  );

  const scoreSnaps = useMemo(
    () => scoresSnap.docs.reduce<QueryDocumentSnapshot<ScoreDoc>[]>(
      (memo, scoreSnap) => {
        const existingScoreSnapIndex = _.findIndex(
          memo,
          (snap) => snap.data().skillRef.id === scoreSnap.data().skillRef.id,
        );

        if (existingScoreSnapIndex < 0) {
          return [
            ...memo,
            scoreSnap,
          ];
        }

        if (
          (memo[existingScoreSnapIndex].data().score || 0) < (scoreSnap.data().score || 0)
        ) {
          const next = [...memo];
          next.splice(existingScoreSnapIndex, 1, scoreSnap);
          return next;
        }

        return memo;
      },
      [],
    ),
    [scoresSnap.docs],
  );

  const items: Array<{ skillRef: DocumentReference<SkillDoc>; score?: number; }> = useMemo(
    () => scoreSnaps.map(
      (scoreSnap) => {
        const scoreDoc = scoreSnap.data();

        const { score, skillRef } = scoreDoc;

        return { score, skillRef };
      },
    ),
    [scoreSnaps],
  );

  return (
    <VStack alignItems="stretch" spacing={3}>
      <Heading lineHeight={9}>
        Scoreboard
      </Heading>

      <BlockList variant="outline">
        {items.length > 2 ? (
          <Center
            minH={0}
            alignItems="stretch"
            h="100%"
            w="100%"
            px={5}
            py={3}
          >
            <ScoreSpiderChart
              items={items}
              maxH="360px"
              maxW="360px"
              minH="100px"
              minW="100px"
            />
          </Center>
        ) : null}

        {scoreSnaps.length ? (
          scoreSnaps.map((scoreSnap) => (
            <LegendRow key={scoreSnap.id} scoreSnap={scoreSnap} />
          ))
        ) : (
          <Text variant="labelMedium">
            No scores found
          </Text>
        )}
      </BlockList>
    </VStack>
  );
};

export const ScoreboardScreenCatchFallback: React.FC = () => null;
export const ScoreboardScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const ScoreboardScreen: React.FC = () => (
  <Catch fallback={<ScoreboardScreenCatchFallback />}>
    <Suspense fallback={<ScoreboardScreenSuspenseFallback />}>
      <ScoreboardScreenMain />
    </Suspense>
  </Catch>
);

export default ScoreboardScreen;
