import { SearchOptions } from '@algolia/client-search';
import {
  Collapse,
  Container,
  HStack,
  Heading,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PlusInCircleIcon from '../../../icons/PlusInCircleIcon';
import SearchIcon from '../../../icons/SearchIcon';
import CandidateList from './CandidateList';
import CandidateSearch from './CandidateSearch';
import SearchBar from './SearchBar';

const CandidatesScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({});

  const navigate = useNavigate();

  const handleNewCandidateClick = useCallback(
    () => navigate(`/organizations/${organizationRef.id}/candidates/new`),
    [navigate, organizationRef.id],
  );

  const onSearchChange = useCallback((newQuery: string, newSearchOptions: SearchOptions) => {
    setQuery(newQuery);
    setSearchOptions(newSearchOptions);
  }, []);

  const handleFilterToggle = useCallback(() => {
    setFilterActive(!filterActive);
  }, [filterActive]);

  return (
    <VStack height="100%" spacing={0} alignItems="stretch" overflow="hidden">
      <Container>
        <VStack spacing={0} alignItems="stretch">
          <HStack py={3}>
            <Heading py="4px" flexGrow={1}>
              Candidates
            </Heading>

            <IconButton
              data-intercom-target="OrganizationCandidateSearch"
              aria-label="Search"
              icon={<SearchIcon />}
              variant="ghost"
              isActive={filterActive}
              onClick={handleFilterToggle}
            />

            <IconButton
              data-intercom-target="OrganizationCandidateAdd"
              icon={<PlusInCircleIcon />}
              onClick={handleNewCandidateClick}
              aria-label="New candidate"
            />
          </HStack>

          <Collapse in={filterActive} animateOpacity>
            <SearchBar onSearchOptionsChange={onSearchChange} />
          </Collapse>
        </VStack>
      </Container>

      {filterActive ? (
        <CandidateSearch searchOptions={searchOptions} query={query} />
      ) : (
        <CandidateList />
      )}
    </VStack>
  );
};

export const CandidatesScreenCatchFallback: React.FC = () => null;
export const CandidatesScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CandidatesScreen: React.FC = () => (
  <Catch fallback={<CandidatesScreenCatchFallback />}>
    <Suspense fallback={<CandidatesScreenSuspenseFallback />}>
      <CandidatesScreenMain />
    </Suspense>
  </Catch>
);

export default CandidatesScreen;
