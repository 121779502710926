import { Text, VStack } from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../../components/BlockList';
import Catch from '../../../../components/Catch';
import { useOrganizationRef } from '../../../../components/OrganizationRefContext';
import { useStageRef } from '../../../../components/StageRefProvider';
import { useCandidatesCollectionRef } from '../../../../types/Candidate';
import CandidateItem, { CandidateItemSuspenseFallback } from './CandidateItem';

const CandidatesMain: React.FC = () => {
  const stageRef = useStageRef();
  const organizationRef = useOrganizationRef();

  const candidatesCollectionRef = useCandidatesCollectionRef();
  const { data: candidatesSnap } = useFirestoreCollection(
    query(
      candidatesCollectionRef,
      where('stageRef', '==', stageRef),
      where('organizationRef', '==', organizationRef),
      orderBy('createdAt', 'desc'),
    ),
  );

  if (!candidatesSnap.docs.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Skills">
      <Text
        pt="1px"
        pb="3px"
        color="cf.cntTertiary"
        fontSize="sm"
        lineHeight="short"
        fontWeight="medium"
      >
        Candidates
      </Text>

      <BlockList variant="outline">
        {candidatesSnap.docs.map((candidateSnap) => (
          <CandidateItem key={candidateSnap.id} candidateSnap={candidateSnap} />
        ))}
      </BlockList>
    </VStack>
  );
};

export const CandidatesSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Candidates
    </Text>

    <BlockList variant="outline">
      <CandidateItemSuspenseFallback />
      <CandidateItemSuspenseFallback />
      <CandidateItemSuspenseFallback />
    </BlockList>
  </VStack>
);

const CandidatesCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Candidates: React.FC = () => (
  <Catch fallback={<CandidatesCatchFallback />}>
    <Suspense fallback={<CandidatesSuspenseFallback />}>
      <CandidatesMain />
    </Suspense>
  </Catch>
);

export default Candidates;
