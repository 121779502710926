import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import React from 'react';

export interface Props extends SelectProps {
  label: string;
  name: string;
  options: Record<string, string>;
}

const SelectField: React.FC<Props> = ({
  label, name, options, isRequired, ...props
}: Props) => {
  const [field, meta] = useField(name);

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <Select
        isRequired={isRequired}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {_.toPairs(options).map(([value, title]) => (
          <option key={value} value={value}>
            {title}
          </option>
        ))}
      </Select>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default SelectField;
