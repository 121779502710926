import React, {
  AudioHTMLAttributes,
  DetailedHTMLProps,
  useEffect,
  useRef,
} from 'react';
import { AudioTrack } from 'twilio-video';

export type Props = DetailedHTMLProps<AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement> & {
  audioTrack: AudioTrack;
};

const Audio: React.FC<Props> = ({
  audioTrack,
  ...props
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const el = audioRef.current;

    if (el) {
      audioTrack.attach(el);
    }

    return () => {
      if (el) {
        audioTrack.detach(el);
      }
    };
  }, [audioTrack, audioRef]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <audio ref={audioRef} {...props} />
  );
};

export default Audio;
