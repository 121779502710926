import React, { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from 'reactfire';

const CheckNoAuthLayout: React.FC = () => {
  const { data: user } = useUser();

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const next = useMemo(() => query.get('next'), [query]);

  if (user) {
    if (next) {
      return (
        <Navigate to={next} />
      );
    }

    return (
      <Navigate to="/" />
    );
  }

  return (<Outlet />);
};

export default CheckNoAuthLayout;
