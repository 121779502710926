import {
  HStack,
  IconButton,
  Select,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import CrossInCircleIcon from '../../icons/CrossInCircleIcon';
import { StageActionFilterOperation, StageActionFilterType } from '../../types/Stage';
// eslint-disable-next-line import/no-cycle
import StageActionAndFilterField, {
  StageActionAndFilterFieldValue,
} from './StageActionAndFilterField';
import StageActionAverageScoreFilterField, {
  StageActionAverageScoreFilterFieldValue,
} from './StageActionAverageScoreFilterField';
// eslint-disable-next-line import/no-cycle
import StageActionOrFilterField, {
  StageActionOrFilterFieldValue,
} from './StageActionOrFilterField';
import StageActionSkillScoreFilterField, {
  StageActionSkillScoreFilterFieldValue,
} from './StageActionSkillScoreFilterField';

export type Props = {
  name: string;
  onRemoveClick: () => unknown;
};

export type StageActionFilterFieldValue =
  | StageActionSkillScoreFilterFieldValue & { type: StageActionFilterType.SKILL_SCORE }
  | StageActionAverageScoreFilterFieldValue & { type: StageActionFilterType.AVERAGE_SCORE }
  | StageActionAndFilterFieldValue & { type: StageActionFilterType.AND }
  | StageActionOrFilterFieldValue & { type: StageActionFilterType.OR };

const StageActionFilterField: React.FC<Props> = ({
  name,
  onRemoveClick,
}) => {
  const [field, , helpers] = useField<StageActionFilterFieldValue>(name);

  const value = useMemo(
    () => field.value.type,
    [field.value?.type],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      switch (e.target.value) {
        case StageActionFilterType.SKILL_SCORE: {
          helpers.setValue({
            type: StageActionFilterType.SKILL_SCORE,
            skillRef: null,
            score: 0.5,
            operation: StageActionFilterOperation.MORE_EQUAL,
          });
          break;
        }
        case StageActionFilterType.AVERAGE_SCORE: {
          helpers.setValue({
            type: StageActionFilterType.AVERAGE_SCORE,
            score: 0.5,
            operation: StageActionFilterOperation.MORE_EQUAL,
          });
          break;
        }
        case StageActionFilterType.AND: {
          helpers.setValue({
            type: StageActionFilterType.AND,
            filters: [],
          });
          break;
        }
        case StageActionFilterType.OR: {
          helpers.setValue({
            type: StageActionFilterType.OR,
            filters: [],
          });
          break;
        }
      }
    },
    [helpers],
  );

  return (
    <VStack alignItems="stretch">
      <HStack>
        <Select value={value} onChange={onChange} variant="unstyled" size="sm">
          <option value={StageActionFilterType.SKILL_SCORE}>Skill score</option>
          <option value={StageActionFilterType.AVERAGE_SCORE}>Average score</option>
          <option value={StageActionFilterType.AND}>And</option>
          <option value={StageActionFilterType.OR}>Or</option>
        </Select>

        <IconButton
          aria-label="Remove"
          icon={<CrossInCircleIcon />}
          size="sm"
          variant="ghost"
          onClick={onRemoveClick}
        />
      </HStack>

      {field.value?.type === StageActionFilterType.SKILL_SCORE ? (
        <StageActionSkillScoreFilterField name={name} />
      ) : null}

      {field.value?.type === StageActionFilterType.AVERAGE_SCORE ? (
        <StageActionAverageScoreFilterField name={name} />
      ) : null}

      {field.value?.type === StageActionFilterType.AND ? (
        <StageActionAndFilterField name={name} />
      ) : null}

      {field.value?.type === StageActionFilterType.OR ? (
        <StageActionOrFilterField name={name} />
      ) : null}
    </VStack>
  );
};

export default StageActionFilterField;
