import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Query,
  collectionGroup,
  documentId,
  query,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useFirestore, useFirestoreCollection, useUser } from 'reactfire';
import AuthError from '../types/AuthError';
import {
  MemberDoc,
  getMemberOrganizationRef,
  useMembersCollectionGroupRef,
} from '../types/Member';
import { useOrganizationsCollectionRef } from '../types/Organization';
import Catch from './Catch';
import ClarwisLogo from './Logo';
import OrganizationItem from './NavUser/OrganizationItem';
import OrganizationAvatar from './OrganizationAvatar';

const NavLogoMain: React.FC = () => {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const match = useMatch('/organizations/:organizationId/*');
  const organizationsCollectionRef = useOrganizationsCollectionRef();
  if (!user) {
    throw new AuthError();
  }

  const membersCollectionGroupRef = useMembersCollectionGroupRef();

  console.log(1);

  const { data: membersSnap } = useFirestoreCollection(membersCollectionGroupRef);

  console.log(2);

  const organizationIds = useMemo(
    () => membersSnap.docs.map(
      (memberSnap) => getMemberOrganizationRef(memberSnap.ref).id,
    ),
    [membersSnap.docs],
  );

  console.log(3);

  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      organizationsCollectionRef,
      where(documentId(), 'in', organizationIds),
    ),
  );

  console.log(4);

  const organizationSnap = useMemo(
    () => (match?.params.organizationId ? _.find(
      organizationsSnap.docs,
      (snap) => snap.id === match.params.organizationId,
    ) : undefined),
    [match?.params.organizationId, organizationsSnap.docs],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <>
      {organizationSnap ? (
        <OrganizationAvatar
          size="sm"
          organizationSnap={organizationSnap}
          cursor="pointer"
          onClick={onOpen}
          userSelect="none"
        />
      ) : (
        <ClarwisLogo
          cursor="pointer"
          onClick={handleClick}
          userSelect="none"
        />
      )}

      {organizationsSnap.docs.length ? (
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          size="sm"
        >
          <DrawerOverlay />
          <DrawerContent
            py={3}
            px={5}
            overflow="auto"
          >
            <VStack alignItems="stretch" spacing={5}>
              {organizationsSnap.docs.map((snap, i) => (
                <OrganizationItem key={snap.id} organizationSnap={snap} />
              ))}
            </VStack>
          </DrawerContent>
        </Drawer>
      ) : null}
    </>
  );
};

export const NavLogoCatchFallback: React.FC = () => null;
export const NavLogoSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const NavLogo: React.FC = () => (
  <Catch fallback={<NavLogoCatchFallback />}>
    <Suspense fallback={<NavLogoSuspenseFallback />}>
      <NavLogoMain />
    </Suspense>
  </Catch>
);

export default NavLogo;
