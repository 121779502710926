import { IconButton } from '@chakra-ui/react';
import React, { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import BackIcon from '../../../../icons/BackIcon';

const BackButtonMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const navigate = useNavigate();
  const handleBackClick = useCallback(
    () => navigate(`/interviews/${interviewRef.id}`),
    [interviewRef.id, navigate],
  );

  return (
    <IconButton
      variant="ghost"
      aria-label="Back"
      icon={<BackIcon />}
      onClick={handleBackClick}
    />
  );
};

const BackButtonCatchFallback: React.FC = () => null;
const BackButtonSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const BackButton: React.FC = () => (
  <Catch fallback={<BackButtonCatchFallback />}>
    <Suspense fallback={<BackButtonSuspenseFallback />}>
      <BackButtonMain />
    </Suspense>
  </Catch>
);

export default BackButton;
