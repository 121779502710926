import { IconProps } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { ExpertDoc } from '../types/Expert';
import ExternalExpertTierIcon from './ExternalExpertTierIcon';
import InternalExpertTierIcon from './InternalExpertTierIcon';
import { useOrganizationRef } from './OrganizationRefContext';

export type Props = {
  expertSnap: QueryDocumentSnapshot<ExpertDoc>;
} & IconProps;

const ExpertTierIcon: React.FC<Props> = ({ expertSnap, ...iconProps }: Props) => {
  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  const organizationRef = useOrganizationRef();

  if (expert.organizationRef.id !== organizationRef.id) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (<ExternalExpertTierIcon tier={expert.tier} {...iconProps} />);
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (<InternalExpertTierIcon tier={expert.tier} {...iconProps} />);
};

export default ExpertTierIcon;
