import {
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { scaleLinear } from 'd3';
import { orderBy, query, where } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import { useInterviewRef } from '../../components/InterviewRefContext';
import Loader from '../../components/Loader';
import useCurrentTime from '../../hooks/useCurrentTime';
import useTimezone from '../../hooks/useTimezone';
import { TwilioRoomStatus, useTwilioRoomsCollectionRef } from '../../types/TwilioRoom';
import BackButton from './BackButton';
import Compositions from './Compositions';
import Controls from './Controls';
import Sections from './Controls/Sections';
import PlaybackContent from './PlaybackContent';
import PlaybackScaleProvider from './PlaybackScaleProvider';
import Score from './Score';

const PlaybackScreenMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const twilioRoomsCollectionRef = useTwilioRoomsCollectionRef();

  const { data: roomsSnap } = useFirestoreCollection(query(
    twilioRoomsCollectionRef,
    where('interviewRef', '==', interviewRef),
    orderBy('createdAt'),
  ));

  // get interview time ranges
  const interviewTimeRanges = useMemo(
    () => roomsSnap.docs.reduce<[number, number][]>(
      (res, s) => {
        const d = s.data();
        if (d.status !== TwilioRoomStatus.ENDED) {
          return res;
        }
        return [
          ...res,
          [d.createdAt.toMillis(), d.endedAt.toMillis()],
        ];
      },
      [],
    ),
    [roomsSnap],
  );

  // get playback time ranges
  const playbackTimeRanges = useMemo(
    () => interviewTimeRanges.reduce<[number, number][]>((res, [start, end]) => {
      const last = _.last(res)?.[1] || 0;
      return [
        ...res,
        [
          last,
          last + end - start,
        ],
      ];
    }, []),
    [interviewTimeRanges],
  );

  // get playback length
  const playbackLength = useMemo(
    () => interviewTimeRanges.reduce((sum, [start, end]) => sum + end - start, 0),
    [interviewTimeRanges],
  );

  // get scale function to convert playback time to interview time
  const playbackToInterviewTime = useMemo(
    () => scaleLinear()
      .domain(playbackTimeRanges.flat())
      .range(interviewTimeRanges.flat()),
    [interviewTimeRanges, playbackTimeRanges],
  );

  // get scale function to convert interview time to playback time
  const interviewToPlaybackTime = useMemo(
    () => scaleLinear()
      .domain(interviewTimeRanges.flat())
      .range(playbackTimeRanges.flat()),
    [interviewTimeRanges, playbackTimeRanges],
  );

  const currentTime = useCurrentTime(100);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [isBuffering, setIsBuffering] = useState<boolean>(false);
  const [realTimeStarted, setRealTimeStarted] = useState<number>(Infinity);
  const [pausedAt, setPausedAt] = useState<number>(0);

  const play = useCallback(() => {
    if (!isPlaying) {
      setPlaying(true);
      setRealTimeStarted(currentTime.getTime() - pausedAt);
    }
  }, [currentTime, isPlaying, pausedAt]);

  const pause = useCallback(() => {
    if (isPlaying) {
      setPlaying(false);
      setPausedAt(currentTime.getTime() - realTimeStarted);
    }
  }, [currentTime, isPlaying, realTimeStarted]);

  const onVideoPlaying = useCallback(() => {
    if (isBuffering) {
      setRealTimeStarted(currentTime.getTime() - pausedAt);
      setIsBuffering(false);
    }
  }, [currentTime, isBuffering, pausedAt]);

  const onVideoWaiting = useCallback(() => {
    if (!isBuffering) {
      setIsBuffering(true);
      setPausedAt(currentTime.getTime() - realTimeStarted);
    }
  }, [currentTime, isBuffering, realTimeStarted]);

  const playbackTime = useMemo<number>(
    () => {
      if (isPlaying && !isBuffering) {
        const nextTime = currentTime.getTime() - realTimeStarted;
        if (nextTime < playbackLength) {
          return nextTime;
        }
        setPlaying(false);
        return playbackLength;
      }

      return pausedAt;
    },
    [currentTime, isBuffering, isPlaying, pausedAt, playbackLength, realTimeStarted],
  );

  const seek = useCallback(
    (time: number) => {
      if (isPlaying) {
        setRealTimeStarted(Date.now() - time);
      } else {
        setPausedAt(time);
      }
    },
    [isPlaying],
  );

  const timezone = useTimezone();

  return (
    <PlaybackScaleProvider value={{
      playbackToInterviewTime,
      interviewToPlaybackTime,
      playbackLength,
      playbackTime,
      seek,
      onVideoPlaying,
      onVideoWaiting,
      isBuffering,
      isPlaying,
      play,
      pause,
    }}
    >
      <Grid
        px={5}
        py={3}
        templateColumns="auto 320px"
        templateRows="auto 44px"
        templateAreas={' "content media"  "schedule schedule" '}
        columnGap={5}
        rowGap={3}
        h="100%"
      >
        <GridItem gridArea="content">
          <PlaybackContent />
        </GridItem>

        <GridItem gridArea="media">
          <VStack spacing={3} alignItems="stretch">
            <Compositions />
            <Text variant="labelMedium">
              {moment(playbackToInterviewTime(playbackTime)).tz(timezone).format('MMM M, LTS')}
            </Text>
            <Score />
          </VStack>
        </GridItem>

        <GridItem gridArea="schedule">
          <HStack spacing={5}>
            <Controls />
            <Sections flexGrow={1} />
            <BackButton flexShrink={0} />
          </HStack>
        </GridItem>
      </Grid>
    </PlaybackScaleProvider>
  );
};

export const PlaybackScreenCatchFallback: React.FC = () => null;
export const PlaybackScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const PlaybackScreen: React.FC = () => (
  <Catch fallback={<PlaybackScreenCatchFallback />}>
    <Suspense fallback={<PlaybackScreenSuspenseFallback />}>
      <PlaybackScreenMain />
    </Suspense>
  </Catch>
);

export default PlaybackScreen;
