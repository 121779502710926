import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  RemoteDataTrack,
  RemoteDataTrackPublication,
  RemoteParticipant,
  Track,
} from 'twilio-video';

const getTrack = (
  publications: Map<Track.SID, RemoteDataTrackPublication> | undefined,
  name: string,
): RemoteDataTrack | null => {
  if (!publications) {
    return null;
  }

  let res: RemoteDataTrack | null = null;

  publications.forEach((publication) => {
    if (_.endsWith(publication.track?.name, name)) {
      res = publication.track;
    }
  });

  return res;
};

export default function useParticipantDataTrack(
  participant: RemoteParticipant | null,
  name: string,
): RemoteDataTrack | null {
  const [track, setTrack] = useState<RemoteDataTrack | null>(
    getTrack(participant?.dataTracks, name),
  );

  useEffect(() => {
    const handler = () => {
      setTrack(getTrack(participant?.dataTracks, name));
    };

    handler();

    if (participant) {
      participant.on('trackDimensionsChanged', handler);
      participant.on('trackDisabled', handler);
      participant.on('trackEnabled', handler);
      participant.on('trackMessage', handler);
      participant.on('trackPublished', handler);
      participant.on('trackPublishPriorityChanged', handler);
      participant.on('trackStarted', handler);
      participant.on('trackSubscribed', handler);
      participant.on('trackSubscriptionFailed', handler);
      participant.on('trackSwitchedOff', handler);
      participant.on('trackSwitchedOn', handler);
      participant.on('trackUnpublished', handler);
      participant.on('trackUnsubscribed', handler);

      return () => {
        participant.off('trackDimensionsChanged', handler);
        participant.off('trackDisabled', handler);
        participant.off('trackEnabled', handler);
        participant.off('trackMessage', handler);
        participant.off('trackPublished', handler);
        participant.off('trackPublishPriorityChanged', handler);
        participant.off('trackStarted', handler);
        participant.off('trackSubscribed', handler);
        participant.off('trackSubscriptionFailed', handler);
        participant.off('trackSwitchedOff', handler);
        participant.off('trackSwitchedOn', handler);
        participant.off('trackUnpublished', handler);
        participant.off('trackUnsubscribed', handler);
      };
    }

    return () => { };
  }, [name, participant, setTrack]);

  return track;
}
