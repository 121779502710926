import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useMemo } from 'react';
import BriefcaseIcon from '../icons/BriefcaseIcon';
import { OrganizationDoc } from '../types/Organization';
import StorageAvatar, { StorageAvatarProps } from './StorageAvatar';

export type OrganizationAvatarProps = Omit<StorageAvatarProps, 'avatarRef'> & {
  organizationSnap: QueryDocumentSnapshot<OrganizationDoc>;
};

const OrganizationAvatar: React.FC<OrganizationAvatarProps> = ({
  organizationSnap,
  ...avatarProps
}) => {
  const organization = useMemo(() => organizationSnap.data(), [organizationSnap]);

  return (
    <StorageAvatar
      icon={<BriefcaseIcon />}
      avatarRef={organization.logoRef}
      name={organization.name}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
    />
  );
};

export default OrganizationAvatar;
