import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CrossInCircleFilledIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19ZM5.29289 6.70711L8.58579 10L5.29289 13.2929L6.70711 14.7071L10 11.4142L13.2929 14.7071L14.7071 13.2929L11.4142 10L14.7071 6.70711L13.2929 5.29289L10 8.58579L6.70711 5.29289L5.29289 6.70711Z" fill="currentColor" />
  </Icon>
);

export default CrossInCircleFilledIcon;
