import {
  Box,
  BoxProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import React from 'react';

export type Props = BoxProps & ThemingProps<'PaddingBlock'>;

const PaddingBlock: React.FC<Props> = ({
  children,
  variant,
  sx,
  ...other
}) => {
  const style = useStyleConfig('PaddingBlock', { variant });

  return (
    <Box
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Box>
  );
};

export default PaddingBlock;
