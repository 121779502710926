import { useToast } from '@chakra-ui/react';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LocalAudioTrack, createLocalAudioTrack } from 'twilio-video';
import useSound from 'use-sound';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';

export type Result = {
  track: LocalAudioTrack | null;
  loading: boolean;
  on: (() => unknown);
  off: (() => unknown);
};

const LocalMicrophoneContext = React.createContext<Result>({
  track: null,
  loading: false,
  on: () => {},
  off: () => {},
});

export const useLocalMicrophone = (): Result => useContext(LocalMicrophoneContext);

export type Props = {
  deviceId: string;
};

const LocalMicrophoneProvider: React.FC<PropsWithChildren<Props>> = ({ children, deviceId }) => {
  const [track, setTrack] = useState<LocalAudioTrack | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const role = useInterviewRole();
  const name = useMemo<string>(() => {
    switch (role) {
      case InterviewRole.INTERVIEWEE: return 'interviewee-microphone';
      case InterviewRole.INTERVIEWER: return 'interviewer-microphone';
      default: return 'visitor-microphone';
    }
  }, [role]);

  const [playOnSound] = useSound(
    'https://storage.googleapis.com/crewfilter.io/sounds/beep_on.mp3',
    { volume: 0.25 },
  );
  const [playOffSound] = useSound(
    'https://storage.googleapis.com/crewfilter.io/sounds/beep_off.mp3',
    { volume: 0.25 },
  );

  const [state, setState] = useState(true);

  const on = useCallback(() => { setState(true); }, []);
  const off = useCallback(() => { setState(false); }, []);

  const toast = useToast();

  useEffect(() => {
    let isObsolete = false;

    if (state) {
      setLoading(true);
      const trackPromise = createLocalAudioTrack({
        echoCancellation: true,
        deviceId,
        name,
      });

      trackPromise.then(
        (newTrack) => {
          playOnSound();
          if (!isObsolete) {
            setTrack(newTrack);
            setLoading(false);
          }
        },
        (err) => {
          toast({
            status: 'error',
            title: 'Failed to enable microphone',
            description: err instanceof Error ? err.message : undefined,
          });
          if (!isObsolete) {
            setTrack(null);
            setLoading(false);
            setState(false);
          }
        },
      );

      return () => {
        trackPromise.then(
          (newTrack) => {
            playOffSound();
            newTrack.stop();
          },
          () => {
          },
        );

        setLoading(false);
        setTrack(null);
        isObsolete = true;
      };
    }

    return () => {};
  }, [deviceId, name, playOffSound, playOnSound, state, toast]);

  const res = useMemo(() => ({
    track, loading, on, off,
  }), [track, loading, on, off]);

  return (
    <LocalMicrophoneContext.Provider value={res}>
      {children}
    </LocalMicrophoneContext.Provider>
  );
};

export default LocalMicrophoneProvider;
