import React, {
  DetailedHTMLProps,
  VideoHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { LocalVideoTrack, VideoTrack } from 'twilio-video';

export type Props = DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> & {
  videoTrack: VideoTrack;
};

const Video: React.FC<Props> = ({
  videoTrack,
  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const el = videoRef.current;

    if (el) {
      videoTrack.attach(el);
    }

    return () => {
      if (el) {
        videoTrack.detach(el);
      }
    };
  }, [videoTrack, videoRef]);

  const mirror = useMemo<boolean>(() => videoTrack instanceof LocalVideoTrack, [videoTrack]);

  return (
    <video
      ref={videoRef}
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        ...(mirror ? { transform: 'scale(-1, 1)' } : {}),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default Video;
