import { editor as monacoEditor } from 'monaco-editor';

export enum MessageType {
  CODE_EDITOR_UPDATE = 'CODE_EDITOR_UPDATE',
  CODE_EDITOR_CURSOR_POSITION_UPDATE = 'CODE_EDITOR_CURSOR_POSITION_UPDATE',
  CODE_EDITOR_CURSOR_SELECTION_UPDATE = 'CODE_EDITOR_CURSOR_SELECTION_UPDATE',
}

export type CodeEditorUpdateMessage = {
  type: MessageType.CODE_EDITOR_UPDATE;
  value: string | undefined;
  event: monacoEditor.IModelContentChangedEvent;
};

export type CodeEditorCursorPositionUpdateMessage = {
  type: MessageType.CODE_EDITOR_CURSOR_POSITION_UPDATE;
  event: monacoEditor.ICursorPositionChangedEvent;
};

export type CodeEditorCursorSelectionUpdateMessage = {
  type: MessageType.CODE_EDITOR_CURSOR_SELECTION_UPDATE;
  event: monacoEditor.ICursorSelectionChangedEvent;
};

export type DataMessage =
  | CodeEditorUpdateMessage
  | CodeEditorCursorPositionUpdateMessage
  | CodeEditorCursorSelectionUpdateMessage;
