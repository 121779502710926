import { SkeletonCircle } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import Spinner from '../icons/Spinner';
import { UserDoc } from '../types/User';
import Catch from './Catch';
import { StorageAvatarStatic } from './StorageAvatar';
import UserAvatar, { ProfileAvatarProps } from './UserAvatar';

export type Props = Omit<ProfileAvatarProps, 'userRef'> & {
  userRef?: DocumentReference<UserDoc>;
};

const MemberAvatarMain: React.FC<Props> = ({
  userRef,
  ...profileAvatarProps
}: Props) => {
  if (!userRef) {
    return (
      <StorageAvatarStatic
        icon={<Spinner />}
        color="cf.status.empty"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...profileAvatarProps}
      />
    );
  }

  return (
    <UserAvatar
      userRef={userRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...profileAvatarProps}
    />
  );
};

MemberAvatarMain.defaultProps = {
  userRef: undefined,
};

export const MemberAvatarSuspenseFallback: React.FC = () => (
  <SkeletonCircle boxSize={11} />
);

export const MemberAvatarCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const MemberAvatar: React.FC<Props> = (props) => (
  <Catch fallback={<MemberAvatarCatchFallback />}>
    <Suspense fallback={<MemberAvatarSuspenseFallback />}>
      <MemberAvatarMain {...props} />
    </Suspense>
  </Catch>
);

MemberAvatar.defaultProps = {
  userRef: undefined,
};

export default MemberAvatar;
