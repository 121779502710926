import { IconButton, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import EndCallIcon from '../icons/EndCallIcon';
import { useRoom } from './RoomProvider';

const LeaveButton: React.FC = () => {
  const { disconnect } = useRoom();

  return (
    <VStack spacing={1}>
      <IconButton
        aria-label="Leave"
        h="44px"
        w="44px"
        borderRadius="100%"
        colorScheme="red"
        icon={<EndCallIcon />}
        onClick={disconnect}
      />

      <Text fontSize="xs" lineHeight="shorter" userSelect="none">
        Leave
      </Text>
    </VStack>
  );
};

export default LeaveButton;
