import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
// eslint-disable-next-line import/no-cycle
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { ExpertDoc, ExpertTier } from './Expert';
import { OrganizationDoc } from './Organization';
import { SkillDoc } from './Skill';

export enum StageActionType {
  TRANSITION = 'TRANSITION',
}

export enum StageActionFilterType {
  SKILL_SCORE = 'SKILL_SCORE',
  AVERAGE_SCORE = 'AVERAGE_SCORE',
  OR = 'OR',
  AND = 'AND',
}

export enum StageActionFilterOperation {
  LESS = '<',
  LESS_EQUAL = '<=',
  EQUAL = '==',
  MORE_EQUAL = '>=',
  MORE = '>',
}

export type StageActionSkillScoreFilter = {
  skillRef: DocumentReference<SkillDoc>;
  score: number;
  operation: StageActionFilterOperation,
  type: StageActionFilterType.SKILL_SCORE,
};

export type StageActionAverageScoreFilter = {
  score: number;
  operation: StageActionFilterOperation,
  type: StageActionFilterType.AVERAGE_SCORE,
};

export type StageActionScalarFilter =
  | StageActionAverageScoreFilter
  | StageActionSkillScoreFilter;

export type StageActionOrFilter = {
  filters: (StageActionScalarFilter | StageActionAndFilter)[];
  type: StageActionFilterType.OR,
};

export type StageActionAndFilter = {
  filters: (StageActionScalarFilter | StageActionOrFilter)[];
  type: StageActionFilterType.AND,
};

export type StageActionFilter =
  | StageActionScalarFilter
  | StageActionOrFilter
  | StageActionAndFilter;

export type StageTransitionAction = {
  filter: StageActionFilter;
  stageRef: DocumentReference<StageDoc>;
  type: StageActionType.TRANSITION;
};

export type StageAction =
  | StageTransitionAction;

export type StageDoc = {
  name: string;
  organizationRef: DocumentReference<OrganizationDoc>;
  skillRefs: DocumentReference<SkillDoc>[];
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  requiredExpertRefs: DocumentReference<ExpertDoc>[];
  optionalExpertRefs: DocumentReference<ExpertDoc>[];
  actions: StageTransitionAction[];
  createdAt: Timestamp;
  _v: 2;
};

export const isStageDoc = (
  data?: DocumentData,
): data is StageDoc => true;

export const isStageRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<StageDoc> => ref.parent.id === 'stages';

function isStageSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
StageDoc
>;
function isStageSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
StageDoc
>;
function isStageSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
StageDoc
> | QueryDocumentSnapshot<
StageDoc
> {
  return isStageRef(snap.ref);
}

export { isStageSnap };

export const useStagesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'stages') as CollectionReference<StageDoc>, [firestore]);
};

export const useStageDocRef = (docId: string) => {
  const collectionRef = useStagesCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
