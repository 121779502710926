import {
  Button,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, serverTimestamp, setDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import AssetField from '../../../components/AssetField';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import MarkdownField from '../../../components/MarkdownField';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import QuestionsField from '../../../components/QuestionsField';
import { useSkillRef } from '../../../components/SkillRefContext';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import TextField from '../../../components/TextField';
import Spinner from '../../../icons/Spinner';
import { AssetDoc } from '../../../types/Asset';
import { QuestionDoc } from '../../../types/Question';
import { SkillDoc } from '../../../types/Skill';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { TagDoc } from '../../../types/Tag';
import Header from './Header';

export interface FormFields {
  name: string;
  description: string;
  logoRef: DocumentReference<AssetDoc> | null;
  dependsOnRefs: DocumentReference<SkillDoc>[];
  questionRefs: DocumentReference<QuestionDoc>[];
  tagRefs: DocumentReference<TagDoc>[];
  synonyms: string[];
  speechContext: string[];
}

const schema = yup.object().shape({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  logoRef: yup.mixed().label('Company logo').required(),
});

const EditSkillScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const skillRef = useSkillRef();

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  if (skill.organizationRef.id !== organizationRef.id) {
    throw new Error();
  }

  const navigate = useNavigate();

  const handleFormSubmit = useCallback(async ({
    name,
    description,
    logoRef,
    dependsOnRefs,
    questionRefs,
    tagRefs,
    synonyms,
    speechContext,
  }: FormFields) => {
    if (!logoRef) {
      throw new Error();
    }

    await setDoc(skillRef, {
      name,
      description,
      logoRef,
      dependsOnRefs,
      questionRefs,
      updatedAt: serverTimestamp(),
      tagRefs,
      synonyms,
      speechContext,
    }, { merge: true });

    navigate(`../${skillRef.id}`);
  }, [navigate, skillRef]);

  const handleCancelClick = useCallback(() => {
    navigate(`../${skillRef.id}`);
  }, [skillRef.id, navigate]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        name: skill.name || '',
        description: skill.description || '',
        logoRef: skill.logoRef || null,
        dependsOnRefs: skill.dependsOnRefs || [],
        questionRefs: skill.questionRefs || [],
        tagRefs: skill.tagRefs || [],
        synonyms: skill.synonyms || [],
        speechContext: skill.speechContext || [],
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
          >
            <GridItem borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Header />
            </GridItem>

            <GridItem py={4} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={4}>
                  <TextField isRequired label="Name" name="name" />
                  <MarkdownField isRequired label="Description" name="description" />
                  <AssetField label="Logo" name="logoRef" isRequired />
                  <QuestionsField name="questionRefs" label="Questions" isRequired />
                  <SkillsSelectorField name="dependsOnRefs" label="Depends on" isRequired />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <SimpleGrid columns={2} gap={4}>
                  <Button
                    variant="outline"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="solid"
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                    spinner={<Spinner />}
                    loadingText="Processing..."
                    className="cart-checkout"
                  >
                    Save
                  </Button>
                </SimpleGrid>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const EditSkillScreenCatchFallback: React.FC = () => null;
export const EditSkillScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const EditSkillScreen: React.FC = () => (
  <Catch fallback={<EditSkillScreenCatchFallback />}>
    <Suspense fallback={<EditSkillScreenSuspenseFallback />}>
      <EditSkillScreenMain />
    </Suspense>
  </Catch>
);

export default EditSkillScreen;
