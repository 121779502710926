import {
  BoxProps,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { QuestionDoc } from '../types/Question';
import Catch from './Catch';

export type Props = {
  questionSnap: QueryDocumentSnapshot<QuestionDoc>;
} & BoxProps;

const QuestionInfoMain: React.FC<Props> = ({ questionSnap, ...boxProps }: Props) => {
  const question = useMemo(() => questionSnap.data(), [questionSnap]);

  const formatMinutes = useCallback(
    (v: number) => new Intl.NumberFormat('en-US', { style: 'unit', unitDisplay: 'long', unit: 'minute' }).format(v),
    [],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <VStack alignItems="stretch" flexGrow={1} spacing={1} {...boxProps}>
      <Text
        fontWeight="semibold"
        lineHeight="short"
        flexGrow={1}
        flexShrink={1}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {question.title}
      </Text>

      <Text variant="labelSmall" pt="3px" pb="1px" color="cf.cntSecondary">
        {formatMinutes(question.duration)}
      </Text>
    </VStack>
  );
};

export const QuestionInfoSuspenseFallback: React.FC = () => (
  <VStack alignItems="stretch" flexGrow={1} spacing={1}>
    <Skeleton h={5} w="140px" />
    <Skeleton h={5} w="140px" />
  </VStack>
);

export const QuestionInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const QuestionInfo: React.FC<Props> = (props) => (
  <Catch fallback={<QuestionInfoCatchFallback />}>
    <Suspense fallback={<QuestionInfoSuspenseFallback />}>
      <QuestionInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default QuestionInfo;
