import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';
import { ReviewAspectType } from '../types/Review';

export type RequestData = {
  interviewId: string;
  value: number;
  aspect: ReviewAspectType;
};

export type ResponseData = void;

const useInterviewSetReview = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'interview-setreview'),
    [functions],
  );
};

export default useInterviewSetReview;
