import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { SkillDoc } from '../types/Skill';

const SkillRefContext = React
  .createContext<DocumentReference<SkillDoc> | undefined>(undefined);

export const useSkillRef = (): DocumentReference<SkillDoc> => {
  const skillRef = useContext(SkillRefContext);
  if (!skillRef) {
    throw new Error('useSkillRef needs to be wrapped with SkillRefProvider');
  }
  return skillRef;
};

export type Props = {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillRefProvider: React.FC<PropsWithChildren<Props>> = ({
  skillRef,
  children,
}) => (
  <SkillRefContext.Provider value={skillRef} key={skillRef.path}>
    {children}
  </SkillRefContext.Provider>
);

export default SkillRefProvider;
