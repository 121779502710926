import { VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { Suspense } from 'react';
import Catch from '../../components/Catch';
import EmailAuthMethod from '../../components/EmailAuthMethod';
import ThirdPartyAuthMethods from '../../components/ThirdPartyAuthMethods';

const AuthFormMain: React.FC = () => (
  <VStack spacing={5} alignItems="stretch">
    <EmailAuthMethod
      label={t('emailAuthMethod.label')}
    />

    <ThirdPartyAuthMethods
      label={t('thirdPartyAuthMethods.label')}
    />
  </VStack>
);

export const AuthFormCatchFallback: React.FC = () => null;
export const AuthFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AuthForm: React.FC = () => (
  <Catch fallback={<AuthFormCatchFallback />}>
    <Suspense fallback={<AuthFormSuspenseFallback />}>
      <AuthFormMain />
    </Suspense>
  </Catch>
);

export default AuthForm;
