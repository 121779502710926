import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

const EmailSent: React.FC = () => (
  <Container maxW="container.sm">
    <VStack alignItems="stretch" spacing={5} py={3}>
      <Box py={1}>
        <Heading>
          Magic link is on the way to your inbox
        </Heading>
      </Box>

      <Text py="2px">
        We have sent an authorization link to your email. The link expires
        shortly, so please click it soon.
      </Text>

      <Flex justifyContent="space-around">
        <svg width="320" height="240" viewBox="0 0 320 240" fill="none">
          <path d="M108 45H55M25 45H20M51 45H29" stroke="black" strokeWidth="2" />
          <path d="M108 57H92M62 57H57M88 57H66M53 57H48" stroke="black" strokeWidth="2" />
          <path d="M120 33H180V69H120V33Z" fill="white" />
          <path d="M120 33H180M120 33V69M120 33L143.5 51.8M180 33V69M180 33L156.5 51.8M180 69H120M180 69L156.5 51.8M120 69L143.5 51.8M143.5 51.8L150 57L156.5 51.8" stroke="#010B14" strokeWidth="2" />
          <path d="M192 120H125M95 120H90M121 120H99" stroke="black" strokeWidth="2" />
          <path d="M192 108H176M146 108H141M172 108H150M137 108H132" stroke="black" strokeWidth="2" />
          <path d="M166 132H161M192 132H170" stroke="black" strokeWidth="2" />
          <path d="M204 94H290V146H204V94Z" fill="white" />
          <path d="M204 94H290M204 94V146M204 94L237.683 121.156M290 94V146M290 94L256.317 121.156M290 146H204M290 146L256.317 121.156M204 146L237.683 121.156M237.683 121.156L247 128.667L256.317 121.156" stroke="#010B14" strokeWidth="2" />
          <path d="M163 183H51M21 183H16M47 183H25" stroke="black" strokeWidth="2" />
          <path d="M58 195H53M163 195H62M49 195H44M40 195H35" stroke="black" strokeWidth="2" />
          <path d="M175 171H235V207H175V171Z" fill="white" />
          <path d="M175 171H235M175 171V207M175 171L198.5 189.8M235 171V207M235 171L211.5 189.8M235 207H175M235 207L211.5 189.8M175 207L198.5 189.8M198.5 189.8L205 195L211.5 189.8" stroke="#010B14" strokeWidth="2" />
        </svg>
      </Flex>
    </VStack>
  </Container>
);

export default EmailSent;
