import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { DeelTeamDoc } from './DeelTeam';
import { OrganizationDoc } from './Organization';

export enum DeelContractWhoReports {
  BOTH = 'both',
  CLIENT = 'client',
  CONTRACTOR = 'contractor',
}

export enum DeelContractType {
  milestones = 'milestones',
  time_based = 'time_based',
  ongoing_time_based = 'ongoing_time_based',
  pay_as_you_go_time_based = 'pay_as_you_go_time_based',
  commission = 'commission',
  payg_milestones = 'payg_milestones',
  payg_tasks = 'payg_tasks',
  eor = 'eor',
  unknown = 'unknown',
  employee = 'employee',
  global_payroll = 'global_payroll',
  shield_msa = 'shield_msa',
}

export enum DeelContractStatus {
  AWAITING_DEPOSIT_PAYMENT = 'awaiting_deposit_payment',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  NEW = 'new',
  PROCESSING_PAYMENT = 'processing_payment',
  REJECTED = 'rejected',
  UNDER_REVIEW = 'under_review',
  USER_CANCELLED = 'user_cancelled',
  WAITING_FOR_CLIENT_PAYMENT = 'waiting_for_client_payment',
  WAITING_FOR_CLIENT_SIGN = 'waiting_for_client_sign',
  WAITING_FOR_CONTRACTOR_SIGN = 'waiting_for_contractor_sign',
  WAITING_FOR_EMPLOYEE_CONTRACT = 'waiting_for_employee_contract',
  WAITING_FOR_EMPLOYEE_SIGN = 'waiting_for_employee_sign',
  WAITING_FOR_EOR_SIGN = 'waiting_for_eor_sign',
}

export type DeelContractDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  deelTeamRef: DocumentReference<DeelTeamDoc>;
  title: string;
  createdAt: Timestamp;
  terminationDate?: Timestamp;
  clientSignedAt?: Timestamp;
  workerSignedAt?: Timestamp;
  status: DeelContractStatus;
  type: DeelContractType;
  whoReports?: DeelContractWhoReports;
  isArchived: boolean;
};

export const isDeelContractDoc = (
  data?: DocumentData,
): data is DeelContractDoc => true;

export const isDeelContractRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelContractDoc> => ref.parent.id === 'deelContracts';

export const isDeelContractSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelContractDoc> => isDeelContractRef(snap.ref);

export const useDeelContractsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'deelContracts') as CollectionReference<DeelContractDoc>, [firestore]);
};

export const useDeelContractDocRef = (docId: string) => {
  const collectionRef = useDeelContractsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
