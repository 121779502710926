import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { InterviewDoc } from '../types/Interview';

const InterviewRefContext = React
  .createContext<DocumentReference<InterviewDoc> | undefined>(undefined);

export const useInterviewRef = (): DocumentReference<InterviewDoc> => {
  const interviewRef = useContext(InterviewRefContext);
  if (!interviewRef) {
    throw new Error('useInterviewRef needs to be wrapped with InterviewRefProvider');
  }
  return interviewRef;
};

export type Props = {
  interviewRef: DocumentReference<InterviewDoc>;
};

const InterviewRefProvider: React.FC<PropsWithChildren<Props>> = ({
  interviewRef,
  children,
}) => (
  <InterviewRefContext.Provider value={interviewRef} key={interviewRef.path}>
    {children}
  </InterviewRefContext.Provider>
);

export default InterviewRefProvider;
