import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { UserDoc } from '../types/User';

const UserRefContext = React
  .createContext<DocumentReference<UserDoc> | undefined>(undefined);

export const useUserRef = (): DocumentReference<UserDoc> => {
  const userRef = useContext(UserRefContext);
  if (!userRef) {
    throw new Error('useUserRef needs to be wrapped with UserRefProvider');
  }
  return userRef;
};

export type Props = {
  userRef: DocumentReference<UserDoc>;
};

const UserRefProvider: React.FC<PropsWithChildren<Props>> = ({
  userRef,
  children,
}) => (
  <UserRefContext.Provider value={userRef} key={userRef.path}>
    {children}
  </UserRefContext.Provider>
);

export default UserRefProvider;
