import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const LinkIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3 8C3 4.13401 6.13401 1 10 1C13.866 1 17 4.13401 17 8V9H15V8C15 5.23858 12.7614 3 10 3C7.23858 3 5 5.23858 5 8V9H3V8ZM9 14V6H11V14H9ZM5 11V12C5 14.7614 7.23858 17 10 17C12.7614 17 15 14.7614 15 12V11H17V12C17 15.866 13.866 19 10 19C6.13401 19 3 15.866 3 12V11H5Z" fill="currentColor" />
  </Icon>
);

export default LinkIcon;
