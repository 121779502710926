import {
  Container,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Catch from '../../../components/Catch';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useTimezone from '../../../hooks/useTimezone';
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon';
import ArrowRightIcon from '../../../icons/ArrowRightIcon';
import FilterIcon from '../../../icons/FilterIcon';
import { InterviewStatus } from '../../../types/Interview';

type Props = {
  onChange: (data: {
    start: number;
    end: number;
    statuses: InterviewStatus[];
  }) => unknown;
};

const HeaderMain: React.FC<Props> = ({ onChange }) => {
  const timezone = useTimezone();

  const currentTime = useCurrentTime(1000 * 60 * 60);

  const [offset, setOffset] = useState<number>(0);

  const onLeftClick = useCallback(() => setOffset(offset - 1), [offset]);
  const onRightClick = useCallback(() => setOffset(offset + 1), [offset]);

  const start = useMemo<number>(
    () => moment(currentTime)
      .tz(timezone)
      .startOf('month')
      .add(offset, 'months')
      .toDate()
      .getTime(),
    [currentTime, offset, timezone],
  );

  const end = useMemo<number>(
    () => moment(currentTime)
      .tz(timezone)
      .endOf('month')
      .add(offset, 'months')
      .toDate()
      .getTime(),
    [currentTime, offset, timezone],
  );

  const label = useMemo(() => moment(start).tz(timezone).format('MMMM'), [start, timezone]);

  const [statuses, setStatuses] = useState([
    InterviewStatus.CREATED,
    InterviewStatus.STARTED,
    InterviewStatus.ENDED,
  ]);

  useEffect(() => {
    onChange({ start, end, statuses });
  }, [onChange, start, end, statuses]);

  return (
    <Container>
      <VStack alignItems="stretch">
        <HStack py={3}>
          <Heading lineHeight={9} flexGrow={1}>
            Interviews
          </Heading>

          <Text color="cf.cntSecondary" variant="labelMedium">
            {label}
          </Text>

          <IconButton
            aria-label="Previous week"
            icon={<ArrowLeftIcon />}
            onClick={onLeftClick}
            variant="ghost"
          />

          <IconButton
            aria-label="Next week"
            icon={<ArrowRightIcon />}
            onClick={onRightClick}
            variant="ghost"
          />

          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Filter"
              icon={<FilterIcon />}
              variant="ghost"
            />

            <MenuList>
              <MenuOptionGroup
                type="checkbox"
                value={statuses}
                onChange={(v) => (
                  typeof v === 'string'
                    ? setStatuses([v as InterviewStatus])
                    : setStatuses(v as InterviewStatus[])
                )}
              >
                <MenuItemOption value={InterviewStatus.CANCELED}>
                  Canceled
                </MenuItemOption>
                <MenuItemOption value={InterviewStatus.CREATED}>
                  Created
                </MenuItemOption>
                <MenuItemOption value={InterviewStatus.ENDED}>
                  Ended
                </MenuItemOption>
                <MenuItemOption value={InterviewStatus.STARTED}>
                  Started
                </MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </HStack>
      </VStack>
    </Container>
  );
};

export const HeaderCatchFallback: React.FC = () => (
  <Container>
    <Heading lineHeight={9}>
      Interviews
    </Heading>
  </Container>
);

export const HeaderSuspenseFallback: React.FC = () => (
  <Container>
    <Heading lineHeight={9}>
      Interviews
    </Heading>
  </Container>
);

/* eslint-disable react/jsx-props-no-spreading */
const Header: React.FC<Props> = (props) => (
  <Catch fallback={<HeaderCatchFallback />}>
    <Suspense fallback={<HeaderSuspenseFallback />}>
      <HeaderMain {...props} />
    </Suspense>
  </Catch>
);

export default Header;
