import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import CallSettingsModal from './CallSettingsModal';
import LocalCameraProvider from './LocalCameraProvider';
import LocalMicrophoneProvider from './LocalMicrophoneProvider';

const LocalMediaInputSettingsOpenContext = React.createContext<(
() => unknown
)
| undefined
>(undefined,
  );

export const useLocalMediaInputSettingsOpen = (): (() => unknown) | undefined => useContext(
  LocalMediaInputSettingsOpenContext,
);

const LocalMediaInputProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const handleSettingsOpen = useCallback(() => {
    setSettingsOpen(true);
  }, [setSettingsOpen]);

  const handleSettingsClose = useCallback(() => {
    setSettingsOpen(false);
  }, [setSettingsOpen]);

  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>('default');
  const [videoInputDeviceId, setVideoInputDeviceId] = useState<string>('default');

  return (
    <LocalMediaInputSettingsOpenContext.Provider value={handleSettingsOpen}>
      <LocalCameraProvider deviceId={videoInputDeviceId}>
        <LocalMicrophoneProvider deviceId={audioInputDeviceId}>
          <CallSettingsModal
            isOpen={settingsOpen}
            onClose={handleSettingsClose}
            onAudioInputDeviceChange={setAudioInputDeviceId}
            audioInputDeviceId={audioInputDeviceId}
            onVideoInputDeviceChange={setVideoInputDeviceId}
            videoInputDeviceId={videoInputDeviceId}
          />

          {children}
        </LocalMicrophoneProvider>
      </LocalCameraProvider>
    </LocalMediaInputSettingsOpenContext.Provider>
  );
};

export default LocalMediaInputProvider;
