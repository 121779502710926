import {
  Box,
  HStack,
  Heading,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../components/Catch';
import SkillLogo from '../../components/SkillLogo';
import { InterviewScheduleItemDoc } from '../../types/InterviewScheduleItem';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';

export type Props = {
  scheduleItemIndex: number;
  scheduleItemSnap: QueryDocumentSnapshot<InterviewScheduleItemDoc>;
};

const PlaybackCurrentQuestionMain: React.FC<Props> = ({ scheduleItemIndex, scheduleItemSnap }) => {
  const currentQuestion = useMemo(() => scheduleItemSnap.data(), [scheduleItemSnap]);

  const { data: questionSnap } = useFirestoreDoc(currentQuestion.questionRef);

  if (!questionSnap.exists()) {
    throw new SnapNotFoundError(questionSnap);
  }

  const question = useMemo(() => questionSnap.data(), [questionSnap]);

  return (
    <HStack spacing={4} alignItems="start" flexGrow={1} userSelect="none">
      <VStack spacing={4} alignItems="start" flexShrink={0} minW="28px">
        <Text fontSize="sm" lineHeight="tall" color="cf.cntSecondary" fontWeight="medium">
          {_.padStart(String(scheduleItemIndex + 1), 2, '0')}
        </Text>

        <SkillLogo skillRef={currentQuestion.skillRef} />
      </VStack>

      <Box minW="0px" flexGrow={1}>
        <VStack spacing={4} alignItems="stretch">
          <Heading>
            {question.title}
          </Heading>

          <Box minW="0px" maxW="100%">
            <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
              {question.content}
            </ReactMarkdown>
          </Box>

          <Box minW="0px" maxW="100%">
            <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
              {question.hint || ''}
            </ReactMarkdown>
          </Box>
        </VStack>
      </Box>
    </HStack>
  );
};

export const PlaybackCurrentQuestionSuspenseFallback: React.FC = () => (
  <Box px={5} py={4} flexGrow={1} flexShrink={1} overflow="auto">
    <HStack spacing={4} alignItems="start">
      <Skeleton h="28px" w="20px" />
      <Box minW="0px" flexGrow={1}>
        <VStack spacing={4} alignItems="start">
          <Skeleton h="28px" w="100%" />

          <SkeletonText w="100%" noOfLines={10} spacing={4} />
        </VStack>
      </Box>
    </HStack>
  </Box>
);

export const PlaybackCurrentQuestionCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const PlaybackCurrentQuestion: React.FC<Props> = (props) => (
  <Catch fallback={<PlaybackCurrentQuestionCatchFallback />}>
    <Suspense fallback={<PlaybackCurrentQuestionSuspenseFallback />}>
      <PlaybackCurrentQuestionMain {...props} />
    </Suspense>
  </Catch>
);

export default PlaybackCurrentQuestion;
