import React from 'react';
import { useParams } from 'react-router-dom';
import InterviewRefProvider from '../../components/InterviewRefContext';
import { useInterviewDocRef } from '../../types/Interview';
import InterviewDetailsScreen from './InterviewDetailsScreen';

export type Params = {
  interviewId: string;
};

const OrganizationInterviewDetailsPage: React.FC = () => {
  const { interviewId } = useParams<Params>();

  if (!interviewId) {
    throw new Error();
  }

  const interviewRef = useInterviewDocRef(interviewId);

  return (
    <InterviewRefProvider interviewRef={interviewRef}>
      <InterviewDetailsScreen key={interviewRef.path} />
    </InterviewRefProvider>
  );
};

export default OrganizationInterviewDetailsPage;
