import React from 'react';
import { useParams } from 'react-router-dom';
import ExpertRefProvider from '../../components/ExpertRefContext';
import { useExpertDocRef } from '../../types/Expert';
import ExpertDetailsScreen from './ExpertDetailsScreen';

export type Params = {
  expertId: string;
  organizationId: string;
};

const OrganizationExpertDetailsPage: React.FC = () => {
  const { expertId } = useParams<Params>();

  if (!expertId) {
    throw new Error();
  }

  const expertRef = useExpertDocRef(expertId);

  return (
    <ExpertRefProvider expertRef={expertRef} key={expertRef.path}>
      <ExpertDetailsScreen />
    </ExpertRefProvider>
  );
};

export default OrganizationExpertDetailsPage;
