import { Container } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import ExpertInfo, { ExpertInfoSuspenseFallback } from '../../../components/ExpertInfo';
import { useExpertRef } from '../../../components/ExpertRefContext';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const HeaderMain: React.FC = () => {
  const expertRef = useExpertRef();

  const { data: expertSnap } = useFirestoreDoc(expertRef);

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  return (
    <Container py={2}>
      <ExpertInfo expertSnap={expertSnap} />
    </Container>
  );
};

export const HeaderSuspenseFallback: React.FC = () => (
  <Container py={2}>
    <ExpertInfoSuspenseFallback />
  </Container>
);

const HeaderCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Header: React.FC = () => (
  <Catch fallback={<HeaderCatchFallback />}>
    <Suspense fallback={<HeaderSuspenseFallback />}>
      <HeaderMain />
    </Suspense>
  </Catch>
);

export default Header;
