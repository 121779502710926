import { HStack } from '@chakra-ui/react';
import React from 'react';
import { StageActionFilterOperation } from '../../types/Stage';
import StageActionFilterOperationField from './StageActionFilterOperationField';
import StageActionFilterScoreField, {
  StageActionFilterScoreFieldValue,
} from './StageActionFilterScoreField';

export type Props = {
  name: string;
};

export type StageActionAverageScoreFilterFieldValue = {
  score: StageActionFilterScoreFieldValue;
  operation: StageActionFilterOperation;
};

const StageActionAverageScoreFilterField: React.FC<Props> = ({
  name,
}) => (
  <HStack>
    <StageActionFilterOperationField name={`${name}.operation`} />
    <StageActionFilterScoreField name={`${name}.score`} />
  </HStack>
);

export default StageActionAverageScoreFilterField;
