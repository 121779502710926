import React from 'react';
import { useParams } from 'react-router-dom';
import StageRefProvider from '../../components/StageRefProvider';
import { useStageDocRef } from '../../types/Stage';
import StageDetailsScreen from './StageDetailsScreen';

export type Params = {
  stageId: string;
  organizationId: string;
};

const OrganizationStageDetailsPage: React.FC = () => {
  const { stageId } = useParams<Params>();

  if (!stageId) {
    throw new Error();
  }

  const stageRef = useStageDocRef(stageId);

  return (
    <StageRefProvider stageRef={stageRef} key={stageRef.path}>
      <StageDetailsScreen />
    </StageRefProvider>
  );
};

export default OrganizationStageDetailsPage;
