import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import Spinner from '../icons/Spinner';
import VideoOnIcon from '../icons/VideoOnIcon';
import { useRoom } from './RoomProvider';

type Props = ButtonProps;

const CallLobbyJoinButton: React.FC<Props> = (props) => {
  const { connect, isConnecting } = useRoom();

  return (
    <Button
      leftIcon={<VideoOnIcon />}
      onClick={connect}
      isLoading={isConnecting}
      spinner={<Spinner />}
      loadingText="Letting you in..."
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      Join the call
    </Button>
  );
};

export default CallLobbyJoinButton;
