import {
  Box,
  HStack,
  IconButton,
  Select,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import InfoInCircleIcon from '../../icons/InfoInCircleIcon';
import PlusIcon from '../../icons/PlusIcon';
import TrashIcon from '../../icons/TrashIcon';
import { TimeSlot, timeSlots } from '../../types/UserSchedule';
import fromOptions from './fromOptions';
import toOptions from './toOptions';

export type ScheduleWeekdayFieldProps = {
  name: string;
};

const ScheduleWeekdayField: React.FC<ScheduleWeekdayFieldProps> = ({ name }) => {
  const [field, , helpers] = useField<TimeSlot[]>(name);

  const ranges = useMemo(
    () => field.value.sort()
      .reduce<{ start: TimeSlot; end: TimeSlot }[]>((memo, slot) => {
      const lastSpan = _.last(memo);

      if (!lastSpan) {
        return [...memo, {
          start: slot,
          end: slot,
        }];
      }

      if ((
        _.findIndex<string>(timeSlots, (v) => v === slot)
        - _.findIndex<string>(timeSlots, (v) => v === lastSpan.end)
      ) > 1) {
        return [...memo, {
          start: slot,
          end: slot,
        }];
      }

      return [..._.dropRight(memo), {
        start: lastSpan.start,
        end: slot,
      }];
    }, []),
    [field.value],
  );

  const handleAddClick = useCallback(
    () => {
      const last = _.last(ranges);

      if (!last) {
        helpers.setValue(_.slice(
          timeSlots,
          _.findIndex(timeSlots, (timeSlot) => timeSlot === '0800'),
          _.findIndex(timeSlots, (timeSlot) => timeSlot === '2000'),
        ));
        return;
      }

      const i = _.findIndex(timeSlots, (timeSlot) => timeSlot === last.end) + 2;

      if (i < timeSlots.length) {
        helpers.setValue(_.uniq([
          ...field.value,
          timeSlots[i],
        ].sort()));
      }
    },
    [field.value, helpers, ranges],
  );

  const handleRemoveClick = useCallback(
    (start: TimeSlot, end: TimeSlot) => {
      const rm = _.slice(
        timeSlots,
        _.findIndex(timeSlots, (timeSlot) => timeSlot === start),
        _.findIndex(timeSlots, (timeSlot) => timeSlot === end) + 1,
      );

      helpers.setValue(_.uniq(_.difference(field.value, rm).sort()));
    },
    [field.value, helpers],
  );

  const handleStartChange = useCallback(
    (value: string, start: TimeSlot) => {
      const valueIndex = _.findIndex(timeSlots, (timeSlot) => timeSlot === value);
      const startIndex = _.findIndex(timeSlots, (timeSlot) => timeSlot === start);

      if (valueIndex > startIndex) {
        const rm = _.slice(
          timeSlots,
          startIndex,
          valueIndex,
        );

        helpers.setValue(_.uniq(_.difference(field.value, rm).sort()));
      }

      if (valueIndex < startIndex) {
        const add = _.slice(
          timeSlots,
          valueIndex,
          startIndex,
        );

        helpers.setValue(_.uniq([
          ...field.value,
          ...add,
        ].sort()));
      }
    },
    [field.value, helpers],
  );

  const handleEndChange = useCallback(
    (value: string, end: TimeSlot) => {
      const valueIndex = _.findIndex(timeSlots, (timeSlot) => timeSlot === value);
      const endIndex = _.findIndex(timeSlots, (timeSlot) => timeSlot === end);

      if (valueIndex < endIndex) {
        const rm = _.slice(
          timeSlots,
          valueIndex + 1,
          endIndex + 1,
        );

        helpers.setValue(_.uniq(_.difference(field.value, rm).sort()));
      }

      if (valueIndex > endIndex) {
        const add = _.slice(
          timeSlots,
          endIndex + 1,
          valueIndex + 1,
        );

        helpers.setValue(_.uniq([
          ...field.value,
          ...add,
        ].sort()));
      }
    },
    [field.value, helpers],
  );

  const coversMorning = useMemo(() => {
    const morningSlots = _.slice(timeSlots, 24, 40);
    return _.intersection(morningSlots, field.value).length > 4;
  }, [field.value]);

  const coversEvening = useMemo(() => {
    const eveningSlots = _.slice(timeSlots, 72, 88);
    return _.intersection(eveningSlots, field.value).length > 4;
  }, [field.value]);

  return (
    <VStack alignItems="stretch" spacing={1}>
      <HStack alignItems="top">
        {ranges.length ? (
          <VStack alignItems="stretch" flexGrow={1}>
            {ranges.map(({ start, end }, i) => {
              const from = i === 0
                ? _.slice(timeSlots, 0, _.indexOf(timeSlots, end))
                : _.slice(
                  timeSlots,
                  _.indexOf(timeSlots, ranges[i - 1].end) + 1,
                  _.indexOf(timeSlots, end) + 1,
                );

              const to = i === ranges.length - 1
                ? _.slice(timeSlots, _.indexOf(timeSlots, start))
                : _.slice(
                  timeSlots,
                  _.indexOf(timeSlots, start),
                  _.indexOf(timeSlots, ranges[i + 1].start),
                );

              const duration = _.indexOf(timeSlots, end) - _.indexOf(timeSlots, start) + 1;

              return (
                <HStack key={start}>
                  <Select
                    value={start}
                    onChange={(v) => handleStartChange(v.target.value, start)}
                    maxW="120px"
                  >
                    {from.map((k) => (
                      <option key={k} value={k}>{fromOptions[k]}</option>
                    ))}
                  </Select>

                  <Box>
                    <Text>
                      -
                    </Text>
                  </Box>

                  <Select
                    value={end}
                    onChange={(v) => handleEndChange(v.target.value, end)}
                    maxW="120px"
                  >
                    {to.map((k) => (
                      <option key={k} value={k}>{toOptions[k]}</option>
                    ))}
                  </Select>

                  <IconButton
                    aria-label="Remove"
                    variant="ghost"
                    icon={<TrashIcon />}
                    size="lg"
                    onClick={() => handleRemoveClick(start, end)}
                  />

                  {duration < 4 ? (
                    <Tooltip
                      label="This range is too short, it would be hard to squeeze an interview into it. Keep it at least an hour long please"
                    >
                      <Box>
                        <InfoInCircleIcon color="#E0942D" />
                      </Box>
                    </Tooltip>
                  ) : null}
                </HStack>
              );
            })}
          </VStack>
        ) : (
          <Text flexGrow={1} lineHeight="44px" variant="labelSmall" color="cf.cntTertiary">
            No time slots available
          </Text>
        )}

        <Box flexGrow={0} flexShrink={0}>
          <IconButton
            onClick={handleAddClick}
            aria-label="Add"
            variant="ghost"
            icon={<PlusIcon />}
            size="lg"
          />
        </Box>
      </HStack>

      {(!coversMorning && coversEvening) ? (
        <Text variant="labelSmall" color="#E0942D">
          It should cover at least an hour in the morning (6 AM - 10 PM)
          to have better chances to get to an interview fast
        </Text>
      ) : null}

      {(!coversEvening && coversMorning) ? (
        <Text variant="labelSmall" color="#E0942D">
          It should cover at least an hour in the evening (6 PM - 10 PM)
          to have better chances to get to an interview fast
        </Text>
      ) : null}

      {(!coversEvening && !coversMorning) ? (
        <Text variant="labelSmall" color="#E0942D">
          It should cover at least an hour in the morning (6 AM - 10 PM)
          and an hour in the evening (6 PM - 10 PM)
          to have better chances to get to an interview fast
        </Text>
      ) : null}
    </VStack>
  );
};

export default ScheduleWeekdayField;
