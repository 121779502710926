import { IconButton } from '@chakra-ui/react';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import BackIcon from '../../../../icons/BackIcon';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const BackButtonMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const navigate = useNavigate();
  const handleBackClick = useCallback(
    () => {
      if (!interview.customerRef) {
        return navigate('/');
      }

      return navigate(`/organizations/${interview.customerRef.id}/interviews/${interviewSnap.id}`);
    },
    [interview.customerRef, interviewSnap.id, navigate],
  );

  return (
    <IconButton
      variant="ghost"
      aria-label="Back"
      icon={<BackIcon />}
      onClick={handleBackClick}
    />
  );
};

const BackButtonCatchFallback: React.FC = () => null;
const BackButtonSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const BackButton: React.FC = () => (
  <Catch fallback={<BackButtonCatchFallback />}>
    <Suspense fallback={<BackButtonSuspenseFallback />}>
      <BackButtonMain />
    </Suspense>
  </Catch>
);

export default BackButton;
