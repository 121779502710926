import { BoxProps } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { StageDoc } from '../types/Stage';
import BlockListItem from './BlockListItem';
import Catch from './Catch';
import StageInfo, { StageInfoSuspenseFallback } from './StageInfo';

export type Props = {
  stageSnap: QueryDocumentSnapshot<StageDoc>;
} & BoxProps;

const StageRowMain: React.FC<Props> = ({ stageSnap, ...boxProps }: Props) => {
  const { stageId: activeStageId } = useParams<{ stageId: string }>();

  const navigate = useNavigate();
  const onClick = useCallback(
    () => navigate(`./${stageSnap.id}`),
    [stageSnap.id, navigate],
  );

  return (
    <BlockListItem
      onClick={onClick}
      aria-pressed={stageSnap.id === activeStageId}
      height="68px"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <StageInfo stageSnap={stageSnap} />
    </BlockListItem>
  );
};

export const StageRowSuspenseFallback: React.FC = () => (
  <BlockListItem height="68px">
    <StageInfoSuspenseFallback />
  </BlockListItem>
);

export const StageRowCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StageRow: React.FC<Props> = (props) => (
  <Catch fallback={<StageRowCatchFallback />}>
    <Suspense fallback={<StageRowSuspenseFallback />}>
      <StageRowMain {...props} />
    </Suspense>
  </Catch>
);

export default StageRow;
