import { Button, Text, VStack } from '@chakra-ui/react';
import { GeoPoint } from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../components/Catch';
import PaddingBlock from '../../components/PaddingBlock';
import { useUserRef } from '../../components/UserRefContext';
import useDeelCreateContract from '../../functions/useDeelCreateContract';
import useDeelSendContractToExpert from '../../functions/useDeelSendContractToExpert';
import Spinner from '../../icons/Spinner';
import { useUserInterviewerProfileDocRef } from '../../types/UserInterviewerProfile';

export interface FormFields {
  location: GeoPoint | null;
}

const ContractFormMain: React.FC = () => {
  const userRef = useUserRef();
  const userInterviewerProfileRef = useUserInterviewerProfileDocRef(userRef);
  const { data: userInterviewerProfileSnap } = useFirestoreDoc(userInterviewerProfileRef);

  const userInterviewerProfile = useMemo(
    () => userInterviewerProfileSnap.data(),
    [userInterviewerProfileSnap],
  );

  const contractExists = useMemo(
    () => userInterviewerProfile?.deelContractId,
    [userInterviewerProfile?.deelContractId],
  );

  const [isLoading, setIsLoading] = useState(false);
  const createContract = useDeelCreateContract();
  const sendInterviewerInvitation = useDeelSendContractToExpert();

  const handleCreateClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await createContract();
    } finally {
      setIsLoading(false);
    }
  }, [createContract]);

  const handleInviteClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await sendInterviewerInvitation();
    } finally {
      setIsLoading(false);
    }
  }, [sendInterviewerInvitation]);

  return (
    <PaddingBlock variant="outline">
      {contractExists ? (
        <VStack alignItems="stretch">
          <Text>
            <Text as="a" href="https://deel.com" target="_blank" color="cf.cntAccent">Deel.com</Text>
            {' '}
            have sent the contract to your email.
            Make sure you sign it to start getting payouts.
          </Text>

          <Button
            onClick={handleInviteClick}
            isLoading={isLoading}
            spinner={<Spinner />}
            loadingText="Resending contract..."
          >
            Resend contract
          </Button>
        </VStack>
      ) : (
        <VStack alignItems="stretch">
          <Text>
            You need to sign a contract to start working with Clarwis.
          </Text>

          <Button
            onClick={handleCreateClick}
            isLoading={isLoading}
            spinner={<Spinner />}
            loadingText="Generating your contract..."
          >
            Get my contract
          </Button>
        </VStack>
      )}
    </PaddingBlock>
  );
};

export const ContractFormCatchFallback: React.FC = () => null;
export const ContractFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ContractForm: React.FC = () => (
  <Catch fallback={<ContractFormCatchFallback />}>
    <Suspense fallback={<ContractFormSuspenseFallback />}>
      <ContractFormMain />
    </Suspense>
  </Catch>
);

export default ContractForm;
