import {
  Box,
  BoxProps,
  HStack,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import {
  DocumentReference,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import BlockListItem from '../../../../components/BlockListItem';
import { useCandidateRef } from '../../../../components/CandidateRefProvider';
import Catch from '../../../../components/Catch';
import ScoreShortInfo from '../../../../components/ScoreShortInfo';
import SkillInfo from '../../../../components/SkillInfo';
import { useScoresCollectionRef } from '../../../../types/Score';
import { SkillDoc } from '../../../../types/Skill';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

export type Props = BoxProps & {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillItemMain: React.FC<Props> = ({ skillRef }) => {
  const candidateRef = useCandidateRef();
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const scoresCollectionRef = useScoresCollectionRef();
  const { data: scoresSnap } = useFirestoreCollection(
    query(
      scoresCollectionRef,
      where('candidateRef', '==', candidateRef),
      where('skillRef', '==', skillRef),
      orderBy('score', 'desc'),
      limit(1),
    ),
  );

  const scoreSnap = useMemo(() => {
    if (!scoresSnap.docs.length) {
      return undefined;
    }

    return scoresSnap.docs[0];
  }, [scoresSnap.docs]);

  const navigate = useNavigate();

  return (
    <BlockListItem
      data-intercom-target="SkillRow"
      onClick={() => navigate(`../../skills/${skillSnap.id}`)}
    >
      <HStack>
        <SkillInfo skillSnap={skillSnap} />

        {scoreSnap ? (
          <ScoreShortInfo scoreSnap={scoreSnap} />
        ) : null}
      </HStack>
    </BlockListItem>
  );
};

export const SkillItemSuspenseFallback: React.FC = () => (
  <HStack>
    <SkeletonCircle boxSize={5} />

    <Box flexGrow={1}>
      <Skeleton h={5} w="120px" />
    </Box>

    <Skeleton h={7} w="35px" borderRadius="md" />
  </HStack>
);

const SkillItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillItem: React.FC<Props> = (props) => (
  <Catch fallback={<SkillItemCatchFallback />}>
    <Suspense fallback={<SkillItemSuspenseFallback />}>
      <SkillItemMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillItem;
