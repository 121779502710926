import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import _ from 'lodash';
import React, { ChangeEvent, useCallback } from 'react';

export interface Props extends InputProps {
  label: string;
  name: string;
  options: { value: string; label: string; }[];
}

const CheckboxListField: React.FC<Props> = ({
  label, name, options, isRequired,
}: Props) => {
  const [field, meta, helpers] = useField<string[]>(name);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      helpers.setValue([...field.value, e.target.value]);
    } else {
      helpers.setValue(_.without(field.value, e.target.value));
    }
  }, [field.value, helpers]);

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <CheckboxGroup>
        {options.map((option) => (
          <Checkbox
            name={name}
            value={option.value}
            key={option.value}
            isChecked={_.includes(field.value, option.value)}
            onChange={onChange}
          >
            {option.label}
          </Checkbox>
        ))}
      </CheckboxGroup>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default CheckboxListField;
