import { BoxProps, useToken } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React from 'react';
import BlockListItem from '../../components/BlockListItem';
import ExpertInfo from '../../components/ExpertInfo';
import useExpertStatusColor from '../../hooks/useExpertStatusColor';
import { ExpertDoc } from '../../types/Expert';

export type Props = {
  expertSnap: QueryDocumentSnapshot<ExpertDoc>;
} & BoxProps;

const ExpertRow: React.FC<Props> = ({ expertSnap, ...boxProps }: Props) => {
  const statusColor = useExpertStatusColor(expertSnap);
  const [styledColor] = useToken('colors', [statusColor]);

  return (
    <BlockListItem
      data-intercom-target="ExpertRow"
      height="68px"
      boxShadow={`inset 4px 0px ${styledColor}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <ExpertInfo expertSnap={expertSnap} />
    </BlockListItem>
  );
};

export default ExpertRow;
