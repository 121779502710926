import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { CandidateDoc } from '../types/Candidate';

const CandidateRefContext = React
  .createContext<DocumentReference<CandidateDoc> | undefined>(undefined);

export const useCandidateRef = (): DocumentReference<CandidateDoc> => {
  const candidateRef = useContext(CandidateRefContext);
  if (!candidateRef) {
    throw new Error('useCandidateRef needs to be wrapped with CandidateRefProvider');
  }
  return candidateRef;
};

export type Props = {
  candidateRef: DocumentReference<CandidateDoc>;
};

const CandidateRefProvider: React.FC<PropsWithChildren<Props>> = ({
  candidateRef,
  children,
}) => (
  <CandidateRefContext.Provider value={candidateRef} key={candidateRef.path}>
    {children}
  </CandidateRefContext.Provider>
);

export default CandidateRefProvider;
