import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ArrowLeftIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.87264 7.88474L12.282 2.30402L13.718 3.696L8.29841 9.28739L8.29289 9.2929C7.90237 9.68343 7.90237 10.3166 8.29289 10.7071L8.29841 10.7126L13.718 16.304L12.282 17.696L6.87261 12.1152C5.70912 10.9452 5.70913 9.05477 6.87264 7.88474Z" fill="currentColor" />
  </Icon>
);

export default ArrowLeftIcon;
