import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import useDeelGetAuthUrl from '../../functions/useDeelGetAuthUrl';

export type Params = {
  organizationId: string;
};

const OrganizationDeelAuthPage: React.FC = () => {
  const { organizationId } = useParams<Params>();

  const getAuthUrl = useDeelGetAuthUrl();

  useEffect(
    () => {
      if (organizationId) {
        getAuthUrl({
          organizationId,
        }).then(({
          data: url,
        }) => (window.location.assign(url)));
      }
    },
    [getAuthUrl, organizationId],
  );

  return (
    <Loader />
  );
};

export default OrganizationDeelAuthPage;
