import { QueryDocumentSnapshot } from 'firebase/firestore';
import { useMemo } from 'react';
import { CandidateDoc, CandidateStatus } from '../types/Candidate';

const useCandidateStatusColor = (candidateSnap: QueryDocumentSnapshot<CandidateDoc>) => useMemo(
  () => {
    const candidate = candidateSnap.data();

    if (!candidate.userRef && candidate.status === CandidateStatus.PAUSED) {
      return 'cf.status.empty';
    }

    switch (candidate.status) {
      case CandidateStatus.ACTIVE:
        return 'cf.status.started';
      case CandidateStatus.FINALIZED:
        return 'cf.status.ended';
      case CandidateStatus.PAUSED:
        return 'cf.status.canceled';
      case CandidateStatus.ARCHIVED:
      default:
        return 'cf.status.archived';
    }
  },
  [candidateSnap],
);

export default useCandidateStatusColor;
