import { SearchOptions } from '@algolia/client-search';
import {
  Input,
  InputGroup,
  InputLeftElement,
  useControllableState,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useDebounce from '../../../functions/useDebounce';
import SearchIcon from '../../../icons/SearchIcon';

type Mutable<Type> = {
  -readonly [Key in keyof Type]: Type[Key];
};

export interface Props {
  onSearchOptionsChange: (query: string, searchOptions: SearchOptions) => unknown;
}

const SearchBar: React.FC<Props> = ({ onSearchOptionsChange }) => {
  const [searchTerm, setSearchTerm] = useControllableState({ defaultValue: '' });
  const query = useDebounce(searchTerm, 500);

  useEffect(() => {
    const searchOptions: Mutable<SearchOptions> = { };

    onSearchOptionsChange(query, searchOptions);
  }, [onSearchOptionsChange, query]);

  return (
    <InputGroup size="sm" mb={3}>
      <InputLeftElement pointerEvents="none" color="cf.cntSecondary">
        <SearchIcon />
      </InputLeftElement>
      <Input
        placeholder="Search for names, skills, etc."
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        variant="flushed"
      />
    </InputGroup>
  );
};

export default SearchBar;
