import { Button } from '@chakra-ui/react';
import React, { Suspense, useCallback } from 'react';
import MicrosoftIcon from '../../icons/MicrosoftIcon';
import Catch from '../Catch';

const SyncMicrosoftCalendarButtonMain: React.FC = () => {
  const handleClick = useCallback(() => {
    window.open(
      `${window.location.origin}/ms-auth`,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,width=480,height=640',
    );
  }, []);

  return (
    <Button
      variant="outline"
      onClick={handleClick}
      leftIcon={<MicrosoftIcon />}
    >
      Sync with Microsoft Calendar
    </Button>
  );
};

export const SyncMicrosoftCalendarButtonCatchFallback: React.FC = () => null;
export const SyncMicrosoftCalendarButtonSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SyncMicrosoftCalendarButton: React.FC = () => (
  <Catch fallback={<SyncMicrosoftCalendarButtonCatchFallback />}>
    <Suspense fallback={<SyncMicrosoftCalendarButtonSuspenseFallback />}>
      <SyncMicrosoftCalendarButtonMain />
    </Suspense>
  </Catch>
);

export default SyncMicrosoftCalendarButton;
