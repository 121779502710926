import { Box, BoxProps } from '@chakra-ui/react';
import * as d3 from 'd3';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import React, { SVGAttributes, Suspense, useMemo } from 'react';
import { SkillDoc } from '../../types/Skill';
import Catch from '../Catch';
import SkillLogo from './SkillLogo';

export type Props = {
  r?: number;
  m?: number;
  items: Array<{
    skillRef: DocumentReference<SkillDoc>;
    score?: number;
  }>;
} & BoxProps;

const ScoreSpiderChartMain: React.FC<Props> = ({
  r = 50,
  m = 10,
  items,
  ...boxProps
}) => {
  const viewBox = useMemo(() => `${(r + m) * -1} ${(r + m) * -1} ${(r + m) * 2} ${(r + m) * 2}`, [m, r]);
  const skillIconSize = useMemo(() => Math.sqrt((m ** 2) / 2), [m]);

  const chartAttributes: SVGAttributes<SVGPathElement> = useMemo(
    () => (
      _.some(items, ({ score }) => score === undefined)
        ? { stroke: '#7A37AD', strokeDasharray: '4 1' }
        : { fill: '#7A37AD' }
    ),
    [items],
  );

  const rScale = useMemo(
    () => d3.scaleLinear()
      .range([r / 5, r])
      .domain([0, 1]),
    [r],
  );

  const angleSlice = useMemo(() => Math.PI * 2 / items.length, [items.length]);

  const radarLine = useMemo(
    () => d3.lineRadial()
      .curve(d3.curveCatmullRomClosed.alpha(1))
      .radius((d) => rScale(d[0]))
      .angle((d, i) => i * angleSlice),
    [angleSlice, rScale],
  );

  const chartPath = useMemo<string | undefined>(
    () => radarLine(items.map(
      ({ score }) => ([score || 0, 0]),
    )) || undefined,
    [items, radarLine],
  );

  const levelPaths = useMemo<Array<string | undefined>>(
    () => d3.range(1, 5).reverse().map(
      (rr) => radarLine(d3.range(0, items.length).map(
        () => ([rr / 4, 0]),
      )) || undefined,
    ),
    [radarLine, items.length],
  );

  return (
    <Box
      as="svg"
      viewBox={viewBox}
      fill="none"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      {levelPaths.map((d) => (
        <path
          key={d}
          d={d}
          fill="#CDCDCD"
          fillOpacity={0.25}
        />
      ))}

      {items.map(({ skillRef }, i) => (
        <SkillLogo
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          x={
            (r + m / 2)
            * Math.cos(angleSlice * i - Math.PI / 2)
            - (skillIconSize / 2)
          }
          y={
            (r + m / 2)
            * Math.sin(angleSlice * i - Math.PI / 2)
            - (skillIconSize / 2)
          }
          height={skillIconSize}
          width={skillIconSize}
          skillRef={skillRef}
        />
      ))}

      <path
        d={chartPath}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...chartAttributes}
      />
    </Box>
  );
};

ScoreSpiderChartMain.defaultProps = {
  r: 50,
  m: 10,
};

const ScoreSpiderChartCatchFallback: React.FC = () => null;
const ScoreSpiderChartSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ScoreSpiderChart: React.FC<Props> = (props) => (
  <Catch fallback={<ScoreSpiderChartCatchFallback />}>
    <Suspense fallback={<ScoreSpiderChartSuspenseFallback />}>
      <ScoreSpiderChartMain {...props} />
    </Suspense>
  </Catch>
);

ScoreSpiderChart.defaultProps = {
  r: 50,
  m: 10,
};

export default ScoreSpiderChart;
