import { Button } from '@chakra-ui/react';
import { logEvent } from 'firebase/analytics';
import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import React, { useCallback, useMemo, useState } from 'react';
import { useAnalytics, useAuth } from 'reactfire';
import MicrosoftIcon from '../../icons/MicrosoftIcon';
import Spinner from '../../icons/Spinner';

const MicrosoftButton: React.FC = () => {
  const auth = useAuth();
  const analytics = useAnalytics();

  const provider = useMemo(
    () => new OAuthProvider('microsoft.com')
      .setCustomParameters({
        prompt: 'select_account',
      }),
    [],
  );

  const [authInProgress, setAuthInProgress] = useState(false);

  const handleGoogleClick = useCallback(async () => {
    setAuthInProgress(true);
    try {
      await signInWithPopup(auth, provider);
      logEvent(analytics, 'login', { method: 'microsoft' });
    } catch (err) {
      /* do nothing */
    } finally {
      setAuthInProgress(false);
    }
  }, [analytics, auth, provider]);

  return (
    <Button
      size="lg"
      width="100%"
      variant="outline"
      leftIcon={<MicrosoftIcon />}
      onClick={handleGoogleClick}
      isLoading={authInProgress}
      spinner={<Spinner />}
      loadingText="Signing you in..."
    >
      Continue with Microsoft account
    </Button>
  );
};

export default MicrosoftButton;
