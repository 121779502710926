import {
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
} from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import MemberAvatar from '../../../components/MemberAvatar';
import { OrganizationDoc } from '../../../types/Organization';
import {
  OrganizationBalanceTransactionDoc,
  OrganizationBalanceTransactionType,
} from '../../../types/OrganizationBalanceTransaction';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

export interface Props {
  transactionSnap: QueryDocumentSnapshot<OrganizationBalanceTransactionDoc>;
}

const CandidateCellMain: React.FC<Props> = ({ transactionSnap }) => {
  const transaction = useMemo(() => transactionSnap.data(), [transactionSnap]);

  const organizationRef = useMemo(
    () => transactionSnap.ref.parent.parent?.parent.parent as DocumentReference<OrganizationDoc>,
    [transactionSnap],
  );

  if (
    transaction.type !== OrganizationBalanceTransactionType.INTERVIEW_CANCELED
    && transaction.type !== OrganizationBalanceTransactionType.INTERVIEW_CREATED
    && transaction.type !== OrganizationBalanceTransactionType.REFUND
  ) {
    throw new Error();
  }

  const { data: candidateSnap } = useFirestoreDoc(transaction.candidateRef);

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  return (
    <a href={`${window.location.origin}/organizations/${organizationRef.id}/candidates/${transaction.candidateRef.id}`} target="_blank" rel="noreferrer">
      <HStack>
        <MemberAvatar userRef={candidate.userRef} size="xs" />

        <Text>
          {candidate.firstName}
          {' '}
          {candidate.lastName}
        </Text>
      </HStack>
    </a>
  );
};

export const CandidateCellSuspenseFallback: React.FC = () => (
  <HStack>
    <SkeletonCircle boxSize={7} />
    <Skeleton h={4} w="100px" />
  </HStack>
);

const CandidateCellCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateCell: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateCellCatchFallback />}>
    <Suspense fallback={<CandidateCellSuspenseFallback />}>
      <CandidateCellMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateCell;
