import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  DocumentReference,
  PartialWithFieldValue,
  deleteField,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import AvatarField from '../../components/AvatarField';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import TextField from '../../components/TextField';
import Spinner from '../../icons/Spinner';
import { AvatarDoc } from '../../types/Avatar';
import { OrganizationDoc } from '../../types/Organization';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';

export type Props = {
  organizationRef: DocumentReference<OrganizationDoc>;
};

export interface FormFields {
  name: string;
  email: string;
  logoRef: DocumentReference<AvatarDoc> | null;
}

const schema = yup.object().shape({
  name: yup.string().label('Company name').required(),
  email: yup.string().label('Contact email').email().required(),
  logoRef: yup.mixed().label('Company logo').required(),
});

const OrganizationFormMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const [validateAll, setValidateAll] = useState(false);

  const { data: organizationSnap } = useFirestoreDoc(organizationRef);

  if (!organizationSnap.exists()) {
    throw new SnapNotFoundError(organizationSnap);
  }

  const organization = useMemo(() => organizationSnap.data(), [organizationSnap]);

  const onSubmit = useCallback(
    async ({
      name,
      email,
      logoRef,
    }: FormFields) => {
      const organizationData: PartialWithFieldValue<OrganizationDoc> = {
        name,
        email,
      };

      if (logoRef) {
        organizationData.logoRef = logoRef;
      } else {
        organizationData.logoRef = deleteField();
      }

      await setDoc(organizationRef, organizationData, { merge: true });
    },
    [organizationRef],
  );

  return (
    <Formik<FormFields>
      initialValues={{
        name: organization.name || '',
        email: organization.email || '',
        logoRef: organization.logoRef || null,
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <AvatarField name="logoRef" label="Logo" placeholder="Upload your logo" logo />

            <HStack spacing={4} alignItems="start">
              <TextField name="name" label="Company name" placeholder="MegaCorp" />
              <TextField name="email" type="email" label="Contact email" placeholder="contact@megacorp.com" />
            </HStack>

            <HStack justifyContent="right">
              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Saving..."
              >
                Save
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

const OrganizationFormCatchFallback: React.FC = () => null;
const OrganizationFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationForm: React.FC = () => (
  <Catch fallback={<OrganizationFormCatchFallback />}>
    <Suspense fallback={<OrganizationFormSuspenseFallback />}>
      <OrganizationFormMain />
    </Suspense>
  </Catch>
);

export default OrganizationForm;
