import React, { PropsWithChildren, ReactNode, useContext } from 'react';

export type CatchProps = PropsWithChildren<{ fallback: ReactNode }>;

export type CatchState = { error: Error | null };

const ErrorContext = React.createContext<CatchState['error']>(null);

export const useError = (): CatchState['error'] => useContext(ErrorContext);

export default class Catch extends React.Component<CatchProps, CatchState> {
  constructor(props: CatchProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): CatchState {
    return { error };
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorContext.Provider value={error}>
          {fallback}
        </ErrorContext.Provider>
      );
    }

    return children;
  }
}
