import { SkeletonText } from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import React, { Suspense, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import { useSkillRef } from '../../../components/SkillRefContext';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const SkillDetailsMain: React.FC = () => {
  const skillRef = useSkillRef();

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  return (
    <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
      {skill.description}
    </ReactMarkdown>
  );
};

export const SkillDetailsSuspenseFallback: React.FC = () => (
  <SkeletonText noOfLines={10} spacing="4" skeletonHeight="2" />
);

const SkillDetailsCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillDetails: React.FC = () => (
  <Catch fallback={<SkillDetailsCatchFallback />}>
    <Suspense fallback={<SkillDetailsSuspenseFallback />}>
      <SkillDetailsMain />
    </Suspense>
  </Catch>
);

export default SkillDetails;
