import {
  Button,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import AvatarField from '../../components/AvatarField';
import PaddingBlock from '../../components/PaddingBlock';
import TextField from '../../components/TextField';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import Spinner from '../../icons/Spinner';
import { AvatarDoc } from '../../types/Avatar';

export type Props = {
  initialValues: FormFields;
  onSubmit: (values: FormFields) => Promise<unknown>;
  onPrevious: () => unknown;
};

export interface FormFields {
  name: string;
  email: string;
  logoRef: DocumentReference<AvatarDoc> | null;
}

const schema = yup.object().shape({
  name: yup.string().label('Company name').required(),
  email: yup.string().label('Contact email').email().required(),
  logoRef: yup.mixed().label('Company logo').required(),
});

const OrganizationForm: React.FC<Props> = ({ initialValues, onSubmit, onPrevious }) => {
  const [validateAll, setValidateAll] = useState(false);

  return (
    <VStack spacing={8} alignItems="stretch" textAlign="left">
      <PaddingBlock>
        <Text>
          In this pivotal step, we invite you to create your organization profile, a crucial
          component in optimizing your technical interviewing process. With your organization&apos;s
          branding, you can send polished and professional emails to invite candidates for
          interviews, leaving a lasting impression on potential hires. Moreover, our collaborative
          features allow you to effortlessly bring your colleagues on board, fostering teamwork and
          collective decision-making based on interview results. This synergy ensures that you make
          the best hiring choices, leveraging the insights and expertise of your entire team.
          Let&apos;s establish your organization profile and empower your team to hire top talent
          while showcasing your company&apos;s unique identity.
        </Text>
      </PaddingBlock>

      <Formik<FormFields>
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnChange={validateAll}
        validateOnBlur={validateAll}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form
            noValidate
            onSubmit={(...props) => {
              setValidateAll(true);
              return handleSubmit(...props);
            }}
          >
            <VStack spacing={8} alignItems="stretch" textAlign="left">
              <AvatarField name="logoRef" label="Logo" placeholder="Upload your logo" logo />

              <HStack spacing={4} alignItems="start">
                <TextField name="name" label="Company name" placeholder="MegaCorp" />
                <TextField name="email" label="Contact email" placeholder="contact@megacorp.com" />
              </HStack>

              <HStack justifyContent="right">
                <Button
                  variant="outline"
                  onClick={onPrevious}
                  leftIcon={<ArrowUpIcon />}
                >
                  Previous
                </Button>

                <Spacer />

                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Saving..."
                  leftIcon={<ArrowDownIcon />}
                >
                  Next
                </Button>
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </VStack>
  );
};

export default OrganizationForm;
