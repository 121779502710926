import {
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  and,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import BlockListItem from '../../../components/BlockListItem';
import CandidateInfo, { CandidateInfoSuspenseFallback } from '../../../components/CandidateInfo';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import { useSkillRef } from '../../../components/SkillRefContext';
import { CandidateStatus, useCandidatesCollectionRef } from '../../../types/Candidate';

const CandidateListMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const skillRef = useSkillRef();

  const candidatesCollectionRef = useCandidatesCollectionRef();

  const { data: candidatesSnap } = useFirestoreCollection(query(
    candidatesCollectionRef,
    and(
      where('organizationRef', '==', organizationRef),
      where('status', 'in', [CandidateStatus.ACTIVE, CandidateStatus.PAUSED, CandidateStatus.FINALIZED]),
      where('skillRefs', 'array-contains', skillRef),
    ),
    orderBy('createdAt', 'desc'),
    limit(5),
  ));

  const navigate = useNavigate();

  if (!candidatesSnap.docs.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Candidates">
      <HStack>
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
          flexGrow={1}
        >
          Candidates
          {' '}
          &middot;
          {' '}
          {candidatesSnap.docs.length}
        </Text>

        <Button
          variant="link"
          size="sm"
          onClick={() => navigate(`../../candidates?skillId=${skillRef.id}`)}
        >
          all
        </Button>
      </HStack>

      <BlockList variant="outline">
        {candidatesSnap.docs.map((candidateSnap) => (
          <BlockListItem
            key={candidateSnap.id}
            data-intercom-target="CandidateRow"
            onClick={() => navigate(`../../candidates/${candidateSnap.id}`)}
          >
            <CandidateInfo candidateSnap={candidateSnap} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

export const CandidateListSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch" data-intercom-target="Candidates">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Candidates
    </Text>

    <BlockList variant="outline">
      <BlockListItem>
        <CandidateInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <CandidateInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <CandidateInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <CandidateInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <CandidateInfoSuspenseFallback />
      </BlockListItem>
    </BlockList>
  </VStack>
);

export const CandidateListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateList: React.FC = () => (
  <Catch fallback={<CandidateListCatchFallback />}>
    <Suspense fallback={<CandidateListSuspenseFallback />}>
      <CandidateListMain />
    </Suspense>
  </Catch>
);

export default CandidateList;
