import {
  Container,
  Divider,
  Grid,
  GridItem,
  ScaleFade,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { Suspense, useCallback, useState } from 'react';
import Catch from '../../../components/Catch';
import useTimezone from '../../../hooks/useTimezone';
import { InterviewStatus } from '../../../types/Interview';
import Header, { HeaderCatchFallback, HeaderSuspenseFallback } from './Header';
import InterviewsList, {
  InterviewsListCatchFallback,
  InterviewsListSuspenseFallback,
} from './InterviewsList';

const OrganizationInterviewsScreenMain: React.FC = () => {
  const timezone = useTimezone();

  const [search, setSearch] = useState<{
    start: number;
    end: number;
    statuses: InterviewStatus[];
  }>({
    start: moment()
      .tz(timezone)
      .startOf('month')
      .toDate()
      .getTime(),
    end: moment()
      .tz(timezone)
      .endOf('month')
      .toDate()
      .getTime(),
    statuses: [
      InterviewStatus.CREATED,
      InterviewStatus.STARTED,
      InterviewStatus.ENDED,
    ],
  });

  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrolledToTop(e.currentTarget.scrollTop <= 0);
  }, []);

  return (
    <Grid
      height="100%"
      minH={0}
      flexGrow={1}
      templateRows="auto 0px 1fr"
    >
      <GridItem>
        <Header onChange={setSearch} />
      </GridItem>

      <GridItem py="-0.5px">
        <Container>
          <ScaleFade in={!scrolledToTop}>
            <Divider />
          </ScaleFade>
        </Container>
      </GridItem>

      <GridItem overflow="auto" minH={0} py={3} onScroll={handleScroll}>
        <Container height="100%">
          <InterviewsList search={search} />
        </Container>
      </GridItem>
    </Grid>
  );
};

export const OrganizationInterviewsScreenCatchFallback: React.FC = () => (
  <Grid
    height="100%"
    minH={0}
    flexGrow={1}
    templateRows="60px 0px 1fr"
  >
    <GridItem py={3}>
      <Container>
        <HeaderCatchFallback />
      </Container>
    </GridItem>

    <GridItem py="-0.5px">
      <Container>
        <Divider />
      </Container>
    </GridItem>

    <GridItem overflow="auto" minH={0} py={3}>
      <Container>
        <InterviewsListCatchFallback />
      </Container>
    </GridItem>
  </Grid>
);

export const OrganizationInterviewsScreenSuspenseFallback: React.FC = () => (
  <Grid
    height="100%"
    minH={0}
    flexGrow={1}
    templateRows="60px 1fr"
  >
    <GridItem py={3}>
      <HeaderSuspenseFallback />
    </GridItem>

    <GridItem overflow="auto" minH={0} py={3}>
      <Container>
        <InterviewsListSuspenseFallback />
      </Container>
    </GridItem>
  </Grid>
);

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationInterviewsScreen: React.FC = () => (
  <Catch fallback={<OrganizationInterviewsScreenCatchFallback />}>
    <Suspense fallback={<OrganizationInterviewsScreenSuspenseFallback />}>
      <OrganizationInterviewsScreenMain />
    </Suspense>
  </Catch>
);

export default OrganizationInterviewsScreen;
