import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const Spinner: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <circle
      cx="10"
      cy="4"
      r="3"
      fill="currentColor"
      style={{
        animationName: 'pulse',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
      }}
    />
    <circle
      cx="16"
      cy="10"
      r="3"
      fill="currentColor"
      style={{
        animationName: 'pulse',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDelay: '.25s',
      }}
    />
    <circle
      cx="10"
      cy="16"
      r="3"
      fill="currentColor"
      style={{
        animationName: 'pulse',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDelay: '.5s',
      }}
    />
    <circle
      cx="4"
      cy="10"
      r="3"
      fill="currentColor"
      style={{
        animationName: 'pulse',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDelay: '.75s',
      }}
    />
  </Icon>
);

export default Spinner;
