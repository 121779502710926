import { useEffect, useMemo, useState } from 'react';
import { AudioTrack } from 'twilio-video';

function rootMeanSquare(samples: Uint8Array) {
  const sumSq = samples.reduce((sum, sample) => sum + sample * sample, 0);
  return Math.sqrt(sumSq / samples.length);
}

export default function useAudioLevel(audioTrack: AudioTrack | null): number | null {
  const audioContext = useMemo<AudioContext | null>(
    () => (window.AudioContext ? new window.AudioContext() : null),
    [],
  );

  const [level, setLevel] = useState<number | null>(null);

  useEffect(() => {
    if (!audioContext || !audioTrack) {
      return;
    }

    audioContext.resume().then(() => {
      const analyser = audioContext.createAnalyser();
      analyser.fftSize = 1024;
      analyser.smoothingTimeConstant = 0.5;

      const stream = new MediaStream([audioTrack.mediaStreamTrack]);
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const samples = new Uint8Array(analyser.frequencyBinCount);
      let l: number | null = null;

      requestAnimationFrame(function checkLevel() {
        analyser.getByteFrequencyData(samples);
        const rms = rootMeanSquare(samples);
        const log2Rms = rms && Math.log2(rms);

        // Audio level ranges from 0 (silence) to 10 (loudest).
        const newLevel = Math.ceil((10 * log2Rms) / 8);
        if (l !== newLevel) {
          l = newLevel;
          setLevel(l);
        }

        // Continue calculating the level only if the audio track is live.
        if (audioTrack.mediaStreamTrack.readyState === 'live') {
          requestAnimationFrame(checkLevel);
        } else {
          requestAnimationFrame(() => setLevel(0));
        }
      });
    });
  }, [audioTrack, audioContext]);

  return level;
}
