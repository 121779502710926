import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { UserDoc, isUserRef } from './User';

export type UserLocationDoc = {
  country?: string;
  state?: string;
  district?: string;
  city?: string;
  twilioMediaRegion?: string;
};

export const isUserLocationDoc = (
  data: DocumentData,
): data is UserLocationDoc => true;

export const isUserLocationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserLocationDoc> => ref.id === 'location' && ref.parent.id === 'private' && ref.parent.parent !== null && isUserRef(ref.parent.parent);

export const isUserLocationSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<UserLocationDoc> => isUserLocationRef(snap.ref);

export const useUserLocationDocRef = (userRef: DocumentReference<UserDoc>) => useMemo(() => doc(collection(userRef, 'private'), 'location') as DocumentReference<UserLocationDoc>, [userRef]);
