import { Container } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useFirestoreDoc } from 'reactfire';
import CandidateInfo, { CandidateInfoSuspenseFallback } from '../../../components/CandidateInfo';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import { CandidateDoc } from '../../../types/Candidate';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const HeaderMain: React.FC = () => {
  const candidateRef = useCandidateRef();

  const { data: candidateSnap } = useFirestoreDoc<CandidateDoc>(candidateRef);

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  return (
    <Container py={2}>
      <CandidateInfo candidateSnap={candidateSnap} />
    </Container>
  );
};

export const HeaderSuspenseFallback: React.FC = () => (
  <Container py={2}>
    <CandidateInfoSuspenseFallback />
  </Container>
);

const HeaderCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Header: React.FC = () => (
  <Catch fallback={<HeaderCatchFallback />}>
    <Suspense fallback={<HeaderSuspenseFallback />}>
      <HeaderMain />
    </Suspense>
  </Catch>
);

export default Header;
