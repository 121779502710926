import _ from 'lodash';
import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { RemoteParticipant } from 'twilio-video';
import { useInterviewRef } from '../components/InterviewRefContext';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export default function useInterviewerParticipants(
  participants: RemoteParticipant[],
): RemoteParticipant[] {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return useMemo(
    () => _.filter(
      participants,
      ({ identity }) => _.some(
        interview.interviewerRefs,
        (interviewerRef) => interviewerRef.id === identity,
      ),
    ),
    [interview.interviewerRefs, participants],
  );
}
