import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { QuestionDoc } from '../types/Question';

const QuestionRefContext = React
  .createContext<DocumentReference<QuestionDoc> | undefined>(undefined);

export const useQuestionRef = (): DocumentReference<QuestionDoc> => {
  const questionRef = useContext(QuestionRefContext);
  if (!questionRef) {
    throw new Error('useQuestionRef needs to be wrapped with QuestionRefProvider');
  }
  return questionRef;
};

export type Props = {
  questionRef: DocumentReference<QuestionDoc>;
};

const QuestionRefProvider: React.FC<PropsWithChildren<Props>> = ({
  questionRef,
  children,
}) => (
  <QuestionRefContext.Provider value={questionRef} key={questionRef.path}>
    {children}
  </QuestionRefContext.Provider>
);

export default QuestionRefProvider;
