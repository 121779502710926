import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { AssetDoc } from './Asset';
import { OrganizationDoc } from './Organization';
import { QuestionDoc } from './Question';
import { TagDoc } from './Tag';

export type SkillDoc = {
  name: string;
  description: string;
  synonyms: string[];
  speechContext: string[];
  logoRef: DocumentReference<AssetDoc>;
  dependsOnRefs: DocumentReference<SkillDoc>[];
  questionRefs: DocumentReference<QuestionDoc>[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
  revision: number;
  tagRefs: DocumentReference<TagDoc>[];
  duration: number;
  organizationRef: DocumentReference<OrganizationDoc>;
};

export const isSkillDoc = (
  data: DocumentData,
): data is SkillDoc => true;

export const isSkillRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<SkillDoc> => ref.parent.id === 'skills';

export const isSkillSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<SkillDoc> => isSkillRef(snap.ref);

export const useSkillsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'skills') as CollectionReference<SkillDoc>, [firestore]);
};

export const useSkillDocRef = (docId: string) => {
  const collectionRef = useSkillsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
