import {
  Alert,
  Container,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import MissionMarsCard from '../../components/MissionMarsCard';
import MissionTitanCard from '../../components/MissionTitanCard';

const OrganizationStageSubscriptionPage: React.FC = () => (
  <Container py={3}>
    <VStack alignItems="stretch" spacing={5}>
      <Alert status="error" borderRadius="sm">
        Stages feature requires a subscription upgrade.
      </Alert>

      <SimpleGrid columns={2} columnGap={5}>
        <MissionMarsCard />
        <MissionTitanCard />
      </SimpleGrid>
    </VStack>
  </Container>
);

export default OrganizationStageSubscriptionPage;
