import React, { Suspense, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { useCallableFunctionResponse } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { OrganizationPlatformTier } from '../../types/OrganizationCustomerProfile';

export type RequestData = {
  organizationId: string;
  successUrl: string;
  cancelUrl: string;
  tier: OrganizationPlatformTier;
};

export type ResponseData = { url: string };

const OrganizationSubscribeStartPageMain: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { search } = useLocation();
  const tier = useMemo(
    () => new URLSearchParams(search).get('tier') as OrganizationPlatformTier,
    [search],
  );

  if (!organizationId || !tier) {
    throw new Error('organizationId is missing');
  }

  const options = useMemo(() => ({
    data: {
      organizationId,
      successUrl: `${window.location.origin}/organizations/${organizationId}/subscribe-redirect`,
      cancelUrl: `${window.location.origin}/organizations/${organizationId}/subscribe-redirect`,
      tier,
    },
  }), [organizationId, tier]);

  const { data: { url } } = useCallableFunctionResponse<RequestData, ResponseData>(
    'stripe-organization-subscription-create',
    options,
  );

  window.location.replace(url);

  return null;
};

const OrganizationSubscribeStartPageCatchFallback: React.FC = () => {
  window.close();
  return (<Loader />);
};

const OrganizationSubscribeStartPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationSubscribeStartPage: React.FC = () => (
  <Catch fallback={<OrganizationSubscribeStartPageCatchFallback />}>
    <Suspense fallback={<OrganizationSubscribeStartPageSuspenseFallback />}>
      <OrganizationSubscribeStartPageMain />
    </Suspense>
  </Catch>
);

export default OrganizationSubscribeStartPage;
