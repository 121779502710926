import { Input } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useMemo } from 'react';

export type Props = {
  name: string;
};

export type StageActionFilterScoreFieldValue = number;

const StageActionFilterScoreField: React.FC<Props> = ({
  name,
}) => {
  const [field, , helpers] = useField<number>(name);

  const value = useMemo(
    () => Math.round(field.value * 100),
    [field.value],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(
        parseInt(e.target.value, 10) / 100,
      );
    },
    [helpers],
  );

  return (
    <Input
      type="number"
      min={0}
      max={100}
      step={1}
      value={value}
      onChange={onChange}
    />
  );
};

export default StageActionFilterScoreField;
