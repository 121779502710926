import { Container, GridItem } from '@chakra-ui/react';
import React from 'react';
import ScoreboardScreen from './ScoreboardScreen';

const UserProfilePage: React.FC = () => (
  <GridItem minH={0} h="100%" overflow="auto" py={3}>
    <Container maxW="container.md">
      <ScoreboardScreen />
    </Container>
  </GridItem>
);

export default UserProfilePage;
