import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const StarFilled28Icon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={7} viewBox="0 0 28 28" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8159 3.54976C15.1011 2.00002 12.8985 2.00002 12.1837 3.54976L9.65842 9.02454L3.67125 9.73441C1.97646 9.93536 1.29583 12.0301 2.54883 13.1889L6.9753 17.2823L5.80029 23.1958C5.46768 24.8698 7.2496 26.1644 8.73883 25.3308L13.9998 22.3859L19.2608 25.3308C20.75 26.1644 22.5319 24.8698 22.1993 23.1958L21.0243 17.2823L25.4508 13.1889C26.7038 12.0301 26.0232 9.93536 24.3284 9.73441L18.3412 9.02454L15.8159 3.54976Z" fill="currentColor" />
  </Icon>
);

export default StarFilled28Icon;
