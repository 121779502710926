import {
  Skeleton,
  SkeletonProps,
  Text,
  TextProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { SkillDoc } from '../types/Skill';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import SkillDuration from './SkillDuration';

export type Props = TextProps & ThemingProps<'SkillName'> & {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillNameMain: React.FC<Props> = ({ skillRef, sx, ...props }: Props) => {
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  const style = useStyleConfig('SkillName', {});

  return (
    <Text
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {skill.name}
      {' '}
      &middot;
      {' '}
      <SkillDuration skillRef={skillRef} />
    </Text>
  );
};

export const SkillNameCatchFallback: React.FC = () => null;

export const SkillNameSuspenseFallback: React.FC<SkeletonProps & ThemingProps<'SkillNameSkeleton'>> = ({ sx, ...props }) => {
  const style = useStyleConfig('SkillNameSkeleton', {});

  return (
    <Skeleton
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SkillName: React.FC<Props> = (props) => (
  <Catch fallback={<SkillNameCatchFallback />}>
    <Suspense fallback={<SkillNameSuspenseFallback />}>
      <SkillNameMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillName;
