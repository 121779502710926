import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';
import { useInterviewRef } from '../../components/InterviewRefContext';
import EndCallIcon from '../../icons/EndCallIcon';
import { InterviewDoc } from '../../types/Interview';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';

const BackButton: React.FC<Partial<IconButtonProps>> = (props) => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc<InterviewDoc>(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap?.data(), [interviewSnap]);

  const navigate = useNavigate();
  const handleBackClick = useCallback(
    () => navigate(`/organizations/${interview?.customerRef?.id}/interviews/${interviewRef?.id}`),
    [navigate, interview.customerRef, interviewRef],
  );

  return (
    <IconButton
      aria-label="Leave"
      h="44px"
      w="44px"
      borderRadius="100%"
      colorScheme="red"
      icon={<EndCallIcon />}
      onClick={handleBackClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default BackButton;
