import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const AddUserIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6 5C6 2.79086 7.79086 1 10 1C12.2091 1 14 2.79086 14 5C14 7.20914 12.2091 9 10 9C7.79086 9 6 7.20914 6 5ZM10 3C8.89543 3 8 3.89543 8 5C8 6.10457 8.89543 7 10 7C11.1046 7 12 6.10457 12 5C12 3.89543 11.1046 3 10 3Z" fill="currentColor" />
    <path d="M15 11V14H18V16H15V19H13V16H10V14H13V11H15Z" fill="currentColor" />
    <path d="M4 15.8C4 13.7013 5.70132 12 7.8 12H11V10H7.8C4.59675 10 2 12.5967 2 15.8C2 17.015 2.98497 18 4.2 18H9V16H4.2C4.08954 16 4 15.9105 4 15.8Z" fill="currentColor" />
  </Icon>
);

export default AddUserIcon;
