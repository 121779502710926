import {
  Skeleton,
  SkeletonProps,
  Text,
  TextProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { SkillDoc } from '../types/Skill';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';

export type Props = TextProps & ThemingProps<'SkillName'> & {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillDurationMain: React.FC<Props> = ({ skillRef, sx, ...props }: Props) => {
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  const style = useStyleConfig('SkillDuration', {});

  const formatMinutes = useCallback(
    (v: number) => new Intl.NumberFormat('en-US', { style: 'unit', unit: 'minute' }).format(v),
    [],
  );

  return (
    <Text
      sx={{ ...style, ...sx }}
      as="span"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {formatMinutes(skill.duration)}
    </Text>
  );
};

export const SkillDurationCatchFallback: React.FC = () => null;

export const SkillDurationSuspenseFallback: React.FC<SkeletonProps & ThemingProps<'SkillNameSkeleton'>> = ({ sx, ...props }) => {
  const style = useStyleConfig('SkillNameSkeleton', {});

  return (
    <Skeleton
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SkillDuration: React.FC<Props> = (props) => (
  <Catch fallback={<SkillDurationCatchFallback />}>
    <Suspense fallback={<SkillDurationSuspenseFallback />}>
      <SkillDurationMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillDuration;
