import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const Play28Icon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={7} viewBox="0 0 28 28" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.49807 5.13509C7.80639 4.95617 8.18664 4.9549 8.49614 5.13176L22.4961 13.1318C22.8077 13.3098 23 13.6411 23 14C23 14.3589 22.8077 14.6902 22.4961 14.8682L8.49614 22.8682C8.18664 23.0451 7.80639 23.0438 7.49807 22.8649C7.18976 22.686 7 22.3565 7 22V6C7 5.64353 7.18976 5.31401 7.49807 5.13509Z" fill="currentColor" />
  </Icon>
);

export default Play28Icon;
