import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { SkillDoc } from '../types/Skill';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import SkillLogo, { SkillLogoSuspenseFallback } from './SkillLogo';
import SkillName, { SkillNameSuspenseFallback } from './SkillName';

export type Props = StackProps & ThemingProps<'SkillShortInfo'> & {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillShortInfoMain: React.FC<Props> = ({ skillRef, sx, ...props }) => {
  const style = useStyleConfig('SkillShortInfo', {});

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  return (
    <Popover isLazy openDelay={300} trigger="hover">
      <PopoverTrigger>
        <HStack
          spacing={2}
          sx={{ ...style, ...sx }}
        // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          <SkillLogo skillRef={skillRef} />
          <SkillName skillRef={skillRef} />
        </HStack>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            {skill.description}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const SkillShortInfoCatchFallback: React.FC = () => null;

export const SkillShortInfoSuspenseFallback: React.FC<StackProps & ThemingProps<'SkillShortInfo'>> = ({ sx, ...props }) => {
  const style = useStyleConfig('SkillShortInfo', {});

  return (
    <HStack
      spacing={2}
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <SkillLogoSuspenseFallback />
      <SkillNameSuspenseFallback />
    </HStack>
  );
};

/* eslint-disable react/jsx-props-no-spreading */
const SkillShortInfo: React.FC<Props> = (props) => (
  <Catch fallback={<SkillShortInfoCatchFallback />}>
    <Suspense fallback={<SkillShortInfoSuspenseFallback />}>
      <SkillShortInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillShortInfo;
