import { Checkbox, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo, useState } from 'react';
import { useFirestoreDoc } from 'reactfire';
import CallLobbyJoinButton from '../../../../components/CallLobbyJoinButton';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import PaddingBlock from '../../../../components/PaddingBlock';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const CallActionMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);
  const [consent, setConsent] = useState<boolean>(false);

  return (
    <PaddingBlock>
      <VStack alignItems="stretch" spacing={3}>
        <Checkbox
          onChange={(e) => setConsent(e.target.checked)}
          checked={consent}
          flexGrow={1}
        >
          I give my consent to record this interview
        </Checkbox>

        <CallLobbyJoinButton
          isDisabled={interview.status === 'ENDED' || !consent}
        />
      </VStack>
    </PaddingBlock>
  );
};

const CallActionCatchFallback: React.FC = () => null;
const CallActionSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallAction: React.FC = () => (
  <Catch fallback={<CallActionCatchFallback />}>
    <Suspense fallback={<CallActionSuspenseFallback />}>
      <CallActionMain />
    </Suspense>
  </Catch>
);

export default CallAction;
