import {
  AvatarGroup,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import useInterviewStatusColor from '../../hooks/useInterviewStatusColor';
import useInterviewStatusLabel from '../../hooks/useInterviewStatusLabel';
import useTimezone from '../../hooks/useTimezone';
import { InterviewDoc } from '../../types/Interview';
import Catch from '../Catch';
import MemberAvatar from '../MemberAvatar';
import SkillLogosRow, { SkillLogosRowSuspenseFallback } from '../SkillLogosRow';
import CustomerName from './CustomerName';
import IntervieweeName from './IntervieweeName';

export type Props = {
  interviewSnap: QueryDocumentSnapshot<InterviewDoc>;
};

const InterviewInfoMain: React.FC<Props> = ({ interviewSnap }) => {
  const timezone = useTimezone();
  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const time = useMemo(
    () => moment(interview?.startsAt.toDate()).tz(timezone).format('LT'),
    [interview?.startsAt, timezone],
  );

  const statusColor = useInterviewStatusColor(interviewSnap);
  const statusLabel = useInterviewStatusLabel(interviewSnap);

  return (
    <HStack
      spacing={3}
      overflow="hidden"
      alignItems="stretch"
    >
      <AvatarGroup size="md" max={2}>
        <MemberAvatar userRef={interview.intervieweeRef} />
        {interview.interviewerRefs.map((interviewerRef) => (
          <MemberAvatar key={interviewerRef.id} userRef={interviewerRef} />
        ))}
      </AvatarGroup>

      <VStack alignItems="stretch" spacing={1} flexGrow={1} flexShrink={1} minW={0}>
        <HStack spacing={1}>
          <IntervieweeName interviewSnap={interviewSnap} />
          <Text
            variant="labelMedium"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            /
          </Text>
          <CustomerName interviewSnap={interviewSnap} />
        </HStack>

        <SkillLogosRow skillRefs={interview.skillRefs} h={5} />
      </VStack>

      <VStack w="80px" alignItems="stretch" flexShrink={0}>
        <Text variant="labelMedium" color="cf.cntSecondary" textAlign="right">
          {time}
        </Text>
        <Text variant="labelSmall" color={statusColor} textAlign="right">
          {statusLabel}
        </Text>
      </VStack>
    </HStack>
  );
};

export const InterviewInfoCatchFallback: React.FC = () => null;

export const InterviewInfoSuspenseFallback: React.FC = () => (
  <HStack
    spacing={3}
    overflow="hidden"
    alignItems="stretch"
  >
    <Flex flexDirection="row-reverse">
      <SkeletonCircle size="44px" />
      <SkeletonCircle size="44px" mr={-3} />
    </Flex>

    <VStack alignItems="start" spacing={1} flexGrow={1} flexShrink={1} minW={0}>
      <Skeleton h="20px" w="140px" />
      <SkillLogosRowSuspenseFallback />
    </VStack>

    <VStack alignItems="end" spacing={2} flexShrink={0}>
      <Skeleton h="20px" w="70px" />
      <Skeleton h="16px" w="45px" />
    </VStack>
  </HStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewInfo: React.FC<Props> = (props) => (
  <Catch fallback={<InterviewInfoCatchFallback />}>
    <Suspense fallback={<InterviewInfoSuspenseFallback />}>
      <InterviewInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewInfo;
