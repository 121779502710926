import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { useUserRef } from '../../components/UserRefContext';
import { useUserSensitiveDocRef } from '../../types/UserSensitive';

export type CheckUserSensitiveProps = {
  fallback: React.ReactElement;
};

const CheckUserSensitiveLayoutMain: React.FC<CheckUserSensitiveProps> = ({ fallback }) => {
  const userRef = useUserRef();
  const userSensitiveRef = useUserSensitiveDocRef(userRef);
  const { data: userSensitiveSnap } = useFirestoreDoc(userSensitiveRef);

  if (!userSensitiveSnap.exists()) {
    return fallback;
  }

  return (
    <Outlet />
  );
};

export const CheckUserSensitiveLayoutCatchFallback: React.FC = () => null;
export const CheckUserSensitiveLayoutSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CheckUserSensitiveLayout: React.FC<CheckUserSensitiveProps> = (props) => (
  <Catch fallback={<CheckUserSensitiveLayoutCatchFallback />}>
    <Suspense fallback={<CheckUserSensitiveLayoutSuspenseFallback />}>
      <CheckUserSensitiveLayoutMain {...props} />
    </Suspense>
  </Catch>
);

export default CheckUserSensitiveLayout;
