import {
  Alert,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  ScaleFade,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  QueryDocumentSnapshot,
  Timestamp,
  and,
  or,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import BlockListItem from '../../../components/BlockListItem';
import Catch from '../../../components/Catch';
import { InterviewInfoSuspenseFallback } from '../../../components/InterviewInfo';
import { useUserRef } from '../../../components/UserRefContext';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useTimezone from '../../../hooks/useTimezone';
import {
  AvailabilityExceptionDoc,
  useAvailabilityExceptionsCollectionRef,
} from '../../../types/AvailabilityException';
import {
  InterviewDoc,
  InterviewStatus,
  isInterviewSnap,
  useInterviewsCollectionRef,
} from '../../../types/Interview';
import AvailabilityExceptionRow from './AvailabilityExceptionRow';
import InterviewRow from './InterviewRow';

const InterviewsScreenMain: React.FC = () => {
  const userRef = useUserRef();
  const interviewsCollectionRef = useInterviewsCollectionRef();
  const availabilityExceptionsCollectionRef = useAvailabilityExceptionsCollectionRef();

  const timezone = useTimezone();
  const currentTime = useCurrentTime(1000 * 60 * 60);
  const minStartsAt = useMemo(
    () => moment(currentTime)
      .tz(timezone)
      .startOf('day')
      .toDate()
      .getTime(),
    [currentTime, timezone],
  );

  const {
    data: interviewsSnap,
  } = useFirestoreCollection(query(
    interviewsCollectionRef,
    and(
      or(
        where('intervieweeRef', '==', userRef),
        where('interviewerRefs', 'array-contains', userRef),
      ),
      where('startsAt', '>=', Timestamp.fromMillis(minStartsAt)),
      where('status', 'in', [InterviewStatus.CREATED, InterviewStatus.STARTED, InterviewStatus.ENDED]),
    ),
    orderBy('startsAt'),
  ));

  const {
    data: availabilityExceptionsSnap,
  } = useFirestoreCollection(
    query(
      availabilityExceptionsCollectionRef,
      where('userRef', '==', userRef),
      where('startsAt', '>=', Timestamp.fromMillis(minStartsAt)),
      orderBy('startsAt'),
    ),
  );

  const blocks: [
    string,
    (QueryDocumentSnapshot<InterviewDoc> | QueryDocumentSnapshot<AvailabilityExceptionDoc>)[],
  ][] = useMemo(
    () => _.toPairs(
      _.groupBy(
        _.sortBy(
          [
            ...interviewsSnap.docs,
            ...availabilityExceptionsSnap.docs,
          ],
          (interviewSnap) => {
            const interview = interviewSnap.data();
            return interview.startsAt.toMillis();
          },
        ),
        (interviewSnap) => {
          const interview = interviewSnap.data();
          return moment(interview.startsAt.toDate()).tz(timezone).startOf('day').calendar(currentTime, {
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd, Do',
            lastDay: 'dddd, Do',
            lastWeek: 'dddd, Do',
            sameElse: 'dddd, Do',
          });
        },
      ),
    ),
    [
      availabilityExceptionsSnap.docs,
      currentTime,
      interviewsSnap.docs,
      timezone,
    ],
  );

  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrolledToTop(e.currentTarget.scrollTop <= 0);
  }, []);

  return (
    <Grid
      height="100%"
      minH={0}
      flexGrow={1}
      templateRows="60px 0px 1fr"
    >
      <GridItem py={3}>
        <Container>
          <Heading lineHeight={9}>
            Interviews
          </Heading>
        </Container>
      </GridItem>

      <GridItem py="-0.5px">
        <Container>
          <ScaleFade in={!scrolledToTop}>
            <Divider />
          </ScaleFade>
        </Container>
      </GridItem>

      <GridItem overflow="auto" minH={0} py={3} onScroll={handleScroll}>
        <Container>
          {blocks.map(([key, interviewSnaps]) => (
            <VStack alignItems="stretch" key={key} spacing={1}>
              <Text
                pt="1px"
                pb="3px"
                fontSize="sm"
                fontWeight="medium"
                lineHeight="short"
                color="cf.cntTertiary"
              >
                {key}
              </Text>

              <BlockList variant="outline">
                {interviewSnaps.map((interviewSnap) => (
                  isInterviewSnap(interviewSnap) ? (
                    <InterviewRow
                      key={interviewSnap.id}
                      interviewSnap={interviewSnap}
                    />
                  ) : (
                    <AvailabilityExceptionRow
                      key={interviewSnap.id}
                      availabilityExceptionSnap={interviewSnap}
                    />
                  )
                ))}
              </BlockList>
            </VStack>
          ))}
        </Container>
      </GridItem>
    </Grid>
  );
};

export const InterviewsScreenCatchFallback: React.FC = () => (
  <Grid
    height="100%"
    minH={0}
    flexGrow={1}
    templateRows="60px 0px 1fr"
  >
    <GridItem py={3}>
      <Container>
        <Heading lineHeight={9}>
          Interviews
        </Heading>
      </Container>
    </GridItem>

    <GridItem py="-0.5px">
      <Container>
        <Divider />
      </Container>
    </GridItem>

    <GridItem overflow="auto" minH={0} py={3}>
      <Container>
        <Alert status="error">
          <Text>Failed to load interviews</Text>
        </Alert>
      </Container>
    </GridItem>
  </Grid>
);

export const InterviewsScreenSuspenseFallback: React.FC = () => (
  <Grid
    height="100%"
    minH={0}
    flexGrow={1}
    templateRows="60px 1fr"
  >
    <GridItem py={3}>
      <Container>
        <Heading lineHeight={9}>
          Interviews
        </Heading>
      </Container>
    </GridItem>

    <GridItem overflow="auto" minH={0} py={3}>
      <Container>
        <VStack spacing={4} alignItems="stretch">
          <VStack alignItems="stretch" spacing={1}>
            <Skeleton h="20px" w="100px" pt="1px" pb="3px" />
            <BlockList variant="outline">
              {[
                (<BlockListItem key={1}><InterviewInfoSuspenseFallback /></BlockListItem>),
                (<BlockListItem key={2}><InterviewInfoSuspenseFallback /></BlockListItem>),
                (<BlockListItem key={3}><InterviewInfoSuspenseFallback /></BlockListItem>),
              ]}
            </BlockList>
          </VStack>

          <VStack alignItems="stretch" spacing={1}>
            <Skeleton h="20px" w="100px" pt="1px" pb="3px" />
            <BlockList variant="outline">
              {[
                (<BlockListItem key={1}><InterviewInfoSuspenseFallback /></BlockListItem>),
                (<BlockListItem key={2}><InterviewInfoSuspenseFallback /></BlockListItem>),
                (<BlockListItem key={3}><InterviewInfoSuspenseFallback /></BlockListItem>),
              ]}
            </BlockList>
          </VStack>
        </VStack>
      </Container>
    </GridItem>
  </Grid>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewsScreen: React.FC = () => (
  <Catch fallback={<InterviewsScreenCatchFallback />}>
    <Suspense fallback={<InterviewsScreenSuspenseFallback />}>
      <InterviewsScreenMain />
    </Suspense>
  </Catch>
);

export default InterviewsScreen;
