import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MissionKeplerCard from '../../components/MissionKeplerCard';
import MissionMarsCard from '../../components/MissionMarsCard';
import MissionMoonCard from '../../components/MissionMoonCard';
import MissionTitanCard from '../../components/MissionTitanCard';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import PaddingBlock from '../../components/PaddingBlock';
import useApiCreateApiToken from '../../functions/useApiCreateApiToken';
import CheckInCircleIcon from '../../icons/CheckInCircleIcon';
import LinkIcon from '../../icons/LinkIcon';
import OtherPageIcon from '../../icons/OtherPageIcon';
import Spinner from '../../icons/Spinner';
import StripeLogo from '../../icons/StripeLogo';
import AutoScheduler from './AutoScheduler';
import Members from './Members';
import OrganizationForm from './OrganizationForm';
import Webhooks from './Webhooks';

const OrganizationSettingsPage: React.FC = () => {
  const navigate = useNavigate();

  const organizationRef = useOrganizationRef();

  const handleBillingClick = useCallback(() => {
    navigate(`/organizations/${organizationRef.id}/billing`);
  }, [navigate, organizationRef.id]);

  const [popup, setPopup] = useState<WindowProxy | null>(null);
  const handleDeelClick = useCallback(async () => {
    if (popup instanceof Window) {
      popup.focus();
    } else {
      setPopup(window.open(
        `${window.location.origin}/organizations/${organizationRef.id}/deel-auth`,
        '_blank',
        'popup,width=480,height=640',
      ));
    }
  }, [organizationRef.id, popup]);

  useEffect(() => {
    const onMessage = (event: MessageEvent<unknown>) => {};
    const onClose = () => setPopup(null);

    popup?.addEventListener('message', onMessage);
    popup?.addEventListener('close', onClose);

    return () => {
      popup?.removeEventListener('message', onMessage);
      popup?.removeEventListener('close', onClose);
    };
  }, [popup]);

  const toast = useToast();
  const createApiToken = useApiCreateApiToken();

  const onApiTokenClick = useCallback(
    async () => {
      const toastId = toast({
        title: 'API Token',
        description: <Skeleton w="320px" h={6} />,
        duration: null,
        icon: <Spinner />,
      });

      const { data: token } = await createApiToken({ organizationId: organizationRef.id });

      toast.update(toastId, {
        title: 'API Token',
        description: token,
        duration: null,
        isClosable: true,
        icon: <CheckInCircleIcon />,
      });
    },
    [createApiToken, organizationRef.id, toast],
  );

  return (
    <Grid templateRows="60px 1fr" h="100%" w="100%" minH={0}>
      <GridItem py={3}>
        <Container>
          <Heading py={1} flexGrow={1}>
            Settings
          </Heading>
        </Container>
      </GridItem>
      <GridItem overflow="auto" py={3}>
        <Container>
          <Tabs isLazy>
            <TabList>
              <Tab>Public</Tab>
              <Tab>Members</Tab>
              <Tab>Billing</Tab>
              <Tab>AutoScheduler</Tab>
              <Tab>API</Tab>
              <Tab>Deel</Tab>
            </TabList>

            <TabPanels>
              <TabPanel px={0}>
                <OrganizationForm key={organizationRef.id} />
              </TabPanel>

              <TabPanel px={0}>
                <Members />
              </TabPanel>

              <TabPanel px={0}>
                <VStack alignItems="stretch" spacing={3}>
                  <PaddingBlock>
                    <VStack spacing={3} alignItems="stretch">
                      <Text>
                        We use
                        {' '}
                        <StripeLogo
                          display="inline-block"
                          h={5}
                          verticalAlign="middle"
                        />
                        {' '}
                        to manage your payment methods, subscriptions, and invoices
                      </Text>
                      <Box
                        display="flex"
                        justifyContent="end"
                      >
                        <Button
                          onClick={handleBillingClick}
                          leftIcon={<OtherPageIcon />}
                        >
                          Billing dashboard
                        </Button>
                      </Box>
                    </VStack>
                  </PaddingBlock>

                  <Grid
                    templateColumns="1fr 1fr"
                    templateRows="1fr 1fr"
                    columnGap={5}
                    rowGap={3}
                  >
                    <MissionMoonCard />
                    <MissionMarsCard />
                    <MissionTitanCard />
                    <MissionKeplerCard />
                  </Grid>
                </VStack>
              </TabPanel>

              <TabPanel px={0}>
                <AutoScheduler />
              </TabPanel>

              <TabPanel px={0}>
                <Button onClick={onApiTokenClick} leftIcon={<LinkIcon />}>
                  Get API Token
                </Button>

                <Webhooks />
              </TabPanel>

              <TabPanel px={0}>
                <Button onClick={handleDeelClick} leftIcon={<LinkIcon />}>
                  Deel
                </Button>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </GridItem>
    </Grid>
  );
};

export default OrganizationSettingsPage;
