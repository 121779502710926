import { datadogLogs } from '@datadog/browser-logs';
import 'resize-observer-polyfill';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import { Auth, User, getAuth } from 'firebase/auth';
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import { getPerformance } from 'firebase/performance';
import {
  RemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import 'moment/locale/uk';
import i18next, { i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';
import {
  AnalyticsProvider,
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  PerformanceProvider,
  RemoteConfigProvider,
  StorageProvider,
} from 'reactfire';
import {
  ReportHandler,
  getCLS,
  getFID,
  getLCP,
} from 'web-vitals';
import { AlgoliaPublicProvider } from './components/AlgoliaPublicProvider';
import AnalyticsSetUserId from './components/AnalyticsSetUserId';
import Catch from './components/Catch';
import CypressActions from './components/CypressActions';
import ErrorPage from './components/ErrorPage';
import IntercomBoot from './components/IntercomBoot';
import MessagingProvider from './components/MessagingProvider';
import { StripePromiseProvider } from './components/StripePromiseProvider';
import usePromise from './hooks/usePromise';
import { I18NextRemoteConfigBackend } from './I18NextRemoteConfigBackend';
import Navigation from './navigation';

const prodDomains = [
  'app.clarwis.com',
  'app.crewfilter.io',
  'crewfilter-production.firebaseapp.com',
  'crewfilter-production.web.app',
];

const firebaseApp = initializeApp(_.includes(prodDomains, window.location.hostname) ? {
  apiKey: 'AIzaSyD0q3P8h-B7b4guwpLB22QQJwer9EYBIcQ',
  appId: '1:390006361311:web:f3c895669bbe6b3a926d6a',
  authDomain: 'crewfilter-production.firebaseapp.com',
  measurementId: 'G-TGP8C9E4PB',
  messagingSenderId: '390006361311',
  projectId: 'crewfilter-production',
  storageBucket: 'crewfilter-production.appspot.com',
} : {
  apiKey: 'AIzaSyB4LtMGA9X9fBo1MuFnWccCxviKqS6G9_8',
  appId: '1:129211865605:web:4aa1301480bb04ff58da6c',
  authDomain: 'crewfilter-development.firebaseapp.com',
  measurementId: 'G-HTV511Q8Z1',
  messagingSenderId: '129211865605',
  projectId: 'crewfilter-development',
  storageBucket: 'crewfilter-development.appspot.com',
});

console.log(firebaseApp.options.projectId);

if (_.includes(prodDomains, window.location.hostname)) {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6Lei--0mAAAAAFoD9ylbeJYHbPN5GasocLu7lZBJ'),
    isTokenAutoRefreshEnabled: true,
  });
} else {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider('6Lep9O0mAAAAAHfhIwDdkAM6BqCdS6RCOc97C988'),
    isTokenAutoRefreshEnabled: true,
  });
}

const getI18nextInstancePromise = async (remoteConfig: RemoteConfig) => {
  const i18nextInstance = i18next
    .createInstance()
    .use(I18NextRemoteConfigBackend)
    .use(LanguageDetector)
    .use(initReactI18next);

  await i18nextInstance.init({
    backend: {
      remoteConfig,
    },
    detection: {
      order: ['navigator'],
    },
    fallbackLng: 'en',
    ns: ['app'],
    defaultNS: 'app',
    supportedLngs: ['en'],
    nonExplicitSupportedLngs: false,
  });

  return i18nextInstance;
};

const waitUserState = async (auth: Auth) => {
  let resolve: (user: User | null) => void;
  let reject: (error: Error) => void;

  const promise = new Promise<User | null>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  const stop = auth.onAuthStateChanged(
    (user: User | null) => {
      stop();
      resolve(user);
    },
    (error: Error) => {
      stop();
      reject(error);
    },
  );

  return promise;
};

const remoteConfigInstance = getRemoteConfig(firebaseApp);
remoteConfigInstance.settings.minimumFetchIntervalMillis = 1000 * 60 * 5;

const authInstance = getAuth(firebaseApp);
const analyticsInstance = getAnalytics(firebaseApp);
const functionsInstance = getFunctions(firebaseApp);
const storageInstance = getStorage(firebaseApp);
const messagingInstance = getMessaging(firebaseApp);
const performanceInstance = getPerformance(firebaseApp);

const firestoreInstance = initializeFirestore(firebaseApp, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
  }),
});

const webVitalsReportHandler: ReportHandler = ({
  name, delta, id,
}) => {
  logEvent(analyticsInstance, name, {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventLabel: id,
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
  });
};

getCLS(webVitalsReportHandler);
getFID(webVitalsReportHandler);
getLCP(webVitalsReportHandler);

const App: React.FC = () => {
  usePromise(fetchAndActivate, [remoteConfigInstance]);

  const datadogClientToken = useMemo(() => getString(remoteConfigInstance, 'datadog_clientToken'), []);
  const intercomAppId = useMemo(() => getString(remoteConfigInstance, 'intercom_appId'), []);
  const algoliaAppId = useMemo(() => getString(remoteConfigInstance, 'algolia_appId'), []);
  const stripePublishableKey = useMemo(() => getString(remoteConfigInstance, 'stripe_publishableKey'), []);

  const i18nInstance: i18n = usePromise(getI18nextInstancePromise, [remoteConfigInstance]);

  useEffect(() => {
    if (datadogClientToken) {
      datadogLogs.init({
        clientToken: datadogClientToken,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sampleRate: 100,
      });
    }
  }, [datadogClientToken]);

  moment.locale('en-US');

  useEffect(() => {
    moment.fn.zoneName = function zoneName(): string {
      // eslint-disable-next-line react/no-this-in-sfc
      const abbr: string = this.zoneAbbr();
      return i18nInstance.t(abbr, { ns: 'timezones' });
    };

    // const languageChangedHandler = (lng: string) => {
    //   moment.locale(lng);
    // };

    // i18nInstance.on('languageChanged', languageChangedHandler);

    // return () => {
    //   i18nInstance.off('languageChanged', languageChangedHandler);
    // };
  }, [i18nInstance]);

  usePromise(waitUserState, [authInstance]);

  return (
    <CookiesProvider>
      <I18nextProvider i18n={i18nInstance}>
        <IntercomProvider appId={intercomAppId} autoBoot>
          <FirebaseAppProvider firebaseApp={firebaseApp} suspense>
            <AuthProvider sdk={authInstance}>
              <AnalyticsProvider sdk={analyticsInstance}>
                <FirestoreProvider sdk={firestoreInstance}>
                  <FunctionsProvider sdk={functionsInstance}>
                    <StorageProvider sdk={storageInstance}>
                      <RemoteConfigProvider sdk={remoteConfigInstance}>
                        <MessagingProvider sdk={messagingInstance}>
                          <PerformanceProvider sdk={performanceInstance}>
                            <AlgoliaPublicProvider appId={algoliaAppId}>
                              <StripePromiseProvider publishableKey={stripePublishableKey}>
                                <Catch fallback={<ErrorPage message="Oops! Something went terribly wrong..." />}>
                                  <IntercomBoot />
                                  <AnalyticsSetUserId />
                                  <CypressActions>
                                    <Navigation />
                                  </CypressActions>
                                </Catch>
                              </StripePromiseProvider>
                            </AlgoliaPublicProvider>
                          </PerformanceProvider>
                        </MessagingProvider>
                      </RemoteConfigProvider>
                    </StorageProvider>
                  </FunctionsProvider>
                </FirestoreProvider>
              </AnalyticsProvider>
            </AuthProvider>
          </FirebaseAppProvider>
        </IntercomProvider>
      </I18nextProvider>
    </CookiesProvider>
  );
};

export default App;
