import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ArrowRightIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path d="M13.1274 7.88475L7.71805 2.30402L6.28195 3.696L11.7016 9.28739L11.7071 9.2929C12.0976 9.68343 12.0976 10.3166 11.7071 10.7071L11.7016 10.7126L6.28195 16.304L7.71805 17.696L13.1274 12.1152C14.2909 10.9452 14.2909 9.05477 13.1274 7.88475Z" fill="currentColor" />
  </Icon>
);

export default ArrowRightIcon;
