import { IconProps } from '@chakra-ui/react';
import React from 'react';
import ChevronInternalAdmiralIcon from '../icons/ChevronInternalAdmiralIcon';
import ChevronInternalCaptainIcon from '../icons/ChevronInternalCaptainIcon';
import ChevronInternalCommanderIcon from '../icons/ChevronInternalCommanderIcon';
import ChevronInternalLieutenantIcon from '../icons/ChevronInternalLieutenantIcon';
import { ExpertTier } from '../types/Expert';

export type Props = {
  tier: ExpertTier;
} & IconProps;

const InternalExpertTierIcon: React.FC<Props> = ({ tier, ...iconProps }: Props) => {
  switch (tier) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.LIEUTENANT: return <ChevronInternalLieutenantIcon {...iconProps} />;
    // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.COMMANDER: return <ChevronInternalCommanderIcon {...iconProps} />;
    // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.CAPTAIN: return <ChevronInternalCaptainIcon {...iconProps} />;
    // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.ADMIRAL: return <ChevronInternalAdmiralIcon {...iconProps} />;
    default: return null;
  }
};

export default InternalExpertTierIcon;
