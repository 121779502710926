import { useToken } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import BlockListItem from '../../../../../components/BlockListItem';
import Catch from '../../../../../components/Catch';
import { useExpertRef } from '../../../../../components/ExpertRefContext';
import InterviewInfo, {
  InterviewInfoSuspenseFallback,
} from '../../../../../components/InterviewInfo';
import useInterviewStatusColor from '../../../../../hooks/useInterviewStatusColor';
import { ExpertDoc } from '../../../../../types/Expert';
import { InterviewDoc } from '../../../../../types/Interview';
import SnapNotFoundError from '../../../../../types/SnapshotNotFoundError';

export interface Props {
  interviewSnap: QueryDocumentSnapshot<InterviewDoc>;
}

const InterviewRowMain: React.FC<Props> = ({ interviewSnap }: Props) => {
  const expertRef = useExpertRef();

  const { data: expertSnap } = useFirestoreDoc<ExpertDoc>(expertRef);

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const statusColor = useInterviewStatusColor(interviewSnap);
  const [styledColor] = useToken('colors', [statusColor]);

  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/organizations/${expert.organizationRef.id}/interviews/${interviewSnap.id}`);
  }, [navigate, expert.organizationRef.id, interviewSnap.id]);

  return (
    <BlockListItem
      data-intercom-target="InterviewRow"
      data-interview-status={interview.status}
      onClick={handleClick}
      boxShadow={`inset 4px 0px ${styledColor}`}
    >
      <InterviewInfo interviewSnap={interviewSnap} />
    </BlockListItem>
  );
};

export const InterviewRowSuspenseFallback: React.FC = () => (
  <BlockListItem>
    <InterviewInfoSuspenseFallback />
  </BlockListItem>
);

export const InterviewRowCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InterviewRow: React.FC<Props> = (props) => (
  <Catch fallback={<InterviewRowCatchFallback />}>
    <Suspense fallback={<InterviewRowSuspenseFallback />}>
      <InterviewRowMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewRow;
