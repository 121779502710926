import {
  Box,
  Container,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import Availability from '../../../components/Availability';
import Catch from '../../../components/Catch';
import { useExpertRef } from '../../../components/ExpertRefContext';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import { ExpertDoc } from '../../../types/Expert';
import { InterviewStatus, useInterviewsCollectionRef } from '../../../types/Interview';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import ContactInfo, { ContactInfoSuspenseFallback } from './ContactInfo';
import DeelContract from './DeelContract';
import DeelContractCreate from './DeelContractCreate';
import Header, { HeaderSuspenseFallback } from './Header';
import InterviewsList, { InterviewsListSuspenseFallback } from './InterviewsList';
import InvitationsList from './InvitationsList';
import ResendInvitation from './ResendInvitation';
import Skills, { SkillsSuspenseFallback } from './Skills';

const ExpertDetailsScreenMain: React.FC = () => {
  const expertRef = useExpertRef();
  const organizationRef = useOrganizationRef();
  const interviewsCollectionRef = useInterviewsCollectionRef();

  const { data: interviewsSnap } = useFirestoreCollection(
    query(
      interviewsCollectionRef,
      where('expertRefs', 'array-contains', expertRef),
      where('customerRef', '==', organizationRef),
      where('status', 'in', [InterviewStatus.CREATED, InterviewStatus.STARTED, InterviewStatus.ENDED]),
      orderBy('startsAt', 'asc'),
    ),
  );

  const { data: expertSnap } = useFirestoreDoc<ExpertDoc>(expertRef);

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  return (
    <VStack
      height="100%"
      spacing={0}
      alignItems="stretch"
      overflow="hidden"
      data-intercom-target="OrganizationExpertDetailsPage"
    >
      <Header />

      <Divider />

      <Box overflow="auto" flexGrow={1} flexShrink={1}>
        <Container>
          <VStack spacing={8} py={3} alignItems="stretch">
            <ContactInfo />

            {expert.userRef ? (
              <Availability userRef={expert.userRef} />
            ) : null}

            {expert.skillRefs?.length ? (
              <Skills title="Skills" skillRefs={expert.skillRefs} />
            ) : null}

            {!expert.userRef ? (
              <InvitationsList />
            ) : null}

            {expert.deelContractRef ? (
              <DeelContract />
            ) : (
              <DeelContractCreate />
            )}

            <InterviewsList interviewSnaps={interviewsSnap.docs} />
          </VStack>
        </Container>
      </Box>

      <Divider />

      <Box flexGrow={0} flexShrink={0}>
        <Container>
          <VStack
            py={4}
            _empty={{
              display: 'none',
            }}
          >
            {!expert.userRef ? (
              <ResendInvitation />
            ) : null}
          </VStack>
        </Container>
      </Box>
    </VStack>
  );
};

export const ExpertDetailsScreenSuspenseFallback: React.FC = () => (
  <VStack
    height="100%"
    spacing={0}
    alignItems="stretch"
    overflow="hidden"
  >
    <HeaderSuspenseFallback />

    <Divider />

    <Box overflow="auto" flexGrow={1} flexShrink={1}>
      <Container py={4}>
        <VStack spacing={8} alignItems="stretch">
          <ContactInfoSuspenseFallback />
          <SkillsSuspenseFallback />
          <InterviewsListSuspenseFallback />
        </VStack>
      </Container>
    </Box>
  </VStack>
);

const ExpertDetailsScreenCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertDetailsScreen: React.FC = () => (
  <Catch fallback={<ExpertDetailsScreenCatchFallback />}>
    <Suspense fallback={<ExpertDetailsScreenSuspenseFallback />}>
      <ExpertDetailsScreenMain />
    </Suspense>
  </Catch>
);

export default ExpertDetailsScreen;
