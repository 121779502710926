import {
  HStack,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, setDoc } from 'firebase/firestore';
import React, {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { MicrosoftCalendarDoc } from '../../types/MicrosoftCalendar';

export interface Props {
  microsoftCalendarSnap: QueryDocumentSnapshot<MicrosoftCalendarDoc>;
}

const MicrosoftCalendar: React.FC<Props> = ({ microsoftCalendarSnap }) => {
  const microsoftCalendar = useMemo(() => microsoftCalendarSnap.data(), [microsoftCalendarSnap]);

  const [loading, setLoading] = useState(false);
  const handleSwitchChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      if (e.target.checked) {
        await setDoc(
          microsoftCalendarSnap.ref,
          { isEnabled: true },
          { merge: true },
        );
      } else {
        await setDoc(
          microsoftCalendarSnap.ref,
          { isEnabled: false },
          { merge: true },
        );
      }
    } catch (err) {
      /* do nothing */
    } finally {
      setLoading(false);
    }
  }, [microsoftCalendarSnap.ref]);

  return (
    <HStack spacing={1}>
      <svg height="20px" width="20px" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="6" fill={microsoftCalendar.calendar.hexColor || 'rgb(5,166,240)'} />
      </svg>

      <Text fontSize="sm" lineHeight="short" color="cf.cntSecondary">
        {microsoftCalendar.calendar.name}
      </Text>

      <Spacer />

      <Switch
        onChange={handleSwitchChange}
        isDisabled={loading}
        isChecked={microsoftCalendar.isEnabled}
      />
    </HStack>
  );
};

export default MicrosoftCalendar;
