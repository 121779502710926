import {
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { StageActionType } from '../../types/Stage';
import StageActionFilterField, { StageActionFilterFieldValue } from './StageActionFilterField';
import StageActionStageRefField, {
  StageActionStageRefFieldValue,
} from './StageActionStageRefField';

export type Props = BoxProps & {
  name: string;
};

export type StageTransitionActionFieldValue = {
  filter: StageActionFilterFieldValue;
  stageRef: StageActionStageRefFieldValue;
  type: StageActionType.TRANSITION;
};

const StageTransitionActionField: React.FC<Props> = ({
  name,
  ...boxProps
}) => {
  const [, meta] = useField<StageTransitionActionFieldValue>(name);

  const onRemove = useCallback(
    () => { },
    [],
  );

  return (
    <FormControl
      isInvalid={!!meta.error}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <VStack alignItems="stretch" spacing={1}>
        <StageActionStageRefField
          name={`${name}.stageRef`}
        />

        <FormControl>
          <FormLabel>
            Filter
          </FormLabel>

          <StageActionFilterField
            name={`${name}.filter`}
            onRemoveClick={() => onRemove()}
          />
        </FormControl>
      </VStack>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default StageTransitionActionField;
