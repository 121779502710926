import React from 'react';
import { useParams } from 'react-router';
import { useScoreDocRef } from '../../types/Score';
import ScoreScreen from './ScoreScreen';

const ScorePage: React.FC = () => {
  const { scoreId } = useParams<{
    scoreId: string;
  }>();

  if (!scoreId) {
    throw new Error();
  }

  const scoreRef = useScoreDocRef(scoreId);

  return (
    <ScoreScreen scoreRef={scoreRef} />
  );
};

export default ScorePage;
