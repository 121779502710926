import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { InterviewDoc, isInterviewRef } from './Interview';

export type InterviewWhiteboardElementDoc = {
  updated: number;
  data: string;
};

export const isInterviewWhiteboardElementDoc = (
  data?: DocumentData,
): data is InterviewWhiteboardElementDoc => true;

export const isInterviewWhiteboardElementRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewWhiteboardElementDoc> => ref.parent.id === 'whiteboardElements' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

export const isInterviewWhiteboardElementSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewWhiteboardElementDoc
> => isInterviewWhiteboardElementRef(snap.ref);

export const useInterviewWhiteboardElementsCollectionRef = (interviewRef: DocumentReference<InterviewDoc>) => useMemo(() => collection(interviewRef, 'whiteboardElements') as CollectionReference<InterviewWhiteboardElementDoc>, [interviewRef]);

export const useInterviewWhiteboardElementDocRef = (
  interviewRef: DocumentReference<InterviewDoc>,
  docId: string,
) => {
  const collectionRef = useInterviewWhiteboardElementsCollectionRef(interviewRef);
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
