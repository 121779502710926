import { DocumentReference } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc, useStorage, useStorageDownloadURL } from 'reactfire';
import { AssetDoc } from '../../types/Asset';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import Catch from '../Catch';

type AssetImageProps = Omit<React.SVGProps<SVGImageElement>, 'href'> & {
  assetRef: DocumentReference<AssetDoc>;
};

const AssetImageMain: React.FC<AssetImageProps> = ({
  assetRef,
  ...imageProps
}) => {
  const storage = useStorage();

  const { data: assetSnap } = useFirestoreDoc(assetRef);

  if (!assetSnap.exists()) {
    throw new SnapNotFoundError(assetSnap);
  }

  const asset = useMemo(() => assetSnap.data(), [assetSnap]);

  const reference = useMemo(() => ref(storage, asset.ref), [asset.ref, storage]);

  const { data: href } = useStorageDownloadURL(reference);

  return (
    <image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
      href={href}
    />
  );
};

export const AssetImageCatchFallback: React.FC = () => null;
export const AssetImageSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AssetImage: React.FC<AssetImageProps> = (props) => (
  <Catch fallback={<AssetImageCatchFallback />}>
    <Suspense fallback={<AssetImageSuspenseFallback />}>
      <AssetImageMain {...props} />
    </Suspense>
  </Catch>
);

export default AssetImage;
