import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const VideoOffIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.70711 1.29289C2.31658 0.902369 1.68342 0.902369 1.29289 1.29289C0.902369 1.68342 0.902369 2.31658 1.29289 2.70711L2.19177 3.60598C1.46779 4.15368 1 5.02221 1 6V14.2857C1 15.6063 1.92434 17 3.42857 17H11.5714C12.7057 17 13.5102 16.2076 13.8374 15.2516L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L2.70711 1.29289ZM12 13.4142L3.64909 5.0633C3.2699 5.20542 3 5.57119 3 6V14.2857C3 14.5374 3.0855 14.7363 3.18664 14.8577C3.286 14.9769 3.37587 15 3.42857 15H11.5714C11.6241 15 11.714 14.9769 11.8134 14.8577C11.9145 14.7363 12 14.5374 12 14.2857V13.4142ZM9.5 5H11C11.5523 5 12 5.44772 12 6V7.5L14 9.5V7.38743L17 6.38743V12.5L19 14.5V6.38743C19 5.02231 17.6626 4.05837 16.3675 4.49006L13.9194 5.30612C13.6062 3.98382 12.418 3 11 3H7.5L9.5 5Z" fill="currentColor" />
  </Icon>
);

export default VideoOffIcon;
