import { ThemeOverride, extendTheme, withDefaultColorScheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

const baseColors = {
  black: '#021729',
  blackAlpha3: 'rgba(2, 23, 41, 0.03)',
  blackAlpha6: 'rgba(2, 23, 41, 0.06)',
  blackAlpha12: 'rgba(2, 23, 41, 0.12)',
  emerald100: '#358596',
  emerald200: '#27788A',
  emerald300: '#1C6D80',
  grey100: '#F9F9F9',
  grey400: '#B2BBC2',
  grey600: '#7D8A95',
  grey700: '#6A7985',
  lightEmerald: '#EFF6F7',
  lightRed: '#F6E8E8',
  red: '#E02D43',
  white: '#FFFFFF',
};

const colorSystem = {
  bgAccent: baseColors.emerald100,
  bgAccentAlpha3: 'rgba(53, 133, 150, 0.06)',
  bgAccentHover: baseColors.emerald200,
  bgAccentPressed: baseColors.emerald300,
  bgBlackAlpha3: baseColors.blackAlpha3,
  bgBlackAlpha6: baseColors.blackAlpha6,
  bgBlackAlpha12: baseColors.blackAlpha12,
  bgLightAccent: baseColors.lightEmerald,
  bgLightNegative: baseColors.lightRed,
  bgLightNegativeHover: baseColors.lightRed,
  bgLightNegativePressed: baseColors.lightRed,
  bgNegative: baseColors.red,
  bgPrimary: baseColors.white,
  bgSecondary: '#F2F4F5',
  bgSuperBlack: '#010B14',
  bgTertiary: baseColors.grey100,
  brdAccent: baseColors.emerald100,
  brdBlackAlpha12: baseColors.blackAlpha12,
  brdNegative: baseColors.red,
  cntAccent: baseColors.emerald100,
  cntEmpty: baseColors.grey400,
  cntNegative: baseColors.red,
  cntOnColor: baseColors.white,
  cntPrimary: baseColors.black,
  cntSecondary: baseColors.grey700,
  cntTertiary: baseColors.grey600,
  status: {
    empty: '#B2BBC2',
    created: '#E0942D',
    started: '#359651',
    ended: '#8B3799',
    canceled: '#E02D43',
    archived: '#6A7985',
  },
};

export const cfChakraThemeOverride: ThemeOverride = {
  breakpoints: {
    sm: '768px',
    base: '0em',
    md: '1024px',
    lg: '1280px',
    xl: '1440px',
    '2xl': '1920px',
  },
  colors: {
    cf: {
      ...baseColors,
      ...colorSystem,
    },
    accent: {
      500: colorSystem.bgAccent,
      600: colorSystem.bgAccentHover,
      700: colorSystem.bgAccentPressed,
    },
  },
  fontSizes: {
    xs: '0.75rem', // 12
    sm: '0.875rem', // 14
    md: '1rem', // 16
    lg: '1.125rem', // 18
    xl: '1.25rem', // 20
    '2xl': '1.375rem', // 22
    '3xl': '1.5rem', // 24
    '4xl': '1.75rem', // 28
    '5xl': '2rem', // 32
    '6xl': '2.25rem', // 36
    '7xl': '2.5rem', // 40
    '8xl': '2.75rem', // 44
    '9xl': '3rem', // 48
  },
  lineHeights: {
    shorter: '1rem',
    short: '1.25rem',
    base: '1.5rem',
    tall: '1.75rem',
    taller: '2rem',
  },
  styles: {
    global: {
      'html, body': {
        bg: '#F9F9F9',
        color: '#021729',
        height: '100%',
        overscrollBehavior: 'none',
      },
      '#root, .App': {
        height: '100%',
      },
      '@keyframes pulse': {
        '0%,100%': {
          opacity: 1,
        },
        '50%': {
          opacity: 0.4,
        },
      },
      '.intervieweeCodeEditorCursorPosition': {
        display: 'inline-block',
        verticalAlign: 'bottom',
        height: '1rem',
        width: '2px',
        background: '#E0942D',
        marginLeft: '0px',
        marginRight: '-2px',
        animationName: 'pulse',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
      },
      '.interviewerCodeEditorCursorPosition': {
        display: 'inline-block',
        verticalAlign: 'bottom',
        height: '1rem',
        width: '2px',
        background: '#8B3799',
        marginLeft: '0px',
        marginRight: '-2px',
        animationName: 'pulse',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
      },
      '.intervieweeCodeEditorCursorSelection': {
        background: 'rgb(224, 148, 45, 0.5)',
      },
      '.interviewerCodeEditorCursorSelection': {
        background: 'rgb(139, 55, 153, 0.5)',
      },
      '@keyframes cursor': {
        '0%,100%': {
          opacity: 1,
        },
        '50%': {
          opacity: 0.5,
        },
      },
    },
  },
  sizes: {
    container: {
      xs: '280px',
    },
    0.25: '0.0625rem',
    0.75: '0.1875rem',
    11: '2.75rem',
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
  radii: {
    md: '8px',
    sm: '4px',
  },
  components: {
    Alert: {
      defaultProps: { variant: 'solid' },
      variants: {
        solid: ({ status }) => {
          let bg = 'cf.status.empty';
          switch (status) {
            case 'info': { bg = 'cf.bgAccent'; break; }
            case 'warning': { bg = 'cf.status.created'; break; }
            case 'success': { bg = 'cf.status.started'; break; }
            case 'error': { bg = 'cf.status.canceled'; break; }
          }

          return {
            title: { color: 'cf.cntOnColor' },
            description: { color: 'cf.cntOnColor' },
            container: { bg },
            icon: { color: 'cf.cntOnColor' },
            spinner: { color: 'cf.cntOnColor' },
          };
        },
      },
    },
    Steps,
    PaddingBlock: {
      baseStyle: {
        borderRadius: 'md',
        px: 5,
        py: 3,
      },
      variants: {
        ghost: {},
        outline: {
          bg: 'cf.bgPrimary',
          boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)',
        },
      },
      defaultProps: {
        variant: 'outline',
      },
    },
    BlockList: {
      baseStyle: {
        borderRadius: 'md',
        px: 5,
      },
      variants: {
        ghost: {},
        outline: {
          bg: 'cf.bgPrimary',
          boxShadow: 'inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)',
        },
      },
      defaultProps: {
        variant: 'ghost',
      },
    },
    BlockListItem: {
      baseStyle: {
        mx: -5,
        px: 5,
        py: 3,
        _first: {
          borderTopRadius: 'md',
        },
        _last: {
          borderBottomRadius: 'md',
        },
      },
    },
    SkillName: {
      baseStyle: {
        fontWeight: 'medium',
        fontSize: 'md',
        lineHeight: 'short',
        flexGrow: 1,
        flexShrink: 1,
        whiteSpace: 'nowrap',
        minW: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    SkillDuration: {
      baseStyle: {
        color: 'cf.cntSecondary',
        fontSize: 'md',
        lineHeight: 'short',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    SkillNameSkeleton: {
      baseStyle: {
        h: 5,
        w: '140px',
      },
    },
    SkillLogo: {
      baseStyle: {
        boxSize: 5,
        borderRadius: 'sm',
      },
    },
    SkillLogoSkeleton: {
      baseStyle: {
        boxSize: 5,
      },
    },
    SkillShortInfo: {
      baseStyle: {
        alignItems: 'center',
        flexGrow: 1,
        flexShrink: 1,
      },
    },
    SkillShortInfoSkeleton: {
      baseStyle: {
        alignItems: 'center',
        flexGrow: 1,
        flexShrink: 1,
      },
    },
    SkillLogosRow: {
      baseStyle: { },
    },
    SkillLogosRowSkeleton: {
      baseStyle: { },
    },
    Divider: {
      defaultProps: {
        variant: 'ghost',
      },
      variants: {
        ghost: {
          opacity: 1,
          height: 0,
          border: 'none',
          boxShadow: '0px 0px 0px 0.5px var(--chakra-colors-cf-brdBlackAlpha12)',
        },
      },
    },
    Text: {
      variants: {
        title: {
          fontWeight: 'bold',
          lineHeight: 'tall',
          fontSize: '2xl',
          pt: 0,
          pb: 0,
        },
        labelSmall: {
          fontWeight: 'medium',
          lineHeight: 'shorter',
          fontSize: 'sm',
          pt: 0,
          pb: 0,
        },
        labelMedium: {
          fontWeight: 'medium',
          lineHeight: 'short',
          fontSize: 'md',
          pt: 0,
          pb: 0,
        },
        subtitle: {
          fontWeight: 'normal',
          lineHeight: 'shorter',
          fontSize: 'sm',
          pt: '1px',
          pb: '3px',
        },
        paragraph: {
          fontWeight: 'normal',
          lineHeight: 'base',
          fontSize: 'md',
          pt: '2px',
          pb: '2px',
        },
      },
    },
    Avatar: {
      sizes: {
        xs: {
          container: {
            boxSize: 7,
          },
        },
        sm: {
          container: {
            boxSize: 9,
          },
        },
        md: {
          container: {
            boxSize: 11,
          },
        },
        '2xl': {
          container: {
            boxSize: '160px',
          },
          label: {
            fontSize: '56px',
            lineHeight: '56px',
            fontWeight: 'semibold',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        lineHeight: 'short',
        fontWeight: 'medium',
        _focus: {
          boxShadow: 'none',
        },
      },
      sizes: {
        lg: {
          h: 11,
          minW: 11,
          fontSize: 'md',
          px: 4,
        },
        md: {
          h: 9,
          minW: 9,
          fontSize: 'md',
          px: 3,
        },
        sm: {
          h: 7,
          minW: 7,
          fontSize: 'md',
          px: 3,
        },
        xs: {
          h: 5,
          minW: 5,
          fontSize: 'md',
          px: 0,
          borderRadius: 'sm',
        },
      },
      variants: {
        outline: {
          bg: 'cf.bgPrimary',
          color: 'cf.cntPrimary',
          borderColor: 'cf.brdBlackAlpha12',
          _hover: {
            bg: 'cf.bgBlackAlpha3',
          },
          _active: {
            bg: 'cf.bgBlackAlpha6',
          },
          _pressed: {
            bg: 'cf.bgAccentAlpha3',
            color: 'cf.cntAccent',
          },
          _disabled: {
            opacity: 0.4,
          },
        },
        ghost: {
          bg: 'transparent',
          color: 'cf.cntPrimary',
          _hover: {
            bg: 'cf.bgBlackAlpha3',
          },
          _active: {
            bg: 'cf.bgBlackAlpha6',
          },
          _pressed: {
            bg: 'cf.bgAccentAlpha3',
            color: 'cf.cntAccent',
          },
          _disabled: {
            opacity: 0.4,
          },
        },
        solid: ({ colorScheme }) => {
          if (colorScheme === 'accent') {
            return {
              bg: 'cf.bgAccent',
              color: 'cf.cntOnColor',
              _hover: {
                bg: 'cf.bgAccentHover',
              },
              _active: {
                bg: 'cf.bgAccentPressed',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          if (colorScheme === 'negative') {
            return {
              bg: 'cf.bgLightNegative',
              color: 'cf.cntNegative',
              _hover: {
                bg: 'cf.bgLightNegativeHover',
              },
              _active: {
                bg: 'cf.bgLightNegativePressed',
              },
              _disabled: {
                opacity: 0.4,
              },
            };
          }

          return {};
        },
      },
    },
    Container: {
      baseStyle: {
        px: 5,
      },
    },
    FormLabel: {
      baseStyle: {
        mb: 1,
        pt: '1px',
        pb: '3px',
        fontSize: 'sm',
        lineHeight: 'short',
        color: 'cf.cntTertiary',
        fontWeight: 'medium',
        _invalid: {
          color: 'cf.cntNegative',
        },
        _active: {
          color: 'cf.cntAccent',
        },
        _focus: {
          color: 'cf.cntAccent',
        },
      },
    },
    FormHelperText: {
      baseStyle: {
        color: 'cf.cntTertiary',
        fontSize: 'sm',
        lineHeight: 'short',
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'bold',
      },
      sizes: {
        xl: {
          fontSize: '2xl',
          lineHeight: 'tall',
          fontWeight: 'bold',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Radio: {
    },
    Input: {
      defaultProps: {
        focusBorderColor: 'cf.brdAccent',
        errorBorderColor: 'cf.brdNegative',
      },
      variants: {
        outline: {
          field: {
            _placeholder: {
              color: 'cf.cntEmpty',
            },
            bg: 'cf.bgPrimary',
            borderColor: 'cf.brdBlackAlpha12',
            _focus: {
              boxShadow: 'none',
            },
            _invalid: {
              boxShadow: 'none',
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            h: '44px',
          },
        },
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: 'cf.brdAccent',
        errorBorderColor: 'cf.brdNegative',
      },
      variants: {
        outline: {
          field: {
            _placeholder: {
              color: 'cf.cntEmpty',
            },
            bg: 'cf.bgPrimary',
            borderColor: 'cf.brdBlackAlpha12',
            _focus: {
              boxShadow: 'none',
            },
            _invalid: {
              boxShadow: 'none',
            },
          },
        },
      },
      sizes: {
        md: {
          field: {
            h: '44px',
          },
        },
      },
    },
  },
};

const extendedTheme = extendTheme(
  cfChakraThemeOverride,
  withDefaultColorScheme({ colorScheme: 'accent' }),
);
export default extendedTheme;
