import { Skeleton, Text, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import BlockList from '../BlockList';
import BlockListItem from '../BlockListItem';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import SkillItem, { SkillItemSuspenseFallback } from './SkillItem';

const InterviewDetailsSkillsMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="InterviewDetailsSkills">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Skills
        {' '}
        &middot;
        {' '}
        {interview.skillRefs.length}
      </Text>

      <BlockList variant="outline">
        {interview.skillRefs.map((skillRef) => (
          <BlockListItem key={skillRef.id}>
            <SkillItem skillRef={skillRef} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

export const InterviewDetailsSkillsCatchFallback: React.FC = () => null;

export const InterviewDetailsSkillsSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Skeleton height="20px" width="140px" />

    <BlockList variant="outline">
      <BlockListItem>
        <SkillItemSuspenseFallback />
      </BlockListItem>

      <BlockListItem>
        <SkillItemSuspenseFallback />
      </BlockListItem>

      <BlockListItem>
        <SkillItemSuspenseFallback />
      </BlockListItem>
    </BlockList>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsSkills: React.FC = () => (
  <Catch fallback={<InterviewDetailsSkillsCatchFallback />}>
    <Suspense fallback={<InterviewDetailsSkillsSuspenseFallback />}>
      <InterviewDetailsSkillsMain />
    </Suspense>
  </Catch>
);

export default InterviewDetailsSkills;
