import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  amount: number;
  expertId: string;
  deelLegalEntityId: number;
  deelTeamId: number;
};

export type ResponseData = string;

const useDeelCreateContract = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'deel-createcontract'),
    [functions],
  );
};

export default useDeelCreateContract;
