import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { AvatarDoc } from './Avatar';

export type OrganizationDoc = {
  name: string;
  email: string;
  logoRef?: DocumentReference<AvatarDoc>;
  createdAt: Timestamp;
};

export const isOrganizationDoc = (
  data: DocumentData,
): data is OrganizationDoc => true;

export const isOrganizationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationDoc> => ref.parent.id === 'organizations';

export const isOrganizationSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<OrganizationDoc> => isOrganizationRef(snap.ref);

export const useOrganizationsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'organizations') as CollectionReference<OrganizationDoc>, [firestore]);
};

export const useOrganizationDocRef = (docId: string) => {
  const collectionRef = useOrganizationsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
