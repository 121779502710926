import {
  AspectRatio,
  Box,
  Center,
  HStack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import LocalCameraButton from './LocalCameraButton';
import { useLocalCamera } from './LocalCameraProvider';
import LocalMicrophoneButton from './LocalMicrophoneButton';
import SettingsButton from './SettingsButton';
import Video from './Video';

const LocalParticipantMediaPreview: React.FC = () => {
  const { track: localCameraTrack } = useLocalCamera();

  return (
    <AspectRatio maxW="640px" ratio={16 / 9}>
      <Box
        borderRadius="md"
        overflow="hidden"
        position="relative"
        transform="translateZ(0)"
        boxShadow="inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)"
      >
        {localCameraTrack ? (
          <Box
            position="absolute"
            left="0"
            right="0"
            top="0"
            bottom="0"
          >
            <Video videoTrack={localCameraTrack} />
          </Box>
        ) : (
          <Center
            bg="cf.cntPrimary"
            position="absolute"
            left="0"
            right="0"
            top="0"
            bottom="0"
          >
            <Text color="cf.cntOnColor" fontSize="2xl" lineHeight="tall" fontWeight="bold">
              Camera is disabled
            </Text>
          </Center>
        )}

        <Box
          px={5}
          py={3}
          position="absolute"
          left="0"
          right="0"
          bottom="0"
        >
          <HStack
            spacing={5}
            justifyContent="center"
          >
            <SettingsButton />
            <LocalMicrophoneButton />
            <LocalCameraButton />
          </HStack>
        </Box>
      </Box>
    </AspectRatio>
  );
};

export default LocalParticipantMediaPreview;
