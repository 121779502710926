import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import Lottie from '../Lottie';
import animationData from './animationData.json';

const Loader: React.FC = () => (
  <VStack alignItems="center" justifyContent="center" height="100%">
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      style={{
        height: 80,
        width: 80,
      }}
    />
    <Text fontWeight="medium" fontSize="sm" lineHeight="short">
      Loading
    </Text>
  </VStack>
);

export default Loader;
