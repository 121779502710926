import {
  Box,
  Container,
  Divider,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import CandidateList from './CandidateList';
import ExpertList from './ExpertList';
import Header from './Header';
import QuestionsList from './QuestionsList';
import SkillDetails from './SkillDetails';
import Skills from './Skills';
import ToolBar from './ToolBar';

const SkillDetailsScreen: React.FC = () => (
  <VStack
    height="100%"
    spacing={0}
    alignItems="stretch"
    overflow="hidden"
    data-intercom-target="OrganizationSkillDetailsPage"
  >
    <Header />

    <Divider />

    <Box overflow="auto" flexGrow={1} flexShrink={1}>
      <Container>
        <VStack spacing={8} py={3} alignItems="stretch">
          <ToolBar />
          <SkillDetails />
          <QuestionsList />
          <Skills />
          <CandidateList />
          <ExpertList />
        </VStack>
      </Container>
    </Box>
  </VStack>
);

export default SkillDetailsScreen;
