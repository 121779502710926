import { useToast } from '@chakra-ui/react';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LocalVideoTrack, createLocalVideoTrack } from 'twilio-video';
import useSound from 'use-sound';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';

export type Result = {
  track: LocalVideoTrack | null;
  loading: boolean;
  on: (() => unknown);
  off: (() => unknown);
};

const LocalCameraContext = React.createContext<Result>({
  track: null,
  loading: false,
  on: () => {},
  off: () => {},
});

export const useLocalCamera = (): Result => useContext(LocalCameraContext);

export type Props = {
  deviceId: string;
};

const LocalCameraProvider: React.FC<PropsWithChildren<Props>> = ({ children, deviceId }) => {
  const [track, setTrack] = useState<LocalVideoTrack | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const role = useInterviewRole();
  const name = useMemo<string>(() => {
    switch (role) {
      case InterviewRole.INTERVIEWEE: return 'interviewee-camera';
      case InterviewRole.INTERVIEWER: return 'interviewer-camera';
      default: return 'visitor-camera';
    }
  }, [role]);

  const [playOnSound] = useSound(
    'https://storage.googleapis.com/crewfilter.io/sounds/beep_on.mp3',
    { volume: 0.25 },
  );
  const [playOffSound] = useSound(
    'https://storage.googleapis.com/crewfilter.io/sounds/beep_off.mp3',
    { volume: 0.25 },
  );

  const [state, setState] = useState(true);

  const on = useCallback(() => { setState(true); }, []);
  const off = useCallback(() => { setState(false); }, []);

  const toast = useToast();

  useEffect(() => {
    let isObsolete = false;

    if (state) {
      setLoading(true);
      const trackPromise = createLocalVideoTrack({
        frameRate: 24,
        height: 720,
        width: 1280,
        aspectRatio: 16 / 9,
        deviceId,
        name,
      });

      trackPromise.then(
        (newTrack) => {
          playOnSound();
          if (!isObsolete) {
            setTrack(newTrack);
            setLoading(false);
          }
        },
        (err) => {
          toast({
            status: 'error',
            title: 'Failed to enable camera',
            description: err instanceof Error ? err.message : undefined,
          });
          if (!isObsolete) {
            setTrack(null);
            setLoading(false);
            setState(false);
          }
        },
      );

      return () => {
        trackPromise.then(
          (newTrack) => {
            playOffSound();
            newTrack.stop();
          },
          () => {
          },
        );

        setLoading(false);
        setTrack(null);
        isObsolete = true;
      };
    }

    return () => { };
  }, [deviceId, name, playOffSound, playOnSound, state, toast]);

  const res = useMemo(() => ({
    track, loading, on, off,
  }), [track, loading, on, off]);

  return (
    <LocalCameraContext.Provider value={res}>
      {children}
    </LocalCameraContext.Provider>
  );
};

export default LocalCameraProvider;
