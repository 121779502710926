import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  code: string;
  redirectUri: string;
};

export type ResponseData = void;

const useGoogleCalendarAdd = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'google-calendar-add'),
    [functions],
  );
};

export default useGoogleCalendarAdd;
