import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CrossIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.99999 8.58568L15.7929 2.79279L17.2071 4.207L11.4142 9.99989L17.2071 15.7928L15.7929 17.207L9.99999 11.4141L4.20709 17.207L2.79288 15.7928L8.58577 9.99989L2.79288 4.207L4.20709 2.79279L9.99999 8.58568Z" fill="currentColor" />
  </Icon>
);

export default CrossIcon;
