import {
  documentId,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { useUserRef } from '../../components/UserRefContext';
import { getMemberOrganizationRef, useMembersCollectionGroupRef } from '../../types/Member';
import { useOrganizationsCollectionRef } from '../../types/Organization';

export type CheckOrganizationProps = {
  fallback: React.ReactElement;
};

const CheckOrganizationLayoutMain: React.FC<CheckOrganizationProps> = ({ fallback }) => {
  const userRef = useUserRef();

  const membersCollectionGroupRef = useMembersCollectionGroupRef();

  const { data: membersSnap } = useFirestoreCollection(
    query(
      membersCollectionGroupRef,
      where('uid', '==', userRef.id),
    ),
  );

  const organizationIds = useMemo(
    () => membersSnap.docs.map(
      (memberSnap) => getMemberOrganizationRef(memberSnap.ref).id,
    ),
    [membersSnap.docs],
  );

  const organizationsCollectionRef = useOrganizationsCollectionRef();

  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      organizationsCollectionRef,
      where(documentId(), 'in', organizationIds),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  if (!organizationsSnap.docs.length) {
    return fallback;
  }

  return (
    <Outlet />
  );
};

export const CheckOrganizationLayoutCatchFallback: React.FC = () => null;
export const CheckOrganizationLayoutSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CheckOrganizationLayout: React.FC<CheckOrganizationProps> = (props) => (
  <Catch fallback={<CheckOrganizationLayoutCatchFallback />}>
    <Suspense fallback={<CheckOrganizationLayoutSuspenseFallback />}>
      <CheckOrganizationLayoutMain {...props} />
    </Suspense>
  </Catch>
);

export default CheckOrganizationLayout;
