import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc } from './Organization';
import { UserDoc } from './User';

export type DeelAccessTokenDoc = {
  organizationRef: DocumentReference<OrganizationDoc>;
  userRef: DocumentReference<UserDoc>;
  createdAt: Timestamp;
  expiresAt: Timestamp;
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  scope: string[];
};

export const isDeelAccessTokenDoc = (
  data?: DocumentData,
): data is DeelAccessTokenDoc => true;

export const isDeelAccessTokenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<DeelAccessTokenDoc> => ref.parent.id === 'deelAccessTokens';

export const isDeelAccessTokenSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<DeelAccessTokenDoc> => isDeelAccessTokenRef(snap.ref);

export const useDeelAccessTokensCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'deelAccessTokens') as CollectionReference<DeelAccessTokenDoc>, [firestore]);
};

export const useDeelAccessTokenDocRef = (docId: string) => {
  const collectionRef = useDeelAccessTokensCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
