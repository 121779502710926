import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

const IntercomUpdate: React.FC = () => {
  const { update } = useIntercom();

  useEffect(() => {
    update();
  }, [update]);

  return null;
};

export default IntercomUpdate;
