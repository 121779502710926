import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';
import { OrganizationPlatformTier } from '../types/OrganizationCustomerProfile';

export type RequestData = {
  organizationId: string;
  tier: OrganizationPlatformTier;
};

export type ResponseData = { url: string };

const useStripeOrganizationSubscriptionChange = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(
      functions,
      'stripe-organization-subscription-change',
    ),
    [functions],
  );
};

export default useStripeOrganizationSubscriptionChange;
