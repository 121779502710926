import { Box } from '@chakra-ui/react';
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw';
import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import { AppState, BinaryFiles, ExcalidrawAPIRefValue } from '@excalidraw/excalidraw/types/types';
import { doc, runTransaction } from 'firebase/firestore';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import useIntervieweeParticipant from '../../hooks/useIntervieweeParticipant';
import useInterviewerParticipants from '../../hooks/useInterviewerParticipants';
import useInterviewRole, { InterviewRole } from '../../hooks/useInterviewRole';
import useParticipants from '../../hooks/useParticipants';
import CheckIcon from '../../icons/CheckIcon';
import Spinner from '../../icons/Spinner';
import UploadIcon from '../../icons/UploadIcon';
import {
  useInterviewWhiteboardElementsCollectionRef,
} from '../../types/InterviewWhiteboardElement';
import { useInterviewWhiteboardFilesCollectionRef } from '../../types/InterviewWhiteboardFile';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import { useLocalData } from '../LocalDataProvider';
import { useRoom } from '../RoomProvider';
import CallWhiteboardParticipant from './CallWhiteboardParticipant';
import { DataMessage, MessageType } from './types';

const CallWhiteboardMain: React.FC = () => {
  const interviewRef = useInterviewRef();

  const { track: localWhiteboardTrack } = useLocalData();

  const { room } = useRoom();
  const participants = useParticipants(room);

  const intervieweeParticipant = useIntervieweeParticipant(participants);
  const interviewerParticipants = useInterviewerParticipants(participants);

  const [excalidraw, setExcalidraw] = useState<ExcalidrawAPIRefValue | null>(null);
  const onRefChange = useCallback((newExcalidraw: ExcalidrawAPIRefValue) => {
    setExcalidraw(newExcalidraw);
  }, []);

  const [filesSent, setFilesSent] = useState<string[]>([]);
  const [selectionSent, setSelectionSent] = useState<Record<string, boolean>>({});
  const [elementsSent, setElementsSent] = useState<Record<string, number>>({});
  const [changed, setChanged] = useState(false);
  const [change, setChange] = useState('');

  const handleChange = useCallback(
    (
      elements: readonly ExcalidrawElement[],
      appState: AppState,
      files: BinaryFiles,
    ) => {
      elements.forEach((element) => {
        if (!_.has(elementsSent, element.id) || elementsSent[element.id] < element.updated) {
          setElementsSent({ ...elementsSent, [element.id]: element.updated });
          setChanged(true);
          setChange(`element:${element.id}:${element.updated}`);
          localWhiteboardTrack?.send(JSON.stringify({
            type: MessageType.WHITEBOARD_ELEMENT_UPDATE,
            element,
          } as DataMessage));
        }
      });

      _.values(files).forEach((file) => {
        if (!_.includes(filesSent, file.id)) {
          setFilesSent([...filesSent, file.id]);
          setChanged(true);
          setChange(`file:${file.id}`);
          localWhiteboardTrack?.send(JSON.stringify({
            type: MessageType.WHITEBOARD_FILE_UPDATE,
            file,
          } as DataMessage));
        }
      });

      if (!_.isEqual(appState.selectedElementIds, selectionSent)) {
        setSelectionSent(appState.selectedElementIds);
        localWhiteboardTrack?.send(JSON.stringify({
          type: MessageType.WHITEBOARD_SELECTION_UPDATE,
          selectedElementIds: appState.selectedElementIds,
        } as DataMessage));
      }
    },
    [
      elementsSent,
      filesSent,
      localWhiteboardTrack,
      selectionSent,
    ],
  );

  const handlePointerUpdate = useCallback(
    ({ pointer, button }: {
      pointer: {
        x: number;
        y: number;
      };
      button: 'down' | 'up';
    }) => {
      localWhiteboardTrack?.send(JSON.stringify({
        type: MessageType.WHITEBOARD_POINTER_UPDATE,
        pointer,
        button,
      } as DataMessage));
    },
    [localWhiteboardTrack],
  );

  const firestore = useFirestore();
  const interviewWhiteboardElementsCollectionRef = useInterviewWhiteboardElementsCollectionRef(
    interviewRef,
  );
  const interviewWhiteboardFilesCollectionRef = useInterviewWhiteboardFilesCollectionRef(
    interviewRef,
  );
  const [saving, setSaving] = useState(false);
  useEffect(
    () => {
      const t = setTimeout(
        async () => {
          if (excalidraw?.ready) {
            const elements = excalidraw.getSceneElementsIncludingDeleted();
            const files = _.toPairs(excalidraw.getFiles());

            await Promise.all([
              ...elements.map(
                (element) => runTransaction(firestore, async (tr) => {
                  const snap = await tr.get(
                    doc(interviewWhiteboardElementsCollectionRef, element.id),
                  );

                  if (!snap.exists() || snap.data().updated < element.updated) {
                    setSaving(true);
                    tr.set(snap.ref, {
                      updated: element.updated,
                      data: JSON.stringify(element),
                    });
                  }
                }, { maxAttempts: 10 }),
              ),
              ...files.map(
                ([id, file]) => runTransaction(firestore, async (tr) => {
                  const snap = await tr.get(
                    doc(interviewWhiteboardFilesCollectionRef, id),
                  );

                  if (!snap.exists() || snap.data().id < file.id) {
                    setSaving(true);
                    tr.set(snap.ref, {
                      id: file.id,
                      data: JSON.stringify(file),
                    });
                  }
                }, { maxAttempts: 10 }),
              ),
            ]);

            setSaving(false);
            setChanged(false);
          }
        },
        1000,
      );

      return () => {
        clearTimeout(t);
      };
    },
    [
      change,
      excalidraw,
      firestore,
      interviewRef,
      interviewWhiteboardElementsCollectionRef,
      interviewWhiteboardFilesCollectionRef,
    ],
  );

  const { data: elementsSnap } = useFirestoreCollection(
    interviewWhiteboardElementsCollectionRef,
  );

  const initialElements = useMemo<ExcalidrawElement[]>(
    () => elementsSnap.docs.map((snap) => JSON.parse(snap.data().data)),
    [elementsSnap.docs],
  );

  const { data: filesSnap } = useFirestoreCollection(
    interviewWhiteboardFilesCollectionRef,
  );

  const initialFiles = useMemo<BinaryFiles>(
    () => filesSnap.docs.reduce(
      (res, snap) => ({ ...res, [snap.id]: JSON.parse(snap.data().data) }),
      {},
    ),
    [filesSnap.docs],
  );

  useEffect(
    () => {
      if (excalidraw?.ready) {
        const canvasElements = excalidraw.getSceneElementsIncludingDeleted();

        elementsSnap.docs.forEach((elementSnap) => {
          const canvasExisting = _.find(
            canvasElements,
            (canvasElement) => canvasElement.id === elementSnap.id,
          );

          if (
            !canvasExisting || canvasExisting.updated < elementSnap.data().updated
          ) {
            excalidraw.updateScene({
              elements: [
                ..._.filter(canvasElements, (element) => element.id !== elementSnap.id),
                JSON.parse(elementSnap.data().data),
              ],
            });
          }
        });
      }
    },
    [elementsSnap, excalidraw],
  );

  useEffect(
    () => {
      if (excalidraw?.ready) {
        const canvasFiles = excalidraw.getFiles();

        filesSnap.docs.forEach((fileSnap) => {
          const canvasExisting = _.find(
            canvasFiles,
            (canvasElement) => canvasElement.id === fileSnap.id,
          );

          if (
            !canvasExisting || canvasExisting.id !== fileSnap.data().id
          ) {
            excalidraw.addFiles([
              JSON.parse(fileSnap.data().data),
            ]);
          }
        });
      }
    },
    [filesSnap, excalidraw],
  );

  const role = useInterviewRole();

  return (
    <Box h="100%" w="100%" borderColor="cf.brdBlackAlpha12" borderWidth={1}>
      <Excalidraw
        onChange={handleChange}
        onPointerUpdate={handlePointerUpdate}
        ref={onRefChange}
        isCollaborating
        viewModeEnabled={role !== InterviewRole.INTERVIEWEE && role !== InterviewRole.INTERVIEWER}
        gridModeEnabled
        initialData={{
          elements: initialElements,
          files: initialFiles,
        }}
        renderTopRightUI={() => (
          <Box py={2} px={1} h={9}>
            {
              // eslint-disable-next-line no-nested-ternary
              saving
                ? (<Spinner h={5} w={5} display="block" color="cf.cntPrimary" />)
                : (
                  changed
                    ? (<UploadIcon h={5} w={5} display="block" color="cf.cntPrimary" />)
                    : (<CheckIcon h={5} w={5} display="block" color="cf.cntPrimary" />)
                )
            }
          </Box>
        )}
      >
        <MainMenu>
          <MainMenu.DefaultItems.ClearCanvas />
          <MainMenu.DefaultItems.SaveAsImage />
          <MainMenu.DefaultItems.Export />
        </MainMenu>
      </Excalidraw>

      {intervieweeParticipant ? (
        <CallWhiteboardParticipant
          key={intervieweeParticipant.identity}
          excalidraw={excalidraw}
          participant={intervieweeParticipant}
        />
      ) : null}

      {interviewerParticipants.map((interviewerParticipant) => (
        <CallWhiteboardParticipant
          key={interviewerParticipant.identity}
          excalidraw={excalidraw}
          participant={interviewerParticipant}
        />
      ))}
    </Box>
  );
};

const CallWhiteboardCatchFallback: React.FC = () => null;
const CallWhiteboardSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallWhiteboard: React.FC = () => (
  <Catch fallback={<CallWhiteboardCatchFallback />}>
    <Suspense fallback={<CallWhiteboardSuspenseFallback />}>
      <CallWhiteboardMain />
    </Suspense>
  </Catch>
);

export default CallWhiteboard;
