import { ChakraProvider } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import cfChakraTheme from './cfChakraTheme';
import Loader from './components/Loader';
import reportWebVitals from './reportWebVitals';
import 'moment-timezone';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={cfChakraTheme}>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
  interface Window { Intercom: (e: string, o?: Record<string, unknown>) => void; }
}
