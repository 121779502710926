import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { InterviewDoc } from './Interview';
import { Language } from './Language';
import { OrganizationDoc } from './Organization';
import { UserDoc } from './User';

export enum ReviewerType {
  CUSTOMER = 'CUSTOMER',
  CANDIDATE = 'CANDIDATE',
}

export enum ReviewAspectType {
  ACCURACY = 'ACCURACY',
  BEHAVIOR = 'BEHAVIOR',
  DEPTH = 'DEPTH',
  LANGUAGE = 'LANGUAGE',
  PUNCTUALITY = 'PUNCTUALITY',
  QUALITY = 'QUALITY',
}

export type ReviewDoc = {
  aspect: ReviewAspectType;
  createdAt: Timestamp;
  interviewRef: DocumentReference<InterviewDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  vendorRefs: DocumentReference<OrganizationDoc>[];
  expertRefs: DocumentReference<ExpertDoc>[];
  reviewer: ReviewerType;
  userRef: DocumentReference<UserDoc>;
  value: number;
  language: Language;
  _v: 1,
};

export const isReviewDoc = (
  data: DocumentData,
): data is ReviewDoc => true;

export const isReviewRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ReviewDoc> => ref.parent.id === 'reviews';

export const isReviewSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<ReviewDoc> => isReviewRef(snap.ref);

export const useReviewsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'reviews') as CollectionReference<ReviewDoc>, [firestore]);
};

export const useReviewDocRef = (docId: string) => {
  const collectionRef = useReviewsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
