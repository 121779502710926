import { HStack } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useGhostParticipants from '../hooks/useGhostParticipants';
import useParticipants from '../hooks/useParticipants';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import { isUserRef, useUsersCollectionRef } from '../types/User';
import Catch from './Catch';
import GhostParticipant from './GhostParticipant';
import { useInterviewRef } from './InterviewRefContext';
import { useRoom } from './RoomProvider';

const GhostParticipantsMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const { room } = useRoom();

  const participants = useParticipants(room);

  const ghostParticipants = useGhostParticipants(participants);

  const usersCollectionRef = useUsersCollectionRef();
  const ghosts = useMemo(
    () => ghostParticipants
      .map((participant) => doc(usersCollectionRef, participant.identity))
      .filter(isUserRef),
    [ghostParticipants, usersCollectionRef],
  );

  if (!ghosts.length) {
    return null;
  }

  return (
    <HStack spacing={5} justifyContent="end">
      {ghosts.map((ghostRef) => (
        <GhostParticipant key={ghostRef.id} userRef={ghostRef} />
      ))}
    </HStack>
  );
};

const GhostParticipantsCatchFallback: React.FC = () => null;
const GhostParticipantsSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const GhostParticipants: React.FC = () => (
  <Catch fallback={<GhostParticipantsCatchFallback />}>
    <Suspense fallback={<GhostParticipantsSuspenseFallback />}>
      <GhostParticipantsMain />
    </Suspense>
  </Catch>
);

export default GhostParticipants;
