import { GeoPoint } from 'firebase/firestore';
import { getString } from 'firebase/remote-config';
import _ from 'lodash';
import { useCallback } from 'react';
import { useRemoteConfig } from 'reactfire';

export type RequestData = GeoPoint;

export type ResponseData = { location: GeoPoint; name: string; };

const useGoogleGetCity = () => {
  const remoteConfig = useRemoteConfig();

  const geocodingApiKey = getString(remoteConfig, 'geocodingApiKey');

  return useCallback(async ({ latitude, longitude }: RequestData): Promise<ResponseData | null> => {
    const url = new URL('https://maps.googleapis.com/maps/api/geocode/json');
    url.searchParams.set('latlng', `${latitude},${longitude}`);
    url.searchParams.set('key', geocodingApiKey);
    url.searchParams.set('language', 'en');

    const resp = await fetch(url.toString());
    const { results, status } = await resp.json();

    if (status !== 'OK') {
      return null;
    }

    const localities = _.filter(results, (result) => _.includes(result.types, 'locality'));

    if (!localities.length) {
      return null;
    }

    return {
      location: new GeoPoint(
        localities[0].geometry.location.lat,
        localities[0].geometry.location.lng,
      ),
      name: _.compact([
        _.find(localities[0].address_components, ({ types }) => _.includes(types, 'locality'))?.long_name,
        _.find(localities[0].address_components, ({ types }) => _.includes(types, 'administrative_area_level_2'))?.short_name,
        _.find(localities[0].address_components, ({ types }) => _.includes(types, 'administrative_area_level_1'))?.short_name,
        _.find(localities[0].address_components, ({ types }) => _.includes(types, 'country'))?.long_name,
      ]).join(', '),
    };
  }, [geocodingApiKey]);
};

export default useGoogleGetCity;
