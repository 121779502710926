import { Skeleton, Text, VStack } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import BlockList from '../../../../components/BlockList';
import Catch from '../../../../components/Catch';
import useCurrentTime from '../../../../hooks/useCurrentTime';
import useTimezone from '../../../../hooks/useTimezone';
import { InterviewDoc } from '../../../../types/Interview';
import InterviewRow, { InterviewRowSuspenseFallback } from './InterviewRow';

export interface Props {
  interviewSnaps: QueryDocumentSnapshot<InterviewDoc>[];
}

const InterviewsListMain: React.FC<Props> = ({ interviewSnaps }: Props) => {
  const currentTime = useCurrentTime(1000 * 60 * 60);

  const timezone = useTimezone();

  const blocks: [string, QueryDocumentSnapshot<InterviewDoc>[]][] = useMemo(
    () => _.toPairs(_.groupBy(interviewSnaps, (interviewSnap) => {
      const interview = interviewSnap.data();
      return moment(interview.startsAt.toDate()).tz(timezone).startOf('day').calendar(currentTime, {
        sameDay: '[Today], Do',
        nextDay: '[Tomorrow], Do',
        nextWeek: 'dddd, Do',
        lastDay: '[Yesterday], Do',
        lastWeek: '[Last] dddd, Do',
        sameElse: 'MMMM Do',
      });
    })),
    [currentTime, interviewSnaps, timezone],
  );

  if (!interviewSnaps.length) {
    return null;
  }

  return (
    <VStack alignItems="stretch" spacing={5}>
      {blocks.map(([key, snaps]) => (
        <VStack alignItems="stretch" key={key} spacing={1}>
          <Text variant="subtitle" color="cf.cntTertiary" fontWeight="medium">
            {key}
          </Text>

          <BlockList variant="outline">
            {snaps.map((interviewSnap) => (
              <InterviewRow
                key={interviewSnap.id}
                interviewSnap={interviewSnap}
              />
            ))}
          </BlockList>
        </VStack>
      ))}
    </VStack>
  );
};

export const InterviewsListSuspenseFallback: React.FC = () => (
  <VStack alignItems="stretch" spacing={5}>
    <VStack alignItems="stretch" spacing={1}>
      <Skeleton h={4} w="100px" />

      <BlockList>
        <InterviewRowSuspenseFallback />
        <InterviewRowSuspenseFallback />
        <InterviewRowSuspenseFallback />
      </BlockList>
    </VStack>
  </VStack>
);

export const InterviewsListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InterviewsList: React.FC<Props> = (props) => (
  <Catch fallback={<InterviewsListCatchFallback />}>
    <Suspense fallback={<InterviewsListSuspenseFallback />}>
      <InterviewsListMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewsList;
