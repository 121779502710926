import {
  Image,
  ImageProps,
  SkeletonCircle,
  SkeletonProps,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { ref } from 'firebase/storage';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc, useStorage, useStorageDownloadURL } from 'reactfire';
import UserIcon from '../icons/UserIcon';
import { AssetDoc } from '../types/Asset';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';

export type AssetImageStaticProps = ImageProps;

export const AssetImageStatic: React.FC<AssetImageStaticProps> = ({
  boxSize = 11,
  borderRadius = 'md',
  ...imageProps
}) => (
  <Image
    icon={<UserIcon />}
    bg="cf.bgPrimary"
    boxSize={boxSize}
    borderRadius={borderRadius}
    objectFit="cover"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...imageProps}
  />
);

export type AssetImageMainProps = AssetImageStaticProps & {
  assetRef: DocumentReference<AssetDoc>;
};

export const AssetImageMain: React.FC<AssetImageMainProps> = ({
  boxSize = 11,
  borderRadius = 'md',
  assetRef,
  ...imageProps
}) => {
  const storage = useStorage();

  const { data: assetSnap } = useFirestoreDoc(assetRef);

  if (!assetSnap.exists()) {
    throw new SnapNotFoundError(assetSnap);
  }

  const asset = useMemo(() => assetSnap.data(), [assetSnap]);

  const reference = useMemo(() => ref(storage, asset.ref), [asset.ref, storage]);

  const { data: src } = useStorageDownloadURL(reference);

  return (
    <AssetImageStatic
      fallback={(
        <AssetImageSuspenseFallback
          boxSize={boxSize}
          borderRadius={borderRadius}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...imageProps}
        />
      )}
      boxSize={boxSize}
      borderRadius={borderRadius}
      src={src}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
    />
  );
};

export const AssetImageSuspenseFallback: React.FC<SkeletonProps> = ({
  boxSize = 11,
  borderRadius = 'md',
  ...skeletonProps
}) => (
  <SkeletonCircle
    boxSize={boxSize}
    borderRadius={borderRadius}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...skeletonProps}
  />
);

export const AssetImageCatchFallback: React.FC<AssetImageStaticProps> = () => null;

export type AssetImageProps = AssetImageStaticProps & {
  assetRef?: DocumentReference<AssetDoc>;
};

/* eslint-disable react/jsx-props-no-spreading */
const AssetImage: React.FC<AssetImageProps> = ({
  assetRef, boxSize, borderRadius, ...props
}) => (
  assetRef ? (
    <Catch
      fallback={
        <AssetImageCatchFallback boxSize={boxSize} borderRadius={borderRadius} {...props} />
      }
    >
      <Suspense
        fallback={
          <AssetImageSuspenseFallback boxSize={boxSize} borderRadius={borderRadius} />
        }
      >
        <AssetImageMain
          assetRef={assetRef}
          boxSize={boxSize}
          borderRadius={borderRadius}
          {...props}
        />
      </Suspense>
    </Catch>
  ) : (
    <AssetImageStatic {...props} />
  )
);

AssetImage.defaultProps = {
  assetRef: undefined,
};

export default AssetImage;
