import { Container } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import StageInfo, { StageInfoSuspenseFallback } from '../../../components/StageInfo';
import { useStageRef } from '../../../components/StageRefProvider';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { StageDoc } from '../../../types/Stage';

const HeaderMain: React.FC = () => {
  const stageRef = useStageRef();

  const { data: stageSnap } = useFirestoreDoc<StageDoc>(stageRef);

  if (!stageSnap.exists()) {
    throw new SnapNotFoundError(stageSnap);
  }

  return (
    <Container py={2}>
      <StageInfo stageSnap={stageSnap} />
    </Container>
  );
};

export const HeaderSuspenseFallback: React.FC = () => (
  <Container py={2}>
    <StageInfoSuspenseFallback />
  </Container>
);

const HeaderCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Header: React.FC = () => (
  <Catch fallback={<HeaderCatchFallback />}>
    <Suspense fallback={<HeaderSuspenseFallback />}>
      <HeaderMain />
    </Suspense>
  </Catch>
);

export default Header;
