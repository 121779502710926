import { VStack } from '@chakra-ui/react';
import React from 'react';
import ClaimedSkillsForm from './ClaimedSkillsForm';
import ContractForm from './ContractForm';
import Skills from './Skills';

const Interviewer: React.FC = () => (
  <VStack alignItems="stretch" spacing={4}>
    <ContractForm />
    <Skills />
    <ClaimedSkillsForm />
  </VStack>
);

export default Interviewer;
