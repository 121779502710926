import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const EditIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 1.58582L18.4142 8.00003L9.29289 17.1213C8.73028 17.684 7.96722 18 7.17157 18H5C3.34315 18 2 16.6569 2 15V12.8285C2 12.0328 2.31607 11.2697 2.87868 10.7071L12 1.58582ZM10.4142 6.00003L14 9.58582L15.5858 8.00003L12 4.41424L10.4142 6.00003ZM12.5858 11L9 7.41424L4.29289 12.1213C4.10536 12.3089 4 12.5632 4 12.8285V15C4 15.5523 4.44772 16 5 16H7.17157C7.43679 16 7.69114 15.8947 7.87868 15.7071L12.5858 11Z" fill="currentColor" />
  </Icon>
);

export default EditIcon;
