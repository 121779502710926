import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  countryCode?: string;
  phoneNumber: string;
};

export type ResponseData = {
  countryCode: string;
  phoneNumber: string;
  nationalFormat: string;
};

const useTwilioLookup = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'twilio-lookup'),
    [functions],
  );
};

export default useTwilioLookup;
