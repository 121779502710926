import {
  Center,
  SimpleGrid,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { ReviewAspectType } from '../../types/Review';
import BlockListItem from '../BlockListItem';
import Catch from '../Catch';
import Star, { StarSuspenseFallback } from './Star';

export type Props = {
  aspect: ReviewAspectType;
};

const AspectMain: React.FC<Props> = ({ aspect }) => {
  const label = useMemo(() => {
    switch (aspect) {
      case ReviewAspectType.ACCURACY: return 'Accuracy';
      case ReviewAspectType.BEHAVIOR: return 'Behavior';
      case ReviewAspectType.DEPTH: return 'Depth';
      case ReviewAspectType.LANGUAGE: return 'Language';
      case ReviewAspectType.PUNCTUALITY: return 'Punctuality';
      case ReviewAspectType.QUALITY: return 'Quality';
      default: return '';
    }
  }, [aspect]);

  return (
    <BlockListItem>
      <VStack alignItems="stretch" spacing={1}>
        <SimpleGrid columns={6}>
          <Center justifyContent="start">
            <Text variant="labelSmall" color="cf.cntSecondary">
              {label}
            </Text>
          </Center>

          <Center>
            <Star
              aspect={aspect}
              value={0}
            />
          </Center>

          <Center>
            <Star
              aspect={aspect}
              value={0.25}
            />
          </Center>

          <Center>
            <Star
              aspect={aspect}
              value={0.5}
            />
          </Center>

          <Center>
            <Star
              aspect={aspect}
              value={0.75}
            />
          </Center>

          <Center>
            <Star
              aspect={aspect}
              value={1}
            />
          </Center>
        </SimpleGrid>
      </VStack>
    </BlockListItem>
  );
};

export const AspectCatchFallback: React.FC = () => null;

export const AspectSuspenseFallback: React.FC = () => (
  <BlockListItem>
    <VStack alignItems="stretch" spacing={1}>
      <SimpleGrid columns={6}>
        <Center justifyContent="start">
          <Skeleton h={4} w="100px" />
        </Center>

        <Center>
          <StarSuspenseFallback />
        </Center>

        <Center>
          <StarSuspenseFallback />
        </Center>

        <Center>
          <StarSuspenseFallback />
        </Center>

        <Center>
          <StarSuspenseFallback />
        </Center>

        <Center>
          <StarSuspenseFallback />
        </Center>
      </SimpleGrid>
    </VStack>
  </BlockListItem>
);

/* eslint-disable react/jsx-props-no-spreading */
const Aspect: React.FC<Props> = (props) => (
  <Catch fallback={<AspectCatchFallback />}>
    <Suspense fallback={<AspectSuspenseFallback />}>
      <AspectMain {...props} />
    </Suspense>
  </Catch>
);

export default Aspect;
