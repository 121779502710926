import {
  HStack,
  IconButton,
  SkeletonCircle,
  Spacer,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router';
import Catch from '../../../components/Catch';
import EditIcon from '../../../icons/EditIcon';

const ContactInfoMain: React.FC = () => {
  const navigate = useNavigate();
  const handleEdit = useCallback(async () => {
    navigate('./edit');
  }, [navigate]);

  return (
    <VStack alignItems="stretch">
      <HStack>
        <Spacer />

        <Tooltip hasArrow label="Edit">
          <IconButton
            variant="outline"
            icon={<EditIcon />}
            aria-label="Edit"
            onClick={handleEdit}
          />
        </Tooltip>
      </HStack>
    </VStack>
  );
};

export const ContactInfoSuspenseFallback: React.FC = () => (
  <VStack alignItems="stretch">
    <HStack>
      <Spacer />
      <SkeletonCircle boxSize={9} />
    </HStack>
  </VStack>
);

const ContactInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ContactInfo: React.FC = () => (
  <Catch fallback={<ContactInfoCatchFallback />}>
    <Suspense fallback={<ContactInfoSuspenseFallback />}>
      <ContactInfoMain />
    </Suspense>
  </Catch>
);

export default ContactInfo;
