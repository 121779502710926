import { FormControl, FormLabel } from '@chakra-ui/react';
import React from 'react';
import BlockList from '../BlockList';
import BlockListItem from '../BlockListItem';
import GoogleAuthMethod from './GoogleAuthMethod';
import MicrosoftAuthMethod from './MicrosoftAuthMethod';

export type ThirdPartyAuthMethodsProps = {
  label?: string;
};

const ThirdPartyAuthMethods: React.FC<ThirdPartyAuthMethodsProps> = ({
  label = 'Connected accounts',
}) => (
  <FormControl>
    <FormLabel>{label}</FormLabel>

    <BlockList variant="outline">
      <BlockListItem>
        <GoogleAuthMethod />
      </BlockListItem>
      <BlockListItem>
        <MicrosoftAuthMethod />
      </BlockListItem>
    </BlockList>
  </FormControl>
);

ThirdPartyAuthMethods.defaultProps = {
  label: undefined,
};

export default ThirdPartyAuthMethods;
