import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import MDEditor, { MDEditorProps } from '@uiw/react-md-editor';
import { useField } from 'formik';
import React, { useCallback } from 'react';

export interface Props extends MDEditorProps {
  label: string;
  name: string;
  isRequired: boolean;
}

const MarkdownField: React.FC<Props> = ({
  label,
  name,
  isRequired,
  ...props
}: Props) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = useCallback(
    (v: string | undefined) => helpers.setValue(v || ''),
    [helpers],
  );

  return (
    <FormControl
      isInvalid={!!meta.error}
      isRequired={isRequired}
      data-color-mode="light"
      onKeyUp={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <FormLabel>{label}</FormLabel>

      <MDEditor
        value={field.value}
        onChange={handleChange}
        autoFocus
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default MarkdownField;
