import { Text, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import BlockList from './BlockList';
import BlockListItem from './BlockListItem';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import SkillShortInfo from './SkillShortInfo';

const CallLobbySkillsInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return (
    <VStack alignItems="stretch" spacing={1}>
      <Text
        fontWeight="medium"
        fontSize="sm"
        lineHeight="short"
        color="cf.cntTertiary"
        pt="1px"
        pb="3px"
      >
        Skills
        {' '}
        &middot;
        {' '}
        {interview.skillRefs.length}
      </Text>

      <BlockList variant="outline">
        {interview.skillRefs.map((skillRef) => (
          <BlockListItem key={skillRef.id}>
            <SkillShortInfo skillRef={skillRef} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

const CallLobbySkillsInfoCatchFallback: React.FC = () => null;
const CallLobbySkillsInfoSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallLobbySkillsInfo: React.FC = () => (
  <Catch fallback={<CallLobbySkillsInfoCatchFallback />}>
    <Suspense fallback={<CallLobbySkillsInfoSuspenseFallback />}>
      <CallLobbySkillsInfoMain />
    </Suspense>
  </Catch>
);

export default CallLobbySkillsInfo;
