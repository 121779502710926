import { HStack, IconProps } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useMemo } from 'react';
import { CandidateDoc } from '../types/Candidate';
import { ExpertTier } from '../types/Expert';
import ExternalExpertTierIcon from './ExternalExpertTierIcon';
import InternalExpertTierIcon from './InternalExpertTierIcon';

export type Props = {
  candidateSnap: QueryDocumentSnapshot<CandidateDoc>;
} & IconProps;

const ml = -4;

const CandidateTiersIcon: React.FC<Props> = ({ candidateSnap, ...iconProps }: Props) => {
  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  return (
    <HStack spacing={0} ml={-ml}>
      {candidate.internalExpertTiers.includes(ExpertTier.LIEUTENANT) ? (
        <InternalExpertTierIcon tier={ExpertTier.LIEUTENANT} ml={ml} />
      ) : null}

      {candidate.internalExpertTiers.includes(ExpertTier.COMMANDER) ? (
        <InternalExpertTierIcon tier={ExpertTier.COMMANDER} ml={ml} />
      ) : null}

      {candidate.internalExpertTiers.includes(ExpertTier.CAPTAIN) ? (
        <InternalExpertTierIcon tier={ExpertTier.CAPTAIN} ml={ml} />
      ) : null}

      {candidate.internalExpertTiers.includes(ExpertTier.ADMIRAL) ? (
        <InternalExpertTierIcon tier={ExpertTier.ADMIRAL} ml={ml} />
      ) : null}

      {candidate.externalExpertTiers.includes(ExpertTier.LIEUTENANT) ? (
        <ExternalExpertTierIcon tier={ExpertTier.LIEUTENANT} ml={ml} />
      ) : null}

      {candidate.externalExpertTiers.includes(ExpertTier.COMMANDER) ? (
        <ExternalExpertTierIcon tier={ExpertTier.COMMANDER} ml={ml} />
      ) : null}

      {candidate.externalExpertTiers.includes(ExpertTier.CAPTAIN) ? (
        <ExternalExpertTierIcon tier={ExpertTier.CAPTAIN} ml={ml} />
      ) : null}

      {candidate.externalExpertTiers.includes(ExpertTier.ADMIRAL) ? (
        <ExternalExpertTierIcon tier={ExpertTier.ADMIRAL} ml={ml} />
      ) : null}
    </HStack>
  );
};

export default CandidateTiersIcon;
