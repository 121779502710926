import { Heading } from '@chakra-ui/react';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useCurrentTime from '../hooks/useCurrentTime';
import useTimezone from '../hooks/useTimezone';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';

const CallLobbyTimeInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const timezone = useTimezone();

  const currentTime = useCurrentTime(1000 * 60);
  const when = useMemo(
    () => moment(interview.startsAt.toDate())
      .tz(timezone)
      .calendar(currentTime, {
        sameDay: '[Today at] LT z',
        nextDay: '[Tomorrow at] LT z',
        nextWeek: 'dddd [at] LT z',
        lastDay: '[Yesterday at] LT z',
        lastWeek: '[Last] dddd [at] LT z',
        sameElse: 'DD/MM/YYYY',
      }),
    [currentTime, interview.startsAt, timezone],
  );

  return (
    <Heading>
      {when}
    </Heading>
  );
};

const CallLobbyTimeInfoCatchFallback: React.FC = () => null;
const CallLobbyTimeInfoSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallLobbyTimeInfo: React.FC = () => (
  <Catch fallback={<CallLobbyTimeInfoCatchFallback />}>
    <Suspense fallback={<CallLobbyTimeInfoSuspenseFallback />}>
      <CallLobbyTimeInfoMain />
    </Suspense>
  </Catch>
);

export default CallLobbyTimeInfo;
