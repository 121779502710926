import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  SkeletonCircle,
  Spacer,
  Tooltip,
  VStack,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import { setDoc } from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import { useExpertRef } from '../../../components/ExpertRefContext';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import AddUserIcon from '../../../icons/AddUserIcon';
import ArchiveIcon from '../../../icons/ArchiveIcon';
import CheckIcon from '../../../icons/CheckIcon';
import EditIcon from '../../../icons/EditIcon';
import EndCallIcon from '../../../icons/EndCallIcon';
import MessageIcon from '../../../icons/MessageIcon';
import OtherPageIcon from '../../../icons/OtherPageIcon';
import Pause28Icon from '../../../icons/Pause28Icon';
import PlayIcon from '../../../icons/PlayIcon';
import Spinner from '../../../icons/Spinner';
import { ExpertStatus } from '../../../types/Expert';
import { useMembersCollectionRef } from '../../../types/Member';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import AssigneeTag from './AssigneeInfo';
import AssigneeMenuItem from './AssigneeMenuItem';

const ContactInfoMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const expertRef = useExpertRef();
  const membersCollectionRef = useMembersCollectionRef(organizationRef);

  const { data: organizationSnap } = useFirestoreDoc(organizationRef);
  const { data: expertSnap } = useFirestoreDoc(expertRef);
  const { data: membersSnap } = useFirestoreCollection(membersCollectionRef);

  if (!organizationSnap.exists()) {
    throw new SnapNotFoundError(organizationSnap);
  }

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  const { hasCopied: hasCopiedEmail, onCopy: handleCopyEmailClick } = useClipboard(expert.email || '');
  const { hasCopied: hasCopiedPhoneNumber, onCopy: handleCopyPhoneNumberClick } = useClipboard(expert.phoneNumber || '');
  const { hasCopied: hasCopiedUrl, onCopy: handleCopyUrlClick } = useClipboard(expert.customerUrl || '');

  const [isArchiving, setIsArchiving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const handleArchive = useCallback(async () => {
    try {
      setIsArchiving(true);
      await setDoc(expertRef, {
        status: ExpertStatus.ARCHIVED,
      }, { merge: true });
      onClose();
    } finally {
      setIsArchiving(false);
    }
  }, [expertRef, onClose]);

  const [isToggling, setIsToggling] = useState(false);

  const handlePause = useCallback(async () => {
    try {
      setIsToggling(true);
      if (expert.status === ExpertStatus.ACTIVE) {
        await setDoc(expertRef, {
          status: ExpertStatus.PAUSED,
        }, { merge: true });
      }
    } finally {
      setIsToggling(false);
    }
  }, [expert.status, expertRef]);

  const handleUnpause = useCallback(async () => {
    try {
      setIsToggling(true);
      if (expert.status === ExpertStatus.PAUSED) {
        await setDoc(expertRef, {
          status: ExpertStatus.ACTIVE,
        }, { merge: true });
      }
    } finally {
      setIsToggling(false);
    }
  }, [expert.status, expertRef]);

  const navigate = useNavigate();
  const handleEdit = useCallback(async () => {
    navigate('./edit');
  }, [navigate]);

  if (expertSnap.data().organizationRef.id !== organizationRef.id) {
    return null;
  }

  return (
    <VStack alignItems="stretch">
      <HStack>
        {expert.email ? (
          <Tooltip label="Copy email">
            <IconButton
              aria-label="Copy Email"
              icon={hasCopiedEmail ? <CheckIcon /> : <MessageIcon />}
              variant="outline"
              onClick={handleCopyEmailClick}
            />
          </Tooltip>
        ) : null}

        {expert.phoneNumber ? (
          <Tooltip label="Copy phone number">
            <IconButton
              aria-label="Copy Phone number"
              icon={hasCopiedPhoneNumber ? <CheckIcon /> : <EndCallIcon />}
              variant="outline"
              onClick={handleCopyPhoneNumberClick}
            />
          </Tooltip>
        ) : null}

        {expert.customerUrl ? (
          <Tooltip label="Copy URL">
            <IconButton
              aria-label="Copy Phone number"
              icon={hasCopiedUrl ? <CheckIcon /> : <OtherPageIcon />}
              variant="outline"
              onClick={handleCopyUrlClick}
            />
          </Tooltip>
        ) : null}

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Assign"
            icon={<AddUserIcon />}
            variant="outline"
          />
          <MenuList>
            {membersSnap.docs.map((memberSnap) => (
              <AssigneeMenuItem key={memberSnap.ref.path} assigneeRef={memberSnap.ref} />
            ))}
          </MenuList>
        </Menu>

        {(expert.assigneeRefs ?? []).map((assigneeRef) => (
          <AssigneeTag key={assigneeRef.path} assigneeRef={assigneeRef} />
        ))}

        <Spacer />

        {expert.userRef && expert.status === ExpertStatus.ACTIVE ? (
          <Tooltip hasArrow label="Pause interview scheduler">
            <IconButton
              variant="outline"
              icon={<Pause28Icon boxSize={5} />}
              aria-label="Pause"
              onClick={handlePause}
              isLoading={isToggling}
              spinner={<Spinner />}
            />
          </Tooltip>
        ) : null}

        {expert.userRef && expert.status === ExpertStatus.PAUSED ? (
          <Tooltip hasArrow label="Resume interview scheduler">
            <IconButton
              variant="outline"
              icon={<PlayIcon />}
              aria-label="Unpause"
              onClick={handleUnpause}
              isLoading={isToggling}
              spinner={<Spinner />}
            />
          </Tooltip>
        ) : null}

        <Tooltip hasArrow label="Edit">
          <IconButton
            variant="outline"
            icon={<EditIcon />}
            aria-label="Edit"
            onClick={handleEdit}
          />
        </Tooltip>

        {expert.status !== ExpertStatus.ARCHIVED ? (
          <Tooltip hasArrow label="Archive expert">
            <IconButton
              icon={<ArchiveIcon />}
              colorScheme="negative"
              aria-label="Archive"
              onClick={onOpen}
            />
          </Tooltip>
        ) : null}

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Archive expert
              </AlertDialogHeader>

              <AlertDialogBody>
                Expert would be hidden from your dashboard.
                We would also stop scheduling new interviews for this expert.
              </AlertDialogBody>

              <AlertDialogFooter>
                <HStack>
                  <Button
                    variant="outline"
                    ref={cancelRef}
                    onClick={onClose}
                  >
                    No, keep
                  </Button>

                  <Button
                    colorScheme="negative"
                    isLoading={isArchiving}
                    spinner={<Spinner />}
                    loadingText="Archiving..."
                    onClick={handleArchive}
                  >
                    Yes, archive
                  </Button>
                </HStack>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </HStack>
    </VStack>
  );
};

export const ContactInfoSuspenseFallback: React.FC = () => (
  <VStack alignItems="stretch">
    <HStack>
      <SkeletonCircle boxSize={9} />
      <SkeletonCircle boxSize={9} />
      <SkeletonCircle boxSize={9} />
      <Spacer />
      <SkeletonCircle boxSize={9} />
    </HStack>
  </VStack>
);

const ContactInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ContactInfo: React.FC = () => (
  <Catch fallback={<ContactInfoCatchFallback />}>
    <Suspense fallback={<ContactInfoSuspenseFallback />}>
      <ContactInfoMain />
    </Suspense>
  </Catch>
);

export default ContactInfo;
