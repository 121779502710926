import { Text, VStack } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import BlockList from '../../../../components/BlockList';
import Catch from '../../../../components/Catch';
import { SkillDoc } from '../../../../types/Skill';
import SkillItem, { SkillItemSuspenseFallback } from './SkillItem';

export type Props = {
  title: string;
  skillRefs: DocumentReference<SkillDoc>[];
};

const SkillsMain: React.FC<Props> = ({ skillRefs, title }) => (
  <VStack spacing={1} alignItems="stretch" data-intercom-target="Skills">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      {title}
      {' '}
      &middot;
      {' '}
      {skillRefs.length}
    </Text>

    <BlockList variant="outline">
      {skillRefs.map((skillRef) => (
        <SkillItem key={skillRef.id} skillRef={skillRef} />
      ))}
    </BlockList>
  </VStack>
);

export const SkillsSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Skills
    </Text>

    <BlockList variant="outline">
      <SkillItemSuspenseFallback />
      <SkillItemSuspenseFallback />
      <SkillItemSuspenseFallback />
    </BlockList>
  </VStack>
);

const SkillsCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Skills: React.FC<Props> = (props) => (
  <Catch fallback={<SkillsCatchFallback />}>
    <Suspense fallback={<SkillsSuspenseFallback />}>
      <SkillsMain {...props} />
    </Suspense>
  </Catch>
);

export default Skills;
