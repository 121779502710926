import { SearchOptions } from '@algolia/client-search';
import { DocumentReference, doc } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useSkillsIndex } from '../../../components/AlgoliaPublicProvider';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import SkillRow, {
  SkillRowCatchFallback,
  SkillRowSuspenseFallback,
} from '../../../components/SkillRow';
import { AlgoliaCandidateRecord } from '../../../types/AlgoliaRecords';
import { SkillDoc, useSkillsCollectionRef } from '../../../types/Skill';

export interface Props {
  query: string;
  searchOptions: SearchOptions;
  visible: boolean;
  page: number;
  hitsPerPage: number;
  onNbPagesChange: (pages: number) => unknown;
}

const SkillSearchPageMain: React.FC<Props> = ({
  query,
  searchOptions,
  visible,
  page,
  hitsPerPage,
  onNbPagesChange,
}) => {
  const skillsIndex = useSkillsIndex();

  const [skillRefs, setSkillRefs] = useState<Array<DocumentReference<SkillDoc>>>();

  const skillsCollectionRef = useSkillsCollectionRef();

  useEffect(() => {
    if (!skillsIndex || !visible) {
      return;
    }

    skillsIndex.search<AlgoliaCandidateRecord>(query, { ...searchOptions, hitsPerPage, page })
      .then((res) => {
        setSkillRefs(res.hits.map(
          (hit) => doc(skillsCollectionRef, hit.objectID),
        ));
        onNbPagesChange(res.nbPages);
      })
      .catch(() => {
        /* do nothing */
      });
  }, [
    skillsIndex,
    skillsCollectionRef,
    hitsPerPage,
    onNbPagesChange,
    page,
    query,
    searchOptions,
    visible,
  ]);

  if (!skillsIndex || !skillRefs) {
    return (
      <SkillSearchPageSuspenseFallback size={hitsPerPage} />
    );
  }

  return (
    <>
      {skillRefs.map((skillRef) => (
        <Catch key={skillRef.path} fallback={<SkillRowCatchFallback />}>
          <Suspense fallback={<SkillRowSuspenseFallback />}>
            <DocumentLoader reference={skillRef}>
              {(skillSnap) => (
                <SkillRow skillSnap={skillSnap} />
              )}
            </DocumentLoader>
          </Suspense>
        </Catch>
      ))}
    </>
  );
};

export const SkillSearchPageSuspenseFallback: React.FC<{ size: number }> = ({ size }) => (
  <BlockList>
    {_.range(size).map((key) => (
      <SkillRowSuspenseFallback key={key} />
    ))}
  </BlockList>
);

export const SkillSearchPageCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillSearchPage: React.FC<Props> = (props) => (
  <Catch fallback={<SkillSearchPageCatchFallback />}>
    {/* eslint-disable-next-line react/destructuring-assignment */}
    <Suspense fallback={<SkillSearchPageSuspenseFallback size={props.hitsPerPage} />}>
      <SkillSearchPageMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillSearchPage;
