import { useToast } from '@chakra-ui/react';
import {
  addDoc,
  doc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import { Messaging, getToken, onMessage } from 'firebase/messaging';
import _ from 'lodash';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUser } from 'reactfire';
import { useFCMRegistrationTokensCollectionRef } from '../types/FCMRegistrationToken';
import { NotificationStatus, useNotificationsCollectionRef } from '../types/Notification';
import { useUsersCollectionRef } from '../types/User';
import DefaultNotification from './DefaultNotification';

export const MessagingContext = React.createContext<Messaging | undefined>(undefined);

export const useMessaging = (): Messaging => {
  const value = useContext(MessagingContext);

  if (value === undefined) {
    throw new Error('Use MessagingProvider');
  }

  return value;
};

export type Props = {
  sdk: Messaging;
};

const prodDomains = [
  'app.clarwis.com',
  'app.crewfilter.io',
  'crewfilter-production.firebaseapp.com',
  'crewfilter-production.web.app',
];

const vapidKey: string = _.includes(prodDomains, window.location.hostname)
  ? 'BClYzf6j7EhS7xZPztmFTA18kxHQQnRHozWjA2Bh06A1QZeoY9ggXnSZT8FG6uPk32Rnc_jzXGgHu4qzPInChmU'
  : 'BDlD8ejBxeerfp5hh52SLTKz10IyuYRbVDsiqt0Jei1HEU-m2Vzp8ztQxEI2g-bBGG3DWhe8d3UNDdSpCUvzu3o';

const MessagingProvider: React.FC<PropsWithChildren<Props>> = ({ sdk, children }) => {
  const [token, setToken] = useState<string>();
  useEffect(
    () => {
      getToken(sdk, { vapidKey }).then(setToken);
    },
    [sdk],
  );

  const { data: user } = useUser();
  const fcmRegistrationTokensCollectionRef = useFCMRegistrationTokensCollectionRef();
  const usersCollectionRef = useUsersCollectionRef();
  const notificationsCollectionRef = useNotificationsCollectionRef();

  useEffect(
    () => {
      if (token && user?.uid) {
        const userRef = doc(usersCollectionRef, user.uid);

        getDocs(
          query(
            fcmRegistrationTokensCollectionRef,
            where('userRef', '==', userRef),
            where('token', '==', token),
            limit(1),
          ),
        ).then(async (fcmRegistrationTokensSnap) => {
          if (fcmRegistrationTokensSnap.docs.length) {
            await setDoc(
              fcmRegistrationTokensSnap.docs[0].ref,
              {
                timestamp: serverTimestamp(),
              },
              { merge: true },
            );
          } else {
            await addDoc(
              fcmRegistrationTokensCollectionRef,
              {
                userRef,
                token,
                timestamp: serverTimestamp(),
              },
            );
          }
        });
      }
    },
    [fcmRegistrationTokensCollectionRef, usersCollectionRef, token, user?.uid],
  );

  const toast = useToast();

  useEffect(
    () => onMessage(sdk, (payload) => {
      let color: 'empty' | 'created' | 'started' | 'ended' | 'canceled' | 'archived' | undefined;
      switch (payload.data?.type) {
        case 'INTERVIEW_CREATED_INTERVIEWEE':
        case 'INTERVIEW_CREATED_INTERVIEWER':
        case 'INTERVIEW_CREATED_CANDIDATE_ASSIGNEE':
        case 'INTERVIEW_CREATED_EXPERT_ASSIGNEE':
        case 'CANDIDATE_CREATED_CANDIDATE_ASSIGNEE':
        case 'EXPERT_CREATED_EXPERT_ASSIGNEE':
        {
          color = 'created';
          break;
        }
        case 'INTERVIEW_CANCELED_INTERVIEWEE':
        case 'INTERVIEW_CANCELED_INTERVIEWER':
        case 'INTERVIEW_CANCELED_CANDIDATE_ASSIGNEE':
        case 'INTERVIEW_CANCELED_EXPERT_ASSIGNEE':
        case 'CANDIDATE_PAUSED_CANDIDATE_ASSIGNEE':
        case 'EXPERT_PAUSED_EXPERT_ASSIGNEE':
        {
          color = 'canceled';
          break;
        }
        case 'INTERVIEW_ENDED_INTERVIEWEE':
        case 'INTERVIEW_ENDED_INTERVIEWER':
        case 'INTERVIEW_ENDED_CANDIDATE_ASSIGNEE':
        case 'INTERVIEW_ENDED_EXPERT_ASSIGNEE':
        case 'CANDIDATE_FINALIZED_CANDIDATE_ASSIGNEE':
        {
          color = 'ended';
          break;
        }
        case 'INTERVIEW_STARTED_INTERVIEWEE':
        case 'INTERVIEW_STARTED_INTERVIEWER':
        case 'INTERVIEW_STARTED_CANDIDATE_ASSIGNEE':
        case 'INTERVIEW_STARTED_EXPERT_ASSIGNEE':
        case 'CANDIDATE_ACTIVATED_CANDIDATE_ASSIGNEE':
        case 'EXPERT_ACTIVATED_EXPERT_ASSIGNEE':
        {
          color = 'started';
          break;
        }
        case 'CANDIDATE_ARCHIVED_CANDIDATE_ASSIGNEE':
        case 'EXPERT_ARCHIVED_EXPERT_ASSIGNEE':
        {
          color = 'archived';
          break;
        }
      }

      toast({
        position: 'top',
        isClosable: true,
        onCloseComplete: () => {
          if (typeof payload.data?.notificationId === 'string') {
            setDoc(
              doc(notificationsCollectionRef, payload.data.notificationId),
              {
                status: NotificationStatus.ACKNOWLEDGED,
                acknowledgedAt: serverTimestamp(),
              },
              { merge: true },
            );
          }
        },
        render: ({ isClosable, onClose }) => (
          <DefaultNotification
            payload={payload}
            onClose={onClose}
            isClosable={isClosable}
            color={color}
          />
        ),
      });
    }),
    [notificationsCollectionRef, sdk, toast],
  );

  return (
    <MessagingContext.Provider value={sdk}>
      {children}
    </MessagingContext.Provider>
  );
};

export default MessagingProvider;
