import {
  Box,
  HStack,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import SkillLogosRow, { SkillLogosRowSuspenseFallback } from '../../../components/SkillLogosRow';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useTimezone from '../../../hooks/useTimezone';
import { OrganizationDoc } from '../../../types/Organization';
import {
  OrganizationBalanceTransactionDoc,
  OrganizationBalanceTransactionType,
} from '../../../types/OrganizationBalanceTransaction';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

export interface Props {
  transactionSnap: QueryDocumentSnapshot<OrganizationBalanceTransactionDoc>;
}

const InterviewCellMain: React.FC<Props> = ({ transactionSnap }) => {
  const transaction = useMemo(() => transactionSnap.data(), [transactionSnap]);

  const organizationRef = useMemo(
    () => transactionSnap.ref.parent.parent?.parent.parent as DocumentReference<OrganizationDoc>,
    [transactionSnap],
  );

  if (
    transaction.type !== OrganizationBalanceTransactionType.INTERVIEW_CANCELED
    && transaction.type !== OrganizationBalanceTransactionType.INTERVIEW_CREATED
    && transaction.type !== OrganizationBalanceTransactionType.REFUND
  ) {
    throw new Error();
  }

  const { data: interviewSnap } = useFirestoreDoc(transaction.interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(
    () => interviewSnap.data(),
    [interviewSnap],
  );

  const currentTime = useCurrentTime(1000 * 60);
  const timezone = useTimezone();
  const startsAt = useMemo(
    () => moment(interview.startsAt.toDate()).tz(timezone).calendar(currentTime),
    [currentTime, interview.startsAt, timezone],
  );

  return (
    <a href={`${window.location.origin}/organizations/${organizationRef.id}/interviews/${transaction.interviewRef.id}`} target="_blank" rel="noreferrer">
      <HStack>
        <Text flexGrow={1}>
          {startsAt}
        </Text>
        <SkillLogosRow skillRefs={interview.skillRefs} />
      </HStack>
    </a>
  );
};

export const InterviewCellSuspenseFallback: React.FC = () => (
  <HStack>
    <Box flexGrow={1}>
      <Skeleton h={4} w="100px" />
    </Box>
    <SkillLogosRowSuspenseFallback />
  </HStack>
);

const InterviewCellCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InterviewCell: React.FC<Props> = (props) => (
  <Catch fallback={<InterviewCellCatchFallback />}>
    <Suspense fallback={<InterviewCellSuspenseFallback />}>
      <InterviewCellMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewCell;
