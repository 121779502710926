import {
  Box,
  Center,
  HStack,
  Text,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import useParticipantAudioTrack from '../hooks/useParticipantAudioTrack';
import useParticipants from '../hooks/useParticipants';
import useParticipantVideoTrack from '../hooks/useParticipantVideoTrack';
import useRemoteTrackEnabled from '../hooks/useRemoteTrackEnabled';
import MicOffIcon from '../icons/MicOffIcon';
import Spinner from '../icons/Spinner';
import VideoOffIcon from '../icons/VideoOffIcon';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import { UserDoc } from '../types/User';
import Audio from './Audio';
import Catch from './Catch';
import LocalVideoPlaceholder from './LocalVideoPlaceholder';
import { useRoom } from './RoomProvider';
import Video from './Video';

export type Props = {
  userRef: DocumentReference<UserDoc>;
};

const ParticipantMediaMain: React.FC<Props> = ({ userRef }) => {
  const { data: userSnap } = useFirestoreDoc(userRef);
  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }
  const user = useMemo(() => userSnap.data(), [userSnap]);

  const { room } = useRoom();
  const participants = useParticipants(room);
  const participant = useMemo(
    () => _.find(
      participants,
      ({ identity }) => userRef.id === identity,
    ) ?? null,
    [participants, userRef.id],
  );

  const cameraTrack = useParticipantVideoTrack(participant, 'camera');
  const cameraTrackEnabled = useRemoteTrackEnabled(cameraTrack);

  const microphoneTrack = useParticipantAudioTrack(participant, 'microphone');
  const microphoneTrackEnabled = useRemoteTrackEnabled(microphoneTrack);

  return (
    <Box
      h={180}
      w={320}
      borderRadius="md"
      overflow="hidden"
      bg="cf.black"
      position="relative"
    >
      {cameraTrack && cameraTrackEnabled ? (
        <Box h="100%" w="100%">
          <Video videoTrack={cameraTrack} />
        </Box>
      ) : (
        <Center h="100%" w="100%">
          <LocalVideoPlaceholder userRef={userRef} />
        </Center>
      )}

      <Box
        position="absolute"
        left={3}
        bottom={2}
        display="inline-block"
        bg="rgba(2, 23, 41, 0.6)"
        borderRadius="sm"
        px={1}
        py="2px"
      >
        <Text fontSize="xs" lineHeight="shorter" color="cf.cntOnColor" userSelect="none">
          {participant ? user.firstName : `Waiting for ${user.firstName} to join...`}
        </Text>
      </Box>

      {!participant ? (
        <Box
          position="absolute"
          right={3}
          bottom={2}
          display="inline-block"
          bg="rgba(2, 23, 41, 0.6)"
          borderRadius="sm"
          p="2px"
          lineHeight="16px"
          color="cf.cntOnColor"
        >
          <Spinner boxSize={4} />
        </Box>
      ) : null}

      {participant && (
        !microphoneTrack
        || !microphoneTrackEnabled
        || !cameraTrack
        || !cameraTrackEnabled
      ) ? (
        <HStack
          position="absolute"
          right={3}
          bottom={2}
          bg="rgba(2, 23, 41, 0.6)"
          borderRadius="sm"
          p="2px"
          lineHeight="16px"
          color="cf.cntOnColor"
          spacing={1}
        >
          {!microphoneTrack || !microphoneTrackEnabled ? (
            <MicOffIcon boxSize={4} />
          ) : null}

          {!cameraTrack || !cameraTrackEnabled ? (
            <VideoOffIcon boxSize={4} />
          ) : null}
        </HStack>
        ) : null}

      {microphoneTrack && microphoneTrackEnabled ? (
        <Audio audioTrack={microphoneTrack} />
      ) : null}
    </Box>
  );
};

const ParticipantMediaCatchFallback: React.FC = () => null;
const ParticipantMediaSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ParticipantMedia: React.FC<Props> = (props) => (
  <Catch fallback={<ParticipantMediaCatchFallback />}>
    <Suspense fallback={<ParticipantMediaSuspenseFallback />}>
      <ParticipantMediaMain {...props} />
    </Suspense>
  </Catch>
);

export default ParticipantMedia;
