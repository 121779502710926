import { IconButton, VStack } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import BriefcaseIcon from '../../icons/BriefcaseIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import CheckInCircleIcon from '../../icons/CheckInCircleIcon';
import FlagIcon from '../../icons/FlagIcon';
import MicOnIcon from '../../icons/MicOnIcon';
import SettingsIcon from '../../icons/SettingsIcon';

const OrganizationMenu: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  const navigate = useNavigate();

  const handleStagesClick = useCallback(() => {
    navigate(`/organizations/${organizationId}/stages`);
  }, [navigate, organizationId]);

  const isStagesActive = useMatch(`/organizations/${organizationId}/stages/*`);

  const handleCandidatesClick = useCallback(() => {
    navigate(`/organizations/${organizationId}/candidates`);
  }, [navigate, organizationId]);

  const isCandidatesActive = useMatch(`/organizations/${organizationId}/candidates/*`);

  const handleExpertsClick = useCallback(() => {
    navigate(`/organizations/${organizationId}/experts`);
  }, [navigate, organizationId]);

  const isExpertsActive = useMatch(`/organizations/${organizationId}/experts/*`);

  const handleInterviewsClick = useCallback(() => {
    navigate(`/organizations/${organizationId}/interviews`);
  }, [navigate, organizationId]);

  const isInterviewsActive = useMatch(`/organizations/${organizationId}/interviews/*`);

  const handleSkillsClick = useCallback(() => {
    navigate(`/organizations/${organizationId}/skills`);
  }, [navigate, organizationId]);

  const isSkillsActive = useMatch(`/organizations/${organizationId}/skills/*`);

  const handleSettingsClick = useCallback(() => {
    navigate(`/organizations/${organizationId}/settings`);
  }, [navigate, organizationId]);

  const isSettingsActive = useMatch(`/organizations/${organizationId}/settings/*`);

  if (!organizationId) {
    return null;
  }

  return (
    <VStack alignItems="center" spacing={2}>
      <IconButton
        isActive={!!isCandidatesActive}
        borderRadius="100%"
        variant="ghost"
        aria-label="Candidates"
        icon={<BriefcaseIcon />}
        onClick={handleCandidatesClick}
      />
      <IconButton
        isActive={!!isExpertsActive}
        borderRadius="100%"
        variant="ghost"
        aria-label="Experts"
        icon={<MicOnIcon />}
        onClick={handleExpertsClick}
      />
      <IconButton
        isActive={!!isInterviewsActive}
        borderRadius="100%"
        variant="ghost"
        aria-label="Interviews"
        icon={<CalendarIcon />}
        onClick={handleInterviewsClick}
      />
      <IconButton
        isActive={!!isStagesActive}
        borderRadius="100%"
        variant="ghost"
        aria-label="Stages"
        icon={<CheckInCircleIcon />}
        onClick={handleStagesClick}
      />
      <IconButton
        isActive={!!isSkillsActive}
        borderRadius="100%"
        variant="ghost"
        aria-label="Skills"
        icon={<FlagIcon />}
        onClick={handleSkillsClick}
      />
      <IconButton
        isActive={!!isSettingsActive}
        borderRadius="100%"
        variant="ghost"
        aria-label="Settings"
        icon={<SettingsIcon />}
        onClick={handleSettingsClick}
      />
    </VStack>
  );
};

export default OrganizationMenu;
