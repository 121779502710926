import {
  HStack,
  IconButton,
  SkeletonCircle,
  Tooltip,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import { useSkillRef } from '../../../components/SkillRefContext';
import EditIcon from '../../../icons/EditIcon';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const ToolBarMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const skillRef = useSkillRef();

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  const navigate = useNavigate();
  const handleEdit = useCallback(async () => {
    navigate('./edit');
  }, [navigate]);

  if (skill.organizationRef.id !== organizationRef.id) {
    return null;
  }

  return (
    <HStack justifyContent="right">
      <Tooltip hasArrow label="Edit">
        <IconButton
          variant="outline"
          icon={<EditIcon />}
          aria-label="Edit"
          onClick={handleEdit}
        />
      </Tooltip>
    </HStack>
  );
};

export const ToolBarSuspenseFallback: React.FC = () => (
  <HStack justifyContent="right">
    <SkeletonCircle boxSize={9} />
  </HStack>
);

const ToolBarCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ToolBar: React.FC = () => (
  <Catch fallback={<ToolBarCatchFallback />}>
    <Suspense fallback={<ToolBarSuspenseFallback />}>
      <ToolBarMain />
    </Suspense>
  </Catch>
);

export default ToolBar;
