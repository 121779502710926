import {
  AvatarBadge,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import { UserDoc } from '../types/User';
import BlockList from './BlockList';
import BlockListItem from './BlockListItem';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';
import MemberAvatar from './MemberAvatar';
import PaddingBlock from './PaddingBlock';

export type Props = {
  interviewerRef: DocumentReference<UserDoc>
};

const CallLobbyInterviewerInfo: React.FC<Props> = ({ interviewerRef }) => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { data: interviewerSnap } = useFirestoreDoc(interviewerRef);

  if (!interviewerSnap.exists()) {
    throw new SnapNotFoundError(interviewerSnap);
  }

  const interviewer = useMemo(() => interviewerSnap.data(), [interviewerSnap]);

  const interviewerIsConnected = useMemo(
    () => _.some(interview.connectedUserRefs, (ref) => ref.id === interviewerRef.id),
    [interview.connectedUserRefs, interviewerRef.id],
  );

  return (

    <HStack spacing={3} alignItems="center">
      <MemberAvatar
        userRef={interviewerRef}
      >
        {interviewerIsConnected ? (<AvatarBadge />) : null}
      </MemberAvatar>

      <VStack spacing={1} alignItems="stretch" flexGrow={1}>
        <Text
          fontWeight="medium"
          fontSize="sm"
          lineHeight="shorter"
          color="cf.cntTertiary"
          pt="1px"
          pb="3px"
        >
          Interviewer
        </Text>

        <Text variant="labelMedium">
          {interviewer.firstName}
          {' '}
          {interviewer.lastName}
        </Text>
      </VStack>
    </HStack>
  );
};

const CallLobbyInterviewersInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  return (
    <BlockList variant="outline">
      {interview.interviewerRefs.map((interviewerRef) => (
        <BlockListItem key={interviewerRef.id}>
          <CallLobbyInterviewerInfo interviewerRef={interviewerRef} />
        </BlockListItem>
      ))}
    </BlockList>
  );
};

const CallLobbyInterviewersInfoCatchFallback: React.FC = () => null;
const CallLobbyInterviewersInfoSuspenseFallback: React.FC = () => (
  <PaddingBlock>
    <HStack spacing={3} alignItems="center">
      <SkeletonCircle size="36px" />

      <VStack spacing={0} alignItems="stretch">
        <Skeleton h="20px" w="100px" pt="1px" />
        <Skeleton h="24px" w="140px" pt="1px" pb="2px" />
      </VStack>
    </HStack>
  </PaddingBlock>
);

/* eslint-disable react/jsx-props-no-spreading */
const CallLobbyInterviewersInfo: React.FC = () => (
  <Catch fallback={<CallLobbyInterviewersInfoCatchFallback />}>
    <Suspense fallback={<CallLobbyInterviewersInfoSuspenseFallback />}>
      <CallLobbyInterviewersInfoMain />
    </Suspense>
  </Catch>
);

export default CallLobbyInterviewersInfo;
