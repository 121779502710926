import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const Star28Icon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={7} viewBox="0 0 28 28" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.1837 3.54976C12.8985 2.00002 15.1011 2.00002 15.8159 3.54976L18.3412 9.02454L24.3284 9.73441C26.0232 9.93536 26.7038 12.0301 25.4508 13.1889L21.0243 17.2823L22.1993 23.1958C22.5319 24.8698 20.75 26.1644 19.2608 25.3308L13.9998 22.3859L8.73883 25.3308C7.2496 26.1644 5.46768 24.8698 5.80029 23.1958L6.9753 17.2823L2.54883 13.1889C1.29583 12.0301 1.97646 9.93536 3.67125 9.73441L9.65842 9.02454L12.1837 3.54976ZM16.5251 9.86223L13.9998 4.38745L11.4745 9.86223C11.1832 10.4938 10.5846 10.9287 9.8939 11.0106L3.90673 11.7205L8.3332 15.814C8.84387 16.2862 9.07251 16.9899 8.93695 17.6721L7.76194 23.5856L13.0229 20.6408C13.6299 20.301 14.3697 20.301 14.9767 20.6408L20.2377 23.5856L19.0627 17.6721C18.9271 16.9899 19.1557 16.2862 19.6664 15.814L24.0929 11.7205L18.1057 11.0106C17.415 10.9287 16.8164 10.4938 16.5251 9.86223Z" fill="currentColor" />
  </Icon>
);

export default Star28Icon;
