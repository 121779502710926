import React from 'react';
import { useParams } from 'react-router-dom';
import CandidateRefProvider from '../../components/CandidateRefProvider';
import { useCandidateDocRef } from '../../types/Candidate';
import CandidateDetailsScreen from './CandidateDetailsScreen';

export type Params = {
  candidateId: string;
  organizationId: string;
};

const OrganizationCandidateDetailsPage: React.FC = () => {
  const { candidateId } = useParams<Params>();

  if (!candidateId) {
    throw new Error();
  }

  const candidateRef = useCandidateDocRef(candidateId);

  return (
    <CandidateRefProvider candidateRef={candidateRef} key={candidateRef.path}>
      <CandidateDetailsScreen />
    </CandidateRefProvider>
  );
};

export default OrganizationCandidateDetailsPage;
