import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import useDebounce from '../functions/useDebounce';
import useTwilioLookup from '../functions/useTwilioLookup';
import COUNTRY_FLAGS from '../helpers/countryFlags';
import CrossInCircleFilledIcon from '../icons/CrossInCircleFilledIcon';
import Spinner from '../icons/Spinner';

export interface FormFields {
  phoneNumber: string;
}

export type PhoneNumberAuthMethodProps = {
  label?: string;
  name?: string;
  placeholder?: string;
};

const PhoneNumberField: React.FC<PhoneNumberAuthMethodProps> = ({
  label = 'Phone number',
  name = 'phoneNumber',
  placeholder = '+177811112233',
}) => {
  const [field, meta] = useField<string>(name);

  const lookup = useTwilioLookup();

  const search = useDebounce(field.value, 500);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(
    () => {
      let stopped = false;
      setLoading(true);
      lookup({ phoneNumber: search })
        .then(({ data }) => {
          if (!stopped) {
            setCountryCode(data.countryCode);
            setError(false);
            setLoading(false);
          }
        })
        .catch(() => {
          if (!stopped) {
            setCountryCode(null);
            setError(true);
            setLoading(false);
          }
        });

      return () => {
        stopped = true;
      };
    },
    [search, lookup],
  );

  const info = useMemo(() => {
    if (loading) {
      return (
        <InputRightElement>
          <Spinner color="cf.cntTertiary" />
        </InputRightElement>
      );
    }

    if (countryCode) {
      return (
        <InputRightElement>
          { COUNTRY_FLAGS[countryCode].emoji }
        </InputRightElement>
      );
    }

    if (error) {
      return (
        <InputRightElement>
          <CrossInCircleFilledIcon color="cf.cntNegative" />
        </InputRightElement>
      );
    }

    return null;
  }, [countryCode, error, loading]);

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>
        {label}
      </FormLabel>

      <InputGroup>
        <Input
          placeholder={placeholder}
          type="tel"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
        />

        {info}

      </InputGroup>

      <Text mt="1px" mb="3px" color="cf.cntTertiary" lineHeight="short" fontSize="sm">
        We use it to call you 15 minutes before the interview starts
      </Text>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

PhoneNumberField.defaultProps = {
  label: undefined,
  name: undefined,
  placeholder: undefined,
};

export default PhoneNumberField;
