import { Grid, GridItem } from '@chakra-ui/react';
import { getRemoteConfig, getString } from 'firebase/remote-config';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useFirebaseApp } from 'reactfire';
import { AlgoliaOrganizationProvider } from '../../components/AlgoliaOrganizationProvider';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import ExpertsScreen from './ExpertsScreen';

const OrganizationExpertsLayout: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const firebaseApp = useFirebaseApp();
  const remoteConfigInstance = useMemo(() => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 5;
    return remoteConfig;
  }, [firebaseApp]);
  const algoliaAppId = useMemo(() => getString(remoteConfigInstance, 'algolia_appId'), [remoteConfigInstance]);

  return (
    <AlgoliaOrganizationProvider appId={algoliaAppId} organizationId={organizationRef.id}>
      <Grid templateColumns="1fr 1fr" height="100%">
        <GridItem minH={0} overflow="auto">
          <ExpertsScreen />
        </GridItem>

        <GridItem minH={0} overflow="auto" bg="cf.bgPrimary">
          <Outlet />
        </GridItem>
      </Grid>
    </AlgoliaOrganizationProvider>
  );
};

export default OrganizationExpertsLayout;
