import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { InterviewDoc, isInterviewRef } from './Interview';

export type InterviewWhiteboardFileDoc = {
  id: string;
  data: string;
};

export const isInterviewWhiteboardFileDoc = (
  data?: DocumentData,
): data is InterviewWhiteboardFileDoc => true;

export const isInterviewWhiteboardFileRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewWhiteboardFileDoc> => ref.parent.id === 'whiteboardFiles' && ref.parent.parent !== null && isInterviewRef(ref.parent.parent);

export const isInterviewWhiteboardFileSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewWhiteboardFileDoc
> => isInterviewWhiteboardFileRef(snap.ref);

export const useInterviewWhiteboardFilesCollectionRef = (interviewRef: DocumentReference<InterviewDoc>) => useMemo(() => collection(interviewRef, 'whiteboardFiles') as CollectionReference<InterviewWhiteboardFileDoc>, [interviewRef]);

export const useInterviewWhiteboardFileDocRef = (
  interviewRef: DocumentReference<InterviewDoc>,
  docId: string,
) => {
  const collectionRef = useInterviewWhiteboardFilesCollectionRef(interviewRef);
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
