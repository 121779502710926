import {
  Button,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, QuerySnapshot } from 'firebase/firestore';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import BlockList from '../../components/BlockList';
import ExternalExpertTiersField from '../../components/ExternalExpertTiersField';
import InternalExpertTiersField from '../../components/InternalExpertTiersField';
import SkillsSelectorField from '../../components/SkillsSelectorField';
import TextField from '../../components/TextField';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import PlusIcon from '../../icons/PlusIcon';
import Spinner from '../../icons/Spinner';
import { CandidateDoc } from '../../types/Candidate';
import { ExpertTier } from '../../types/Expert';
import { SkillDoc } from '../../types/Skill';
import CandidateRow from './CandidateRow';

export interface FormFields {
  email: string;
  firstName: string;
  lastName: string;
  skillRefs: DocumentReference<SkillDoc>[];
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
}

const schema = yup.object().shape({
  email: yup.string().email().label('Candidate email').required(),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  skillRefs: yup.array().min(1).label('Skills').required(),
  internalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  externalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
});

export type Props = {
  onPrevious: () => unknown;
  onNext: () => unknown;
  onSubmit: (fields: FormFields) => Promise<unknown>
  candidatesSnap: QuerySnapshot<CandidateDoc>;
};

const CandidateForm: React.FC<Props> = ({
  onPrevious, onNext, onSubmit, candidatesSnap,
}) => {
  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        skillRefs: [],
        internalExpertTiers: [ExpertTier.COMMANDER],
        externalExpertTiers: [],
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            setValidateAll(true);
            return handleSubmit(e);
          }}
        >
          <VStack spacing={8} alignItems="stretch">
            <VStack alignItems="stretch" spacing={4}>
              <HStack spacing={4} alignItems="start" className="candidate-name">
                <TextField isRequired label="First name" name="firstName" placeholder="John" />
                <TextField isRequired label="Last name" name="lastName" placeholder="Doe" />
              </HStack>

              <TextField isRequired label="Candidate email" name="email" type="email" placeholder="candidate@email.com" />

              <HStack spacing={5}>
                <InternalExpertTiersField
                  label="Expert ranks"
                  name="internalExpertTiers"
                />

                <ExternalExpertTiersField
                  label="Clarwis Expert ranks"
                  name="externalExpertTiers"
                />
              </HStack>

              <SkillsSelectorField
                name="skillRefs"
                label="Skills"
                isRequired
              />
            </VStack>

            {candidatesSnap.docs.length ? (
              <VStack spacing={1} alignItems="stretch">
                <Text
                  pt="1px"
                  pb="3px"
                  color="cf.cntTertiary"
                  fontSize="sm"
                  lineHeight="short"
                  fontWeight="medium"
                >
                  Candidates
                </Text>

                <BlockList variant="outline">
                  {candidatesSnap.docs.map((candidateSnap) => (
                    <CandidateRow
                      key={candidateSnap.id}
                      candidateSnap={candidateSnap}
                    />
                  ))}
                </BlockList>
              </VStack>
            ) : null}

            <HStack justifyContent="right">
              <Button
                variant="outline"
                onClick={onPrevious}
                leftIcon={<ArrowUpIcon />}
              >
                Previous
              </Button>

              <Spacer />

              <Button
                onClick={onNext}
                variant="ghost"
                leftIcon={<ArrowDownIcon />}
              >
                Next
              </Button>

              <Button
                type="submit"
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Saving..."
                leftIcon={<PlusIcon />}
              >
                Add
              </Button>
            </HStack>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export default CandidateForm;
