import {
  Button,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { } from 'react';
import PaddingBlock from '../../components/PaddingBlock';
import ArrowDownIcon from '../../icons/ArrowDownIcon';

export type Props = {
  onNext: () => unknown;
};

const BriefingScreen: React.FC<Props> = ({
  onNext,
}) => (
  <VStack spacing={8} alignItems="stretch" textAlign="left">
    <PaddingBlock>
      <Text>
        Welcome to Clarwis! We&apos;re thrilled to have you join us on this journey to simplify and
        enhance your company&apos;s technical interviewing process. With this wizard, you&apos;re
        about to embark on a transformative experience that will save you time, reduce stress, and
        ultimately help you make smarter hiring decisions. We understand the challenges of technical
        interviews, and our platform is designed to streamline the entire process, from scheduling
        interviews to evaluating candidates. Let&apos;s get started and revolutionize the way you
        hire top talent!
      </Text>
    </PaddingBlock>

    <HStack justifyContent="right">
      <Button onClick={onNext} leftIcon={<ArrowDownIcon />}>
        Next
      </Button>
    </HStack>
  </VStack>
);

export default BriefingScreen;
