import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MissionMoonIcon: React.FC<IconProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" {...props}>
    <path d="M19.502,13.109C21.219,7.861 18.357,2.216 13.109,0.498C7.861,-1.219 2.216,1.643 0.498,6.891C-1.219,12.139 1.643,17.785 6.891,19.502C12.139,21.219 17.784,18.357 19.502,13.109" fill="rgb(123,134,146)" fillRule="nonzero" />
    <path opacity="0.5" d="M7.534,2.155C8.51,2.237 8.923,2.837 9.263,3.135C9.497,3.198 10.501,3.897 9.687,4.142C7.056,4.934 7.77,7.036 8.153,7.579C8.786,8.478 8.287,10.119 7.932,10.5C7.257,11.288 6.624,9.042 5.777,10.418C4.904,12.095 7.38,11.002 6.772,12.211C6.002,13.741 3.57,13.217 2.618,11.62C2.225,10.96 0.033,9.272 2.103,6.159C2.497,5.567 2.97,4.485 5.608,2.706C6.201,2.306 6.882,2.101 7.534,2.155" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path opacity="0.5" d="M5.989,15.565C5.62,15.417 5.536,14.258 4.71,14.106C3.883,13.955 3.117,14.363 2.997,15.018C2.877,15.672 3.568,16.034 4.276,16.477C5.322,17.131 6.5,15.77 5.989,15.565" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path opacity="0.5" d="M11.27,2.67C11.575,2.697 11.917,3.599 12.556,3.516C13.196,3.433 13.656,2.921 13.585,2.371C13.514,1.821 12.923,1.704 12.299,1.525C11.377,1.262 10.847,2.633 11.27,2.67" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path opacity="0.5" d="M15.489,7.714C15.178,7.723 13.492,7.665 12.926,8.198C11.322,9.709 9.732,8.169 10.589,7.212C11.895,5.754 11.232,6.344 10.865,4.376C10.828,4.18 11.362,3.606 11.844,4.1C12.362,4.632 13.634,6.222 15.334,5.899C17.091,5.565 18.349,4.589 18.238,6.459C18.184,7.372 16.57,7.684 15.489,7.714" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path opacity="0.5" d="M18.944,11.097C19.036,10.444 18.568,9.846 17.912,9.773C16.806,9.65 15.163,9.562 14.229,9.969C12.729,10.621 11.751,9.486 11.267,10.58C11.123,10.907 10.953,11.093 10.785,11.194C10.377,11.44 10.137,11.889 10.174,12.364C10.237,13.18 10.204,14.189 9.729,14.581C8.878,15.282 8.523,17.723 9.085,18.071C10.007,18.643 11.077,18.26 11.919,18.44C12.306,18.523 12.709,18.475 13.067,18.307C14.016,17.861 15.815,16.909 16.053,15.999C16.377,14.756 16.654,13.458 17.792,13.418C18.487,13.393 18.803,12.101 18.944,11.097" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M9.687,2.506C9.687,3.281 9.06,3.909 8.285,3.909C7.51,3.909 6.883,3.281 6.883,2.506C6.883,1.732 7.51,1.104 8.285,1.104C9.06,1.104 9.687,1.732 9.687,2.506" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M4.425,10.499C4.425,11.406 3.709,12.142 2.826,12.142C1.942,12.142 1.226,11.406 1.226,10.499C1.226,9.591 1.942,8.856 2.826,8.856C3.709,8.856 4.425,9.591 4.425,10.499" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M16.01,4.028C16.01,4.564 15.586,4.999 15.064,4.999C14.542,4.999 14.119,4.564 14.119,4.028C14.119,3.492 14.542,3.057 15.064,3.057C15.586,3.057 16.01,3.492 16.01,4.028" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M11.591,8.437C11.591,9.141 11.035,9.713 10.349,9.713C9.663,9.713 9.107,9.141 9.107,8.437C9.107,7.732 9.663,7.161 10.349,7.161C11.035,7.161 11.591,7.732 11.591,8.437" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M8.669,12.894C8.669,13.31 8.341,13.647 7.936,13.647C7.531,13.647 7.203,13.31 7.203,12.894C7.203,12.479 7.531,12.142 7.936,12.142C8.341,12.142 8.669,12.479 8.669,12.894" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M6.612,6.881C6.612,7.165 6.388,7.394 6.112,7.394C5.837,7.394 5.613,7.165 5.613,6.881C5.613,6.598 5.837,6.368 6.112,6.368C6.388,6.368 6.612,6.598 6.612,6.881" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M17.76,13.201C17.76,14.271 16.915,15.139 15.873,15.139C14.831,15.139 13.986,14.271 13.986,13.201C13.986,12.131 14.831,11.263 15.873,11.263C16.915,11.263 17.76,12.131 17.76,13.201" fill="rgb(172,183,191)" fillRule="nonzero" />
    <path d="M9.687,17.204C9.687,17.909 9.131,18.48 8.445,18.48C7.759,18.48 7.203,17.909 7.203,17.204C7.203,16.499 7.759,15.928 8.445,15.928C9.131,15.928 9.687,16.499 9.687,17.204" fill="rgb(172,183,191)" fillRule="nonzero" />
  </Icon>
);

export default MissionMoonIcon;
