import { HStack } from '@chakra-ui/react';
import {
  DocumentReference,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import { useScoresCollectionRef } from '../../types/Score';
import { SkillDoc } from '../../types/Skill';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import ScoreShortInfo, { ScoreShortInfoSuspenseFallback } from '../ScoreShortInfo';
import SkillShortInfo, { SkillShortInfoSuspenseFallback } from '../SkillShortInfo';

export interface Props {
  skillRef: DocumentReference<SkillDoc>;
}

const SkillItemMain: React.FC<Props> = ({
  skillRef,
}) => {
  const interviewRef = useInterviewRef();
  const scoresCollectionRef = useScoresCollectionRef();
  const { data: scoresSnap } = useFirestoreCollection(
    query(
      scoresCollectionRef,
      where('interviewRef', '==', interviewRef),
      where('skillRef', '==', skillRef),
      orderBy('score', 'desc'),
      limit(1),
    ),
  );

  const scoreSnap = useMemo(() => {
    if (!scoresSnap.docs.length) {
      return undefined;
    }

    return scoresSnap.docs[0];
  }, [scoresSnap.docs]);

  return (
    <HStack alignItems="center">
      <SkillShortInfo skillRef={skillRef} flexGrow={1} />
      {scoreSnap ? (
        <ScoreShortInfo scoreSnap={scoreSnap} />
      ) : null}
    </HStack>
  );
};

export const SkillItemCatchFallback: React.FC = () => null;

export const SkillItemSuspenseFallback: React.FC = () => (
  <HStack alignItems="center">
    <SkillShortInfoSuspenseFallback flexGrow={1} />
    <ScoreShortInfoSuspenseFallback />
  </HStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const SkillItem: React.FC<Props> = (props) => (
  <Catch fallback={<SkillItemCatchFallback />}>
    <Suspense fallback={<SkillItemSuspenseFallback />}>
      <SkillItemMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillItem;
