import { BoxProps, useToken } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import useExpertStatusColor from '../hooks/useExpertStatusColor';
import { ExpertDoc } from '../types/Expert';
import BlockListItem from './BlockListItem';
import Catch from './Catch';
import ExpertInfo, { ExpertInfoSuspenseFallback } from './ExpertInfo';

export type Props = {
  expertSnap: QueryDocumentSnapshot<ExpertDoc>;
} & BoxProps;

const ExpertRowMain: React.FC<Props> = ({ expertSnap, ...boxProps }: Props) => {
  const { expertId: activeExpertId } = useParams<{ expertId: string }>();

  const statusColor = useExpertStatusColor(expertSnap);
  const [styledColor] = useToken('colors', [statusColor]);

  const navigate = useNavigate();
  const onClick = useCallback(
    () => navigate(expertSnap.id),
    [expertSnap.id, navigate],
  );

  return (
    <BlockListItem
      data-intercom-target="ExpertRow"
      onClick={onClick}
      aria-pressed={expertSnap.id === activeExpertId}
      height="68px"
      boxShadow={`inset 4px 0px ${styledColor}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <ExpertInfo expertSnap={expertSnap} />
    </BlockListItem>
  );
};

export const ExpertRowSuspenseFallback: React.FC = () => (
  <BlockListItem height="68px">
    <ExpertInfoSuspenseFallback />
  </BlockListItem>
);

export const ExpertRowCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertRow: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertRowCatchFallback />}>
    <Suspense fallback={<ExpertRowSuspenseFallback />}>
      <ExpertRowMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertRow;
