import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Skeleton,
  SkeletonCircle,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { serverTimestamp, setDoc } from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import Spinner from '../../../../icons/Spinner';
import {
  InterviewCanceledBy,
  InterviewCancellationReason,
  InterviewStatus,
} from '../../../../types/Interview';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';
import CanceledAt from './CanceledAt';
import CreatedAt from './CreatedAt';
import Duration from './Duration';
import EndedAt from './EndedAt';
import StartedAt from './StartedAt';
import StartsAt from './StartsAt';

const InterviewTimeInfoMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const [isCancelling, setCancelling] = useState(false);
  const toast = useToast();
  const handleCancelClick = useCallback(async () => {
    try {
      setCancelling(true);
      await setDoc(interviewRef, {
        status: InterviewStatus.CANCELED,
        canceledBy: InterviewCanceledBy.CUSTOMER,
        cancellationReason: InterviewCancellationReason.DECLINED,
        canceledAt: serverTimestamp(),
      }, { merge: true });
    } catch (err) {
      toast({
        status: 'error',
        title: 'Failed to cancel interview',
        description: err instanceof Error ? err.message : undefined,
      });
    } finally {
      setCancelling(false);
    }
    onClose();
  }, [interviewRef, onClose, toast]);

  return (
    <VStack alignItems="stretch" spacing={4}>
      <VStack alignItems="stretch" spacing={1}>
        <HStack>
          <StartsAt flexGrow={1} />

          {interview.status === InterviewStatus.CREATED ? (
            <Button
              colorScheme="negative"
              size="sm"
              onClick={onOpen}
            >
              Cancel
            </Button>
          ) : null}

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Cancel interview
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to cancel this interview?
                  You can&apos;t undo this action afterwards.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <HStack>
                    <Button
                      variant="outline"
                      ref={cancelRef}
                      onClick={onClose}
                    >
                      No, keep it
                    </Button>

                    <Button
                      colorScheme="negative"
                      isLoading={isCancelling}
                      spinner={<Spinner />}
                      loadingText="Cancelling..."
                      onClick={handleCancelClick}
                    >
                      Yes, cancel it
                    </Button>
                  </HStack>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </HStack>
        <Duration />
      </VStack>

      <VStack alignItems="stretch" spacing={1}>
        <CreatedAt />
        <StartedAt />
        <EndedAt />
        <CanceledAt />
      </VStack>
    </VStack>
  );
};

export const InterviewTimeInfoCatchFallback: React.FC = () => null;

export const InterviewTimeInfoSuspenseFallback: React.FC = () => (
  <VStack alignItems="stretch" spacing={4}>
    <VStack alignItems="stretch" spacing={1}>
      <Skeleton h={7} w="240px" />
      <HStack spacing={2}>
        <SkeletonCircle boxSize={5} />
        <Skeleton h={5} w="212px" />
      </HStack>
    </VStack>
    <VStack alignItems="stretch" spacing={1}>
      <HStack spacing={2}>
        <SkeletonCircle boxSize={5} />
        <Skeleton h={5} w="212px" />
      </HStack>
      <HStack spacing={2}>
        <SkeletonCircle boxSize={5} />
        <Skeleton h={5} w="212px" />
      </HStack>
      <HStack spacing={2}>
        <SkeletonCircle boxSize={5} />
        <Skeleton h={5} w="212px" />
      </HStack>
    </VStack>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewTimeInfo: React.FC = () => (
  <Catch fallback={<InterviewTimeInfoCatchFallback />}>
    <Suspense fallback={<InterviewTimeInfoSuspenseFallback />}>
      <InterviewTimeInfoMain />
    </Suspense>
  </Catch>
);

export default InterviewTimeInfo;
