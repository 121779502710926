import {
  Box,
  Center,
  Container,
  Divider,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  and,
  getCountFromServer,
  limit,
  or,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFirestoreCollection, useRemoteConfigString } from 'reactfire';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import ExpertRow, { ExpertRowSuspenseFallback } from '../../../components/ExpertRow';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import AddPosition64Icon from '../../../icons/AddPosition64Icon';
import Spinner from '../../../icons/Spinner';
import { ExpertStatus, useExpertsCollectionRef } from '../../../types/Expert';
import { useOrganizationDocRef } from '../../../types/Organization';

export type Props = {
  showExternal: boolean;
};

const ExpertListMain: React.FC<Props> = ({ showExternal }) => {
  const organizationRef = useOrganizationRef();

  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const currentPage = useDeferredValue(page);
  const perPage = 20;

  const expertsCollectionRef = useExpertsCollectionRef();

  const { data: clarwisOrganizationId } = useRemoteConfigString('clarwis_organization_id');

  const clarwisOrganizationRef = useOrganizationDocRef(clarwisOrganizationId);

  const { data: expertsSnap } = useFirestoreCollection(
    showExternal
      ? query(
        expertsCollectionRef,
        or(
          and(
            where('organizationRef', '==', organizationRef),
            where('status', 'in', [ExpertStatus.ACTIVE, ExpertStatus.PAUSED]),
          ),
          and(
            where('organizationRef', '==', clarwisOrganizationRef),
            where('status', 'in', [ExpertStatus.ACTIVE]),
          ),
        ),
        orderBy('createdAt', 'desc'),
        limit(currentPage * perPage),
      )
      : query(
        expertsCollectionRef,
        where('organizationRef', '==', organizationRef),
        where('status', 'in', [ExpertStatus.ACTIVE, ExpertStatus.PAUSED]),
        orderBy('createdAt', 'desc'),
        limit(currentPage * perPage),
      ),
  );

  useEffect(
    () => {
      getCountFromServer(
        showExternal
          ? query(
            expertsCollectionRef,
            or(
              and(
                where('organizationRef', '==', organizationRef),
                where('status', 'in', [ExpertStatus.ACTIVE, ExpertStatus.PAUSED]),
              ),
              and(
                where('organizationRef', '==', clarwisOrganizationRef),
                where('status', 'in', [ExpertStatus.ACTIVE]),
              ),
            ),
          )
          : query(
            expertsCollectionRef,
            where('organizationRef', '==', organizationRef),
            where('status', 'in', [ExpertStatus.ACTIVE, ExpertStatus.PAUSED]),
          ),
      ).then(
        (v) => setTotalPages(Math.ceil(v.data().count / perPage)),
      );
    },
    [clarwisOrganizationRef, expertsCollectionRef, organizationRef, showExternal],
  );

  const el = useRef<HTMLDivElement>(null);

  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrolledToTop(e.currentTarget.scrollTop <= 0);

    const scrollBottom = e.currentTarget.scrollHeight
      - e.currentTarget.scrollTop
      - e.currentTarget.clientHeight;

    if (scrollBottom <= 0) {
      if (totalPages > currentPage) {
        setPage(currentPage + 1);
      }
    }
  }, [currentPage, totalPages]);

  if (!expertsSnap.docs.length) {
    return (
      <Center height="100%">
        <Container maxW="280px" px={0}>
          <VStack spacing={4}>
            <VStack spacing={2}>
              <Text color="cf.cntEmpty">
                <AddPosition64Icon />
              </Text>
              <Text variant="labelMedium" textAlign="center">
                No experts yet
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Center>
    );
  }

  return (
    <>
      <ScaleFade in={!scrolledToTop}>
        <Container>
          <Divider />
        </Container>
      </ScaleFade>

      <Box ref={el} pb={3} height="100%" overflow="auto" onScroll={handleScroll}>
        <Container>
          <VStack alignItems="stretch" spacing={3}>
            <BlockList variant="outline">
              {expertsSnap.docs.map((expertSnap) => (
                <ExpertRow
                  key={expertSnap.id}
                  expertSnap={expertSnap}
                />
              ))}
            </BlockList>

            {totalPages > currentPage ? (
              <Center>
                <Spinner color="cf.cntSecondary" />
              </Center>
            ) : null}
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export const ExpertListSuspenseFallback: React.FC = () => (
  <Box pb={3} height="100%" overflow="auto">
    <Container>
      <BlockList variant="outline">
        <ExpertRowSuspenseFallback />
        <ExpertRowSuspenseFallback />
        <ExpertRowSuspenseFallback />
        <ExpertRowSuspenseFallback />
        <ExpertRowSuspenseFallback />
      </BlockList>
    </Container>
  </Box>
);

export const ExpertListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertList: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertListCatchFallback />}>
    <Suspense fallback={<ExpertListSuspenseFallback />}>
      <ExpertListMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertList;
