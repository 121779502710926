import { Box, BoxProps } from '@chakra-ui/react';
import lottie, { AnimationConfigWithData, AnimationConfigWithPath } from 'lottie-web';
import React, { useEffect, useRef } from 'react';

export type Props = BoxProps & {
  options: Omit<AnimationConfigWithPath, 'container'> | Omit<AnimationConfigWithData, 'container'>;
};

const Lottie: React.FC<Props> = ({
  options,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const animation = lottie.loadAnimation({
      container: ref.current,
      ...options,
    });

    return () => {
      animation.destroy();
    };
  }, [ref, options]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box ref={ref} {...props} />
  );
};

export default Lottie;
