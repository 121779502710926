import { Button, VStack } from '@chakra-ui/react';
import { setDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import Spinner from '../icons/Spinner';
import { UserScheduleDoc, timeSlots, useUserScheduleDocRef } from '../types/UserSchedule';
import Catch from './Catch';
import ScheduleField from './ScheduleField';
import { useUserRef } from './UserRefContext';

export interface FormFields {
  schedule: UserScheduleDoc;
}

const weekDaySchema = yup.array(yup.string().oneOf([...timeSlots])).required();

const schema = yup.object().shape({
  schedule: yup
    .object()
    .shape({
      su: weekDaySchema,
      mo: weekDaySchema,
      tu: weekDaySchema,
      we: weekDaySchema,
      th: weekDaySchema,
      fr: weekDaySchema,
      sa: weekDaySchema,
    })
    .required(),
});

const ScheduleFormMain: React.FC = () => {
  const userRef = useUserRef();
  const userScheduleRef = useUserScheduleDocRef(userRef);
  const { data: userAvailabilitySnap } = useFirestoreDoc(userScheduleRef);

  const availabilityFormInitialValues = useMemo<FormFields>(
    () => {
      const userAvailabilityData = userAvailabilitySnap.data();

      if (userAvailabilitySnap.exists() && userAvailabilityData) {
        return { schedule: userAvailabilityData };
      }

      return {
        schedule: {
          su: [],
          mo: [],
          tu: [],
          we: [],
          th: [],
          fr: [],
          sa: [],
        },
      };
    },
    [userAvailabilitySnap],
  );

  const handleAvailabilityFormSubmit = useCallback(async ({ schedule }: FormFields) => {
    await setDoc(userScheduleRef, schedule);
  }, [userScheduleRef]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={availabilityFormInitialValues}
      onSubmit={handleAvailabilityFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <VStack spacing={8} alignItems="stretch" textAlign="left">
            <ScheduleField name="schedule" />

            <Button
              type="submit"
              isLoading={isSubmitting}
              spinner={<Spinner />}
              loadingText="Saving..."
            >
              Save
            </Button>
          </VStack>
        </form>
      )}
    </Formik>
  );
};

export const ScheduleFormCatchFallback: React.FC = () => null;
export const ScheduleFormSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ScheduleForm: React.FC = () => (
  <Catch fallback={<ScheduleFormCatchFallback />}>
    <Suspense fallback={<ScheduleFormSuspenseFallback />}>
      <ScheduleFormMain />
    </Suspense>
  </Catch>
);

export default ScheduleForm;
