import { Box, Center, HStack } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import Catch from './Catch';
import LocalCameraButton from './LocalCameraButton';
import { useLocalCamera } from './LocalCameraProvider';
import LocalMicrophoneButton from './LocalMicrophoneButton';
import LocalVideoPlaceholder from './LocalVideoPlaceholder';
import SettingsButton from './SettingsButton';
import { useUserRef } from './UserRefContext';
import Video from './Video';

const LocalParticipantMediaMain: React.FC = () => {
  const userRef = useUserRef();

  const { track: localCameraTrack } = useLocalCamera();

  return (
    <Box
      h={180}
      w={320}
      borderRadius="md"
      overflow="hidden"
      bg="cf.black"
      position="relative"
    >
      {localCameraTrack ? (
        <Box h="100%" w="100%">
          <Video videoTrack={localCameraTrack} />
        </Box>
      ) : (
        <Center h="100%" w="100%">
          <LocalVideoPlaceholder userRef={userRef} />
        </Center>
      )}

      <Box
        px={5}
        py={3}
        position="absolute"
        left={0}
        right={0}
        bottom={0}
      >
        <HStack spacing={5} justifyContent="center">
          <SettingsButton />
          <LocalMicrophoneButton />
          <LocalCameraButton />
        </HStack>
      </Box>
    </Box>
  );
};

const LocalParticipantMediaCatchFallback: React.FC = () => null;
const LocalParticipantMediaSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const LocalParticipantMedia: React.FC = () => (
  <Catch fallback={<LocalParticipantMediaCatchFallback />}>
    <Suspense fallback={<LocalParticipantMediaSuspenseFallback />}>
      <LocalParticipantMediaMain />
    </Suspense>
  </Catch>
);

export default LocalParticipantMedia;
