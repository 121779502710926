import {
  Box,
  Button,
  Container,
  Divider,
} from '@chakra-ui/react';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirestoreDoc } from 'reactfire';
import useCurrentTime from '../hooks/useCurrentTime';
import VideoOnIcon from '../icons/VideoOnIcon';
import { InterviewStatus } from '../types/Interview';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import { useInterviewRef } from './InterviewRefContext';

export const InterviewDetailsActionMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const navigate = useNavigate();
  const handleJoinClick = useCallback(async () => {
    navigate(`/call/${interviewSnap.id}`);
  }, [navigate, interviewSnap.id]);

  const currentTime = useCurrentTime(1000 * 60);

  const goodTime = useMemo(() => {
    const from = interview.startsAt.toMillis() - 1000 * 60 * 5;
    const to = interview.endsAt.toMillis() + 1000 * 60 * 5;
    const now = currentTime.getTime();
    return (
      from < now && now < to
    );
  }, [currentTime, interview]);

  const goodStatus = useMemo(() => (
    interview.status !== InterviewStatus.CANCELED
      && interview.status !== InterviewStatus.ENDED
  ), [interview]);

  if (!goodStatus) {
    return null;
  }

  return (
    <>
      <Divider />
      <Box py={3}>
        <Container>
          <Button
            leftIcon={<VideoOnIcon />}
            width="100%"
            onClick={handleJoinClick}
            variant={goodTime ? 'solid' : 'outline'}
          >
            Hop on call
          </Button>
        </Container>
      </Box>
    </>
  );
};

export const InterviewDetailsActionCatchFallback: React.FC = () => null;
export const InterviewDetailsActionSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsAction: React.FC = () => (
  <Catch fallback={<InterviewDetailsActionCatchFallback />}>
    <Suspense fallback={<InterviewDetailsActionSuspenseFallback />}>
      <InterviewDetailsActionMain />
    </Suspense>
  </Catch>
);

export default InterviewDetailsAction;
