import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const AlertInTriangleFilledIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 2C10.7113 2 11.3691 2.37782 11.7276 2.99226L18.7276 14.9923C19.0884 15.6108 19.091 16.3751 18.7343 16.9961C18.3776 17.6171 17.7161 18 17 18H3C2.28387 18 1.62239 17.6171 1.26572 16.9961C0.909039 16.3751 0.911605 15.6108 1.27244 14.9923L8.27244 2.99226C8.63086 2.37782 9.28867 2 10 2ZM11 6V12H9V6H11ZM11 16V14H9V16H11Z" fill="currentColor" />
  </Icon>
);

export default AlertInTriangleFilledIcon;
