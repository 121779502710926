import algolia, { SearchClient, SearchIndex } from 'algoliasearch';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUser } from 'reactfire';
import useAlgoliaGetOrganizationApiKey from '../functions/useAlgoliaGetOrganizationApiKey';

const OrganizationSearchClientContext = React.createContext<SearchClient | undefined>(undefined);

export const useCandidatesIndex = (): SearchIndex | undefined => {
  const searchClient = useContext(OrganizationSearchClientContext);
  return useMemo(() => searchClient?.initIndex('candidates'), [searchClient]);
};

export const useExpertsIndex = (): SearchIndex | undefined => {
  const searchClient = useContext(OrganizationSearchClientContext);
  return useMemo(() => searchClient?.initIndex('experts'), [searchClient]);
};

export type AlgoliaOrganizationProviderProps = {
  appId: string;
  organizationId: string;
};

export const AlgoliaOrganizationProvider: React.FC<
PropsWithChildren<AlgoliaOrganizationProviderProps>
> = (
  { children, appId, organizationId },
) => {
  const [searchClient, setSearchClient] = useState<SearchClient | undefined>();

  const getOrganizationApiKey = useAlgoliaGetOrganizationApiKey();
  const { data: user } = useUser();

  useEffect(() => {
    if (!user?.uid) {
      setSearchClient(undefined);

      return () => {};
    }

    let stopped = false;

    getOrganizationApiKey({ organizationId })
      .then(({ data: apiKey }) => {
        if (!stopped) {
          setSearchClient(algolia(appId, apiKey));
        }
      })
      .catch(() => {
        if (!stopped) {
          setSearchClient(undefined);
        }
      });

    return () => {
      stopped = true;
    };
  }, [appId, getOrganizationApiKey, organizationId, user?.uid]);

  return (
    <OrganizationSearchClientContext.Provider value={searchClient}>
      {children}
    </OrganizationSearchClientContext.Provider>
  );
};
