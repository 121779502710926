import {
  Container,
  Grid,
  GridItem,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import Availability from '../../components/Availability';
import ConnectedCalendars from '../../components/ConnectedCalendars';
import ScheduleForm from '../../components/ScheduleForm';
import { useUserRef } from '../../components/UserRefContext';
import AuthForm from './AuthForm';
import Interviewer from './Interviewer';
import PrivateForm from './PrivateForm';
import ProfileForm from './ProfileForm';

const UserSettingsPage: React.FC = () => {
  const userRef = useUserRef();

  return (
    <Grid templateRows="60px 1fr" h="100%" w="100%" minH={0}>
      <GridItem py={3}>
        <Container>
          <Heading py={1} flexGrow={1}>
            Settings
          </Heading>
        </Container>
      </GridItem>
      <GridItem overflow="auto" py={3}>
        <Container>
          <Tabs isLazy>
            <TabList>
              <Tab>Public</Tab>
              <Tab>Private</Tab>
              <Tab>Authorization</Tab>
              <Tab>Availability</Tab>
              <Tab>Interviewer</Tab>
            </TabList>

            <TabPanels>
              <TabPanel px={0}>
                <ProfileForm />
              </TabPanel>

              <TabPanel px={0}>
                <PrivateForm />
              </TabPanel>

              <TabPanel px={0}>
                <AuthForm />
              </TabPanel>

              <TabPanel px={0}>
                <VStack alignItems="stretch" spacing={4}>
                  <Availability userRef={userRef} />
                  <ConnectedCalendars />
                  <ScheduleForm />
                </VStack>
              </TabPanel>

              <TabPanel px={0}>
                <Interviewer />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </GridItem>
    </Grid>
  );
};

export default UserSettingsPage;
