import React from 'react';
import { useParams } from 'react-router';
import SkillRefProvider from '../../components/SkillRefContext';
import { useSkillDocRef } from '../../types/Skill';
import EditSkillScreen from './EditSkillScreen';

export type Params = {
  skillId: string;
  organizationId: string;
};

const OrganizationSkillEditPage: React.FC = () => {
  const { skillId } = useParams<Params>();

  if (!skillId) {
    throw new Error();
  }

  const skillRef = useSkillDocRef(skillId);

  return (
    <SkillRefProvider skillRef={skillRef} key={skillRef.path}>
      <EditSkillScreen />
    </SkillRefProvider>
  );
};

export default OrganizationSkillEditPage;
