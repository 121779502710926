import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const OtherPageIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5858 4H9V2H15C15.8282 2 16.5795 2.33688 17.1213 2.87868C17.6631 3.42048 18 4.17185 18 5V11H16V5.41421L9.20711 12.2071L7.79289 10.7929L14.5858 4ZM5 7C4.44772 7 4 7.44772 4 8V15C4 15.5523 4.44772 16 5 16H12C12.5523 16 13 15.5523 13 15V13H15V15C15 16.6569 13.6569 18 12 18H5C3.34315 18 2 16.6569 2 15V8C2 6.34315 3.34315 5 5 5H7V7H5Z" fill="currentColor" />
  </Icon>
);

export default OtherPageIcon;
