import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MessageIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 3H18V13C18 14.6569 16.6569 16 15 16H8.55397C8.37272 16 8.19489 16.0493 8.03947 16.1425L5.02899 17.9488C3.69595 18.7486 2 17.7884 2 16.2338V3ZM4 5V16.2338L7.01048 14.4275C7.47673 14.1478 8.01024 14 8.55397 14H15C15.5523 14 16 13.5523 16 13V5H4Z" fill="currentColor" />
  </Icon>
);

export default MessageIcon;
