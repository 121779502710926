const getCroppedImage = async (blob: Blob, circle = true): Promise<Blob> => {
  let resolve: (value: Blob) => unknown;
  let reject: () => unknown;
  const promise = new Promise<Blob>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  const img = new Image();
  img.src = URL.createObjectURL(blob);

  img.addEventListener('load', () => {
    const size = Math.min(img.naturalWidth, img.naturalHeight);
    const x = Math.floor((size - img.naturalWidth) / 2);
    const y = Math.floor((size - img.naturalHeight) / 2);

    const myCanvas = document.createElement('canvas');
    myCanvas.width = size;
    myCanvas.height = size;
    const ctx = myCanvas.getContext('2d');
    if (!ctx) {
      reject();
      return;
    }

    ctx.drawImage(img, x, y);

    if (circle) {
      ctx.globalCompositeOperation = 'destination-in';
      ctx.fillStyle = '#000';
      ctx.beginPath();
      ctx.arc(
        size * 0.5, // x
        size * 0.5, // y
        size * 0.5, // radius
        0, // start angle
        2 * Math.PI, // end angle
      );
      ctx.fill();
      ctx.globalCompositeOperation = 'source-over';
    }

    ctx.save();

    myCanvas.toBlob((result) => {
      if (!result) {
        reject();
        return;
      }

      resolve(result);
    }, 'image/webp');
  });

  return promise;
};

export default getCroppedImage;
