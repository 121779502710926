import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  WithFieldValue,
  deleteField,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import ExpertsSelectorField from '../../../components/ExpertsSelectorField';
import ExternalExpertTiersField from '../../../components/ExternalExpertTiersField';
import InternalExpertTiersField from '../../../components/InternalExpertTiersField';
import Loader from '../../../components/Loader';
import PhoneNumberField from '../../../components/PhoneNumberField';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import StageRefField from '../../../components/StageRefField';
import TextField from '../../../components/TextField';
import Spinner from '../../../icons/Spinner';
import { CandidateDoc } from '../../../types/Candidate';
import { ExpertDoc, ExpertTier } from '../../../types/Expert';
import { SkillDoc } from '../../../types/Skill';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { StageDoc } from '../../../types/Stage';
import Header from './Header';

export interface FormFields {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  skillRefs: DocumentReference<SkillDoc>[];
  requiredExpertRefs: DocumentReference<ExpertDoc>[];
  optionalExpertRefs: DocumentReference<ExpertDoc>[];
  customerUrl: string;
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  stageRef: DocumentReference<StageDoc> | null;
}

const schema = yup.object().shape({
  email: yup.string().email().label('Candidate email').required(),
  phoneNumber: yup.string().label('Candidate phone'),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  skillRefs: yup.array().label('Skills').required(),
  requiredExpertRefs: yup.array().label('Required experts').required(),
  optionalExpertRefs: yup.array().label('Optional experts').required(),
  customerUrl: yup.string().url().label('RMS profile'),
  internalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  externalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  stageRef: yup.object().label('Stage').nullable(),
});

const EditCandidateScreenMain: React.FC = () => {
  const candidateRef = useCandidateRef();

  const { data: candidateSnap } = useFirestoreDoc<CandidateDoc>(candidateRef);

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  const navigate = useNavigate();

  const handleFormSubmit = useCallback(async ({
    email,
    firstName,
    lastName,
    skillRefs,
    requiredExpertRefs,
    optionalExpertRefs,
    phoneNumber,
    customerUrl,
    internalExpertTiers,
    externalExpertTiers,
    stageRef,
  }: FormFields) => {
    const requestData: Partial<WithFieldValue<CandidateDoc>> = {
      email,
      firstName,
      lastName,
      internalExpertTiers,
      externalExpertTiers,
      skillRefs,
      requiredExpertRefs,
      optionalExpertRefs,
      phoneNumber: phoneNumber !== '' ? phoneNumber : deleteField(),
      customerUrl: customerUrl !== '' ? customerUrl : deleteField(),
      stageRef: stageRef !== null ? stageRef : deleteField(),
    };

    await setDoc(
      candidateRef,
      requestData,
      { merge: true },
    );

    navigate(`../${candidateRef.id}`);
  }, [candidateRef, navigate]);

  const handleCancelClick = useCallback(() => {
    navigate(`../${candidateRef.id}`);
  }, [candidateRef.id, navigate]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: candidate.firstName || '',
        lastName: candidate.lastName || '',
        email: candidate.email || '',
        phoneNumber: candidate.phoneNumber || '',
        skillRefs: candidate.skillRefs || [],
        requiredExpertRefs: candidate.requiredExpertRefs || [],
        optionalExpertRefs: candidate.optionalExpertRefs || [],
        customerUrl: candidate.customerUrl || '',
        internalExpertTiers: candidate.internalExpertTiers || [],
        externalExpertTiers: candidate.externalExpertTiers || [],
        stageRef: candidate.stageRef || null,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
            data-intercom-target="OrganizationCreateCandidatePage"
          >
            <GridItem borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Header />
            </GridItem>

            <GridItem py={3} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={3}>
                  <HStack spacing={5} alignItems="start" className="candidate-name">
                    <TextField isRequired label="First name" name="firstName" placeholder="John" data-intercom-target="OrganizationCreateCandidateFormFirstName" />
                    <TextField isRequired label="Last name" name="lastName" placeholder="Doe" data-intercom-target="OrganizationCreateCandidateFormLastName" />
                  </HStack>

                  <TextField isRequired label="Candidate email" name="email" type="email" placeholder="candidate@email.com" data-intercom-target="OrganizationCreateCandidateFormEmail" />

                  <HStack spacing={5} alignItems="start">
                    <PhoneNumberField label="Candidate phone (optional)" name="phoneNumber" />
                    <TextField label="RMS profile (optional)" name="customerUrl" type="url" placeholder="https://example.com/profile/123" />
                  </HStack>

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                    data-intercom-target="OrganizationCreateCandidateFormSkills"
                    isRequired
                  />

                  <ExpertsSelectorField
                    name="requiredExpertRefs"
                    label="Required experts"
                  />

                  <HStack spacing={5}>
                    <InternalExpertTiersField
                      label="Expert ranks"
                      name="internalExpertTiers"
                    />

                    <ExternalExpertTiersField
                      label="Clarwis Expert ranks"
                      name="externalExpertTiers"
                    />
                  </HStack>

                  <ExpertsSelectorField
                    name="optionalExpertRefs"
                    label="Optional experts"
                  />

                  <StageRefField
                    name="stageRef"
                    label="Stage"
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <SimpleGrid columns={2} gap={4}>
                  <Button
                    variant="outline"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="solid"
                    type="submit"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                    spinner={<Spinner />}
                    loadingText="Processing..."
                    className="cart-checkout"
                    data-intercom-target="OrganizationCreateCandidateFormSubmit"
                  >
                    Save
                  </Button>
                </SimpleGrid>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const EditCandidateScreenCatchFallback: React.FC = () => null;
export const EditCandidateScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const EditCandidateScreen: React.FC = () => (
  <Catch fallback={<EditCandidateScreenCatchFallback />}>
    <Suspense fallback={<EditCandidateScreenSuspenseFallback />}>
      <EditCandidateScreenMain />
    </Suspense>
  </Catch>
);

export default EditCandidateScreen;
