import {
  Box,
  HStack,
  Heading,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useFirestoreDoc } from 'reactfire';
import useInterviewRole, { InterviewRole } from '../hooks/useInterviewRole';
import { InterviewScheduleItemDoc } from '../types/InterviewScheduleItem';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import Catch from './Catch';
import SkillLogo from './SkillLogo';

export type Props = {
  currentQuestion: number;
  scheduleItemSnap: QueryDocumentSnapshot<InterviewScheduleItemDoc>;
};

const CallCurrentQuestionMain: React.FC<Props> = ({ currentQuestion, scheduleItemSnap }) => {
  const { data: questionSnap } = useFirestoreDoc(scheduleItemSnap.data().questionRef);

  const role = useInterviewRole();

  if (!questionSnap.exists()) {
    throw new SnapNotFoundError(questionSnap);
  }

  const question = useMemo(() => questionSnap.data(), [questionSnap]);

  return (
    <HStack spacing={4} alignItems="start" flexGrow={1} userSelect="none">
      <VStack spacing={4} alignItems="start" flexShrink={0} minW="28px">
        <Text fontSize="sm" lineHeight="tall" color="cf.cntSecondary" fontWeight="medium">
          {_.padStart(String(currentQuestion + 1), 2, '0')}
        </Text>

        <SkillLogo skillRef={scheduleItemSnap.data().skillRef} />
      </VStack>

      <Box minW="0px" flexGrow={1}>
        <VStack spacing={4} alignItems="stretch">
          <Heading>
            {question.title}
          </Heading>

          <Box minW="0px" maxW="100%">
            <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
              {question.content}
            </ReactMarkdown>
          </Box>

          {role !== InterviewRole.INTERVIEWEE ? (
            <Box minW="0px" maxW="100%">
              <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
                {question.hint || ''}
              </ReactMarkdown>
            </Box>
          ) : null}
        </VStack>
      </Box>

    </HStack>
  );
};

export const CallCurrentQuestionSuspenseFallback: React.FC = () => (
  <Box px={5} py={4} flexGrow={1} flexShrink={1} overflow="auto">
    <HStack spacing={4} alignItems="start">
      <Skeleton h="28px" w="20px" />
      <Box minW="0px" flexGrow={1}>
        <VStack spacing={4} alignItems="start">
          <Skeleton h="28px" w="100%" />

          <SkeletonText w="100%" noOfLines={10} spacing={4} />
        </VStack>
      </Box>
    </HStack>
  </Box>
);

export const CallCurrentQuestionCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallCurrentQuestion: React.FC<Props> = (props) => (
  <Catch fallback={<CallCurrentQuestionCatchFallback />}>
    <Suspense fallback={<CallCurrentQuestionSuspenseFallback />}>
      <CallCurrentQuestionMain {...props} />
    </Suspense>
  </Catch>
);

export default CallCurrentQuestion;
