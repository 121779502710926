import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import Editor, { EditorProps } from '@monaco-editor/react';
import { useField } from 'formik';
import React, { useCallback } from 'react';

export interface Props extends EditorProps {
  label: string;
  name: string;
}

const EditorField: React.FC<Props> = ({
  label, name, ...props
}: Props) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = useCallback(
    (value: string | undefined) => {
      helpers.setValue(value || '');
    },
    [helpers],
  );

  return (
    <FormControl
      isInvalid={!!meta.error}
      onKeyUp={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <FormLabel>{label}</FormLabel>

      <Editor
        value={field.value}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default EditorField;
