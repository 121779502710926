import { Box, Tooltip } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import { UserDoc } from '../types/User';
import Catch from './Catch';
import { StorageAvatarCatchFallback, StorageAvatarSuspenseFallback } from './StorageAvatar';
import UserAvatar from './UserAvatar';

export interface Props {
  userRef: DocumentReference<UserDoc>;
}

export const GhostParticipantMain: React.FC<Props> = ({ userRef }: Props) => {
  const { data: userSnap } = useFirestoreDoc(userRef);

  if (!userSnap.exists()) {
    throw new SnapNotFoundError(userSnap);
  }

  const profile = useMemo(() => userSnap.data(), [userSnap]);

  return (
    <Tooltip label={`${profile.firstName} ${profile.lastName}`} hasArrow placement="top">
      <Box>
        <UserAvatar userRef={userRef} />
      </Box>
    </Tooltip>
  );
};

export const GhostParticipantSuspenseFallback: React.FC = () => (<StorageAvatarSuspenseFallback />);

export const GhostParticipantCatchFallback: React.FC = () => (<StorageAvatarCatchFallback />);

/* eslint-disable react/jsx-props-no-spreading */
const GhostParticipant: React.FC<Props> = (props) => (
  <Catch fallback={<GhostParticipantCatchFallback />}>
    <Suspense fallback={<GhostParticipantSuspenseFallback />}>
      <GhostParticipantMain {...props} />
    </Suspense>
  </Catch>
);

export default GhostParticipant;
