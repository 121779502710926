import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { DocumentReference, doc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';
import { useField } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { useStorage } from 'reactfire';
import getCroppedImage from '../helpers/getCroppedImage';
import BriefcaseIcon from '../icons/BriefcaseIcon';
import EditIcon from '../icons/EditIcon';
import TrashIcon from '../icons/TrashIcon';
import UploadIcon from '../icons/UploadIcon';
import UserIcon from '../icons/UserIcon';
import { AvatarDoc, useAvatarsCollectionRef } from '../types/Avatar';
import PaddingBlock from './PaddingBlock';
import StorageAvatar from './StorageAvatar';

export type AvatarFieldProps = {
  name: string;
  placeholder?: string;
  label: string;
  logo?: boolean;
};

const AvatarField: React.FC<AvatarFieldProps> = ({
  name,
  placeholder = 'Upload your photo',
  label,
  logo = false,
}) => {
  const [field, meta, helpers] = useField<DocumentReference<AvatarDoc> | null>(name);
  const storage = useStorage();
  const avatarsCollectionRef = useAvatarsCollectionRef();
  const inputFile = useRef<HTMLInputElement>(null);

  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const handleUploadButtonClick = useCallback(() => {
    if (inputFile.current === null) {
      return;
    }

    inputFile.current.click();
  }, [inputFile]);

  const handleRemoveButtonClick = useCallback(() => {
    helpers.setValue(null);
  }, [helpers]);

  const handleImageChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }
    const file = e.target.files[0];

    const data = await getCroppedImage(file);

    const avatarRef = doc(avatarsCollectionRef);

    const dest = ref(storage, `avatars/${avatarRef.id}.webp`);
    const uploadTask = uploadBytesResumable(dest, data, { contentType: 'image/webp' });

    const stop1 = uploadTask.on(
      'state_changed',
      {
        next: (snap) => {
          setUploadProgress(snap.bytesTransferred / snap.totalBytes);
        },
        complete: async () => {
          stop1();
          const stop2 = onSnapshot(avatarRef, (snap) => {
            if (snap.exists()) {
              stop2();
              helpers.setValue(avatarRef);
              setUploadProgress(null);
            }
          });
        },
      },
    );
  }, [avatarsCollectionRef, helpers, storage]);

  return (
    <FormControl isInvalid={!!meta.error}>
      <FormLabel>{label}</FormLabel>

      <PaddingBlock>
        {!field.value ? (
          <HStack spacing={3}>
            <StorageAvatar
              icon={
                // eslint-disable-next-line no-nested-ternary
                uploadProgress !== null ? (
                  <CircularProgress value={uploadProgress * 100} size={5} color="cf.cntAccent" />
                ) : (
                  logo ? <BriefcaseIcon /> : <UserIcon />
                )
              }
            />

            <input
              type="file"
              accept="image/*"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />

            <Text fontWeight="medium" lineHeight="short">
              {placeholder}
            </Text>

            <Spacer />

            <Button variant="outline" leftIcon={<UploadIcon />} onClick={handleUploadButtonClick}>Upload</Button>
          </HStack>
        ) : (
          <HStack spacing={3}>
            <Box>
              <StorageAvatar
                avatarRef={field.value}
                icon={<CircularProgress isIndeterminate size={5} color="cf.cntAccent" />}
              />
            </Box>

            <Spacer />

            <input
              type="file"
              accept="image/*"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <Button variant="outline" leftIcon={<EditIcon />} onClick={handleUploadButtonClick}>Change</Button>

            <IconButton colorScheme="negative" icon={<TrashIcon />} onClick={handleRemoveButtonClick} aria-label="Remove" />
          </HStack>
        )}
      </PaddingBlock>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

AvatarField.defaultProps = {
  placeholder: undefined,
  logo: false,
};

export default AvatarField;
