import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc } from './Organization';
import { TagDoc } from './Tag';

export type QuestionDoc = {
  title: string;
  content: string;
  hint: string;
  codeEditor: {
    value: string;
    language: string;
  };
  duration: number;
  answerHint5: string;
  answerHint4: string;
  answerHint3: string;
  answerHint2: string;
  answerHint1: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  revision: number;
  tagRefs: DocumentReference<TagDoc>[];
  organizationRef: DocumentReference<OrganizationDoc>;
};

export const isQuestionDoc = (
  data: DocumentData,
): data is QuestionDoc => true;

export const isQuestionRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<QuestionDoc> => ref.parent.id === 'questions';

export const isQuestionSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<QuestionDoc> => isQuestionRef(snap.ref);

export const useQuestionsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'questions') as CollectionReference<QuestionDoc>, [firestore]);
};

export const useQuestionDocRef = (docId: string) => {
  const collectionRef = useQuestionsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
