import {
  BoxProps,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import usePluralized from '../hooks/usePluralized';
import { SkillDoc } from '../types/Skill';
import AssetImage from './AssetImage';
import Catch from './Catch';

export type Props = {
  skillSnap: QueryDocumentSnapshot<SkillDoc>;
} & BoxProps;

const SkillInfoMain: React.FC<Props> = ({ skillSnap, ...boxProps }) => {
  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  const formatMinutes = useCallback(
    (v: number) => new Intl.NumberFormat('en-US', { style: 'unit', unitDisplay: 'long', unit: 'minute' }).format(v),
    [],
  );

  const questions = usePluralized(skill.questionRefs.length, 'question', 'questions');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HStack spacing={3} flexGrow={1} {...boxProps}>
      <AssetImage
        assetRef={skill.logoRef}
        boxSize={11}
      />

      <VStack alignItems="stretch" flexGrow={1} spacing={1} minW={0}>
        <Text
          fontWeight="semibold"
          lineHeight="short"
          flexGrow={1}
          flexShrink={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {skill.name}
        </Text>

        <Text
          variant="labelSmall"
          pt="3px"
          pb="1px"
          color="cf.cntSecondary"
          flexGrow={1}
          flexShrink={1}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {questions}
          {' '}
          &middot;
          {' '}
          {formatMinutes(skill.duration)}
        </Text>
      </VStack>
    </HStack>
  );
};

export const SkillInfoCatchFallback: React.FC = () => null;

export const SkillInfoSuspenseFallback: React.FC = () => (
  <HStack spacing={3} flexGrow={1}>
    <SkeletonCircle boxSize={11} />
    <VStack alignItems="stretch" flexGrow={1} spacing={1}>
      <Skeleton h={5} w="100px" />
      <Skeleton h={5} w="100px" />
    </VStack>
  </HStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const SkillInfo: React.FC<Props> = (props) => (
  <Catch fallback={<SkillInfoCatchFallback />}>
    <Suspense fallback={<SkillInfoSuspenseFallback />}>
      <SkillInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillInfo;
