import { Checkbox, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo, useState } from 'react';
import { useFirestoreDoc } from 'reactfire';
import CallLobbyJoinButton from '../../../../components/CallLobbyJoinButton';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import PaddingBlock from '../../../../components/PaddingBlock';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const CallActionMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);
  const [recordingConcent, setRecordingConsent] = useState<boolean>(false);
  const [legalConsent, setLegalConsent] = useState<boolean>(false);

  return (
    <PaddingBlock>
      <VStack alignItems="stretch" spacing={3}>
        <Checkbox
          onChange={(e) => setRecordingConsent(e.target.checked)}
          checked={recordingConcent}
          flexGrow={1}
        >
          I give my consent to record this interview
        </Checkbox>

        <Checkbox
          onChange={(e) => setLegalConsent(e.target.checked)}
          checked={legalConsent}
          flexGrow={1}
        >
          I understand that asking questions about a candidate&apos;s
          address, citizenship, national origin, race, color, religion,
          creed, sex, orientation, gender identity,
          disabilities, age, height, weight, family status, education,
          arrest record, convictions, financial status,
          marital status, medical information, military service, name,
          organizations, photographs
          could open me up to a discrimination lawsuit.
        </Checkbox>

        <CallLobbyJoinButton
          isDisabled={interview.status === 'ENDED' || !recordingConcent || !legalConsent}
        />
      </VStack>
    </PaddingBlock>
  );
};

const CallActionCatchFallback: React.FC = () => null;
const CallActionSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CallAction: React.FC = () => (
  <Catch fallback={<CallActionCatchFallback />}>
    <Suspense fallback={<CallActionSuspenseFallback />}>
      <CallActionMain />
    </Suspense>
  </Catch>
);

export default CallAction;
