import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const PlusIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9 9V3H11V9H17V11H11V17H9V11H3V9H9Z" fill="currentColor" />
  </Icon>
);

export default PlusIcon;
