import {
  Box,
  Center,
  Container,
  Text,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import StageRow from '../../../components/StageRow';
import AddPosition64Icon from '../../../icons/AddPosition64Icon';
import { useStagesCollectionRef } from '../../../types/Stage';

const StagesGraphMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const stagesCollectionRef = useStagesCollectionRef();

  const { data: stagesSnap } = useFirestoreCollection(
    query(
      stagesCollectionRef,
      where('organizationRef', '==', organizationRef),
      orderBy('createdAt', 'desc'),
    ),
  );

  if (!stagesSnap.docs.length) {
    return (
      <Center height="100%">
        <Container maxW="280px" px={0}>
          <VStack spacing={4}>
            <VStack spacing={2}>
              <Text color="cf.cntEmpty">
                <AddPosition64Icon />
              </Text>
              <Text variant="labelMedium" textAlign="center">
                No stages yet
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Center>
    );
  }

  return (
    <Box pb={3} height="100%" overflow="auto">
      <Container>
        <VStack alignItems="stretch" spacing={3}>
          <BlockList variant="outline">
            {stagesSnap.docs.map((stageSnap) => (
              <StageRow
                key={stageSnap.id}
                stageSnap={stageSnap}
              />
            ))}
          </BlockList>
        </VStack>
      </Container>
    </Box>
  );
};

export const StagesGraphSuspenseFallback: React.FC = () => null;

export const StagesGraphCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StagesGraph: React.FC = () => (
  <Catch fallback={<StagesGraphCatchFallback />}>
    <Suspense fallback={<StagesGraphSuspenseFallback />}>
      <StagesGraphMain />
    </Suspense>
  </Catch>
);

export default StagesGraph;
