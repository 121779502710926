import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  webhookId: string;
};

export type ResponseData = string;

const useApiRemoveWebhook = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'api-removewebhook'),
    [functions],
  );
};

export default useApiRemoveWebhook;
