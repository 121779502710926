import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  SimpleGrid,
} from '@chakra-ui/react';
import { DocumentReference, serverTimestamp, setDoc } from 'firebase/firestore';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import Spinner from '../../icons/Spinner';
import { QuestionDoc } from '../../types/Question';
import { TagDoc } from '../../types/Tag';
import EditorField from '../EditorField';
import MarkdownField from '../MarkdownField';
import { useOrganizationRef } from '../OrganizationRefContext';
import { useQuestionRef } from '../QuestionRefContext';
import SelectField from '../SelectField';
import TextareaField from '../TextareaField';
import TextField from '../TextField';

export type Props = Omit<ModalProps, 'children'> & {
  onCreate: (ref: DocumentReference<QuestionDoc>) => unknown;
};

export interface FormFields {
  title: string;
  content: string;
  hint: string;
  duration: number;
  answerHint5: string;
  answerHint4: string;
  answerHint3: string;
  answerHint2: string;
  answerHint1: string;
  tagRefs: DocumentReference<TagDoc>[];
  codeEditor: {
    value: string;
    language: string;
  }
}

const languageOptions = {
  plaintext: 'Plain Text',
  abap: 'ABAP',
  apex: 'Apex',
  azcli: 'Azure CLI',
  bat: 'Batch',
  bicep: 'Bicep',
  cameligo: 'Cameligo',
  clojure: 'Clojure',
  coffeescript: 'CoffeeScript',
  c: 'C',
  cpp: 'C++',
  csharp: 'C#',
  csp: 'CSP',
  css: 'CSS',
  cypher: 'Cypher',
  dart: 'Dart',
  dockerfile: 'Dockerfile',
  ecl: 'ECL',
  elixir: 'Elixir',
  flow9: 'Flow9',
  fsharp: 'F#',
  freemarker2: 'FreeMarker2',
  go: 'Go',
  graphql: 'GraphQL',
  handlebars: 'Handlebars',
  html: 'HTML',
  ini: 'Ini',
  pug: 'Jade',
  java: 'Java',
  javascript: 'JavaScript',
  json: 'JSON',
  julia: 'Julia',
  kotlin: 'Kotlin',
  less: 'Less',
  lexon: 'Lexon',
  lua: 'Lua',
  liquid: 'Liquid',
  m3: 'Modula-3',
  markdown: 'Markdown',
  mips: 'MIPS',
  msdax: 'MSDAX',
  mysql: 'MySQL',
  'objective-c': 'Objective-C',
  pascal: 'Pascal',
  pascaligo: 'Pascaligo',
  perl: 'Perl',
  pgsql: 'PostgreSQL',
  php: 'PHP',
  pla: 'PLA',
  postiats: 'Postiats',
  powerquery: 'Power Query',
  powershell: 'PowerShell',
  proto: 'Protocol Buffers',
  python: 'Python',
  qsharp: 'Q#',
  r: 'R',
  razor: 'Razor',
  redis: 'redis',
  redshift: 'Redshift',
  restructuredtext: 'reStructuredText',
  ruby: 'Ruby',
  rust: 'Rust',
  sb: 'Small Basic',
  scala: 'Scala / Dotty',
  scheme: 'Scheme',
  scss: 'Sass',
  shell: 'Shell',
  sol: 'Solidity',
  aes: 'Sophia',
  sparql: 'SPARQL',
  sql: 'SQL',
  st: 'StructuredText',
  swift: 'Swift',
  systemverilog: 'SystemVerilog',
  tcl: 'Tcl/Tk',
  hcl: 'Terraform',
  twig: 'Twig',
  typescript: 'TypeScript',
  verilog: 'Verilog',
  vb: 'Visual Basic',
  xml: 'XML',
  yaml: 'YAML',
};

const schema = yup.object().shape({
  title: yup.string().label('Title').required(),
  content: yup.string().label('Content').required(),
  hint: yup.string().label('Hint'),
  duration: yup.number().label('Duration').required(),
  answerHint5: yup.string().label('Answer hint 5').required(),
  answerHint4: yup.string().label('Answer hint 4').required(),
  answerHint3: yup.string().label('Answer hint 3').required(),
  answerHint2: yup.string().label('Answer hint 2').required(),
  answerHint1: yup.string().label('Answer hint 1').required(),
  codeEditor: yup.object().shape({
    value: yup.string().label('Code'),
    language: yup.string().label('Language').required(),
  }),
});

const CreateQuestionModal: React.FC<Props> = ({
  onClose,
  onCreate,
  ...modalProps
}) => {
  const questionRef = useQuestionRef();
  const organizationRef = useOrganizationRef();

  const handleFormSubmit = useCallback(
    async ({
      title,
      content,
      hint,
      duration,
      answerHint5,
      answerHint4,
      answerHint3,
      answerHint2,
      answerHint1,
      tagRefs,
      codeEditor,
    }: FormFields) => {
      await setDoc(questionRef, {
        title,
        content,
        hint,
        codeEditor,
        duration,
        answerHint5,
        answerHint4,
        answerHint3,
        answerHint2,
        answerHint1,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        tagRefs,
        revision: 0,
        organizationRef,
      });

      onCreate(questionRef);
    },
    [onCreate, organizationRef, questionRef],
  );

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        title: '',
        content: '',
        hint: '',
        duration: 3,
        answerHint5: 'Excellent',
        answerHint4: 'Good',
        answerHint3: 'Moderate',
        answerHint2: 'Bad',
        answerHint1: 'Terrible',
        tagRefs: [],
        codeEditor: {
          value: '',
          language: 'plaintext',
        },
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        values,
      }) => (
        <Modal
          onClose={onClose}
          size="full"
          trapFocus={false}
            // eslint-disable-next-line react/jsx-props-no-spreading
          {...modalProps}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody py={3} px={5}>
              <form
                style={{ height: '100%' }}
                noValidate
                onSubmit={(...props) => {
                  setValidateAll(true);
                  return handleSubmit(...props);
                }}
              >
                <TextField isRequired label="Title" name="title" />
                <MarkdownField isRequired label="Content" name="content" />
                <MarkdownField isRequired label="Hint" name="hint" />
                <TextField type="number" isRequired label="Duration" name="duration" />
                <SimpleGrid columns={5} columnGap={5}>
                  <TextareaField isRequired label="Excellent level feedback" name="answerHint5" />
                  <TextareaField isRequired label="Good level feedback" name="answerHint4" />
                  <TextareaField isRequired label="Moderate level feedback" name="answerHint3" />
                  <TextareaField isRequired label="Bad level feedback" name="answerHint2" />
                  <TextareaField isRequired label="Terrible level feedback" name="answerHint1" />
                </SimpleGrid>

                <SelectField
                  name="codeEditor.language"
                  label="Language"
                  options={languageOptions}
                />
                <EditorField
                  name="codeEditor.value"
                  label="Code"
                  language={values.codeEditor.language}
                  height="300px"
                />
              </form>
            </ModalBody>

            <ModalFooter py={3} px={5}>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isDisabled={!isValid}
                isLoading={isSubmitting}
                spinner={<Spinner />}
                loadingText="Creating..."
                onClick={() => handleSubmit()}
              >
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  );
};

export default CreateQuestionModal;
