import { Text, VStack } from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import PaddingBlock from '../../components/PaddingBlock';
import { useUserRef } from '../../components/UserRefContext';
import { ExpertStatus, useExpertsCollectionRef } from '../../types/Expert';
import { useMembersCollectionRef } from '../../types/Member';
import ExpertForm, { FormFields } from './ExpertForm';

export type Props = {
  onPrevious: () => unknown;
  onNext: () => unknown;
};

const ExpertsScreenMain: React.FC<Props> = ({ onPrevious, onNext }) => {
  const organizationRef = useOrganizationRef();
  const userRef = useUserRef();
  const expertsCollectionRef = useExpertsCollectionRef();

  const { data: expertsSnap } = useFirestoreCollection(
    query(
      expertsCollectionRef,
      where('organizationRef', '==', organizationRef),
      where('status', 'in', [ExpertStatus.ACTIVE, ExpertStatus.PAUSED]),
      orderBy('createdAt', 'desc'),
      limit(10),
    ),
  );

  const [expertRef, setExpertRef] = useState(doc(expertsCollectionRef));

  const membersCollectionRef = useMembersCollectionRef(organizationRef);
  const memberRef = useMemo(
    () => doc(membersCollectionRef, userRef.id),
    [membersCollectionRef, userRef.id],
  );

  const onSubmit = useCallback(
    async ({
      email,
      firstName,
      lastName,
      skillRefs,
      tier,
    }: FormFields) => {
      await setDoc(
        expertRef,
        {
          email,
          firstName,
          lastName,
          organizationRef,
          skillRefs,
          tier,
          createdAt: serverTimestamp(),
          assigneeRefs: [memberRef],
          rating: 0.5,
          status: ExpertStatus.PAUSED,
          _v: 3,
        },
      );

      setExpertRef(doc(expertsCollectionRef));
    },
    [expertRef, expertsCollectionRef, memberRef, organizationRef],
  );

  return (
    <VStack spacing={8} alignItems="stretch" textAlign="left">
      <PaddingBlock>
        <Text>
          Now, let&apos;s bring your internal technical experts into the fold to enhance your
          interviewing process. In this step, you can seamlessly invite these experts to join our
          platform and play a pivotal role in evaluating candidates. We&apos;ll send them tailored
          invitation emails and ask for their availability, just as we did with you. Our advanced
          matching algorithm takes into account various parameters, including expertise, skills,
          experience, and availability, to ensure the perfect pairing between experts and
          candidates. This meticulous approach guarantees a top-notch interviewing experience,
          where each candidate is assessed by the most suitable technical expert. Let&apos;s
          connect your experts and candidates for an interview process that&apos;s both efficient
          and effective!
        </Text>
      </PaddingBlock>

      <ExpertForm
        key={expertRef.id}
        onPrevious={onPrevious}
        onNext={onNext}
        onSubmit={onSubmit}
        expertsSnap={expertsSnap}
      />
    </VStack>
  );
};

export const ExpertsScreenCatchFallback: React.FC = () => null;
export const ExpertsScreenSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertsScreen: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertsScreenCatchFallback />}>
    <Suspense fallback={<ExpertsScreenSuspenseFallback />}>
      <ExpertsScreenMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertsScreen;
