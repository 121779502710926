import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

export type NavigateWithNextProps = NavigateProps;

const NavigateWithNext: React.FC<NavigateWithNextProps> = ({ to, ...props }) => {
  const location = useLocation();

  if (location.pathname === '/') {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Navigate to={to} {...props} />
    );
  }

  if (typeof to === 'string') {
    const searchParams = new URLSearchParams();
    searchParams.set('next', location.pathname);

    return (
    // eslint-disable-next-line react/jsx-props-no-spreading
      <Navigate to={{ pathname: to, search: searchParams.toString() }} {...props} />
    );
  }

  const searchParams = new URLSearchParams(to.search);
  searchParams.set('next', location.pathname);

  return (
  // eslint-disable-next-line react/jsx-props-no-spreading
    <Navigate to={{ ...to, search: searchParams.toString() }} {...props} />
  );
};

export default NavigateWithNext;
