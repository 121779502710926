import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ArrowDownIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.88473 13.1273L2.304 7.71802L3.69599 6.28192L9.28737 11.7016L9.29289 11.7071C9.68341 12.0976 10.3166 12.0976 10.7071 11.7071L10.7126 11.7016L16.304 6.28192L17.696 7.71802L12.1152 13.1274C10.9452 14.2908 9.05476 14.2908 7.88473 13.1273Z" fill="currentColor" />
  </Icon>
);

export default ArrowDownIcon;
