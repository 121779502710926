import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QueryDocumentSnapshot,
  collection,
  collectionGroup,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc, isOrganizationRef } from './Organization';

export enum MemberRole {
  OWNER = 'OWNER',
}

export type MemberDoc = {
  role: MemberRole;
  _v: 1;
};

export const isMemberDoc = (
  data: DocumentData,
): data is MemberDoc => true;

export const isMemberRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<MemberDoc> => ref.parent.id === 'members' && ref.parent.parent !== null && isOrganizationRef(ref.parent.parent);

function isMemberSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<
MemberDoc
>;
function isMemberSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<
MemberDoc
>;
function isMemberSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<
MemberDoc
> | QueryDocumentSnapshot<
MemberDoc
> {
  return isMemberRef(snap.ref);
}

export { isMemberSnap };

export const useMembersCollectionRef = (
  organizationRef: DocumentReference<OrganizationDoc>,
) => useMemo(() => collection(organizationRef, 'members') as CollectionReference<MemberDoc>, [organizationRef]);

export const useMembersCollectionGroupRef = () => {
  const firestore = useFirestore();

  return useMemo(
    () => collectionGroup(firestore, 'members') as Query<MemberDoc>,
    [firestore],
  );
};

export const useMemberDocRef = (
  organizationRef: DocumentReference<OrganizationDoc>,
  docId: string,
) => useMemo(() => doc(collection(organizationRef, 'members'), docId) as DocumentReference<MemberDoc>, [docId, organizationRef]);

export const useMemberOrganizationRef = (
  memberRef: DocumentReference<MemberDoc>,
) => useMemo(
  () => {
    if (!memberRef.parent.parent) {
      throw new Error();
    }

    return memberRef.parent.parent as DocumentReference<OrganizationDoc>;
  },
  [memberRef.parent.parent],
);

export const getMemberOrganizationRef = (
  memberRef: DocumentReference<MemberDoc>,
) => {
  if (!memberRef.parent.parent) {
    throw new Error();
  }

  return memberRef.parent.parent as DocumentReference<OrganizationDoc>;
};
