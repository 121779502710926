import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ArrowUpIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.1152 6.8726L17.696 12.282L16.304 13.718L10.7126 8.29841L10.7071 8.29289C10.3166 7.90237 9.68341 7.90237 9.29289 8.29289L9.28737 8.29841L3.69599 13.718L2.304 12.282L7.88474 6.87263C9.05476 5.70913 10.9452 5.70912 12.1152 6.8726Z" fill="currentColor" />
  </Icon>
);

export default ArrowUpIcon;
