import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const BackIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.87263 7.88475L9.28195 2.30402L10.718 3.696L5.57696 9.00001H17V11H5.57696L10.718 16.304L9.28195 17.696L3.87264 12.1153C2.70912 10.9452 2.70912 9.05478 3.87263 7.88475Z" fill="currentColor" />
  </Icon>
);

export default BackIcon;
