import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import OrganizationInterviewsScreen from './OrganizationInterviewsScreen';

const OrganizationInterviewsLayout: React.FC = () => (
  <Grid templateColumns="1fr 1fr" height="100%">
    <GridItem minH={0} overflow="auto">
      <OrganizationInterviewsScreen />
    </GridItem>

    <GridItem minH={0} overflow="auto" bg="cf.bgPrimary">
      <Outlet />
    </GridItem>
  </Grid>
);

export default OrganizationInterviewsLayout;
