import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { UserDoc } from './User';

export type GoogleCredentialsDoc = {
  email: string;
  userRef: DocumentReference<UserDoc>;
  credentials: unknown;
};

export const isGoogleCredentialsDoc = (
  data: DocumentData,
): data is GoogleCredentialsDoc => true;

export const isGoogleCredentialsRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<GoogleCredentialsDoc> => ref.parent.id === 'googleCredentials';

export const isGoogleCredentialsSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<GoogleCredentialsDoc> => isGoogleCredentialsRef(snap.ref);

/**
 * @deprecated
 */
export const getGoogleCredentialsCollectionRef = (firestore: Firestore): CollectionReference<GoogleCredentialsDoc> => collection(firestore, 'googleCredentials') as CollectionReference<GoogleCredentialsDoc>;

export const useGoogleCredentialsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'googleCredentials') as CollectionReference<GoogleCredentialsDoc>, [firestore]);
};

export const useGoogleCredentialDocRef = (docId: string) => {
  const collectionRef = useGoogleCredentialsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
