import {
  documentId,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { Navigate } from 'react-router';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';
import { useUserRef } from '../../components/UserRefContext';
import { getMemberOrganizationRef, useMembersCollectionGroupRef } from '../../types/Member';
import { useOrganizationsCollectionRef } from '../../types/Organization';

const OrganizationsPageMain: React.FC = () => {
  const userRef = useUserRef();

  const membersCollectionGroupRef = useMembersCollectionGroupRef();

  const { data: membersSnap } = useFirestoreCollection(
    query(
      membersCollectionGroupRef,
      where('uid', '==', userRef.id),
    ),
  );

  const organizationIds = useMemo(
    () => membersSnap.docs.map(
      (memberSnap) => getMemberOrganizationRef(memberSnap.ref).id,
    ),
    [membersSnap.docs],
  );

  const organizationsCollectionRef = useOrganizationsCollectionRef();

  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      organizationsCollectionRef,
      where(documentId(), 'in', organizationIds),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  if (organizationsSnap.docs.length > 0) {
    return (
      <Navigate to={organizationsSnap.docs[0].id} replace />
    );
  }

  return (
    <Navigate to="new" replace />
  );
};

export const OrganizationsPageCatchFallback: React.FC = () => null;
export const OrganizationsPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const OrganizationsPage: React.FC = () => (
  <Catch fallback={<OrganizationsPageCatchFallback />}>
    <Suspense fallback={<OrganizationsPageSuspenseFallback />}>
      <OrganizationsPageMain />
    </Suspense>
  </Catch>
);

export default OrganizationsPage;
