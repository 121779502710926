import {
  Button,
  Center,
  Container,
  Grid,
  GridItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import PaddingBlock from '../../../components/PaddingBlock';
import SkillLogo from '../../../components/SkillLogo';
import UserAvatar from '../../../components/UserAvatar';
import { ScoreDoc } from '../../../types/Score';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

export interface Props {
  scoreRef: DocumentReference<ScoreDoc>;
}

const ScoreScreenMain: React.FC<Props> = ({ scoreRef }) => {
  const { data: scoreSnap } = useFirestoreDoc(scoreRef);

  if (!scoreSnap.exists()) {
    throw new SnapNotFoundError(scoreSnap);
  }

  const score = useMemo(() => scoreSnap.data(), [scoreSnap]);

  const { data: intervieweeSnap } = useFirestoreDoc(score.userRef);

  if (!intervieweeSnap.exists()) {
    throw new SnapNotFoundError(intervieweeSnap);
  }

  const interviewee = useMemo(() => intervieweeSnap.data(), [intervieweeSnap]);

  const { data: skillSnap } = useFirestoreDoc(score.skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  return (
    <Center minH="100%">
      <Container>
        <PaddingBlock userSelect="none">
          <Grid
            templateColumns="repeat(3, 1fr)"
            templateRows="repeat(2, auto)"
            columnGap={5}
            rowGap={10}
            alignItems="center"
            justifyItems="center"
          >
            <GridItem minW={0} maxW="100%">
              <VStack alignItems="center">
                <UserAvatar userRef={score.userRef} />

                <Text
                  variant="labelMedium"
                  textAlign="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxW="100%"
                >
                  {interviewee.firstName}
                  {' '}
                  {interviewee.lastName}
                </Text>
              </VStack>
            </GridItem>

            <GridItem minW={0} maxW="100%">
              <VStack alignItems="center">
                <Text
                  fontSize="44px"
                  lineHeight="44px"
                  textAlign="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxW="100%"
                  fontWeight="light"
                >
                  {Math.round(score.score * 100)}
                </Text>

                <Text
                  variant="labelMedium"
                  textAlign="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxW="100%"
                  color="cf.cntSecondary"
                >
                  out of 100
                </Text>
              </VStack>
            </GridItem>

            <GridItem minW={0} maxW="100%">
              <VStack alignItems="center">
                <SkillLogo
                  sx={{ boxSize: 11 }}
                  skillRef={score.skillRef}
                />

                <Text
                  variant="labelMedium"
                  textAlign="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  maxW="100%"
                >
                  {skill.name}
                </Text>
              </VStack>
            </GridItem>
            <GridItem colSpan={3}>
              <Text textAlign="center" color="cf.cntSecondary">
                This is a score
                {' '}
                {interviewee.firstName}
                {' '}
                {interviewee.lastName}
                {' '}
                got for technical screening with one of professional interviewers on
                {' '}
                <Button
                  as="a"
                  href="https://clarwis.com"
                  variant="link"
                >
                  Clarwis
                </Button>
              </Text>
            </GridItem>
          </Grid>
        </PaddingBlock>
      </Container>
    </Center>
  );
};

const ScoreScreenCatchFallback: React.FC = () => null;
const ScoreScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const ScoreScreen: React.FC<Props> = (props) => (
  <Catch fallback={<ScoreScreenCatchFallback />}>
    <Suspense fallback={<ScoreScreenSuspenseFallback />}>
      <ScoreScreenMain {...props} />
    </Suspense>
  </Catch>
);

export default ScoreScreen;
