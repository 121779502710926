import { Text } from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import { useGoogleCredentialsCollectionRef } from '../../types/GoogleCredentials';
import { useMicrosoftCredentialsCollectionRef } from '../../types/MicrosoftAuthCredentials';
import BlockList from '../BlockList';
import Catch from '../Catch';
import { useUserRef } from '../UserRefContext';
import GoogleCredentialsItem from './GoogleCredentialsItem';
import MicrosoftCredentialsItem from './MicrosoftCredentialsItem';

const CredentialsListMain: React.FC = () => {
  const userRef = useUserRef();
  const googleCredentialsCollectionRef = useGoogleCredentialsCollectionRef();
  const microsoftCredentialsCollectionRef = useMicrosoftCredentialsCollectionRef();
  const { data: googleCredentialsSnap } = useFirestoreCollection(
    query(
      googleCredentialsCollectionRef,
      where('userRef', '==', userRef),
    ),
  );

  const { data: microsoftCredentialsSnap } = useFirestoreCollection(
    query(
      microsoftCredentialsCollectionRef,
      where('userRef', '==', userRef),
    ),
  );

  if (!googleCredentialsSnap.docs.length) {
    return null;
  }

  return (
    <BlockList variant="outline">
      {googleCredentialsSnap.docs.map((googleCredentialSnap) => (
        <GoogleCredentialsItem
          key={googleCredentialSnap.id}
          googleCredentialSnap={googleCredentialSnap}
        />
      ))}

      {microsoftCredentialsSnap.docs.map((microsoftCredentialSnap) => (
        <MicrosoftCredentialsItem
          key={microsoftCredentialSnap.id}
          microsoftCredentialSnap={microsoftCredentialSnap}
        />
      ))}

      {googleCredentialsSnap.docs.length + microsoftCredentialsSnap.docs.length === 0 ? (
        <Text>
          No calendars connected
        </Text>
      ) : null}
    </BlockList>
  );
};

export const CredentialsListCatchFallback: React.FC = () => null;
export const CredentialsListSuspenseFallback: React.FC = () => (<>Loading</>);

/* eslint-disable react/jsx-props-no-spreading */
const CredentialsList: React.FC = () => (
  <Catch fallback={<CredentialsListCatchFallback />}>
    <Suspense fallback={<CredentialsListSuspenseFallback />}>
      <CredentialsListMain />
    </Suspense>
  </Catch>
);

export default CredentialsList;
