import {
  FormControl,
  FormLabel,
  Select,
  VStack,
} from '@chakra-ui/react';
import React, { ChangeEvent, useCallback } from 'react';
import { LocalAudioTrack } from 'twilio-video';
import AudioLevel from './AudioLevel';

export interface Props {
  devices: MediaDeviceInfo[];
  audioTrack: LocalAudioTrack | null;
  onChange: (deviceId: string) => unknown;
  value: string;
}

const MicrophoneSettings: React.FC<Props> = ({
  devices, audioTrack, onChange, value,
}: Props) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <VStack spacing={4} alignItems="stretch">
      <FormControl>
        <FormLabel>
          Microphone
        </FormLabel>

        <Select onChange={handleChange} value={value}>
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <AudioLevel audioTrack={audioTrack} />
    </VStack>
  );
};

export default MicrophoneSettings;
