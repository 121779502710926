import { Heading, HeadingProps } from '@chakra-ui/react';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import useCurrentTime from '../../../../hooks/useCurrentTime';
import useTimezone from '../../../../hooks/useTimezone';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

export type Props = HeadingProps;

const StartsAtMain: React.FC<Props> = ({ ...headingProps }: Props) => {
  const timezone = useTimezone();
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const currentTime = useCurrentTime(1000 * 60);
  const when = useMemo(
    () => moment(interview.startsAt?.toDate())
      .tz(timezone)
      .calendar(currentTime, {
        sameDay: '[Today at] LT z',
        nextDay: '[Tomorrow at] LT z',
        nextWeek: 'dddd [at] LT z',
        lastDay: '[Yesterday at] LT z',
        lastWeek: '[Last] dddd [at] LT z',
        sameElse: 'DD/MM/YYYY',
      }),
    [currentTime, interview.startsAt, timezone],
  );

  return (
    <Heading
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...headingProps}
    >
      {when}
    </Heading>
  );
};

const StartsAtCatchFallback: React.FC = () => null;
const StartsAtSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StartsAt: React.FC<Props> = (props) => (
  <Catch fallback={<StartsAtCatchFallback />}>
    <Suspense fallback={<StartsAtSuspenseFallback />}>
      <StartsAtMain {...props} />
    </Suspense>
  </Catch>
);

export default StartsAt;
