import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../components/Catch';
import ExpertRow, {
  ExpertRowCatchFallback,
  ExpertRowSuspenseFallback,
} from '../../../components/ExpertRow';
import { ExpertDoc } from '../../../types/Expert';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

type Props = {
  expertRef: DocumentReference<ExpertDoc>;
};

const InterviewDetailsInterviewerMain: React.FC<Props> = ({ expertRef }) => {
  const { data: expertSnap } = useFirestoreDoc(expertRef);

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate(`/organizations/${expert.organizationRef.id}/experts/${expertRef.id}`);
  }, [navigate, expert.organizationRef.id, expertRef.id]);

  return (
    <ExpertRow
      expertSnap={expertSnap}
      onClick={handleClick}
      key={expertSnap.ref.path}
    />
  );
};

export const InterviewDetailsInterviewerCatchFallback: React.FC = () => (
  <ExpertRowCatchFallback />
);

export const InterviewDetailsInterviewerSuspenseFallback: React.FC = () => (
  <ExpertRowSuspenseFallback />
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsInterviewer: React.FC<Props> = (props) => (
  <Catch fallback={<InterviewDetailsInterviewerCatchFallback />}>
    <Suspense fallback={<InterviewDetailsInterviewerSuspenseFallback />}>
      <InterviewDetailsInterviewerMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewDetailsInterviewer;
