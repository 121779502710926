import {
  HStack,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import useGoogleCalendarDisable from '../../functions/useGoogleCalendarDisable';
import useGoogleCalendarEnable from '../../functions/useGoogleCalendarEnable';
import { GoogleCalendarDoc } from '../../types/GoogleCalendar';

export interface Props {
  googleCalendarSnap: QueryDocumentSnapshot<GoogleCalendarDoc>;
}

const GoogleCalendar: React.FC<Props> = ({ googleCalendarSnap }) => {
  const googleCalendar = useMemo(() => googleCalendarSnap.data(), [googleCalendarSnap]);

  const userGoogleCalendarDisable = useGoogleCalendarDisable();
  const userGoogleCalendarEnable = useGoogleCalendarEnable();

  const [loading, setLoading] = useState(false);
  const handleSwitchChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    try {
      if (e.target.checked) {
        await userGoogleCalendarEnable({ calendarId: googleCalendarSnap.id });
      } else {
        await userGoogleCalendarDisable({ calendarId: googleCalendarSnap.id });
      }
    } catch (err) {
      /* do nothing */
    } finally {
      setLoading(false);
    }
  }, [userGoogleCalendarDisable, userGoogleCalendarEnable, setLoading, googleCalendarSnap.id]);

  return (
    <HStack spacing={1}>
      <svg height="20px" width="20px" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="6" fill={googleCalendar.calendar.backgroundColor || 'red'} />
      </svg>

      <Text fontSize="sm" lineHeight="short" color="cf.cntSecondary">
        {googleCalendar.calendar.summary}
      </Text>

      <Spacer />

      <Switch
        onChange={handleSwitchChange}
        isDisabled={loading}
        isChecked={googleCalendar.isEnabled}
      />
    </HStack>
  );
};

export default GoogleCalendar;
