import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ReactNode } from 'react';

export interface CheckboxFieldProps extends CheckboxProps {
  label: ReactNode;
  name: string;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label, name, ...props
}: CheckboxFieldProps) => {
  const [field, meta] = useField(name);

  return (
    <FormControl isInvalid={!!meta.error}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Checkbox {...field} {...props} isChecked={field.value}>
        {label}
      </Checkbox>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

export default CheckboxField;
