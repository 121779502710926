import { IconProps } from '@chakra-ui/react';
import React from 'react';
import ChevronExternalAdmiralIcon from '../icons/ChevronExternalAdmiralIcon';
import ChevronExternalCaptainIcon from '../icons/ChevronExternalCaptainIcon';
import ChevronExternalCommanderIcon from '../icons/ChevronExternalCommanderIcon';
import ChevronExternalLieutenantIcon from '../icons/ChevronExternalLieutenantIcon';
import { ExpertTier } from '../types/Expert';

export type Props = {
  tier: ExpertTier;
} & IconProps;

const ExternalExpertTierIcon: React.FC<Props> = ({ tier, ...iconProps }: Props) => {
  switch (tier) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.LIEUTENANT: return <ChevronExternalLieutenantIcon {...iconProps} />;
      // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.COMMANDER: return <ChevronExternalCommanderIcon {...iconProps} />;
      // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.CAPTAIN: return <ChevronExternalCaptainIcon {...iconProps} />;
      // eslint-disable-next-line react/jsx-props-no-spreading
    case ExpertTier.ADMIRAL: return <ChevronExternalAdmiralIcon {...iconProps} />;
    default: return null;
  }
};

export default ExternalExpertTierIcon;
