import {
  BoxProps,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { StageActionFilterType, StageActionType } from '../../types/Stage';
import BlockList from '../BlockList';
import BlockListItem from '../BlockListItem';
import StageTransitionActionField, {
  StageTransitionActionFieldValue,
} from './StageTransitionActionField';

export type Props = BoxProps & {
  name: string;
  label: string;
  isRequired?: boolean;
};

export type StageActionsFieldValue = StageTransitionActionFieldValue[];

const StageActionsField: React.FC<Props> = ({
  name,
  label,
  isRequired,
  ...boxProps
}) => {
  const [field, meta, helpers] = useField<StageActionsFieldValue>(name);

  const onAddClick = useCallback(
    () => {
      helpers.setValue([
        ...field.value,
        {
          filter: {
            type: StageActionFilterType.AND,
            filters: [],
          },
          stageRef: null,
          type: StageActionType.TRANSITION,
        },
      ]);
    },
    [field.value, helpers],
  );

  return (
    <FormControl
      isInvalid={!!meta.error}
      isRequired={isRequired}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <FormLabel flexGrow={1}>
        {label}
      </FormLabel>

      <BlockList variant="outline">
        {field.value.map((v, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <BlockListItem key={i}>
            <StageTransitionActionField
              name={`${name}[${i}]`}
            />
          </BlockListItem>
        ))}
        <BlockListItem>
          <Button onClick={onAddClick}>
            Add transition
          </Button>
        </BlockListItem>
      </BlockList>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

StageActionsField.defaultProps = {
  isRequired: undefined,
};

export default StageActionsField;
