import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import {
  InterviewWhiteboardElementDoc,
  isInterviewWhiteboardElementRef,
} from './InterviewWhiteboardElement';

export type InterviewWhiteboardElementStateDoc = InterviewWhiteboardElementDoc & {
  timestamp: Timestamp;
};

export const isInterviewWhiteboardElementStateDoc = (
  data?: DocumentData,
): data is InterviewWhiteboardElementStateDoc => true;

export const isInterviewWhiteboardElementStateRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewWhiteboardElementStateDoc> => ref.parent.id === 'states' && ref.parent.parent !== null && isInterviewWhiteboardElementRef(ref.parent.parent);

export const isInterviewWhiteboardElementStateSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
InterviewWhiteboardElementStateDoc
> => isInterviewWhiteboardElementStateRef(snap.ref);

export const useInterviewWhiteboardElementStatesCollectionRef = (whiteboardElementRef: DocumentReference<InterviewWhiteboardElementDoc>) => useMemo(() => collection(whiteboardElementRef, 'states') as CollectionReference<InterviewWhiteboardElementStateDoc>, [whiteboardElementRef]);

export const useInterviewWhiteboardElementStateDocRef = (
  whiteboardElementRef: DocumentReference<InterviewWhiteboardElementDoc>,
  docId: string,
) => {
  const collectionRef = useInterviewWhiteboardElementStatesCollectionRef(whiteboardElementRef);
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
