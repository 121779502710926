import { HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import Catch from '../../../../components/Catch';
import { useInterviewRef } from '../../../../components/InterviewRefContext';
import useCurrentTime from '../../../../hooks/useCurrentTime';
import ClockIcon from '../../../../icons/ClockIcon';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

const StartsAtMain: React.FC = () => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const durationToText = useCallback((duration: moment.Duration): React.ReactNode => {
    const h = duration.hours();
    const m = duration.minutes();

    if (!h && !m) {
      return 'less than a minute';
    }

    return (
      <>
        {h > 0 ? Intl.NumberFormat('en-US', { style: 'unit', unit: 'hour', unitDisplay: 'long' }).format(h) : null}
        {' '}
        {m > 0 ? Intl.NumberFormat('en-US', { style: 'unit', unit: 'minute', unitDisplay: 'long' }).format(m) : null}
      </>
    );
  }, []);

  const currentTime = useCurrentTime(1000 * 15);

  const duration = useMemo(() => {
    const scheduled = moment.duration(interview.duration, 'minutes');

    if (!interview.startedAt) {
      return (
        <>
          Scheduled for
          {' '}
          {durationToText(scheduled)}
        </>
      );
    }

    if (!interview.endedAt && !interview.canceledAt) {
      const live = moment.duration(
        moment(currentTime).diff(interview.startedAt.toDate()),
      );

      return (
        <>
          Live for
          {' '}
          {durationToText(live)}
          ,
          {' '}
          scheduled for
          {' '}
          {durationToText(scheduled)}
        </>
      );
    }

    const actual = moment.duration(
      moment(interview.endedAt?.toDate() || interview.canceledAt?.toDate())
        .diff(interview.startedAt.toDate()),
    );

    return (
      <>
        Was live for
        {' '}
        {durationToText(actual)}
        ,
        {' '}
        scheduled for
        {' '}
        {durationToText(scheduled)}
      </>
    );
  }, [currentTime, durationToText, interview]);

  return (
    <HStack spacing={2}>
      <ClockIcon />
      <Text
        variant="subtitle"
        fontWeight="medium"
        flexGrow={1}
        flexShrink={1}
        whiteSpace="nowrap"
        minW={0}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {duration}
      </Text>
    </HStack>
  );
};

const StartsAtCatchFallback: React.FC = () => null;
const StartsAtSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const StartsAt: React.FC = () => (
  <Catch fallback={<StartsAtCatchFallback />}>
    <Suspense fallback={<StartsAtSuspenseFallback />}>
      <StartsAtMain />
    </Suspense>
  </Catch>
);

export default StartsAt;
