import {
  Divider,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import useDevices from '../../hooks/useDevices';
import CrossIcon from '../../icons/CrossIcon';
import { useLocalCamera } from '../LocalCameraProvider';
import { useLocalMicrophone } from '../LocalMicrophoneProvider';
import CameraSettings from './CameraSettings';
import MicrophoneSettings from './MicrophoneSettings';

export type Props = Omit<ModalProps, 'children'> & {
  onAudioInputDeviceChange: (deviceId: string) => unknown;
  audioInputDeviceId: string;
  onVideoInputDeviceChange: (deviceId: string) => unknown;
  videoInputDeviceId: string;
};

const CallSettingsModal: React.FC<Props> = ({
  onClose,
  audioInputDeviceId,
  videoInputDeviceId,
  onVideoInputDeviceChange,
  onAudioInputDeviceChange,
  ...props
}: Props) => {
  const { track: audioTrack } = useLocalMicrophone();
  const { track: videoTrack } = useLocalCamera();

  const { audioInputDevices, videoInputDevices } = useDevices({
    audioTrack,
    videoTrack,
  });

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Modal onClose={onClose} size="lg" {...props}>
      <ModalOverlay />
      <ModalContent>
        <HStack spacing={2} alignItems="center" py={3} px={5}>
          <Heading py="2px">
            Settings
          </Heading>

          <Spacer />

          <IconButton
            icon={<CrossIcon />}
            variant="outline"
            aria-label="Close"
            onClick={onClose}
          />
        </HStack>

        <Divider />

        <VStack spacing={8} alignItems="stretch" px={5} py={4}>
          <CameraSettings
            devices={videoInputDevices}
            videoTrack={videoTrack}
            onChange={onVideoInputDeviceChange}
            value={videoInputDeviceId}
          />

          <MicrophoneSettings
            devices={audioInputDevices}
            audioTrack={audioTrack}
            onChange={onAudioInputDeviceChange}
            value={audioInputDeviceId}
          />
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default CallSettingsModal;
