import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { OrganizationDoc, isOrganizationRef } from './Organization';

export type OrganizationBalanceDoc = {
  tierCQuantity?: number;
  tierFQuantity?: number;
  tierIQuantity?: number;
  tierLQuantity?: number;
};

export const isOrganizationBalanceDoc = (
  data?: DocumentData,
): data is OrganizationBalanceDoc => true;

export const isOrganizationBalanceRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<OrganizationBalanceDoc> => ref.id === 'balance' && ref.parent.id === 'private' && ref.parent.parent !== null && isOrganizationRef(ref.parent.parent);

export const isOrganizationBalanceSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
OrganizationBalanceDoc
> => isOrganizationBalanceRef(snap.ref);

export const useOrganizationBalanceDocRef = (organizationRef: DocumentReference<OrganizationDoc>) => useMemo(() => doc(collection(organizationRef, 'private'), 'balance') as DocumentReference<OrganizationBalanceDoc>, [organizationRef]);
