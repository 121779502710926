import {
  Box,
  Container,
  Divider,
  VStack,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import Catch from '../../../components/Catch';
import InterviewDetailsAction from '../../../components/InterviewDetailsAction';
import InterviewDetailsCompositions from '../../../components/InterviewDetailsCompositions';
import InterviewDetailsSkills from '../../../components/InterviewDetailsSkills';
import ScoreChartItem from '../../../components/InterviewDetailsSkills/ScoreChartItem';
import InterviewReview from '../../../components/InterviewReview';
import Loader from '../../../components/Loader';
import Header from './Header';
import InterviewDetailsCandidate from './InterviewDetailsCandidate';
import InterviewDetailsInterviewers from './InterviewDetailsInterviewers';
import InterviewTimeInfo from './InterviewTimeInfo';

const InterviewDetailsScreenMain: React.FC = () => (
  <VStack
    height="100%"
    spacing={0}
    alignItems="stretch"
    overflow="hidden"
  >
    <Header />

    <Divider />

    <Box overflow="auto" flexGrow={1} flexShrink={1}>
      <Container>
        <VStack spacing={8} py={3} alignItems="stretch">
          <InterviewTimeInfo />
          <InterviewDetailsCandidate />
          <InterviewDetailsInterviewers />
          <ScoreChartItem />
          <InterviewDetailsSkills />
          <InterviewDetailsCompositions />
          <InterviewReview />
        </VStack>
      </Container>
    </Box>

    <InterviewDetailsAction />
  </VStack>
);

export const InterviewDetailsScreenCatchFallback: React.FC = () => null;
export const InterviewDetailsScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewDetailsScreen: React.FC = () => (
  <Catch fallback={<InterviewDetailsScreenCatchFallback />}>
    <Suspense fallback={<InterviewDetailsScreenSuspenseFallback />}>
      <InterviewDetailsScreenMain />
    </Suspense>
  </Catch>
);

export default InterviewDetailsScreen;
