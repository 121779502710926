import { IconButton } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import MicOffIcon from '../icons/MicOffIcon';
import MicOnIcon from '../icons/MicOnIcon';
import Spinner from '../icons/Spinner';
import { useLocalMicrophone } from './LocalMicrophoneProvider';

const LocalMicrophoneButton: React.FC = () => {
  const {
    track, on, off, loading,
  } = useLocalMicrophone();

  const handleClick = useCallback(() => {
    if (track) {
      off();
    } else {
      on();
    }
  }, [on, off, track]);

  return (
    <IconButton
      aria-label="Mute"
      h="44px"
      w="44px"
      isRound
      colorScheme="blackAlpha"
      icon={track ? <MicOnIcon /> : <MicOffIcon />}
      onClick={handleClick}
      isLoading={loading}
      spinner={<Spinner />}
    />
  );
};

export default LocalMicrophoneButton;
