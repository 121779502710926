import React, { Suspense, useMemo } from 'react';
import { useCallableFunctionResponse } from 'reactfire';
import Catch from '../../components/Catch';
import Loader from '../../components/Loader';

const UserMicrosoftAuthPageMain: React.FC = () => {
  const data = useMemo(() => ({
    redirectUri: `${window.location.origin}/ms-redirect`,
  }), []);

  const { data: authUrl } = useCallableFunctionResponse<{ redirectUri: string; }, string>(
    'microsoft-calendar-getauthurl',
    { data },
  );

  window.location.replace(authUrl);

  return null;
};

const UserMicrosoftAuthPageCatchFallback: React.FC = () => null;
const UserMicrosoftAuthPageSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const UserMicrosoftAuthPage: React.FC = () => (
  <Catch fallback={<UserMicrosoftAuthPageCatchFallback />}>
    <Suspense fallback={<UserMicrosoftAuthPageSuspenseFallback />}>
      <UserMicrosoftAuthPageMain />
    </Suspense>
  </Catch>
);

export default UserMicrosoftAuthPage;
