import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const FlagIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.75 4.29103C5.00142 4.15196 5.36187 3.98719 5.80504 3.8764C6.6963 3.65359 7.96926 3.63334 9.45924 4.59119C11.4693 5.88334 13.3213 5.90359 14.68 5.5639C14.8823 5.51333 15.0726 5.45512 15.25 5.39272V10.709C14.9986 10.8481 14.6381 11.0128 14.195 11.1236C13.3037 11.3464 12.0307 11.3667 10.5408 10.4088C8.53074 9.11668 6.6787 9.09643 5.31996 9.43612C5.11767 9.48669 4.92737 9.5449 4.75 9.6073V4.29103ZM2.75 3.75001C2.75 3.45008 2.88463 3.16598 3.11676 2.97605L3.75 3.75001C3.11676 2.97605 3.11719 2.9757 3.11764 2.97534L3.11858 2.97457L3.12066 2.97288L3.12562 2.96888L3.13868 2.95848C3.14878 2.95051 3.16168 2.94049 3.1773 2.92864C3.20853 2.90494 3.25076 2.87385 3.30351 2.83712C3.40884 2.76376 3.55703 2.66726 3.74411 2.56203C4.11684 2.35237 4.65326 2.10279 5.31996 1.93612C6.6787 1.59643 8.53074 1.61668 10.5408 2.90883C12.0307 3.86668 13.3037 3.84643 14.195 3.62362C14.6533 3.50904 15.0231 3.33674 15.2754 3.19484C15.4008 3.1243 15.4948 3.06259 15.5535 3.02169C15.5828 3.00129 15.6031 2.98624 15.6138 2.97808L15.6223 2.97152C15.9209 2.73076 16.3312 2.6819 16.6782 2.84634C17.0274 3.0118 17.25 3.36362 17.25 3.75001V11.25C17.25 11.5499 17.1154 11.834 16.8832 12.024L16.25 11.25C16.8832 12.024 16.8828 12.0243 16.8824 12.0247L16.8814 12.0254L16.8793 12.0271L16.8744 12.0311L16.8613 12.0415C16.8512 12.0495 16.8383 12.0595 16.8227 12.0714C16.7915 12.0951 16.7492 12.1262 16.6965 12.1629C16.5912 12.2363 16.443 12.3328 16.2559 12.438C15.8832 12.6477 15.3467 12.8972 14.68 13.0639C13.3213 13.4036 11.4693 13.3833 9.45924 12.0912C7.96926 11.1333 6.6963 11.1536 5.80504 11.3764C5.36187 11.4872 5.00142 11.652 4.75 11.791V17.25C4.75 17.8023 4.30228 18.25 3.75 18.25C3.19772 18.25 2.75 17.8023 2.75 17.25V3.75001Z" fill="currentColor" />
  </Icon>
);

export default FlagIcon;
