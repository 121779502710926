import {
  Box,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
  useToken,
} from '@chakra-ui/react';
import { MessagePayload, Messaging } from 'firebase/messaging';
import React, { useCallback } from 'react';
import CrossIcon from '../icons/CrossIcon';
import LinkIcon from '../icons/LinkIcon';

export const MessagingContext = React.createContext<Messaging | null>(null);

export type Props = {
  payload: MessagePayload;
  onClose: () => unknown;
  isClosable?: boolean;
  color?: 'empty' | 'created' | 'started' | 'ended' | 'canceled' | 'archived';
};

const DefaultNotification: React.FC<Props> = ({
  payload, onClose, isClosable = false, color = 'empty',
}) => {
  const [c] = useToken('colors', [`cf.status.${color}`]);

  const onLinkClick = useCallback(
    () => {
      if (payload.fcmOptions?.link) {
        window.location.assign(payload.fcmOptions.link);
      }
    },
    [payload.fcmOptions?.link],
  );

  return (
    <Box boxShadow="xl" rounded="md" bg="cf.bgPrimary">
      <Box boxShadow="inset 0px 0px 0px 1px var(--chakra-colors-cf-brdBlackAlpha12)" rounded="md">
        <Box boxShadow={`inset 4px 0px ${c}`} px={5} py={3} rounded="md">
          <HStack spacing={3} alignItems="start">
            {payload.notification?.image ? (
              <Image boxSize={11} src={payload.notification.image} flexGrow={0} flexShrink={0} />
            ) : null}

            <VStack alignItems="stretch" spacing={1} flexGrow={1} flexShrink={1} overflow="hidden">
              {payload.notification?.title ? (
                <Text
                  variant="labelMedium"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {payload.notification.title}

                </Text>
              ) : null}

              {payload.notification?.body ? (
                <Text
                  variant="labelSmall"
                  color="cf.cntSecondary"
                  py="2px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {payload.notification.body}
                </Text>
              ) : null}
            </VStack>

            <VStack alignItems="stretch" spacing={1} flexGrow={0} flexShrink={0}>
              {isClosable ? (
                <IconButton
                  variant="ghost"
                  icon={<CrossIcon boxSize={4} />}
                  aria-label="Close"
                  onClick={onClose}
                  size="xs"
                />
              ) : null}

              {payload.fcmOptions?.link ? (
                <IconButton
                  variant="ghost"
                  icon={<LinkIcon boxSize={4} />}
                  aria-label="Go"
                  onClick={onLinkClick}
                  size="xs"
                />
              ) : null}
            </VStack>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

DefaultNotification.defaultProps = {
  isClosable: false,
  color: 'empty',
};

export default DefaultNotification;
