import {
  Alert,
  Center,
  Container,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  QueryDocumentSnapshot,
  Timestamp,
  and,
  or,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../../components/BlockList';
import BlockListItem from '../../../components/BlockListItem';
import Catch from '../../../components/Catch';
import { InterviewInfoSuspenseFallback } from '../../../components/InterviewInfo';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useTimezone from '../../../hooks/useTimezone';
import AddPosition64Icon from '../../../icons/AddPosition64Icon';
import {
  InterviewDoc,
  InterviewStatus,
  useInterviewsCollectionRef,
} from '../../../types/Interview';
import InterviewRow from './InterviewRow';

type Props = {
  search: {
    start: number;
    end: number;
    statuses: InterviewStatus[];
  }
};

const InterviewsListMain: React.FC<Props> = ({ search }) => {
  const organizationRef = useOrganizationRef();
  const timezone = useTimezone();

  const interviewsCollectionRef = useInterviewsCollectionRef();

  const { data: interviewsSnap } = useFirestoreCollection(
    query(
      interviewsCollectionRef,
      and(
        or(
          where('customerRef', '==', organizationRef),
          where('vendorRefs', 'array-contains', organizationRef),
        ),
        where('status', 'in', search.statuses),
        where('startsAt', '>=', Timestamp.fromMillis(search.start)),
        where('startsAt', '<=', Timestamp.fromMillis(search.end)),
      ),
      orderBy('startsAt', 'asc'),
    ),
  );

  const currentTime = useCurrentTime(1000 * 60 * 60);

  const blocks: [ string, QueryDocumentSnapshot<InterviewDoc>[] ][] = useMemo(
    () => _.toPairs(
      _.groupBy(
        interviewsSnap.docs,
        (interviewSnap) => {
          const interview = interviewSnap.data();
          return moment(interview.startsAt.toDate()).tz(timezone).startOf('day').calendar(currentTime, {
            sameDay: '[Today], Do',
            nextDay: '[Tomorrow], Do',
            nextWeek: 'dddd, Do',
            lastDay: '[Yesterday], Do',
            lastWeek: '[Last] dddd, Do',
            sameElse: 'MMMM Do',
          });
        },
      ),
    ),
    [currentTime, interviewsSnap, timezone],
  );

  if (!blocks.length) {
    return (
      <Center height="100%">
        <Container maxW="280px" px={0}>
          <VStack spacing={4}>
            <VStack spacing={2}>
              <Text color="cf.cntEmpty">
                <AddPosition64Icon />
              </Text>
              <Text variant="labelMedium" textAlign="center">
                No interviews yet
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Center>
    );
  }

  return (
    <VStack spacing={4} alignItems="stretch">
      {blocks.map(([key, interviewSnaps]) => (
        <VStack alignItems="stretch" key={key} spacing={1}>
          <Text
            pt="1px"
            pb="3px"
            fontSize="sm"
            fontWeight="medium"
            lineHeight="short"
            color="cf.cntTertiary"
          >
            {key}
          </Text>

          <BlockList variant="outline">
            {interviewSnaps.map((interviewSnap) => (
              <InterviewRow
                key={interviewSnap.id}
                interviewSnap={interviewSnap}
              />
            ))}
          </BlockList>
        </VStack>
      ))}
    </VStack>
  );
};

export const InterviewsListCatchFallback: React.FC = () => (
  <Alert status="error">
    <Text>Failed to load interviews</Text>
  </Alert>
);

export const InterviewsListSuspenseFallback: React.FC = () => (
  <VStack spacing={4} alignItems="stretch">
    <VStack alignItems="stretch" spacing={1}>
      <Skeleton h="20px" w="100px" pt="1px" pb="3px" />
      <BlockList variant="outline">
        <BlockListItem><InterviewInfoSuspenseFallback /></BlockListItem>
        <BlockListItem><InterviewInfoSuspenseFallback /></BlockListItem>
        <BlockListItem><InterviewInfoSuspenseFallback /></BlockListItem>
      </BlockList>
    </VStack>

    <VStack alignItems="stretch" spacing={1}>
      <Skeleton h="20px" w="100px" pt="1px" pb="3px" />
      <BlockList variant="outline">
        <BlockListItem><InterviewInfoSuspenseFallback /></BlockListItem>
        <BlockListItem><InterviewInfoSuspenseFallback /></BlockListItem>
        <BlockListItem><InterviewInfoSuspenseFallback /></BlockListItem>
      </BlockList>
    </VStack>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewsList: React.FC<Props> = (props) => (
  <Catch fallback={<InterviewsListCatchFallback />}>
    <Suspense fallback={<InterviewsListSuspenseFallback />}>
      <InterviewsListMain {...props} />
    </Suspense>
  </Catch>
);

export default InterviewsList;
