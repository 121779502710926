import { setUserId } from 'firebase/analytics';
import React, { useEffect } from 'react';
import { useAnalytics, useUser } from 'reactfire';

const AnalyticsSetUserId: React.FC = () => {
  const analytics = useAnalytics();
  const { data: user } = useUser();

  useEffect(() => {
    if (user) {
      setUserId(analytics, user.uid, { global: true });
    }
  }, [user, analytics]);

  return null;
};

export default AnalyticsSetUserId;
