import {
  Box,
  Card,
  CardBody,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import * as d3 from 'd3';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import ease, { Easing } from '../../../helpers/ease';
import EasingField from './EasingField';
import InfluenceField from './InfluenceField';

const HOUR = 1000 * 60 * 60;
const STEP = 1000 * 60 * 1;
const DURATION_SCORE_MAX = 2 * HOUR;
const DURATION_SCORE_BEST = 1 * HOUR;
const DURATION_SCORE_MIN = 0 * HOUR;

const DurationScoreField: React.FC = () => {
  const [field] = useField<{
    influence: number,
    easing: Easing,
  }>({ name: 'duration' });

  const scaleX = d3.scaleLinear()
    .domain([0, 1])
    .range([456, 0]);

  const scaleY = d3.scaleLinear()
    .domain([0, 1])
    .range([100, 0]);

  const path = useMemo(
    () => {
      const p = d3.path();
      p.moveTo(scaleX(0), scaleY(0));
      for (let duration = DURATION_SCORE_MIN; duration <= DURATION_SCORE_MAX; duration += STEP) {
        const x = (duration - DURATION_SCORE_MIN) / (DURATION_SCORE_MAX - DURATION_SCORE_MIN);

        if (duration <= DURATION_SCORE_BEST) {
          const y = Math.max(
            1 - (
              (DURATION_SCORE_BEST - duration)
              / (DURATION_SCORE_BEST - DURATION_SCORE_MIN)
            ),
            0,
          );

          p.lineTo(
            scaleX(x),
            scaleY((
              ease(y, field.value.easing) * field.value.influence
            ) + (1 - field.value.influence)),
          );
        } else {
          const y = Math.max(
            1 - (
              (duration - DURATION_SCORE_BEST)
              / (DURATION_SCORE_MAX - DURATION_SCORE_BEST)
            ),
            0,
          );

          p.lineTo(
            scaleX(x),
            scaleY((
              ease(y, field.value.easing) * field.value.influence
            ) + (1 - field.value.influence)),
          );
        }
      }
      p.lineTo(scaleX(1), scaleY(0));
      p.closePath();
      return p.toString();
    },
    [field.value.easing, field.value.influence, scaleX, scaleY],
  );

  return (
    <Card variant="outline">
      <CardBody px={5} py={3}>
        <VStack spacing={1} alignItems="stretch">
          <Text
            pt="1px"
            pb="3px"
            color="cf.cntTertiary"
            fontSize="sm"
            lineHeight="short"
            fontWeight="medium"
          >
            Interview duration
          </Text>

          <Grid templateColumns="40px 456px 14px" templateRows="100px 16px" columnGap={1} rowGap={1}>
            <VStack alignItems="end">
              <Text variant="labelSmall" color="cf.cntSecondary">100%</Text>
              <Spacer />
              <Text variant="labelSmall" color="cf.cntSecondary">0%</Text>
            </VStack>
            <GridItem>
              <Box as="svg" viewBox="0 0 456 100" h="100%" w="100%">
                <rect
                  x={scaleX(1)}
                  y={scaleY(1)}
                  width={scaleX(0) - scaleX(1)}
                  height={scaleY(0) - scaleY(1)}
                  fill="rgba(224, 45, 67, 0.1)"
                />
                <path d={path} fill="#359651" />
              </Box>
            </GridItem>
            <GridItem>
              <InfluenceField name="duration.influence" />
            </GridItem>
            <GridItem />
            <HStack alignItems="start">
              <Text variant="labelSmall" color="cf.cntSecondary">0</Text>
              <Spacer />
              <Text variant="labelSmall" color="cf.cntSecondary">2 hours</Text>
            </HStack>
            <GridItem />
          </Grid>

          <EasingField name="duration.easing" />
        </VStack>
      </CardBody>
    </Card>
  );
};

export default DurationScoreField;
