import React from 'react';
import { useParams } from 'react-router-dom';
import CandidateRefProvider from '../../components/CandidateRefProvider';
import { useCandidateDocRef } from '../../types/Candidate';
import CreateInterviewScreen from './CreateInterviewScreen';

export type Params = {
  candidateId: string;
};

const OrganizationCandidateInterviewCreatePage: React.FC = () => {
  const { candidateId } = useParams<Params>();

  if (!candidateId) {
    throw new Error();
  }

  const candidateRef = useCandidateDocRef(candidateId);

  return (
    <CandidateRefProvider candidateRef={candidateRef}>
      <CreateInterviewScreen key={candidateRef.path} />
    </CandidateRefProvider>
  );
};

export default OrganizationCandidateInterviewCreatePage;
