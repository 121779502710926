import {
  Box,
  BoxProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';

export type Props = BoxProps & ThemingProps<'BlockListItem'>;

const BlockListItem: React.FC<Props> = ({ onClick, sx, ...boxProps }) => {
  const style = useStyleConfig('BlockListItem');

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      e.preventDefault();
    } else if (e.key === 'Enter') {
      e.preventDefault();

      e.currentTarget.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    } else if (e.key === 'Escape') {
      e.currentTarget.blur();
    }
  }, []);

  const onKeyUp = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      e.preventDefault();

      e.currentTarget.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    }
  }, []);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(onClick ? {
        onClick,
        onKeyUp,
        onKeyDown,
        cursor: 'pointer',
        _hover: {
          bg: 'cf.bgBlackAlpha3',
        },
        _active: {
          bg: 'cf.bgBlackAlpha6',
        },
        _focusVisible: {
          outlineWidth: '1px',
          outlineColor: 'cf.bgAccent',
          outlineOffset: '-1px',
          outlineStyle: 'solid',
        },
        _pressed: {
          bg: 'cf.bgAccentAlpha3',
          color: 'cf.cntAccent',
        },
        tabIndex: 0,
        role: 'button',
      } : {})}
      sx={{ ...style, ...sx }}
      userSelect="none"
      role="listitem"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    />
  );
};

export default BlockListItem;
