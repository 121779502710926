import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import { Functions } from 'firebase/functions';
import { FirebaseStorage } from 'firebase/storage';
import React, { PropsWithChildren } from 'react';
import {
  useAuth,
  useFirestore,
  useFunctions,
  useStorage,
} from 'reactfire';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  interface Window {
    Cypress?: unknown;
    cy_auth?: Auth;
    cy_firestore?: Firestore;
    cy_functions?: Functions;
    cy_storage?: FirebaseStorage;
  }
}

const CypressActions: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const firestore = useFirestore();
  const functions = useFunctions();
  const storage = useStorage();

  if (window.Cypress) {
    window.cy_auth = auth;
    window.cy_firestore = firestore;
    window.cy_functions = functions;
    window.cy_storage = storage;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (<>{children}</>);
};

export default CypressActions;
