import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';
import { WebhookEventType } from '../types/Webhook';

export type RequestData = {
  organizationId: string;
  url: string;
  eventTypes: WebhookEventType[];
};

export type ResponseData = string;

const useApiCreateWebhook = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'api-createwebhook'),
    [functions],
  );
};

export default useApiCreateWebhook;
