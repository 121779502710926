import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { ExpertDoc } from '../types/Expert';

const ExpertRefContext = React
  .createContext<DocumentReference<ExpertDoc> | undefined>(undefined);

export const useExpertRef = (): DocumentReference<ExpertDoc> => {
  const expertRef = useContext(ExpertRefContext);
  if (!expertRef) {
    throw new Error('useExpertRef needs to be wrapped with ExpertRefProvider');
  }
  return expertRef;
};

export type Props = {
  expertRef: DocumentReference<ExpertDoc>;
};

const ExpertRefProvider: React.FC<PropsWithChildren<Props>> = ({
  expertRef,
  children,
}) => (
  <ExpertRefContext.Provider value={expertRef} key={expertRef.path}>
    {children}
  </ExpertRefContext.Provider>
);

export default ExpertRefProvider;
