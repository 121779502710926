import { Text, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { InterviewStatus } from '../../types/Interview';
import { ReviewAspectType } from '../../types/Review';
import SnapNotFoundError from '../../types/SnapshotNotFoundError';
import BlockList from '../BlockList';
import Catch from '../Catch';
import { useInterviewRef } from '../InterviewRefContext';
import { useUserRef } from '../UserRefContext';
import Aspect, { AspectSuspenseFallback } from './Aspect';

const InterviewReviewMain: React.FC = () => {
  const userRef = useUserRef();
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  if (interview.status !== InterviewStatus.ENDED) {
    return null;
  }

  if (_.some(interview.interviewerRefs, (ref) => ref.id === userRef.id)) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Review
      </Text>

      <BlockList variant="outline">
        {Object.values(ReviewAspectType).map((aspect) => (
          <Aspect aspect={aspect} key={aspect} />
        ))}
      </BlockList>
    </VStack>
  );
};

export const InterviewReviewCatchFallback: React.FC = () => null;

export const InterviewReviewSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
      Review
    </Text>

    <BlockList variant="outline">
      {Object.values(ReviewAspectType).map((aspect) => (
        <AspectSuspenseFallback key={aspect} />
      ))}
    </BlockList>
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewReview: React.FC = () => (
  <Catch fallback={<InterviewReviewCatchFallback />}>
    <Suspense fallback={<InterviewReviewSuspenseFallback />}>
      <InterviewReviewMain />
    </Suspense>
  </Catch>
);

export default InterviewReview;
