import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const GoogleIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon width="21px" height="20px" viewBox="0 0 21 20" {...props}>
    <circle cx="10.5" cy="10" r="9" fill="white" />
    <g clipPath="url(#clip0)">
      <path d="M17.4926 10.131C17.4926 9.55744 17.445 9.13889 17.3419 8.70483H10.6395V11.2936H14.5737C14.4944 11.937 14.066 12.9059 13.1142 13.5569L13.1009 13.6436L15.2201 15.2479L15.3669 15.2622C16.7153 14.0453 17.4926 12.2548 17.4926 10.131Z" fill="#4285F4" />
      <path d="M10.6394 16.952C12.5668 16.952 14.1849 16.3319 15.3668 15.2623L13.1141 13.557C12.5113 13.9678 11.7022 14.2546 10.6394 14.2546C8.75162 14.2546 7.14939 13.0377 6.57824 11.3557L6.49453 11.3627L4.29096 13.0292L4.26215 13.1074C5.43605 15.3863 7.84735 16.952 10.6394 16.952Z" fill="#34A853" />
      <path d="M6.57828 11.3556C6.42758 10.9216 6.34036 10.4565 6.34036 9.97594C6.34036 9.49534 6.42758 9.0303 6.57035 8.59624L6.56636 8.5038L4.33518 6.81055L4.26218 6.84448C3.77835 7.79012 3.50073 8.85204 3.50073 9.97594C3.50073 11.0998 3.77835 12.1617 4.26218 13.1073L6.57828 11.3556Z" fill="#FBBC05" />
      <path d="M10.6394 5.69734C11.9799 5.69734 12.8841 6.26317 13.3997 6.73601L15.4144 4.81374C14.1771 3.68985 12.5669 3 10.6394 3C7.84737 3 5.43606 4.5657 4.26215 6.84448L6.57033 8.59624C7.14941 6.91427 8.75165 5.69734 10.6394 5.69734Z" fill="#EB4335" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="14" height="14" fill="white" transform="translate(3.5 3)" />
      </clipPath>
    </defs>
  </Icon>
);

export default GoogleIcon;
