/* eslint-disable quote-props */
import { TimeSlot } from '../../types/UserSchedule';

const toOptions: Record<TimeSlot, string> = {
  '0000': '12:15 AM',
  '0015': '12:30 AM',
  '0030': '12:45 AM',
  '0045': '1:00 AM',
  '0100': '1:15 AM',
  '0115': '1:30 AM',
  '0130': '1:45 AM',
  '0145': '2:00 AM',
  '0200': '2:15 AM',
  '0215': '2:30 AM',
  '0230': '2:45 AM',
  '0245': '3:00 AM',
  '0300': '3:15 AM',
  '0315': '3:30 AM',
  '0330': '3:45 AM',
  '0345': '4:00 AM',
  '0400': '4:15 AM',
  '0415': '4:30 AM',
  '0430': '4:45 AM',
  '0445': '5:00 AM',
  '0500': '5:15 AM',
  '0515': '5:30 AM',
  '0530': '5:45 AM',
  '0545': '6:00 AM',
  '0600': '6:15 AM',
  '0615': '6:30 AM',
  '0630': '6:45 AM',
  '0645': '7:00 AM',
  '0700': '7:15 AM',
  '0715': '7:30 AM',
  '0730': '7:45 AM',
  '0745': '8:00 AM',
  '0800': '8:15 AM',
  '0815': '8:30 AM',
  '0830': '8:45 AM',
  '0845': '9:00 AM',
  '0900': '9:15 AM',
  '0915': '9:30 AM',
  '0930': '9:45 AM',
  '0945': '10:00 AM',
  '1000': '10:15 AM',
  '1015': '10:30 AM',
  '1030': '10:45 AM',
  '1045': '11:00 AM',
  '1100': '11:15 AM',
  '1115': '11:30 AM',
  '1130': '11:45 AM',
  '1145': '12:00 PM',
  '1200': '12:15 PM',
  '1215': '12:30 PM',
  '1230': '12:45 PM',
  '1245': '1:00 PM',
  '1300': '1:15 PM',
  '1315': '1:30 PM',
  '1330': '1:45 PM',
  '1345': '2:00 PM',
  '1400': '2:15 PM',
  '1415': '2:30 PM',
  '1430': '2:45 PM',
  '1445': '3:00 PM',
  '1500': '3:15 PM',
  '1515': '3:30 PM',
  '1530': '3:45 PM',
  '1545': '4:00 PM',
  '1600': '4:15 PM',
  '1615': '4:30 PM',
  '1630': '4:45 PM',
  '1645': '5:00 PM',
  '1700': '5:15 PM',
  '1715': '5:30 PM',
  '1730': '5:45 PM',
  '1745': '6:00 PM',
  '1800': '6:15 PM',
  '1815': '6:30 PM',
  '1830': '6:45 PM',
  '1845': '7:00 PM',
  '1900': '7:15 PM',
  '1915': '7:30 PM',
  '1930': '7:45 PM',
  '1945': '8:00 PM',
  '2000': '8:15 PM',
  '2015': '8:30 PM',
  '2030': '8:45 PM',
  '2045': '9:00 PM',
  '2100': '9:15 PM',
  '2115': '9:30 PM',
  '2130': '9:45 PM',
  '2145': '10:00 PM',
  '2200': '10:15 PM',
  '2215': '10:30 PM',
  '2230': '10:45 PM',
  '2245': '11:00 PM',
  '2300': '11:15 PM',
  '2315': '11:30 PM',
  '2330': '11:45 PM',
  '2345': '12:00 AM',
};

export default toOptions;
