import { BoxProps } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { Suspense, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SkillDoc } from '../types/Skill';
import BlockListItem from './BlockListItem';
import Catch from './Catch';
import SkillInfo, { SkillInfoSuspenseFallback } from './SkillInfo';

export type Props = {
  skillSnap: QueryDocumentSnapshot<SkillDoc>;
} & BoxProps;

const SkillRowMain: React.FC<Props> = ({ skillSnap, ...boxProps }: Props) => {
  const { skillId: activeSkillId } = useParams<{ skillId: string }>();

  const navigate = useNavigate();
  const onClick = useCallback(
    () => navigate(skillSnap.id),
    [skillSnap.id, navigate],
  );

  return (
    <BlockListItem
      data-intercom-target="SkillRow"
      onClick={onClick}
      aria-pressed={skillSnap.id === activeSkillId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <SkillInfo skillSnap={skillSnap} />
    </BlockListItem>
  );
};

export const SkillRowSuspenseFallback: React.FC = () => (
  <BlockListItem>
    <SkillInfoSuspenseFallback />
  </BlockListItem>
);

export const SkillRowCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillRow: React.FC<Props> = (props) => (
  <Catch fallback={<SkillRowCatchFallback />}>
    <Suspense fallback={<SkillRowSuspenseFallback />}>
      <SkillRowMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillRow;
