import { SearchOptions } from '@algolia/client-search';
import {
  Box,
  Center,
  Container,
  Divider,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import AddPosition64Icon from '../../../icons/AddPosition64Icon';
import CandidateSearchPage, { CandidateSearchPageSuspenseFallback } from './CandidateSearchPage';

export interface Props {
  query: string;
  searchOptions: SearchOptions;
}

const CandidateSearchMain: React.FC<Props> = ({ query, searchOptions }) => {
  const hitsPerPage = 20;
  const itemHeight = 68;

  const [nbPages, seNbPages] = useState<number>(1);

  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  const [currentVisiblePages, setCurrentVisiblePages] = useState<number>(1);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setCurrentVisiblePages(Math.ceil(
      (e.currentTarget.scrollTop + e.currentTarget.clientHeight) / (hitsPerPage * itemHeight),
    ));
    setScrolledToTop(e.currentTarget.scrollTop <= 0);
  }, []);

  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calc = () => {
      if (el.current) {
        const visiblePages = Math.ceil(
          (el.current.scrollTop + el.current.clientHeight) / (hitsPerPage * itemHeight),
        );
        setCurrentVisiblePages(visiblePages);
      } else {
        setCurrentVisiblePages(1);
      }
    };

    window.addEventListener('resize', calc);

    return () => {
      window.removeEventListener('resize', calc);
    };
  }, []);

  const [maxVisiblePages, setMaxVisiblePages] = useState<number>(1);

  useEffect(() => {
    setMaxVisiblePages(Math.max(currentVisiblePages, maxVisiblePages));
  }, [currentVisiblePages, maxVisiblePages]);

  useEffect(() => {
    if (el.current) {
      const visiblePages = Math.ceil(
        (el.current.scrollTop + el.current.clientHeight) / (hitsPerPage * itemHeight),
      );
      setCurrentVisiblePages(visiblePages);
      setMaxVisiblePages(visiblePages);
    } else {
      setCurrentVisiblePages(1);
      setMaxVisiblePages(1);
    }
    seNbPages(1);
  }, [query, searchOptions]);

  if (!nbPages) {
    return (
      <Center height="100%">
        <Container maxW="280px" px={0}>
          <VStack spacing={4}>
            <VStack spacing={2}>
              <Text color="cf.cntEmpty">
                <AddPosition64Icon />
              </Text>
              <Text variant="labelMedium" textAlign="center">
                No candidates found
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Center>
    );
  }

  return (
    <>
      <ScaleFade in={!scrolledToTop}>
        <Container>
          <Divider />
        </Container>
      </ScaleFade>

      <Box ref={el} pb={3} height="100%" overflow="auto" onScroll={handleScroll}>
        <Container>
          <VStack alignItems="stretch">
            <BlockList variant="outline">
              {_.range(nbPages).map((page) => (
                <CandidateSearchPage
                  key={page}
                  query={query}
                  searchOptions={searchOptions}
                  visible={maxVisiblePages - 1 >= page}
                  page={page}
                  hitsPerPage={hitsPerPage}
                  onNbPagesChange={seNbPages}
                />
              ))}
            </BlockList>
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export const CandidateSearchSuspenseFallback: React.FC = () => (
  <Box pb={3} height="100%" overflow="auto">
    <Container>
      <VStack alignItems="stretch">
        <BlockList variant="outline">
          <CandidateSearchPageSuspenseFallback size={20} />
        </BlockList>
      </VStack>
    </Container>
  </Box>
);

export const CandidateSearchCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateSearch: React.FC<Props> = (props) => (
  <Catch fallback={<CandidateSearchCatchFallback />}>
    <Suspense fallback={<CandidateSearchSuspenseFallback />}>
      <CandidateSearchMain {...props} />
    </Suspense>
  </Catch>
);

export default CandidateSearch;
