import { ExcalidrawElement } from '@excalidraw/excalidraw/types/element/types';
import { BinaryFileData } from '@excalidraw/excalidraw/types/types';

export enum MessageType {
  WHITEBOARD_ELEMENT_UPDATE = 'WHITEBOARD_ELEMENT_UPDATE',
  WHITEBOARD_FILE_UPDATE = 'WHITEBOARD_FILE_UPDATE',
  WHITEBOARD_POINTER_UPDATE = 'WHITEBOARD_POINTER_UPDATE',
  WHITEBOARD_SELECTION_UPDATE = 'WHITEBOARD_SELECTION_UPDATE',
}

export type WhiteboardElementUpdateMessage = {
  type: MessageType.WHITEBOARD_ELEMENT_UPDATE;
  element: ExcalidrawElement;
};

export type WhiteboardFileUpdateMessage = {
  type: MessageType.WHITEBOARD_FILE_UPDATE;
  file: BinaryFileData;
};

export type WhiteboardPointerUpdateMessage = {
  type: MessageType.WHITEBOARD_POINTER_UPDATE;
  pointer: {
    x: number;
    y: number;
  };
  button: 'down' | 'up';
};

export type WhiteboardSelectionUpdateMessage = {
  type: MessageType.WHITEBOARD_SELECTION_UPDATE;
  selectedElementIds: {
    [id: string]: boolean;
  }
};

export type DataMessage =
  | WhiteboardPointerUpdateMessage
  | WhiteboardElementUpdateMessage
  | WhiteboardFileUpdateMessage
  | WhiteboardSelectionUpdateMessage;
