import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  GeoPoint,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { Gender } from './Gender';
import { Language } from './Language';
import { Race } from './Race';
import { UserDoc, isUserRef } from './User';

export type UserSensitiveDoc = {
  gender?: Gender;
  languages: Language[];
  location: GeoPoint;
  race?: Race;
  phoneNumber: string;
};

export const isUserSensitiveDoc = (
  data: DocumentData,
): data is UserSensitiveDoc => true;

export const isUserSensitiveRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<UserSensitiveDoc> => ref.id === 'sensitive' && ref.parent.id === 'private' && ref.parent.parent !== null && isUserRef(ref.parent.parent);

export const isUserSensitiveSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<
UserSensitiveDoc
> => isUserSensitiveRef(snap.ref);

export const useUserSensitiveDocRef = (userRef: DocumentReference<UserDoc>) => useMemo(() => doc(collection(userRef, 'private'), 'sensitive') as DocumentReference<UserSensitiveDoc>, [userRef]);
