import { useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Loader from '../../components/Loader';
import useExpertAcceptInvitation from '../../functions/useExpertAcceptInvitation';

export type Params = {
  expertId: string;
  invitationId: string;
};

const OrganizationExpertAcceptInvitationPage: React.FC = () => {
  const { expertId, invitationId } = useParams<Params>();

  const acceptInvitation = useExpertAcceptInvitation();
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    if (!expertId || !invitationId) {
      return;
    }

    acceptInvitation({ expertId, invitationId })
      .then(() => {
        toast({
          title: 'Invitation accepted',
          status: 'success',
        });
        navigate('/');
      })
      .catch((err) => {
        if (err instanceof Error) {
          toast({
            title: 'Failed to accept invitation',
            status: 'error',
            description: err.message,
          });
        }
        navigate('/');
      });
  }, [acceptInvitation, expertId, invitationId, navigate, toast]);

  return (
    <Loader />
  );
};

export default OrganizationExpertAcceptInvitationPage;
