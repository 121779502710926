import { Select } from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';
import { StageActionFilterOperation } from '../../types/Stage';

export type Props = {
  name: string;
};

export type StageActionFilterOperationFieldValue = StageActionFilterOperation;

const StageActionFilterOperationField: React.FC<Props> = ({
  name,
}) => {
  const [field] = useField<StageActionFilterOperation>(name);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Select {...field}>
      <option value={StageActionFilterOperation.LESS}>less than</option>
      <option value={StageActionFilterOperation.LESS_EQUAL}>less or equal to</option>
      <option value={StageActionFilterOperation.EQUAL}>equal to</option>
      <option value={StageActionFilterOperation.MORE_EQUAL}>more or equal to</option>
      <option value={StageActionFilterOperation.MORE}>more than</option>
    </Select>
  );
};

export default StageActionFilterOperationField;
