import {
  Flex,
  HStack,
  Spacer,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  OAuthProvider,
  UserInfo,
  linkWithPopup,
  unlink,
} from 'firebase/auth';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useUser } from 'reactfire';
import MicrosoftIcon from '../../icons/MicrosoftIcon';

const MicrosoftAuthMethod: React.FC = () => {
  const user = useUser();

  const provider = useMemo(
    () => new OAuthProvider('microsoft.com')
      .setCustomParameters({
        prompt: 'select_account',
      }),
    [],
  );

  const [providerData, setProviderData] = useState<UserInfo | null>(
    _.find(user.data?.providerData, { providerId: 'microsoft.com' }) || null,
  );

  const handleDisconnectClick = useCallback(() => {
    if (user.data) {
      unlink(user.data, 'microsoft.com');
      setProviderData(null);
    }
  }, [user.data]);

  const handleConnectClick = useCallback(async () => {
    if (user.data) {
      const result = await linkWithPopup(user.data, provider);
      setProviderData(_.find(result.user?.providerData || [], { providerId: 'microsoft.com' }) || null);
    }
  }, [user.data, provider]);

  return (
    <Flex alignItems="center">
      <HStack alignItems="center" spacing={3}>
        <MicrosoftIcon boxSize={11} />
        <VStack alignItems="start" spacing={1}>
          <Text fontWeight="medium" lineHeight="short">
            Microsoft
          </Text>
          {providerData?.email ? (
            <Text color="cf.cntSecondary" fontSize="sm" lineHeight="shorter" pt="3px" pb="1px">
              {providerData.email}
            </Text>
          ) : (
            <Text fontStyle="italic" color="cf.cntSecondary" fontSize="sm" lineHeight="shorter" pt="3px" pb="1px">
              Disconnected
            </Text>
          )}
        </VStack>
      </HStack>
      <Spacer />

      <Switch
        isChecked={!!providerData}
        onChange={(e) => (e.target.checked
          ? handleConnectClick()
          : handleDisconnectClick()
        )}
      />
    </Flex>
  );
};

export default MicrosoftAuthMethod;
