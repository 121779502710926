import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const CrossInCircleIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10ZM8.58579 10L6.29289 7.70711L7.70711 6.29289L10 8.58579L12.2929 6.29289L13.7071 7.70711L11.4142 10L13.7071 12.2929L12.2929 13.7071L10 11.4142L7.70711 13.7071L6.29289 12.2929L8.58579 10Z" fill="currentColor" />
  </Icon>
);

export default CrossInCircleIcon;
