import React from 'react';
import { useParams } from 'react-router';
import ExpertRefProvider from '../../components/ExpertRefContext';
import { useExpertDocRef } from '../../types/Expert';
import EditExpertScreen from './EditExpertScreen';

export type Params = {
  expertId: string;
  organizationId: string;
};

const OrganizationExpertEditPage: React.FC = () => {
  const { expertId } = useParams<Params>();

  if (!expertId) {
    throw new Error();
  }

  const expertRef = useExpertDocRef(expertId);

  return (
    <ExpertRefProvider expertRef={expertRef} key={expertRef.path}>
      <EditExpertScreen />
    </ExpertRefProvider>
  );
};

export default OrganizationExpertEditPage;
