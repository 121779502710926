import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const AddPosition64Icon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={16} viewBox="0 0 64 64" {...props}>
    <path d="M42 54H10C7.79086 54 6 52.2091 6 50V8H54V42M12 16H48M12 24H48M12 32H42M12 40H30M46 54H62M54 46V62" stroke="currentColor" fill="none" strokeWidth="4" />
  </Icon>
);

export default AddPosition64Icon;
