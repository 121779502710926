import React, { Suspense } from 'react';
import Catch from '../../../components/Catch';
import Loader from '../../../components/Loader';
import LocalDataProvider from '../../../components/LocalDataProvider';
import LocalMediaInputProvider from '../../../components/LocalMediaInputProvider';
import RoomProvider, { RoomContext } from '../../../components/RoomProvider';
import CallRoom from './CallRoom';
import Lobby from './Lobby';

const InterviewerScreenMain: React.FC = () => (
  <LocalMediaInputProvider>
    <LocalDataProvider>
      <RoomProvider>
        <RoomContext.Consumer>
          {(context) => (
            context?.room ? (
              <CallRoom />
            ) : (
              <Lobby />
            )
          )}
        </RoomContext.Consumer>
      </RoomProvider>
    </LocalDataProvider>
  </LocalMediaInputProvider>
);

export const InterviewerScreenCatchFallback: React.FC = () => null;
export const InterviewerScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const InterviewerScreen: React.FC = () => (
  <Catch fallback={<InterviewerScreenCatchFallback />}>
    <Suspense fallback={<InterviewerScreenSuspenseFallback />}>
      <InterviewerScreenMain />
    </Suspense>
  </Catch>
);

export default InterviewerScreen;
