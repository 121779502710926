import React, { useEffect } from 'react';
import Loader from '../../components/Loader';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import useStripeCreatePortalLink from '../../functions/useStripeCreatePortalLink';

const OrganizationBillingPage: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const getBillingPortalSession = useStripeCreatePortalLink();

  useEffect(
    () => {
      getBillingPortalSession({
        returnUrl: `${window.location.origin}/organizations/${organizationRef.id}`,
        organizationId: organizationRef.id,
      }).then(({
        data: { url },
      }) => window.location.replace(url));
    },
    [getBillingPortalSession, organizationRef],
  );

  return (
    <Loader />
  );
};

export default OrganizationBillingPage;
