import {
  ImageProps,
  SkeletonProps,
  ThemingProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { SkillDoc } from '../types/Skill';
import SnapNotFoundError from '../types/SnapshotNotFoundError';
import AssetImage, { AssetImageSuspenseFallback } from './AssetImage';
import Catch from './Catch';

export type Props = ImageProps & ThemingProps<'SkillLogo'> & {
  skillRef: DocumentReference<SkillDoc>;
};

export const SkillLogoMain: React.FC<Props> = ({ skillRef, sx, ...imageProps }) => {
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  if (!skill.logoRef) {
    throw new Error('Skill has no logo');
  }

  const style = useStyleConfig('SkillLogo', {});

  return (
    <AssetImage
      sx={{ ...style, ...sx }}
      assetRef={skill.logoRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
    />
  );
};

export const SkillLogoSuspenseFallback: React.FC<SkeletonProps & ThemingProps<'SkillLogoSkeleton'> & {
  // eslint-disable-next-line react/require-default-props
  skillRef?: DocumentReference<SkillDoc>;
}> = ({ sx, skillRef, ...props }) => {
  const style = useStyleConfig('SkillLogoSkeleton', {});

  return (
    <AssetImageSuspenseFallback
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export const SkillLogoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillLogo: React.FC<Props> = (props) => (
  <Catch fallback={<SkillLogoCatchFallback {...props} />}>
    <Suspense fallback={<SkillLogoSuspenseFallback {...props} />}>
      <SkillLogoMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillLogo;
