import {
  QueryDocumentSnapshot,
  documentId,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { ReactNode, Suspense, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../components/Catch';
import Loader from '../components/Loader';
import { useUserRef } from '../components/UserRefContext';
import { getMemberOrganizationRef, useMembersCollectionGroupRef } from '../types/Member';
import { OrganizationDoc, useOrganizationsCollectionRef } from '../types/Organization';

export type Props = {
  children: (snapshot: QueryDocumentSnapshot<OrganizationDoc> | null) => ReactNode;
};

const LoadOrganizationMain: React.FC<Props> = ({ children }) => {
  const userRef = useUserRef();

  const membersCollectionGroupRef = useMembersCollectionGroupRef();

  const { data: membersSnap } = useFirestoreCollection(
    query(
      membersCollectionGroupRef,
      where('uid', '==', userRef.id),
    ),
  );

  const organizationIds = useMemo(
    () => membersSnap.docs.map(
      (memberSnap) => getMemberOrganizationRef(memberSnap.ref).id,
    ),
    [membersSnap.docs],
  );

  const organizationsCollectionRef = useOrganizationsCollectionRef();
  const { data: organizationsSnap } = useFirestoreCollection(
    query(
      organizationsCollectionRef,
      where(documentId(), 'in', organizationIds),
      orderBy('createdAt', 'asc'),
      limit(1),
    ),
  );

  if (organizationsSnap.docs.length) {
    return (
      <>
        {children(organizationsSnap.docs[0])}
      </>
    );
  }

  return (
    <>
      {children(null)}
    </>
  );
};

export const LoadOrganizationCatchFallback: React.FC = () => null;
export const LoadOrganizationSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const LoadOrganization: React.FC<Props> = (props) => (
  <Catch fallback={<LoadOrganizationCatchFallback />}>
    <Suspense fallback={<LoadOrganizationSuspenseFallback />}>
      <LoadOrganizationMain {...props} />
    </Suspense>
  </Catch>
);

export default LoadOrganization;
