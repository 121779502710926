import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const Pause28Icon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={7} viewBox="0 0 28 28" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 5C7.44772 5 7 5.44772 7 6V22C7 22.5523 7.44772 23 8 23H10C10.5523 23 11 22.5523 11 22V6C11 5.44772 10.5523 5 10 5H8ZM18 5C17.4477 5 17 5.44772 17 6V22C17 22.5523 17.4477 23 18 23H20C20.5523 23 21 22.5523 21 22V6C21 5.44772 20.5523 5 20 5H18Z" fill="currentColor" />
  </Icon>
);

export default Pause28Icon;
