import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ArchiveIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.00003 2H19V7C19 8.10457 18.1046 9 17 9V15C17 16.6569 15.6569 18 14 18H6.00003C4.34318 18 3.00003 16.6569 3.00003 15V9C1.89546 9 1.00003 8.10457 1.00003 7V2ZM5.00003 9V15C5.00003 15.5523 5.44775 16 6.00003 16H14C14.5523 16 15 15.5523 15 15V9H5.00003ZM3.00003 4V7H17V4H3.00003ZM12 13H8.00003V11H12V13Z" fill="currentColor" />
  </Icon>
);

export default ArchiveIcon;
