import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Center,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import useStripeOrganizationSubscriptionCancel from '../functions/useStripeOrganizationSubscriptionCancel';
import usePlatformTier from '../hooks/usePlatformTier';
import ArrowRightIcon from '../icons/ArrowRightIcon';
import CheckIcon from '../icons/CheckIcon';
import MissionKeplerIcon from '../icons/MissionKeplerIcon';
import Spinner from '../icons/Spinner';
import { OrganizationPlatformTier } from '../types/OrganizationCustomerProfile';
import { useOrganizationRef } from './OrganizationRefContext';

const MissionKeplerCard: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const { showNewMessage } = useIntercom();

  const handleHelpClick = useCallback(() => {
    showNewMessage('Hi guys, I need your help!');
  }, [showNewMessage]);

  const cancel = useStripeOrganizationSubscriptionCancel();
  const [isLoading, setLoading] = useState(false);

  const handleCancelClick = useCallback(
    async () => {
      try {
        setLoading(true);
        await cancel({
          organizationId: organizationRef.id,
        });
      } finally {
        setLoading(false);
      }
    },
    [cancel, organizationRef.id],
  );

  const tier = usePlatformTier();

  return (
    <Card
      boxShadow={
        tier === OrganizationPlatformTier.KEPLER
          ? 'rgb(53, 150, 81) 0px 0px 0px 2px inset'
          : 'rgba(2, 23, 41, 0.12) 0px 0px 0px 1px inset'
      }
    >
      {tier === OrganizationPlatformTier.KEPLER ? (
        <Center
          position="absolute"
          top={-3}
          left={0}
          right={0}
        >
          <Badge
            variant="solid"
            colorScheme="green"
            shadow="md"
            bg="#359651"
          >
            ACTIVE
          </Badge>
        </Center>
      ) : null}

      <CardBody>
        <VStack alignItems="stretch" spacing={3}>
          <HStack alignItems="start" spacing={3}>
            <MissionKeplerIcon boxSize={16} />

            <VStack alignItems="stretch" spacing={0}>
              <Text fontWeight="medium" lineHeight={6} fontSize="lg">
                Mission &ldquo;Kepler&rdquo;
              </Text>

              <Text fontSize="7xl" lineHeight={10}>
                Custom
              </Text>
            </VStack>
          </HStack>
          <Text variant="labelSmall" color="cf.cntTertiary">
            Enterprise
          </Text>
          <List spacing={1}>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={ArrowRightIcon} />
              All from Mission &ldquo;Titan&rdquo;
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              SSO
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              White label
            </ListItem>
            <ListItem fontSize="sm" color="cf.cntSecondary" lineHeight="shorter">
              <ListIcon as={CheckIcon} />
              Dedicated CSM
            </ListItem>
          </List>
        </VStack>
      </CardBody>
      <CardFooter>
        <VStack alignItems="stretch" spacing={3} width="100%">
          {!tier || tier !== OrganizationPlatformTier.KEPLER ? (
            <Button
              onClick={handleHelpClick}
              variant="solid"
            >
              Contact us
            </Button>
          ) : (
            <Button
              onClick={handleCancelClick}
              variant="outline"
              isLoading={isLoading}
              spinner={<Spinner />}
              loadingText="Cancelling..."
            >
              Cancel
            </Button>
          )}
        </VStack>
      </CardFooter>
    </Card>
  );
};

export default MissionKeplerCard;
