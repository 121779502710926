import {
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, deleteDoc } from 'firebase/firestore';
import React, { useCallback, useMemo, useState } from 'react';
import MicrosoftIcon from '../../icons/MicrosoftIcon';
import Spinner from '../../icons/Spinner';
import TrashIcon from '../../icons/TrashIcon';
import { MicrosoftCredentialsDoc } from '../../types/MicrosoftAuthCredentials';
import MicrosoftCalendars from './MicrosoftCalendars';

export interface Props {
  microsoftCredentialSnap: QueryDocumentSnapshot<MicrosoftCredentialsDoc>;
}

const MicrosoftCredentialsItem: React.FC<Props> = ({ microsoftCredentialSnap }) => {
  const [loading, setLoading] = useState(false);
  const handleRemoveClick = useCallback(async () => {
    setLoading(true);
    try {
      await deleteDoc(microsoftCredentialSnap.ref);
    } catch (err) {
      /* do nothing */
    } finally {
      setLoading(false);
    }
  }, [microsoftCredentialSnap.ref]);

  const microsoftCredential = useMemo(
    () => microsoftCredentialSnap.data(),
    [microsoftCredentialSnap],
  );

  return (
    <VStack alignItems="stretch" spacing={3} py={3}>
      <HStack spacing={2}>
        <MicrosoftIcon />

        <Text lineHeight="short" fontWeight="medium">
          {microsoftCredential.email}
        </Text>

        <Spacer />

        <IconButton
          icon={<TrashIcon />}
          colorScheme="negative"
          aria-label="Remove"
          spinner={<Spinner />}
          isLoading={loading}
          onClick={handleRemoveClick}
        />
      </HStack>

      <MicrosoftCalendars credentialsRef={microsoftCredentialSnap.ref} />
    </VStack>
  );
};

export default MicrosoftCredentialsItem;
