import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useUserRef } from '../components/UserRefContext';
import { useUserTimezoneDocRef } from '../types/UserTimezone';

export default function useTimezone(): string {
  const userRef = useUserRef();
  const userTimezoneRef = useUserTimezoneDocRef(userRef);
  const { data: userTimezoneSnap } = useFirestoreDoc(userTimezoneRef);
  const userTimezone = useMemo(() => userTimezoneSnap.data(), [userTimezoneSnap]);
  return useMemo(() => userTimezone?.timezone || 'UTC', [userTimezone]);
}
