import {
  Box,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  Spacer,
  UseCheckboxProps,
  VStack,
  Wrap,
  useCheckbox,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { ExpertDoc } from '../../types/Expert';
import BlockListItem from '../BlockListItem';
import Catch from '../Catch';
import DocumentLoader from '../DocumentLoader';
import ExpertInfo, { ExpertInfoSuspenseFallback } from '../ExpertInfo';

export type Props = UseCheckboxProps & {
  expertRef: DocumentReference<ExpertDoc>;
};

const ExpertCheckboxMain: React.FC<Props> = ({ expertRef, ...props }) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);

  return (
    <BlockListItem
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...getCheckboxProps()}
      as="label"
      cursor="pointer"
      _hover={{
        bg: 'cf.bgBlackAlpha3',
      }}
      _checked={{
        bg: 'cf.bgAccentAlpha3',
        color: 'cf.cntAccent',
      }}
      _focus={{
        outlineWidth: '1px',
        outlineColor: 'cf.bgAccent',
        outlineOffset: '-1px',
        outlineStyle: 'solid',
      }}
      _focusVisible={{
        outlineWidth: '1px',
        outlineColor: 'cf.bgAccent',
        outlineOffset: '-1px',
        outlineStyle: 'solid',
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <input {...getInputProps()} />
      <VStack alignItems="stretch" spacing={3}>
        <HStack spacing={4} alignItems="center">
          <Suspense fallback={<ExpertInfoSuspenseFallback />}>
            <DocumentLoader reference={expertRef}>
              {(expertSnap) => (
                <ExpertInfo expertSnap={expertSnap} flexGrow={1} />
              )}
            </DocumentLoader>
          </Suspense>
        </HStack>
      </VStack>
    </BlockListItem>
  );
};

export const ExpertCheckboxSuspenseFallback: React.FC = () => (
  <Box background="white" borderRadius="md" border="1px solid rgba(2, 23, 41, 0.12)" p={4}>
    <Wrap direction="column">
      <Flex direction="row" alignItems="center">
        <HStack spacing={4} alignItems="center">
          <Skeleton height="20px" width="20px" />

          <HStack spacing={2} alignItems="center">
            <SkeletonCircle size="20px" />

            <Skeleton height="20px" width="120px" />
          </HStack>
        </HStack>

        <Spacer />

        <Skeleton height="20px" width="30px" />
      </Flex>
    </Wrap>
  </Box>
);

export const ExpertCheckboxCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertCheckbox: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertCheckboxCatchFallback />}>
    <Suspense fallback={<ExpertCheckboxSuspenseFallback />}>
      <ExpertCheckboxMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertCheckbox;
