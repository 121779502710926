import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { OrganizationDoc } from './Organization';
import { TagDoc } from './Tag';

export type AssetDoc = {
  title: string;
  description: string;
  ref: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  revision: number;
  tagRefs: DocumentReference<TagDoc>[];
  organizationRef: DocumentReference<OrganizationDoc>;
};

export const isAssetDoc = (
  data: DocumentData,
): data is AssetDoc => true;

export const isAssetRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AssetDoc> => ref.parent.id === 'assets';

export const isAssetSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<AssetDoc> => isAssetRef(snap.ref);

export const useAssetsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'assets') as CollectionReference<AssetDoc>, [firestore]);
};

export const useAssetDocRef = (docId: string) => {
  const collectionRef = useAssetsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
