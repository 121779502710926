import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { OrganizationDoc } from './Organization';
import { QuestionDoc } from './Question';
import { SkillDoc } from './Skill';
import { UserDoc } from './User';

export enum InterviewCanceledBy {
  INTERVIEWEE = 'INTERVIEWEE',
  INTERVIEWER = 'INTERVIEWER',
  CUSTOMER = 'CUSTOMER',
  SYSTEM = 'SYSTEM',
}

export enum InterviewCancellationReason {
  MISSED = 'MISSED',
  EXPIRED = 'EXPIRED',
  DECLINED = 'DECLINED',
  UNKNOWN = 'UNKNOWN',
  MISSED_BY_INTERVIEWER = 'MISSED_BY_INTERVIEWER',
  MISSED_BY_INTERVIEWEE = 'MISSED_BY_INTERVIEWEE',
}

export const questionsFieldsToCopy = [
  'title',
  'content',
  'duration',
  'answerHint5',
  'answerHint4',
  'answerHint3',
  'answerHint2',
  'answerHint1',
  'revision',
] as const;

export type InterviewScheduleItem = Pick<QuestionDoc, typeof questionsFieldsToCopy[number]> & {
  questionRef: DocumentReference<QuestionDoc>;
  skillRef: DocumentReference<SkillDoc>;
  score?: number;
  startedAt?: Timestamp;
  endedAt?: Timestamp;
  comments?: string;
};

export enum InterviewStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  CANCELED = 'CANCELED',
}

export type InterviewDoc = {
  language: string;
  region: string;
  candidateRef: DocumentReference<CandidateDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  expertRefs: DocumentReference<ExpertDoc>[];
  vendorRefs: DocumentReference<OrganizationDoc>[];
  intervieweeRef?: DocumentReference<UserDoc>;
  interviewerRefs: DocumentReference<UserDoc>[];
  startsAt: Timestamp;
  endsAt: Timestamp;
  skillRefs: DocumentReference<SkillDoc>[];
  duration: number;
  status: InterviewStatus;
  createdAt: Timestamp;
  canceledBy?: InterviewCanceledBy;
  cancellationReason?: InterviewCancellationReason;
  canceledAt?: Timestamp;
  startedAt?: Timestamp;
  endedAt?: Timestamp;
  connectedUserRefs: DocumentReference<UserDoc>[];
  playbackIsReady?: boolean;
  stripeUsageRecordId?: string;
  _v: 1,
};

export const isInterviewDoc = (
  data: DocumentData,
): data is InterviewDoc => true;

export const isInterviewRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewDoc> => ref.parent.id === 'interviews';

export const isInterviewSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<InterviewDoc> => isInterviewRef(snap.ref);

export const useInterviewsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'interviews') as CollectionReference<InterviewDoc>, [firestore]);
};

export const useInterviewDocRef = (docId: string) => {
  const collectionRef = useInterviewsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
