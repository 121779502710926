import { Text, VStack } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import BlockList from '../../../../components/BlockList';
import Catch from '../../../../components/Catch';
import { ExpertDoc } from '../../../../types/Expert';
import ExpertItem, { ExpertItemSuspenseFallback } from './ExpertItem';

export type Props = {
  expertRefs: DocumentReference<ExpertDoc>[];
};

const ExpertsMain: React.FC<Props> = ({ expertRefs }) => {
  if (!expertRefs.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Experts">
      <Text
        pt="1px"
        pb="3px"
        color="cf.cntTertiary"
        fontSize="sm"
        lineHeight="short"
        fontWeight="medium"
      >
        Experts
        {' '}
        &middot;
        {' '}
        {expertRefs.length}
      </Text>

      <BlockList variant="outline">
        {expertRefs.map((expertRef) => (
          <ExpertItem key={expertRef.id} expertRef={expertRef} />
        ))}
      </BlockList>
    </VStack>
  );
};

export const ExpertsSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Experts
    </Text>

    <BlockList variant="outline">
      <ExpertItemSuspenseFallback />
      <ExpertItemSuspenseFallback />
      <ExpertItemSuspenseFallback />
    </BlockList>
  </VStack>
);

const ExpertsCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Experts: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertsCatchFallback />}>
    <Suspense fallback={<ExpertsSuspenseFallback />}>
      <ExpertsMain {...props} />
    </Suspense>
  </Catch>
);

export default Experts;
