import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import OrganizationRefProvider from '../../components/OrganizationRefContext';
import { useOrganizationDocRef } from '../../types/Organization';

const OrganizationLayout: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();

  if (!organizationId) {
    throw new Error();
  }

  const organizationRef = useOrganizationDocRef(organizationId);

  return (
    <OrganizationRefProvider organizationRef={organizationRef}>
      <Outlet />
    </OrganizationRefProvider>
  );
};

export default OrganizationLayout;
