import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

const StripeLogo: React.FC<BoxProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box as="svg" viewBox="0 0 56 24" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" color="rgb(99,91,255)" {...props}>
    <path d="M37.941,5.585C36.489,5.585 35.556,6.267 35.037,6.741L34.844,5.822L31.585,5.822L31.585,23.096L35.289,22.311L35.304,18.119C35.837,18.504 36.622,19.052 37.926,19.052C40.578,19.052 42.993,16.919 42.993,12.222C42.978,7.926 40.533,5.585 37.941,5.585ZM15.096,2.622L11.481,3.393L11.467,15.259C11.467,17.452 13.111,19.067 15.304,19.067C16.519,19.067 17.407,18.844 17.896,18.578L17.896,15.57C17.422,15.763 15.081,16.444 15.081,14.252L15.081,8.993L17.896,8.993L17.896,5.837L15.081,5.837L15.096,2.622ZM5.081,9.6C5.081,9.022 5.556,8.8 6.341,8.8C7.467,8.8 8.889,9.141 10.015,9.748L10.015,6.267C8.785,5.778 7.57,5.585 6.341,5.585C3.333,5.585 1.333,7.156 1.333,9.778C1.333,13.867 6.963,13.215 6.963,14.978C6.963,15.659 6.37,15.881 5.541,15.881C4.311,15.881 2.741,15.378 1.496,14.696L1.496,18.222C2.874,18.815 4.267,19.067 5.541,19.067C8.622,19.067 10.741,17.541 10.741,14.889C10.726,10.474 5.081,11.259 5.081,9.6ZM54.667,12.37C54.667,8.578 52.83,5.585 49.319,5.585C45.793,5.585 43.659,8.578 43.659,12.341C43.659,16.8 46.178,19.052 49.793,19.052C51.556,19.052 52.889,18.652 53.896,18.089L53.896,15.126C52.889,15.63 51.733,15.941 50.267,15.941C48.83,15.941 47.556,15.437 47.393,13.689L54.637,13.689C54.637,13.496 54.667,12.726 54.667,12.37ZM30.207,5.837L26.489,5.837L26.489,18.8L30.207,18.8L30.207,5.837ZM22.504,6.933L22.267,5.837L19.067,5.837L19.067,18.8L22.77,18.8L22.77,10.015C23.644,8.874 25.126,9.081 25.585,9.244L25.585,5.837C25.111,5.659 23.378,5.333 22.504,6.933ZM37.052,15.793C36.178,15.793 35.659,15.481 35.304,15.096L35.289,9.6C35.674,9.17 36.207,8.874 37.052,8.874C38.4,8.874 39.333,10.385 39.333,12.326C39.333,14.311 38.415,15.793 37.052,15.793ZM47.348,10.963C47.348,9.289 48.37,8.593 49.304,8.593C50.207,8.593 51.17,9.289 51.17,10.963L47.348,10.963ZM26.489,4.711L30.207,3.911L30.207,0.904L26.489,1.689L26.489,4.711Z" fill="currentColor" />
  </Box>
);

export default StripeLogo;
