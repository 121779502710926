import {
  Box,
  Center,
  Container,
  Divider,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  getCountFromServer,
  limit,
  or,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, {
  Suspense,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFirestoreCollection, useRemoteConfigString } from 'reactfire';
import BlockList from '../../../components/BlockList';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import SkillRow, { SkillRowSuspenseFallback } from '../../../components/SkillRow';
import AddPosition64Icon from '../../../icons/AddPosition64Icon';
import Spinner from '../../../icons/Spinner';
import { useOrganizationsCollectionRef } from '../../../types/Organization';
import { useSkillsCollectionRef } from '../../../types/Skill';

export type Props = {
  showExternal: boolean;
};

const SkillListMain: React.FC<Props> = ({ showExternal }) => {
  const organizationRef = useOrganizationRef();

  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const currentPage = useDeferredValue(page);
  const perPage = 20;

  const organizationsCollectionRef = useOrganizationsCollectionRef();
  const skillsCollectionRef = useSkillsCollectionRef();

  const { data: clarwisOrganizationId } = useRemoteConfigString('clarwis_organization_id');

  const clarwisOrganizationRef = useMemo(
    () => doc(organizationsCollectionRef, clarwisOrganizationId),
    [clarwisOrganizationId, organizationsCollectionRef],
  );

  const q1 = useMemo(
    () => {
      if (showExternal) {
        return query(
          skillsCollectionRef,
          or(
            where('organizationRef', '==', organizationRef),
            where('organizationRef', '==', clarwisOrganizationRef),
          ),
          limit(currentPage * perPage),
          orderBy('name'),
        );
      }
      return query(
        skillsCollectionRef,
        where('organizationRef', '==', organizationRef),
        limit(currentPage * perPage),
        orderBy('name'),
      );
    },
    [clarwisOrganizationRef, currentPage, skillsCollectionRef, organizationRef, showExternal],
  );

  const { data: skillsSnap } = useFirestoreCollection(q1);

  const q2 = useMemo(
    () => {
      if (showExternal) {
        return query(
          skillsCollectionRef,
          or(
            where('organizationRef', '==', organizationRef),
            where('organizationRef', '==', clarwisOrganizationRef),
          ),
        );
      }
      return query(
        skillsCollectionRef,
        where('organizationRef', '==', organizationRef),
      );
    },
    [clarwisOrganizationRef, skillsCollectionRef, organizationRef, showExternal],
  );

  useEffect(
    () => {
      getCountFromServer(q2).then(
        (v) => setTotalPages(Math.ceil(v.data().count / perPage)),
      );
    },
    [q2],
  );

  const el = useRef<HTMLDivElement>(null);

  const [scrolledToTop, setScrolledToTop] = useState<boolean>(true);
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    setScrolledToTop(e.currentTarget.scrollTop <= 0);

    const scrollBottom = e.currentTarget.scrollHeight
      - e.currentTarget.scrollTop
      - e.currentTarget.clientHeight;

    if (scrollBottom <= 0) {
      if (totalPages > currentPage) {
        setPage(currentPage + 1);
      }
    }
  }, [currentPage, totalPages]);

  if (!skillsSnap.docs.length) {
    return (
      <Center height="100%">
        <Container maxW="280px" px={0}>
          <VStack spacing={4}>
            <VStack spacing={2}>
              <Text color="cf.cntEmpty">
                <AddPosition64Icon />
              </Text>
              <Text variant="labelMedium" textAlign="center">
                No skills yet
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Center>
    );
  }

  return (
    <>
      <ScaleFade in={!scrolledToTop}>
        <Container>
          <Divider />
        </Container>
      </ScaleFade>

      <Box ref={el} pb={3} height="100%" overflow="auto" onScroll={handleScroll}>
        <Container>
          <VStack alignItems="stretch" spacing={3}>
            <BlockList variant="outline">
              {skillsSnap.docs.map((skillSnap) => (
                <SkillRow key={skillSnap.id} skillSnap={skillSnap} />
              ))}
            </BlockList>

            {totalPages > currentPage ? (
              <Center>
                <Spinner color="cf.cntSecondary" />
              </Center>
            ) : null}
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export const SkillListSuspenseFallback: React.FC = () => (
  <Box pb={3} height="100%" overflow="auto">
    <Container>
      <BlockList variant="outline">
        <SkillRowSuspenseFallback />
        <SkillRowSuspenseFallback />
        <SkillRowSuspenseFallback />
        <SkillRowSuspenseFallback />
        <SkillRowSuspenseFallback />
      </BlockList>
    </Container>
  </Box>
);

export const SkillListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillList: React.FC<Props> = (props) => (
  <Catch fallback={<SkillListCatchFallback />}>
    <Suspense fallback={<SkillListSuspenseFallback />}>
      <SkillListMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillList;
