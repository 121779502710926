import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CredentialsList from './CredentialsList';
import SyncGoogleCalendarButton from './SyncGoogleCalendarButton';
import SyncMicrosoftCalendarButton from './SyncMicrosoftCalendarButton';

const ConnectedCalendars: React.FC = () => (
  <VStack alignItems="stretch" spacing={4}>
    <SyncGoogleCalendarButton />

    <SyncMicrosoftCalendarButton />

    <VStack alignItems="stretch" spacing={1}>
      <Text pt="1px" pb="3px" fontSize="sm" fontWeight="medium" color="cf.cntTertiary">
        Calendars
      </Text>

      <CredentialsList />
    </VStack>
  </VStack>
);

export default ConnectedCalendars;
