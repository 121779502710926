import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MicOffIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 5V8.58579L14.7659 10.3517C14.9174 9.92945 15 9.47437 15 9V5C15 2.79086 13.2091 1 11 1H9C8.00085 1 7.08726 1.36633 6.38623 1.97201L7.80805 3.39384C8.14093 3.1464 8.55337 3 9 3H11C12.1046 3 13 3.89543 13 5ZM18.0082 13.594L16.5311 12.1169C16.8319 11.474 17 10.7566 17 10H19C19 11.314 18.638 12.5434 18.0082 13.594Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.19061 3.7764L2.70711 1.29289C2.31658 0.902369 1.68342 0.902369 1.29289 1.29289C0.902369 1.68342 0.902369 2.31658 1.29289 2.70711L5 6.41421V9C5 11.2091 6.79086 13 9 13H11C11.1861 13 11.3692 12.9873 11.5485 12.9627L13.39 14.8042C12.9487 14.9317 12.4823 15 12 15H10H8C5.23858 15 3 12.7614 3 10H1C1 13.866 4.13401 17 8 17H9V18C9 18.5523 9.44771 19 10 19C10.5523 19 11 18.5523 11 18V17H12C13.0501 17 14.0462 16.7688 14.9402 16.3544L17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L16.6482 15.234L5.19061 3.7764ZM7 9V8.41421L9.58579 11H9C7.89543 11 7 10.1046 7 9Z" fill="currentColor" />
  </Icon>
);

export default MicOffIcon;
