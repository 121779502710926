import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MicOnIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M5 5C5 2.79086 6.79086 1 9 1H11C13.2091 1 15 2.79086 15 5V9C15 11.2091 13.2091 13 11 13H9C6.79086 13 5 11.2091 5 9V5ZM9 3C7.89543 3 7 3.89543 7 5V9C7 10.1046 7.89543 11 9 11H11C12.1046 11 13 10.1046 13 9V5C13 3.89543 12.1046 3 11 3H9ZM11 17H12C15.866 17 19 13.866 19 10H17C17 12.7614 14.7614 15 12 15H10H8C5.23858 15 3 12.7614 3 10H1C1 13.866 4.13401 17 8 17H9V18C9 18.5523 9.44771 19 10 19C10.5523 19 11 18.5523 11 18V17Z" fill="currentColor" />
  </Icon>
);

export default MicOnIcon;
