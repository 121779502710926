import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';

export type AvatarResolutionMap = {
  '1x'?: string;
  '1.5x'?: string;
  '2x'?: string;
  '3x'?: string;
};

export type AvatarDoc = {
  original: string;
  xs?: AvatarResolutionMap;
  sm?: AvatarResolutionMap;
  md?: AvatarResolutionMap;
  '2xl'?: AvatarResolutionMap;
};

export const isAvatarDoc = (
  data: DocumentData,
): data is AvatarDoc => true;

export const isAvatarRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AvatarDoc> => ref.parent.id === 'avatars';

export const isAvatarSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<AvatarDoc> => isAvatarRef(snap.ref);

export const useAvatarsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'avatars') as CollectionReference<AvatarDoc>, [firestore]);
};

export const useAvatarDocRef = (docId: string) => {
  const collectionRef = useAvatarsCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
