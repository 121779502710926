import { DocumentReference } from 'firebase/firestore';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import AlertInTriangleFilledIcon from '../icons/AlertInTriangleFilledIcon';
import UserIcon from '../icons/UserIcon';
import { UserDoc } from '../types/User';
import Catch from './Catch';
import StorageAvatar, { StorageAvatarProps, StorageAvatarStatic } from './StorageAvatar';

export type ProfileAvatarProps = Omit<StorageAvatarProps, 'avatarRef'> & {
  userRef: DocumentReference<UserDoc>;
};

const UserAvatarMain: React.FC<ProfileAvatarProps> = ({
  userRef,
  ...avatarProps
}) => {
  const { data: userSnap } = useFirestoreDoc(userRef);

  const user = useMemo(() => userSnap.data(), [userSnap]);

  if (!user) {
    return (
      <StorageAvatarStatic
        icon={<AlertInTriangleFilledIcon color="cf.cntNegative" />}
      />
    );
  }

  return (
    <StorageAvatar
      icon={<UserIcon />}
      avatarRef={user.avatarRef}
      name={`${user.firstName} ${user.lastName}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
    />
  );
};

export const UserAvatarCatchFallback: React.FC = () => null;
export const UserAvatarSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const UserAvatar: React.FC<ProfileAvatarProps> = (props) => (
  <Catch fallback={<UserAvatarCatchFallback />}>
    <Suspense fallback={<UserAvatarSuspenseFallback />}>
      <UserAvatarMain {...props} />
    </Suspense>
  </Catch>
);

export default UserAvatar;
