import {
  Box,
  BoxProps,
  Button,
  Divider,
  HStack,
  Heading,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, getDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useRemoteConfigString } from 'reactfire';
import BlockListItem from '../../../components/BlockListItem';
import Catch from '../../../components/Catch';
import SkillShortInfo from '../../../components/SkillShortInfo';
import CrossIcon from '../../../icons/CrossIcon';
import LinkedInIcon from '../../../icons/LinkedInIcon';
import { ScoreDoc } from '../../../types/Score';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

export type Props = BoxProps & {
  scoreSnap: QueryDocumentSnapshot<ScoreDoc>;
};

const LegendRowMain: React.FC<Props> = ({ scoreSnap, ...boxProps }) => {
  const score = useMemo(() => scoreSnap.data(), [scoreSnap]);

  const { data: domainCustomer } = useRemoteConfigString('domain_customer');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onClick = useCallback(
    async () => {
      const skillSnap = await getDoc(score.skillRef);

      if (!skillSnap.exists()) {
        throw new SnapNotFoundError(skillSnap);
      }

      const skill = skillSnap.data();

      const issue = moment.tz(score.timestamp.toDate(), 'utc');
      const expiration = moment(issue).add(1, 'year');

      const url = new URL('https://www.linkedin.com/profile/add');
      url.searchParams.set('startTask', 'CERTIFICATION_NAME');
      url.searchParams.set('name', skill.name);
      url.searchParams.set('organizationId', '68229267');
      url.searchParams.set('issueYear', issue.format('YYYY'));
      url.searchParams.set('issueMonth', issue.format('M'));
      url.searchParams.set('expirationYear', expiration.format('YYYY'));
      url.searchParams.set('expirationMonth', expiration.format('M'));
      url.searchParams.set('certUrl', `https://${domainCustomer}/scores/${scoreSnap.id}`);
      url.searchParams.set('certId', scoreSnap.id);

      window.open(url.toString(), '_blank');
    },
    [domainCustomer, score.skillRef, score.timestamp, scoreSnap.id],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BlockListItem {...boxProps} onClick={onOpen}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <HStack alignItems="center" px={5} py={3}>
            <Heading flexGrow={1}>
              Share your score
            </Heading>

            <IconButton
              icon={<CrossIcon />}
              aria-label="Close"
              variant="outline"
              onClick={onClose}
            />
          </HStack>

          <Divider />

          <Box px={5} py={3}>
            <Button
              leftIcon={<LinkedInIcon color="white" />}
              colorScheme="linkedin"
              onClick={onClick}
              size="lg"
            >
              Add to your LinkedIn profile
            </Button>
          </Box>
        </ModalContent>
      </Modal>

      <HStack>
        <SkillShortInfo skillRef={score.skillRef} />
        <Text
          lineHeight="short"
          fontWeight="medium"
          py={1}
          px={3}
          color="cf.cntAccent"
          bg="cf.bgLightAccent"
          borderRadius="md"
          flexShrink={0}
        >
          {Math.round(score.score * 100)}
        </Text>
      </HStack>
    </BlockListItem>
  );
};

const LegendRowCatchFallback: React.FC = () => null;
const LegendRowSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const LegendRow: React.FC<Props> = (props) => (
  <Catch fallback={<LegendRowCatchFallback />}>
    <Suspense fallback={<LegendRowSuspenseFallback />}>
      <LegendRowMain {...props} />
    </Suspense>
  </Catch>
);

export default LegendRow;
