import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MicrosoftIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" {...props}>
    <g transform="matrix(0.869565,0,0,0.869565,0,0)">
      <rect x="0" y="0" width="23" height="23" fill="rgb(243,243,243)" fillRule="nonzero" />
    </g>
    <g transform="matrix(0.869565,0,0,0.869565,0,0)">
      <rect x="1" y="1" width="10" height="10" fill="rgb(243,83,37)" fillRule="nonzero" />
    </g>
    <g transform="matrix(0.869565,0,0,0.869565,0,0)">
      <rect x="12" y="1" width="10" height="10" fill="rgb(129,188,6)" fillRule="nonzero" />
    </g>
    <g transform="matrix(0.869565,0,0,0.869565,0,0)">
      <rect x="1" y="12" width="10" height="10" fill="rgb(5,166,240)" fillRule="nonzero" />
    </g>
    <g transform="matrix(0.869565,0,0,0.869565,0,0)">
      <rect x="12" y="12" width="10" height="10" fill="rgb(255,186,8)" fillRule="nonzero" />
    </g>
  </Icon>
);

export default MicrosoftIcon;
