import {
  Box,
  Container,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { orderBy, query, where } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import Availability from '../../../components/Availability';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import { CandidateDoc } from '../../../types/Candidate';
import { InterviewStatus, useInterviewsCollectionRef } from '../../../types/Interview';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import ContactInfo, { ContactInfoSuspenseFallback } from './ContactInfo';
import Header, { HeaderSuspenseFallback } from './Header';
import InterviewsList, { InterviewsListSuspenseFallback } from './InterviewsList';
import InvitationsList from './InvitationsList';
import ResendInvitation from './ResendInvitation';
import Skills, { SkillsSuspenseFallback } from './Skills';
import ScoreChartItem, { ScoreChartItemSuspenseFallback } from './Skills/ScoreChartItem';
import Stage from './Stage';

const CandidateDetailsScreenMain: React.FC = () => {
  const candidateRef = useCandidateRef();
  const organizationRef = useOrganizationRef();
  const interviewsCollectionRef = useInterviewsCollectionRef();

  const { data: interviewsSnap } = useFirestoreCollection(
    query(
      interviewsCollectionRef,
      where('candidateRef', '==', candidateRef),
      where('customerRef', '==', organizationRef),
      where('status', 'in', [InterviewStatus.CREATED, InterviewStatus.STARTED, InterviewStatus.ENDED]),
      orderBy('startsAt'),
    ),
  );

  const { data: candidateSnap } = useFirestoreDoc<CandidateDoc>(candidateRef);

  if (!candidateSnap.exists()) {
    throw new SnapNotFoundError(candidateSnap);
  }

  const candidate = useMemo(() => candidateSnap.data(), [candidateSnap]);

  const interviewSkillRefs = useMemo(() => _.uniqBy(
    interviewsSnap.docs
      .filter(
        (interviewSnap) => interviewSnap.data()?.status === InterviewStatus.ENDED,
      )
      .flatMap(
        (interviewSnap) => interviewSnap.data()?.skillRefs || [],
      ),
    (ref) => ref.path,
  ), [interviewsSnap.docs]);

  return (
    <VStack
      height="100%"
      spacing={0}
      alignItems="stretch"
      overflow="hidden"
      data-intercom-target="OrganizationCandidateDetailsPage"
    >
      <Header />

      <Divider />

      <Box overflow="auto" flexGrow={1} flexShrink={1}>
        <Container>
          <VStack spacing={8} py={3} alignItems="stretch">
            <ContactInfo />

            {candidate.stageRef ? (
              <Stage />
            ) : null}

            <ScoreChartItem skillRefs={interviewSkillRefs} />

            <Skills />

            {!candidate.userRef ? (
              <InvitationsList />
            ) : null}

            {candidate.userRef ? (
              <Availability userRef={candidate.userRef} />
            ) : null}

            <InterviewsList interviewSnaps={interviewsSnap.docs} />
          </VStack>
        </Container>
      </Box>

      <Divider />

      <Box flexGrow={0} flexShrink={0}>
        <Container>
          <VStack
            py={4}
            _empty={{
              display: 'none',
            }}
          >
            {!candidate.userRef ? (
              <ResendInvitation />
            ) : null}
          </VStack>
        </Container>
      </Box>
    </VStack>
  );
};

export const CandidateDetailsScreenSuspenseFallback: React.FC = () => (
  <VStack
    height="100%"
    spacing={0}
    alignItems="stretch"
    overflow="hidden"
  >
    <HeaderSuspenseFallback />

    <Divider />

    <Box overflow="auto" flexGrow={1} flexShrink={1}>
      <Container py={4}>
        <VStack spacing={8} alignItems="stretch">
          <ContactInfoSuspenseFallback />
          <ScoreChartItemSuspenseFallback />
          <SkillsSuspenseFallback />
          <InterviewsListSuspenseFallback />
        </VStack>
      </Container>
    </Box>
  </VStack>
);

const CandidateDetailsScreenCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const CandidateDetailsScreen: React.FC = () => (
  <Catch fallback={<CandidateDetailsScreenCatchFallback />}>
    <Suspense fallback={<CandidateDetailsScreenSuspenseFallback />}>
      <CandidateDetailsScreenMain />
    </Suspense>
  </Catch>
);

export default CandidateDetailsScreen;
