import { Text, VStack } from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../components/BlockList';
import BlockListItem from '../../components/BlockListItem';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import PaddingBlock from '../../components/PaddingBlock';
import { useMembersCollectionRef } from '../../types/Member';
import {
  useOrganizationMemberInvitationsCollectionRef,
} from '../../types/OrganizationMemberInvitation';
import InvitationItem from './InvitationItem';
import MemberInvitationForm from './MemberInvitationForm';
import MemberItem from './MemberItem';

const MembersMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const membersCollectionRef = useMembersCollectionRef(organizationRef);
  const { data: membersSnap } = useFirestoreCollection(membersCollectionRef);

  const organizationMemberInvitationsCollectionRef = useOrganizationMemberInvitationsCollectionRef(
    organizationRef,
  );
  const { data: memberInvitationsSnap } = useFirestoreCollection(
    query(
      organizationMemberInvitationsCollectionRef,
      where('accepted', '==', false),
    ),
  );

  return (
    <VStack alignItems="stretch" spacing={8}>
      <VStack spacing={1} alignItems="stretch">
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
        >
          Members
          {' '}
          &middot;
          {' '}
          {membersSnap.docs.length}
        </Text>

        <BlockList variant="outline">
          {membersSnap.docs.map((memberSnap) => (
            <BlockListItem key={memberSnap.id}>
              <MemberItem memberSnap={memberSnap} />
            </BlockListItem>
          ))}

          {memberInvitationsSnap.docs.map((invitationSnap) => (
            <BlockListItem key={invitationSnap.id}>
              <InvitationItem invitationSnap={invitationSnap} />
            </BlockListItem>
          ))}
        </BlockList>
      </VStack>

      <VStack spacing={1} alignItems="stretch">
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
        >
          New Member
        </Text>

        <PaddingBlock>
          <MemberInvitationForm />
        </PaddingBlock>
      </VStack>
    </VStack>
  );
};

const MembersCatchFallback: React.FC = () => null;
const MembersSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Members: React.FC = () => (
  <Catch fallback={<MembersCatchFallback />}>
    <Suspense fallback={<MembersSuspenseFallback />}>
      <MembersMain />
    </Suspense>
  </Catch>
);

export default Members;
