import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React from 'react';

export type Props = {
  name: string;
};

const InfluenceField: React.FC<Props> = ({ name }) => {
  const [field, , helper] = useField<number>({ name });

  return (
    <Slider
      aria-label="influence"
      orientation="vertical"
      value={field.value}
      onChange={helper.setValue}
      min={0}
      max={0.99}
      step={0.01}
      minH="100px"
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  );
};

export default InfluenceField;
