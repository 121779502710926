import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { CandidateDoc } from './Candidate';
import { ExpertDoc } from './Expert';
import { InterviewDoc } from './Interview';
import { OrganizationDoc } from './Organization';
import { SkillDoc } from './Skill';
import { UserDoc } from './User';

export type ScoreDoc = {
  userRef: DocumentReference<UserDoc>;
  skillRef: DocumentReference<SkillDoc>;
  interviewRef: DocumentReference<InterviewDoc>;
  candidateRef: DocumentReference<CandidateDoc>;
  customerRef: DocumentReference<OrganizationDoc>;
  expertRefs: DocumentReference<ExpertDoc>[];
  vendorRefs: DocumentReference<OrganizationDoc>[];
  score: number;
  timestamp: Timestamp;
  _v: 1;
};

export const isScoreDoc = (
  data: DocumentData,
): data is ScoreDoc => true;

export const isScoreRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<ScoreDoc> => ref.parent.id === 'scores';

export const isScoreSnap = (
  snap: DocumentSnapshot<DocumentData>,
): snap is DocumentSnapshot<ScoreDoc> => isScoreRef(snap.ref);

export const useScoresCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'scores') as CollectionReference<ScoreDoc>, [firestore]);
};

export const useScoreDocRef = (docId: string) => {
  const collectionRef = useScoresCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
