import {
  Box,
  Container,
  Divider,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { isSignInWithEmailLink } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import { useAuth } from 'reactfire';
import EmailForm from './EmailForm';
import EmailRedirect from './EmailRedirect';
import EmailSent from './EmailSent';
import GoogleButton from './GoogleButton';
import MicrosoftButton from './MicrosoftButton';

const LoginPage: React.FC = () => {
  const auth = useAuth();

  const [emailSent, setEmailSent] = useState<boolean>(false);

  const handleEmailSent = useCallback(() => {
    setEmailSent(true);
  }, []);

  if (isSignInWithEmailLink(auth, window.location.href)) {
    return (
      <EmailRedirect />
    );
  }

  if (emailSent) {
    return (
      <EmailSent />
    );
  }

  return (
    <Container maxW="container.sm">
      <VStack alignItems="stretch" spacing={5} py={3}>
        <Box py={1}>
          <Heading>
            Welcome to Clarwis
          </Heading>
        </Box>

        <Text>
          Unlock the future of streamlined technical interviews
        </Text>

        <VStack alignItems="stretch">
          <GoogleButton />
          <MicrosoftButton />
        </VStack>

        <Divider />

        <EmailForm onEmailSent={handleEmailSent} />
      </VStack>
    </Container>
  );
};

export default LoginPage;
