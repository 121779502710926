import { Select } from '@chakra-ui/react';
import {
  DocumentReference,
  doc,
  orderBy,
  query,
} from 'firebase/firestore';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import { SkillDoc, useSkillsCollectionRef } from '../../types/Skill';

export type Props = {
  name: string;
};

export type StageActionFilterSkillRefFieldValue = DocumentReference<SkillDoc> | null;

const StageActionFilterSkillRefField: React.FC<Props> = ({
  name,
}) => {
  const [field, , helpers] = useField<DocumentReference<SkillDoc> | null>(name);

  const skillsCollectionRef = useSkillsCollectionRef();

  const value = useMemo(
    () => field.value?.id ?? '',
    [field.value],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helpers.setValue(
        doc(skillsCollectionRef, e.target.value),
      );
    },
    [skillsCollectionRef, helpers],
  );

  const { data: skillsSnap } = useFirestoreCollection(
    query(
      skillsCollectionRef,
      orderBy('name'),
    ),
  );

  return (
    <Select value={value} onChange={onChange}>
      <option value="">
        Select skill
      </option>

      {skillsSnap.docs.map((skillSnap) => (
        <option
          key={skillSnap.id}
          value={skillSnap.id}
        >
          {skillSnap.data().name}
        </option>
      ))}
    </Select>
  );
};

export default StageActionFilterSkillRefField;
