import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const MissionMarsIcon: React.FC<IconProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" {...props}>
    <path d="M20,9.991C20,14.116 17.486,17.656 13.901,19.171C13.898,19.172 13.896,19.173 13.892,19.175C12.696,19.679 11.38,19.958 10,19.958C8.574,19.958 7.217,19.66 5.99,19.124L5.989,19.124C5.316,18.83 4.683,18.464 4.097,18.037C3.932,17.916 3.771,17.791 3.613,17.66L3.613,17.66C3.497,17.564 3.384,17.467 3.273,17.366C2.461,16.63 1.771,15.762 1.237,14.797C1.227,14.78 1.218,14.762 1.208,14.744C1.205,14.739 1.203,14.734 1.2,14.73C0.435,13.32 0,11.706 0,9.991C0,5.888 2.488,2.363 6.043,0.835C6.044,0.835 6.044,0.835 6.044,0.835C7.257,0.313 8.595,0.025 10,0.025C11.37,0.025 12.675,0.299 13.864,0.796C14.917,1.236 15.88,1.851 16.713,2.604C16.714,2.604 16.714,2.604 16.714,2.604C16.834,2.713 16.952,2.825 17.066,2.939C17.104,2.977 17.142,3.015 17.179,3.054L17.18,3.054C17.222,3.098 17.264,3.142 17.306,3.186C17.335,3.217 17.364,3.248 17.392,3.28C17.41,3.298 17.427,3.317 17.443,3.335C17.52,3.421 17.596,3.507 17.669,3.595C17.669,3.595 17.67,3.595 17.67,3.596C18.043,4.04 18.378,4.516 18.669,5.021C18.669,5.021 18.67,5.021 18.67,5.021C18.742,5.147 18.812,5.274 18.879,5.402C18.894,5.43 18.908,5.459 18.923,5.487C18.935,5.511 18.947,5.534 18.959,5.558C18.96,5.561 18.962,5.564 18.964,5.567C18.963,5.567 18.964,5.567 18.964,5.567C19.044,5.729 19.12,5.893 19.192,6.059L19.192,6.06C19.712,7.266 20,8.595 20,9.991" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path opacity="0.8" d="M17.534,8.255C18.128,8.144 18.717,8.491 18.904,9.063C18.95,9.205 19.023,9.356 19.133,9.51C19.746,10.368 18.791,16.687 13.66,14.158C11.469,13.078 13.66,8.983 17.534,8.255" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path d="M8.231,17.897C7.743,17.66 7.48,17.197 7.643,16.864C7.807,16.532 8.334,16.455 8.822,16.693C9.31,16.93 9.573,17.393 9.41,17.725C9.246,18.058 8.719,18.135 8.231,17.897" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.6" d="M8.26,17.839C7.872,17.65 7.667,17.273 7.802,16.998C7.938,16.723 8.361,16.653 8.749,16.841C9.137,17.03 9.341,17.407 9.206,17.682C9.071,17.958 8.647,18.028 8.26,17.839" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path d="M19.192,6.059L19.192,6.06C19.15,6.164 19.096,6.264 19.027,6.356C18.408,7.193 16.91,7.141 15.682,6.238C14.453,5.335 13.96,3.925 14.58,3.087C15.004,2.515 15.836,2.358 16.713,2.604C16.714,2.604 16.714,2.604 16.714,2.604C16.834,2.713 16.952,2.825 17.066,2.939C17.104,2.977 17.142,3.015 17.179,3.054L17.18,3.054C17.222,3.098 17.264,3.142 17.306,3.186C17.335,3.217 17.364,3.248 17.392,3.28C17.41,3.298 17.427,3.317 17.443,3.335C17.52,3.421 17.596,3.507 17.669,3.595C17.669,3.595 17.67,3.595 17.67,3.596C18.043,4.04 18.378,4.516 18.669,5.021C18.669,5.021 18.67,5.021 18.67,5.021C18.742,5.147 18.812,5.274 18.879,5.402C18.894,5.43 18.908,5.459 18.923,5.487C18.935,5.511 18.947,5.534 18.959,5.558C18.96,5.561 18.962,5.564 18.964,5.567C19.044,5.729 19.12,5.893 19.192,6.059" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.6" d="M18.964,5.567C18.963,5.567 18.964,5.567 18.964,5.567C18.928,5.728 18.862,5.877 18.764,6.01C18.251,6.704 17.044,6.684 16.068,5.967C15.092,5.25 14.716,4.106 15.229,3.412C15.616,2.889 16.399,2.772 17.179,3.054L17.18,3.054C17.222,3.098 17.264,3.142 17.306,3.186C17.335,3.217 17.364,3.248 17.392,3.28C17.41,3.298 17.427,3.317 17.443,3.335C17.52,3.421 17.596,3.507 17.669,3.595C17.669,3.595 17.67,3.595 17.67,3.596C18.043,4.04 18.378,4.516 18.669,5.021C18.669,5.021 18.67,5.021 18.67,5.021C18.742,5.147 18.812,5.274 18.879,5.402C18.894,5.43 18.908,5.459 18.923,5.487C18.935,5.511 18.947,5.534 18.959,5.558C18.96,5.561 18.962,5.564 18.964,5.567" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path d="M7.969,14.192C7.999,13.79 8.334,13.488 8.718,13.517C9.101,13.545 9.388,13.893 9.358,14.294C9.328,14.695 8.993,14.998 8.609,14.969C8.226,14.941 7.94,14.593 7.969,14.192" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.6" d="M8.084,14.2C8.107,13.881 8.373,13.641 8.678,13.664C8.982,13.686 9.21,13.963 9.186,14.282C9.162,14.6 8.896,14.84 8.592,14.818C8.287,14.795 8.06,14.519 8.084,14.2" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path d="M2.06,11.082C1.628,10.429 1.784,9.568 2.408,9.159C3.031,8.749 3.886,8.946 4.318,9.598C4.749,10.251 4.593,11.111 3.97,11.521C3.346,11.931 2.491,11.734 2.06,11.082" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.6" d="M2.155,11.019C1.812,10.501 1.956,9.803 2.476,9.462C2.996,9.12 3.696,9.263 4.039,9.782C4.381,10.3 4.238,10.997 3.718,11.339C3.197,11.681 2.498,11.537 2.155,11.019" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path d="M10.133,3.446C9.621,3.138 9.367,2.622 9.565,2.294C9.764,1.966 10.34,1.95 10.853,2.259C11.365,2.567 11.619,3.083 11.42,3.411C11.222,3.739 10.645,3.755 10.133,3.446" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.6" d="M10.178,3.371C9.771,3.126 9.572,2.712 9.734,2.445C9.895,2.178 10.357,2.161 10.764,2.406C11.171,2.651 11.37,3.065 11.208,3.332C11.047,3.599 10.586,3.617 10.178,3.371" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path opacity="0.8" d="M7.969,2.965C7.969,2.965 4.371,-0.875 1.74,7.098C0.715,10.206 9.51,4.816 7.969,2.965" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.6" d="M5.891,4.086C5.319,3.91 4.947,3.473 5.059,3.109C5.172,2.745 5.727,2.593 6.299,2.769C6.871,2.945 7.244,3.383 7.131,3.746C7.018,4.11 6.463,4.262 5.891,4.086" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path opacity="0.6" d="M4.903,5.389C4.882,5.788 4.507,6.093 4.066,6.07C3.626,6.046 3.286,5.704 3.307,5.304C3.328,4.905 3.703,4.6 4.144,4.624C4.584,4.647 4.924,4.99 4.903,5.389" fill="rgb(255,0,50)" fillRule="nonzero" />
    <path opacity="0.65" d="M4.097,18.037C3.932,17.916 3.771,17.791 3.613,17.66L3.613,17.66C3.497,17.564 3.384,17.466 3.273,17.366C2.461,16.63 1.771,15.762 1.237,14.797C1.227,14.78 1.218,14.762 1.208,14.744C1.266,14.493 1.421,14.249 1.701,14.021C1.853,13.896 1.999,13.79 2.138,13.701C2.142,13.699 2.147,13.696 2.151,13.693C4.076,12.479 4.767,14.583 5.628,15.481C5.852,15.715 6.019,15.996 6.111,16.306C6.113,16.313 6.116,16.32 6.117,16.327C6.471,17.543 5.199,18.553 4.097,18.037" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.7" d="M3.613,17.66L3.613,17.66C3.497,17.564 3.384,17.466 3.273,17.366C2.461,16.63 1.771,15.762 1.237,14.797C1.302,14.626 1.424,14.46 1.616,14.302C1.772,14.175 1.918,14.072 2.056,13.989C2.06,13.987 2.064,13.984 2.068,13.982C3.623,13.067 4.195,14.785 4.904,15.526C5.092,15.721 5.231,15.956 5.308,16.216C5.31,16.222 5.312,16.227 5.313,16.233C5.61,17.254 4.538,18.102 3.613,17.66" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.65" d="M15.072,16.904C15.056,17.26 14.791,17.555 14.441,17.645C13.61,17.858 10.038,17.199 10.314,15.904C10.681,14.181 15.089,16.519 15.072,16.904" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.7" d="M14.292,16.844C14.281,17.084 14.102,17.282 13.867,17.343C13.308,17.486 10.904,17.043 11.089,16.171C11.336,15.012 14.303,16.585 14.292,16.844" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.65" d="M13.711,8.101C13.707,8.114 13.702,8.128 13.698,8.141C13.425,8.939 12.393,9.121 11.849,8.472C11.54,8.102 11.042,7.671 10.572,7.971C10.572,7.971 9.348,8.384 10.505,9.517C11.156,10.155 11.115,11.037 10.954,11.686C10.75,12.514 9.63,12.585 9.316,11.788C8.727,10.295 7.553,11.811 7.349,11.933C7.349,11.933 6.528,12.767 6.255,11.725C6.086,11.079 6.536,10.634 6.917,10.384C7.161,10.223 7.278,9.922 7.217,9.631C7.126,9.196 7.112,8.572 7.59,8.123C8.135,7.611 8.899,7.355 9.338,7.243C9.807,7.123 10.079,6.632 9.914,6.169C9.905,6.143 9.895,6.117 9.884,6.091C9.689,5.624 9.898,5.085 10.346,4.867C10.664,4.712 11.07,4.638 11.548,4.811C12.559,5.179 14.134,6.789 13.711,8.101" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path d="M6.036,0.836C6.036,0.836 9.834,-1.018 13.864,0.796C13.864,0.796 11.44,1.545 9.896,0.97C9.525,0.831 9.139,0.735 8.744,0.705C8.159,0.66 7.265,0.658 6.036,0.836" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path d="M5.973,19.121C5.973,19.121 9.84,21.065 13.902,19.171C13.902,19.171 11.377,18.413 9.832,18.988C9.461,19.126 9.076,19.223 8.681,19.253C8.096,19.298 7.201,19.299 5.973,19.121" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path opacity="0.7" d="M10.635,6.563C10.216,6.005 10.83,5.253 11.457,5.561C11.807,5.732 12.208,6.08 12.61,6.751C13.497,8.234 12.444,7.986 11.976,7.823C11.847,7.778 11.729,7.707 11.628,7.615C11.4,7.41 10.954,6.988 10.635,6.563" fill="rgb(247,135,0)" fillRule="nonzero" />
    <path d="M16.392,10.093C16.795,9.251 18.107,9.347 18.144,10.225C18.165,10.714 18.004,11.385 17.453,12.29C16.235,14.291 15.848,13.013 15.741,12.406C15.711,12.238 15.72,12.064 15.765,11.892C15.865,11.506 16.086,10.734 16.392,10.093" fill="rgb(255,0,50)" fillRule="nonzero" />
  </Icon>
);

export default MissionMarsIcon;
