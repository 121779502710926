import React from 'react';
import { Outlet } from 'react-router';
import { useUser } from 'reactfire';

export type CheckAuthProps = {
  fallback: React.ReactElement;
};

const CheckAuthLayout: React.FC<CheckAuthProps> = ({ fallback }) => {
  const { data: user } = useUser();
  return user ? <Outlet /> : fallback;
};

export default CheckAuthLayout;
