import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  organizationId: string;
};

export type ResponseData = void;

const useStripeOrganizationSubscriptionCancel = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(
      functions,
      'stripe-organization-subscription-cancel',
    ),
    [functions],
  );
};

export default useStripeOrganizationSubscriptionCancel;
