import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const TrashIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 1V3H18V5H17V16C17 17.6569 15.6569 19 14 19H6C4.34315 19 3 17.6569 3 16V5H2V3H8V1H12ZM15 5H5V16C5 16.5523 5.44772 17 6 17H14C14.5523 17 15 16.5523 15 16V5ZM7 15V7H9V15H7ZM11 15V7H13V15H11Z" fill="currentColor" />
  </Icon>
);

export default TrashIcon;
