import { useEffect, useState } from 'react';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

export default function useRemoteTrackEnabled(
  track: RemoteVideoTrack | RemoteAudioTrack | null,
): boolean | null {
  const [enabled, setEnabled] = useState<boolean | null>(track ? track.isEnabled : null);

  useEffect(() => {
    if (!track) {
      setEnabled(null);
      return () => { };
    }

    setEnabled(track.isEnabled);

    const enable = () => { setEnabled(true); };
    const disable = () => { setEnabled(false); };

    track.on('enabled', enable);
    track.on('disabled', disable);

    return () => {
      track.off('enabled', enable);
      track.off('disabled', disable);
    };
  }, [track]);

  return enabled;
}
