import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router';
import Logo from '../../components/Logo';

const AuthLayout: React.FC = () => (
  <Grid
    gridTemplateColumns="auto 1fr"
    gridTemplateRows="1fr"
    height="100%"
    width="100%"
  >
    <GridItem
      py={3}
      pl={5}
      boxSizing="border-box"
      borderBottomWidth="none"
      borderBottomStyle="solid"
    >
      <Logo />
    </GridItem>

    <GridItem minH={0} overflow="auto">
      <Outlet />
    </GridItem>
  </Grid>
);

export default AuthLayout;
