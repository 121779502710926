import * as Automerge from '@automerge/automerge';
import { Box } from '@chakra-ui/react';
import Editor from '@monaco-editor/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import React, { Suspense, useMemo } from 'react';
import Catch from '../../components/Catch';
import base64ToUint8Array from '../../helpers/base64ToUint8Array';
import Spinner from '../../icons/Spinner';
import { CodeEditorDoc, InterviewScheduleItemDoc } from '../../types/InterviewScheduleItem';
import { usePlayback } from './PlaybackScaleProvider';

export type Props = {
  scheduleItemSnap: QueryDocumentSnapshot<InterviewScheduleItemDoc>;
};

const PlaybackCodeEditorMain: React.FC<Props> = ({ scheduleItemSnap }) => {
  const { playbackTime, interviewToPlaybackTime } = usePlayback();

  const scheduleItem = useMemo(() => scheduleItemSnap.data(), [scheduleItemSnap]);

  const history = useMemo(() => {
    const automergeDoc = Automerge.load<CodeEditorDoc>(
      base64ToUint8Array(scheduleItem.codeEditorState),
    );
    return Automerge.getHistory(automergeDoc);
  }, [scheduleItem.codeEditorState]);

  const currentState = useMemo(
    () => _.findLast(history, ({ change: { time } }) => {
      const timestamp = Math.round(interviewToPlaybackTime(time));
      return timestamp < playbackTime;
    })?.snapshot,
    [history, interviewToPlaybackTime, playbackTime],
  );

  const currentValue = useMemo(
    () => currentState?.value.toString() || '',
    [currentState],
  );

  const currentLanguage = useMemo(
    () => currentState?.language || 'plaintext',
    [currentState],
  );

  return (
    <Box
      borderColor="cf.brdBlackAlpha12"
      borderRadius="sm"
      overflow="hidden"
      borderWidth={1}
      h="100%"
      w="100%"
    >
      <Editor
        width="100%"
        options={{
          minimap: { enabled: false },
          readOnly: true,
        }}
        language={currentLanguage}
        value={currentValue}
        path="./index.ts"
        loading={<Spinner />}
      />
    </Box>
  );
};

const PlaybackCodeEditorCatchFallback: React.FC = () => null;
const PlaybackCodeEditorSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const PlaybackCodeEditor: React.FC<Props> = (props) => (
  <Catch fallback={<PlaybackCodeEditorCatchFallback />}>
    <Suspense fallback={<PlaybackCodeEditorSuspenseFallback />}>
      <PlaybackCodeEditorMain {...props} />
    </Suspense>
  </Catch>
);

export default PlaybackCodeEditor;
