import {
  Box,
  BoxProps,
  HStack,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import BlockListItem from '../../../../components/BlockListItem';
import Catch from '../../../../components/Catch';
import SkillInfo from '../../../../components/SkillInfo';
import { SkillDoc } from '../../../../types/Skill';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

export type Props = BoxProps & {
  skillRef: DocumentReference<SkillDoc>;
};

const SkillItemMain: React.FC<Props> = ({ skillRef }) => {
  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const navigate = useNavigate();

  return (
    <BlockListItem
      data-intercom-target="SkillRow"
      onClick={() => navigate(`../../skills/${skillSnap.id}`)}
    >
      <SkillInfo skillSnap={skillSnap} />
    </BlockListItem>
  );
};

export const SkillItemSuspenseFallback: React.FC = () => (
  <BlockListItem>
    <HStack>
      <SkeletonCircle boxSize={5} />

      <Box flexGrow={1}>
        <Skeleton h={5} w="120px" />
      </Box>

      <Skeleton h={7} w="35px" borderRadius="md" />
    </HStack>
  </BlockListItem>
);

const SkillItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillItem: React.FC<Props> = (props) => (
  <Catch fallback={<SkillItemCatchFallback />}>
    <Suspense fallback={<SkillItemSuspenseFallback />}>
      <SkillItemMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillItem;
