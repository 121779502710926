import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import {
  DocumentReference,
  doc,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useField } from 'formik';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useFirestoreCollection } from 'reactfire';
import { StageDoc, useStagesCollectionRef } from '../types/Stage';
import { useOrganizationRef } from './OrganizationRefContext';

export type Props = {
  label: string;
  name: string;
  isRequired?: boolean;
};

const StageRefField: React.FC<Props> = ({
  label,
  name,
  isRequired,
}) => {
  const [field, meta, helpers] = useField<DocumentReference<StageDoc> | null>(name);

  const organizationRef = useOrganizationRef();
  const stagesCollectionRef = useStagesCollectionRef();

  const { data: stagesSnap } = useFirestoreCollection(
    query(
      stagesCollectionRef,
      where('organizationRef', '==', organizationRef),
      orderBy('createdAt', 'desc'),
    ),
  );

  const value = useMemo(
    () => field.value?.id ?? '',
    [field.value],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      helpers.setValue(
        e.target.value === ''
          ? null
          : doc(stagesCollectionRef, e.target.value),
      );
    },
    [stagesCollectionRef, helpers],
  );

  return (
    <FormControl isInvalid={!!meta.error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>

      <Select value={value} onChange={onChange}>
        <option value="">
          None
        </option>

        {stagesSnap.docs.map((stageSnap) => (
          <option key={stageSnap.id} value={stageSnap.id}>
            {stageSnap.data().name}
          </option>
        ))}
      </Select>

      <FormErrorMessage>
        {meta.error}
      </FormErrorMessage>
    </FormControl>
  );
};

StageRefField.defaultProps = {
  isRequired: false,
};

export default StageRefField;
