import {
  HStack,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import moment from 'moment';
import React, { Suspense, useMemo } from 'react';
import { useRemoteConfigString } from 'reactfire';
import Catch from '../../../../components/Catch';
import { useExpertRef } from '../../../../components/ExpertRefContext';
import useCurrentTime from '../../../../hooks/useCurrentTime';
import useTimezone from '../../../../hooks/useTimezone';
import CheckIcon from '../../../../icons/CheckIcon';
import CopyIcon from '../../../../icons/CopyIcon';
import LinkIcon from '../../../../icons/LinkIcon';
import { ExpertInvitationDoc } from '../../../../types/ExpertInvitation';

export interface Props {
  invitationSnap: QueryDocumentSnapshot<ExpertInvitationDoc>;
}

const InvitationRowMain: React.FC<Props> = ({ invitationSnap }) => {
  const expertRef = useExpertRef();
  const timezone = useTimezone();
  const invitation = useMemo(() => invitationSnap.data(), [invitationSnap]);

  const currentTime = useCurrentTime(1000 * 60);

  const status = useMemo(() => {
    const c = {
      sameDay: '[today]',
      nextDay: '[tomorrow]',
      nextWeek: 'dddd',
      lastDay: '[yesterday]',
      lastWeek: '[last] dddd',
      sameElse: 'DD/MM/YYYY',
    };

    switch (invitation.status) {
      case 'sent': {
        const when = moment(invitation.sentAt.toDate()).tz(timezone).calendar(currentTime, c);
        return `Sent ${when}`;
      }
      case 'processed': {
        const when = moment(invitation.processedAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Processed ${when}`;
      }
      case 'dropped': {
        const when = moment(invitation.droppedAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Dropped ${when}`;
      }
      case 'deferred': {
        const when = moment(invitation.deferredAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Deferred ${when}`;
      }
      case 'bounce': {
        const when = moment(invitation.bounceAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Bounced ${when}`;
      }
      case 'delivered': {
        const when = moment(invitation.deferredAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Delivered ${when}`;
      }
      case 'open': {
        const when = moment(invitation.openAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Was opened ${when}`;
      }
      case 'click': {
        const when = moment(invitation.clickAt?.toDate()).tz(timezone).calendar(currentTime, c);
        return `Was clicked ${when}`;
      }
      default: return 'Unknown status';
    }
  }, [
    currentTime,
    invitation.bounceAt,
    invitation.clickAt,
    invitation.deferredAt,
    invitation.droppedAt,
    invitation.openAt,
    invitation.processedAt,
    invitation.sentAt,
    invitation.status,
    timezone,
  ]);

  const { data: domain } = useRemoteConfigString('domain_customer');

  const expertInvitationLink = useMemo(
    () => `https://${domain}/experts/${expertRef.id}/invitations/${invitationSnap.id}/accept`,
    [domain, expertRef.id, invitationSnap.id],
  );

  const { hasCopied, onCopy: handleCopyLinkClick } = useClipboard(expertInvitationLink);

  return (
    <HStack>
      <LinkIcon />

      <Text variant="labelMedium" flexGrow={1}>
        {status}
      </Text>

      <Tooltip label="Copy invitation link">
        <IconButton
          aria-label="Copy invitation link"
          icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
          variant="outline"
          onClick={handleCopyLinkClick}
        />
      </Tooltip>
    </HStack>
  );
};

export const InvitationRowCatchFallback: React.FC = () => null;
export const InvitationRowSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const InvitationRow: React.FC<Props> = (props) => (
  <Catch fallback={<InvitationRowCatchFallback />}>
    <Suspense fallback={<InvitationRowSuspenseFallback />}>
      <InvitationRowMain {...props} />
    </Suspense>
  </Catch>
);

export default InvitationRow;
