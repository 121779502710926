import { GeoPoint } from 'firebase/firestore';
import { getString } from 'firebase/remote-config';
import _ from 'lodash';
import { useCallback } from 'react';
import { useRemoteConfig } from 'reactfire';

export type RequestData = { query: string };

export type Record = { location: GeoPoint; name: string; };
export type ResponseData = Record[];

export type GeocodeResponse = {
  results: {
    address_components: {
      long_name: string;
      short_name: string;
      types: string[];
    }[];
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    place_id: string;
    types: string[];
  }[];
  status: string;
};

const useGoogleSearchCities = () => {
  const remoteConfig = useRemoteConfig();

  const geocodingApiKey = getString(remoteConfig, 'geocodingApiKey');

  return useCallback(async ({ query }: RequestData): Promise<ResponseData> => {
    const url = new URL('https://maps.googleapis.com/maps/api/geocode/json');
    url.searchParams.set('components', `locality:"${query}"`);
    url.searchParams.set('key', geocodingApiKey);
    url.searchParams.set('language', 'en');

    const resp = await fetch(url.toString());
    const { results, status } = await resp.json() as GeocodeResponse;

    if (status !== 'OK') {
      return [];
    }

    return results.map((city) => ({
      location: new GeoPoint(
        city.geometry.location.lat,
        city.geometry.location.lng,
      ),
      name: _.compact([
        _.find(city.address_components, ({ types }) => _.includes(types, 'locality'))?.long_name,
        _.find(city.address_components, ({ types }) => _.includes(types, 'administrative_area_level_2'))?.short_name,
        _.find(city.address_components, ({ types }) => _.includes(types, 'administrative_area_level_1'))?.short_name,
        _.find(city.address_components, ({ types }) => _.includes(types, 'country'))?.long_name,
      ]).join(', '),
    }));
  }, [geocodingApiKey]);
};

export default useGoogleSearchCities;
