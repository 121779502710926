import { Box } from '@chakra-ui/react';
import { Excalidraw, MainMenu } from '@excalidraw/excalidraw';
import { ExcalidrawAPIRefValue } from '@excalidraw/excalidraw/types/types';
import { QueryDocumentSnapshot, orderBy, query } from 'firebase/firestore';
import _ from 'lodash';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFirestoreCollection } from 'reactfire';
import Catch from '../../components/Catch';
import { useInterviewRef } from '../../components/InterviewRefContext';
import {
  InterviewWhiteboardElementDoc,
  useInterviewWhiteboardElementsCollectionRef,
} from '../../types/InterviewWhiteboardElement';
import {
  InterviewWhiteboardElementStateDoc,
  useInterviewWhiteboardElementStatesCollectionRef,
} from '../../types/InterviewWhiteboardElementState';
import { usePlayback } from './PlaybackScaleProvider';

type ElProps = {
  whiteboardElementSnap: QueryDocumentSnapshot<InterviewWhiteboardElementDoc>;
  setState: (
    element: QueryDocumentSnapshot<InterviewWhiteboardElementDoc>,
    state: QueryDocumentSnapshot<InterviewWhiteboardElementStateDoc> | undefined
  ) => void;
};

const El: React.FC<ElProps> = ({ whiteboardElementSnap, setState }) => {
  const { playbackTime, interviewToPlaybackTime } = usePlayback();

  // eslint-disable-next-line max-len
  const interviewWhiteboardElementStatesCollectionRef = useInterviewWhiteboardElementStatesCollectionRef(
    whiteboardElementSnap.ref,
  );

  const { data: whiteboardElementStatesSnap } = useFirestoreCollection(
    query(
      interviewWhiteboardElementStatesCollectionRef,
      orderBy('timestamp'),
    ),
  );

  const [
    currentState,
    setCurrentState,
  ] = useState<QueryDocumentSnapshot<InterviewWhiteboardElementStateDoc> | undefined>();

  useEffect(
    () => {
      const state = _.findLast(whiteboardElementStatesSnap.docs, (snap) => {
        const data = snap.data();
        const timestamp = Math.round(interviewToPlaybackTime(data.timestamp.toMillis()));
        return timestamp < playbackTime;
      });

      if (
        currentState?.data().timestamp.toMillis()
        !== state?.data().timestamp.toMillis()
      ) {
        setCurrentState(state);
      }
    },
    [currentState, interviewToPlaybackTime, playbackTime, whiteboardElementStatesSnap.docs],
  );

  useEffect(
    () => {
      setState(whiteboardElementSnap, currentState);
    },
    [currentState, setState, whiteboardElementSnap],
  );

  return null;
};

const PlaybackWhiteboardMain: React.FC = () => {
  const [excalidraw, setExcalidraw] = useState<ExcalidrawAPIRefValue | null>(null);
  const onRefChange = useCallback((newExcalidraw: ExcalidrawAPIRefValue) => {
    setExcalidraw(newExcalidraw);
  }, []);

  const interviewRef = useInterviewRef();

  const interviewWhiteboardElementsCollectionRef = useInterviewWhiteboardElementsCollectionRef(
    interviewRef,
  );

  const { data: whiteboardElementsSnap } = useFirestoreCollection(
    interviewWhiteboardElementsCollectionRef,
  );

  const handleStateChange = useCallback(
    (
      element: QueryDocumentSnapshot<InterviewWhiteboardElementDoc>,
      state: QueryDocumentSnapshot<InterviewWhiteboardElementStateDoc> | undefined,
    ) => {
      if (excalidraw?.ready) {
        const existing = _.cloneDeep(excalidraw.getSceneElementsIncludingDeleted());

        if (state) {
          excalidraw.updateScene({
            elements: [
              ..._.filter(existing, (el) => el.id !== element.id),
              JSON.parse(state.data().data),
            ],
          });
        } else {
          excalidraw.updateScene({
            elements: [
              ..._.filter(existing, (el) => el.id !== element.id),
            ],
          });
        }
      }
    },
    [excalidraw],
  );

  return (
    <Box h="100%" w="100%" borderColor="cf.brdBlackAlpha12" borderWidth={1}>
      {whiteboardElementsSnap.docs.map((whiteboardElementSnap) => (
        <El
          key={whiteboardElementSnap.id}
          whiteboardElementSnap={whiteboardElementSnap}
          setState={handleStateChange}
        />
      ))}

      <Excalidraw
        isCollaborating
        viewModeEnabled
        gridModeEnabled
        ref={onRefChange}
      >
        <MainMenu>
          <MainMenu.DefaultItems.ClearCanvas />
          <MainMenu.DefaultItems.SaveAsImage />
          <MainMenu.DefaultItems.Export />
        </MainMenu>
      </Excalidraw>
    </Box>
  );
};

const PlaybackWhiteboardCatchFallback: React.FC = () => null;
const PlaybackWhiteboardSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const PlaybackWhiteboard: React.FC = () => (
  <Catch fallback={<PlaybackWhiteboardCatchFallback />}>
    <Suspense fallback={<PlaybackWhiteboardSuspenseFallback />}>
      <PlaybackWhiteboardMain />
    </Suspense>
  </Catch>
);

export default PlaybackWhiteboard;
