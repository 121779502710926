import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
// eslint-disable-next-line import/no-cycle
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { ExpertDoc, ExpertTier } from './Expert';
import { MemberDoc } from './Member';
import { OrganizationDoc } from './Organization';
import { SkillDoc } from './Skill';
// eslint-disable-next-line import/no-cycle
import { StageDoc } from './Stage';
import { UserDoc } from './User';

export enum CandidateStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  FINALIZED = 'FINALIZED',
  ARCHIVED = 'ARCHIVED',
}

export enum CandidatePauseReason {
  INTERVIEW_MISSED_BY_CANDIDATE = 'INTERVIEW_MISSED_BY_CANDIDATE',
  INTERVIEW_MISSED_BY_EXPERT = 'INTERVIEW_MISSED_BY_EXPERT',
  NO_MATCHING_EXPERT = 'NO_MATCHING_EXPERT',
  NO_PAYMENT_METHOD = 'NO_PAYMENT_METHOD',
}

export type CandidateDoc = {
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  customerUrl?: string;
  createdAt: Timestamp;
  assigneeRefs?: DocumentReference<MemberDoc>[];
  userRef?: DocumentReference<UserDoc>;
  organizationRef: DocumentReference<OrganizationDoc>;
  skillRefs: DocumentReference<SkillDoc>[];
  requiredExpertRefs: DocumentReference<ExpertDoc>[];
  optionalExpertRefs: DocumentReference<ExpertDoc>[];
  status: CandidateStatus;
  pauseReason?: CandidatePauseReason;
  stageRef?: DocumentReference<StageDoc>;
  _v: 4;
};

export const isCandidateDoc = (
  data: DocumentData,
): data is CandidateDoc => true;

export const isCandidateRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CandidateDoc> => ref.parent.id === 'candidates';

export const isCandidateSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<CandidateDoc> => isCandidateRef(snap.ref);

export const useCandidatesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'candidates') as CollectionReference<CandidateDoc>, [firestore]);
};

export const useCandidateDocRef = (docId: string) => {
  const collectionRef = useCandidatesCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
