import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const ChevronInternalLieutenantIcon: React.FC<IconProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" {...props}>
    <path d="M10,0.5C11.028,0.5 17.713,4.359 18.227,5.25C18.741,6.141 18.741,13.859 18.227,14.75C17.713,15.641 11.028,19.5 10,19.5C8.972,19.5 2.287,15.641 1.773,14.75C1.259,13.859 1.259,6.141 1.773,5.25C2.287,4.359 8.972,0.5 10,0.5Z" fill="rgb(34,68,104)" />
    <path d="M10,0C9.81,0 9.46,0.089 9.006,0.275C8.131,0.632 6.771,1.342 5.444,2.108C4.116,2.874 2.822,3.697 2.074,4.277C1.687,4.577 1.435,4.836 1.34,5C1.245,5.164 1.147,5.513 1.081,5.998C0.953,6.935 0.887,8.467 0.887,10C0.887,11.533 0.953,13.065 1.081,14.002C1.147,14.487 1.245,14.836 1.34,15C1.435,15.164 1.687,15.423 2.074,15.723C2.822,16.303 4.116,17.126 5.444,17.892C6.771,18.658 8.131,19.368 9.006,19.725C9.46,19.911 9.81,20 10,20C10.19,20 10.54,19.911 10.994,19.725C11.869,19.368 13.229,18.658 14.556,17.892C15.884,17.126 17.178,16.303 17.926,15.723C18.313,15.423 18.565,15.164 18.66,15C18.755,14.836 18.853,14.487 18.919,14.002C19.047,13.065 19.113,11.533 19.113,10C19.113,8.467 19.047,6.935 18.919,5.998C18.853,5.513 18.755,5.164 18.66,5C18.565,4.836 18.313,4.577 17.926,4.277C17.178,3.697 15.884,2.874 14.556,2.108C13.229,1.342 11.869,0.632 10.994,0.275C10.54,0.089 10.19,0 10,0ZM10,1C10.119,1 10.331,1.084 10.616,1.2C11.462,1.546 12.774,2.234 14.056,2.974C15.339,3.714 16.591,4.507 17.313,5.067C17.556,5.255 17.735,5.397 17.794,5.5C17.854,5.603 17.887,5.829 17.929,6.133C18.052,7.039 18.113,8.519 18.113,10C18.113,11.481 18.052,12.961 17.929,13.867C17.887,14.171 17.854,14.397 17.794,14.5C17.735,14.603 17.556,14.745 17.313,14.933C16.591,15.493 15.339,16.286 14.056,17.026C12.774,17.766 11.462,18.454 10.616,18.8C10.331,18.916 10.119,19 10,19C9.881,19 9.669,18.916 9.384,18.8C8.538,18.454 7.226,17.766 5.944,17.026C4.661,16.286 3.409,15.493 2.687,14.933C2.444,14.745 2.265,14.603 2.206,14.5C2.146,14.397 2.113,14.171 2.071,13.867C1.948,12.961 1.887,11.481 1.887,10C1.887,8.519 1.948,7.039 2.071,6.133C2.113,5.829 2.146,5.603 2.206,5.5C2.265,5.397 2.444,5.255 2.687,5.067C3.409,4.507 4.661,3.714 5.944,2.974C7.226,2.234 8.538,1.546 9.384,1.2C9.669,1.084 9.881,1 10,1ZM10,5.115L11.656,7.69L14.468,8.57L12.679,11.042L12.761,14.16L10,13.113L7.239,14.16L7.321,11.042L5.532,8.57L8.344,7.69L10,5.115Z" fill="rgb(157,175,194)" />
  </Icon>
);

export default ChevronInternalLieutenantIcon;
