import { HStack } from '@chakra-ui/react';
import React from 'react';
import { StageActionFilterOperation } from '../../types/Stage';
import StageActionFilterOperationField from './StageActionFilterOperationField';
import StageActionFilterScoreField, {
  StageActionFilterScoreFieldValue,
} from './StageActionFilterScoreField';
import StageActionFilterSkillRefField, {
  StageActionFilterSkillRefFieldValue,
} from './StageActionFilterSkillRefField';

export type Props = {
  name: string;
};

export type StageActionSkillScoreFilterFieldValue = {
  skillRef: StageActionFilterSkillRefFieldValue;
  score: StageActionFilterScoreFieldValue;
  operation: StageActionFilterOperation;
};

const StageActionSkillScoreFilterField: React.FC<Props> = ({
  name,
}) => (
  <HStack>
    <StageActionFilterSkillRefField name={`${name}.skillRef`} />
    <StageActionFilterOperationField name={`${name}.operation`} />
    <StageActionFilterScoreField name={`${name}.score`} />
  </HStack>
);

export default StageActionSkillScoreFilterField;
