import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useFirestore } from 'reactfire';
import { UserDoc } from './User';

export type FCMRegistrationTokenDoc = {
  userRef: DocumentReference<UserDoc>;
  token: string;
  timestamp: Timestamp;
};

export const isFCMRegistrationTokenDoc = (
  data: DocumentData,
): data is FCMRegistrationTokenDoc => true;

export const isFCMRegistrationTokenRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<FCMRegistrationTokenDoc> => ref.parent.id === 'fcmRegistrationTokens';

export const isFCMRegistrationTokenSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<FCMRegistrationTokenDoc> => isFCMRegistrationTokenRef(snap.ref);

export const useFCMRegistrationTokensCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'fcmRegistrationTokens') as CollectionReference<FCMRegistrationTokenDoc>, [firestore]);
};

export const useFCMRegistrationTokenDocRef = (docId: string) => {
  const collectionRef = useFCMRegistrationTokensCollectionRef();
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
