import {
  RemoteConfig,
  ensureInitialized,
  fetchAndActivate,
  getString,
} from 'firebase/remote-config';
import { ModuleType } from 'i18next';

export type I18NextRemoteConfigBackendOptions = {
  remoteConfig: RemoteConfig;
  keyPattern?: string;
};

export class I18NextRemoteConfigBackend {
  static type: ModuleType = 'backend';

  protected remoteConfig?: RemoteConfig;

  protected keyPattern?: string;

  init(
    services: unknown,
    {
      remoteConfig,
      keyPattern = 'i18next_{{ns}}_{{lang}}',
    }: I18NextRemoteConfigBackendOptions,
  ): void {
    this.remoteConfig = remoteConfig;
    this.keyPattern = keyPattern;
  }

  async read(
    lang: string,
    ns: string,
    cb: (error: unknown | null, value: unknown | null) => void,
  ): Promise<void> {
    if (!this.remoteConfig || !this.keyPattern) {
      return;
    }

    if (!cb) return;

    await fetchAndActivate(this.remoteConfig);
    await ensureInitialized(this.remoteConfig);

    const key = this.keyPattern.replace(/{{ns}}/ig, ns).replace(/{{lang}}/ig, lang);

    const value = getString(this.remoteConfig, key);

    try {
      cb(null, JSON.parse(value));
    } catch (err) {
      cb(err, null);
    }
  }
}
