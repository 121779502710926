import {
  ThemingProps,
  Wrap,
  WrapItem,
  WrapProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { SkillDoc } from '../types/Skill';
import Catch from './Catch';
import SkillLogo, { SkillLogoSuspenseFallback } from './SkillLogo';

export type Props = WrapProps & ThemingProps<'SkillLogosRow'> & {
  skillRefs: DocumentReference<SkillDoc>[];
};

const SkillLogosRowMain: React.FC<Props> = ({ skillRefs, sx, ...props }) => {
  const style = useStyleConfig('SkillLogosRow', {});

  if (!skillRefs.length) {
    return null;
  }

  return (
    <Wrap
      spacing={1}
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {skillRefs.map((skillRef) => (
        <WrapItem key={skillRef.id}>
          <SkillLogo skillRef={skillRef} />
        </WrapItem>
      ))}
    </Wrap>
  );
};

export const SkillLogosRowSuspenseFallback: React.FC<WrapProps & ThemingProps<'SkillLogosRowSkeleton'>> = ({ sx, ...props }) => {
  const style = useStyleConfig('SkillLogosRowSkeleton', {});

  return (
    <Wrap
      spacing={1}
      sx={{ ...style, ...sx }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <WrapItem>
        <SkillLogoSuspenseFallback />
      </WrapItem>
      <WrapItem>
        <SkillLogoSuspenseFallback />
      </WrapItem>
      <WrapItem>
        <SkillLogoSuspenseFallback />
      </WrapItem>
    </Wrap>
  );
};

export const SkillLogosRowCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const SkillLogosRow: React.FC<Props> = (props) => (
  <Catch fallback={<SkillLogosRowCatchFallback />}>
    <Suspense fallback={<SkillLogosRowSuspenseFallback />}>
      <SkillLogosRowMain {...props} />
    </Suspense>
  </Catch>
);

export default SkillLogosRow;
