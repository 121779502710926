import { BoxProps, useToken } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React from 'react';
import BlockListItem from '../../components/BlockListItem';
import CandidateInfo from '../../components/CandidateInfo';
import useCandidateStatusColor from '../../hooks/useCandidateStatusColor';
import { CandidateDoc } from '../../types/Candidate';

export type Props = {
  candidateSnap: QueryDocumentSnapshot<CandidateDoc>;
} & BoxProps;

const CandidateRow: React.FC<Props> = ({ candidateSnap, ...boxProps }: Props) => {
  const statusColor = useCandidateStatusColor(candidateSnap);
  const [styledColor] = useToken('colors', [statusColor]);

  return (
    <BlockListItem
      boxShadow={`inset 4px 0px ${styledColor}`}
      height="68px"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <CandidateInfo candidateSnap={candidateSnap} />
    </BlockListItem>
  );
};

export default CandidateRow;
