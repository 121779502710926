import { IconButton, VStack } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import PlusInCircleIcon from '../../icons/PlusInCircleIcon';
import { StageActionFilterOperation, StageActionFilterType } from '../../types/Stage';
// eslint-disable-next-line import/no-cycle
import StageActionFilterField, { StageActionFilterFieldValue } from './StageActionFilterField';

export type Props = {
  name: string;
};

export type StageActionAndFilterFieldValue = {
  filters: StageActionFilterFieldValue[];
};

const StageActionAndFilterField: React.FC<Props> = ({
  name,
}) => {
  const [field, , helpers] = useField<StageActionAndFilterFieldValue>(name);

  const onClick = useCallback(
    () => {
      helpers.setValue({
        ...field.value,
        filters: [
          ...field.value.filters,
          {
            type: StageActionFilterType.SKILL_SCORE,
            skillRef: null,
            score: 0.5,
            operation: StageActionFilterOperation.MORE_EQUAL,
          },
        ],
      });
    },
    [field.value, helpers],
  );

  const onRemove = useCallback(
    (n: number) => {
      helpers.setValue({
        ...field.value,
        filters: [
          ...field.value.filters.slice(0, n),
          ...field.value.filters.slice(n + 1),
        ],
      });
    },
    [field.value, helpers],
  );

  return (
    <VStack alignItems="stretch" pl={5} borderLeft="1px solid var(--chakra-colors-chakra-border-color)">
      {field.value.filters.map((v, i) => (
        <StageActionFilterField
          name={`${name}.filters[${i}]`}
          onRemoveClick={() => onRemove(i)}
        />
      ))}

      <IconButton
        onClick={onClick}
        aria-label="Add filter"
        icon={<PlusInCircleIcon />}
        alignSelf="flex-start"
        variant="outline"
      />
    </VStack>
  );
};

export default StageActionAndFilterField;
