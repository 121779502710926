import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const LogOutIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 2H11L11 6L9 6L9 4H4V15C4 15.5523 4.44772 16 5 16H8C8.55229 16 9 15.5523 9 15V14H11V15C11 16.6569 9.65686 18 8 18H5C3.34315 18 2 16.6569 2 15V2ZM15.4328 9L13.2691 6.68243L14.7309 5.31757L17.1346 7.89205C17.7113 8.47596 17.9999 9.2396 17.9999 10C17.9999 10.7604 17.7113 11.524 17.1346 12.1079L14.7309 14.6824L13.2691 13.3176L15.4328 11L6 11L6 9L15.4328 9Z" fill="currentColor" />
  </Icon>
);

export default LogOutIcon;
