import {
  Center,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import CallLobbyIntervieweeInfo from '../../../../components/CallLobbyIntervieweeInfo';
import CallLobbyInterviewersInfo from '../../../../components/CallLobbyInterviewersInfo';
import CallLobbySkillsInfo from '../../../../components/CallLobbySkillsInfo';
import CallLobbyTimeInfo from '../../../../components/CallLobbyTimeInfo';
import LocalParticipantMediaPreview from '../../../../components/LocalParticipantMediaPreview';
import NavUser from '../../../../components/NavUser';
import BackButton from './BackButton';
import CallAction from './CallAction';

const Lobby: React.FC = () => (
  <Grid
    templateColumns="36px auto"
    columnGap={5}
    rowGap={3}
    height="100%"
    px={5}
    py={3}
  >
    <GridItem>
      <Grid
        gridTemplateColumns="1fr"
        gridTemplateRows="36px 1fr 36px"
        gap={5}
        height="100%"
        width="100%"
        alignItems="center"
        alignContent="center"
      >
        <GridItem>
          <BackButton />
        </GridItem>
        <GridItem />
        <GridItem>
          <NavUser />
        </GridItem>
      </Grid>
    </GridItem>

    <GridItem>
      <Center minH="100%">
        <Grid
          templateColumns="640px 320px"
          templateRows="360px auto"
          columnGap={5}
          rowGap={3}
        >
          <GridItem>
            <LocalParticipantMediaPreview />
          </GridItem>

          <GridItem rowSpan={2} overflow="auto">
            <VStack spacing={4} alignItems="stretch">
              <CallLobbyTimeInfo />
              <CallLobbyIntervieweeInfo />
              <CallLobbyInterviewersInfo />
              <CallLobbySkillsInfo />
            </VStack>
          </GridItem>

          <GridItem>
            <CallAction />
          </GridItem>
        </Grid>
      </Center>
    </GridItem>
  </Grid>
);

export default Lobby;
