import _ from 'lodash';
import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { RemoteParticipant } from 'twilio-video';
import { useInterviewRef } from '../components/InterviewRefContext';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export default function useGhostParticipants(
  participants: RemoteParticipant[],
): RemoteParticipant[] {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(
    () => interviewSnap.data(),
    [interviewSnap],
  );

  return useMemo(
    () => _.reject(
      participants,
      ({ identity }) => _.includes(
        _.compact([
          interview.intervieweeRef?.id,
          ...interview.interviewerRefs.map((ref) => ref.id),
        ]),
        identity,
      ),
    ),
    [interview.intervieweeRef?.id, interview.interviewerRefs, participants],
  );
}
