import { Text, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../../components/BlockList';
import BlockListItem from '../../../components/BlockListItem';
import Catch from '../../../components/Catch';
import DocumentLoader from '../../../components/DocumentLoader';
import QuestionInfo, { QuestionInfoSuspenseFallback } from '../../../components/QuestionInfo';
import { useSkillRef } from '../../../components/SkillRefContext';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';

const QuestionsListMain: React.FC = () => {
  const skillRef = useSkillRef();

  const { data: skillSnap } = useFirestoreDoc(skillRef);

  if (!skillSnap.exists()) {
    throw new SnapNotFoundError(skillSnap);
  }

  const skill = useMemo(() => skillSnap.data(), [skillSnap]);

  return (
    <VStack spacing={1} alignItems="stretch" data-intercom-target="Questions">
      <Text
        pt="1px"
        pb="3px"
        color="cf.cntTertiary"
        fontSize="sm"
        lineHeight="short"
        fontWeight="medium"
      >
        Questions
        {' '}
        &middot;
        {' '}
        {skill.questionRefs.length}
      </Text>

      <BlockList variant="outline">
        {skill.questionRefs.map((questionRef) => (
          <DocumentLoader
            reference={questionRef}
            key={questionRef.id}
          >
            {(questionSnap) => (
              <BlockListItem>
                <QuestionInfo questionSnap={questionSnap} />
              </BlockListItem>
            )}
          </DocumentLoader>
        ))}
      </BlockList>
    </VStack>
  );
};

export const QuestionsListSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch" data-intercom-target="Questions">
    <Text
      pt="1px"
      pb="3px"
      color="cf.cntTertiary"
      fontSize="sm"
      lineHeight="short"
      fontWeight="medium"
    >
      Questions
    </Text>

    <BlockList variant="outline">
      <BlockListItem>
        <QuestionInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <QuestionInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <QuestionInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <QuestionInfoSuspenseFallback />
      </BlockListItem>
      <BlockListItem>
        <QuestionInfoSuspenseFallback />
      </BlockListItem>
    </BlockList>
  </VStack>
);

export const QuestionsListCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const QuestionsList: React.FC = () => (
  <Catch fallback={<QuestionsListCatchFallback />}>
    <Suspense fallback={<QuestionsListSuspenseFallback />}>
      <QuestionsListMain />
    </Suspense>
  </Catch>
);

export default QuestionsList;
