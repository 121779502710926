import React from 'react';
import ErrorPage from '../../components/ErrorPage';

const Error404Page: React.FC = () => (
  <ErrorPage
    message="The page you are looking for can't be found."
    code={404}
  />
);

Error404Page.defaultProps = {
  code: undefined,
};

export default Error404Page;
