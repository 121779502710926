export default function easeInOutExpo(x: number): number {
  // eslint-disable-next-line no-nested-ternary
  return x === 0
    ? 0
    // eslint-disable-next-line no-nested-ternary
    : x === 1
      ? 1
      : x < 0.5 ? 2 ** (20 * x - 10) / 2
        : (2 - 2 ** (-20 * x + 10)) / 2;
}
