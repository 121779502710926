import {
  HStack,
  IconButton,
  Select,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { limit, query, where } from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import * as yup from 'yup';
import Catch from '../../../../components/Catch';
import { useExpertRef } from '../../../../components/ExpertRefContext';
import { useOrganizationRef } from '../../../../components/OrganizationRefContext';
import PaddingBlock from '../../../../components/PaddingBlock';
import { useUserRef } from '../../../../components/UserRefContext';
import useDeelCreateContract from '../../../../functions/useDeelCreateContract';
import useDeelRefreshLegalEntities from '../../../../functions/useDeelRefreshLegalEntities';
import useDeelRefreshTeams from '../../../../functions/useDeelRefreshTeams';
import ArrowRightIcon from '../../../../icons/ArrowRightIcon';
import DeelLogo from '../../../../icons/DeelLogo';
import Spinner from '../../../../icons/Spinner';
import { useDeelAccessTokensCollectionRef } from '../../../../types/DeelAccessToken';
import { useDeelLegalEntitiesCollectionRef } from '../../../../types/DeelLegalEntity';
import { useDeelTeamsCollectionRef } from '../../../../types/DeelTeam';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

export interface FormFields {
  deelLegalEntityId: string;
  deelTeamId: string;
}

const DeelContractCreateMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const expertRef = useExpertRef();
  const { data: exportSnap } = useFirestoreDoc(expertRef);

  if (!exportSnap.exists()) {
    throw new SnapNotFoundError(exportSnap);
  }

  const createContract = useDeelCreateContract();
  const handleCreate = useCallback(async ({ deelLegalEntityId, deelTeamId }: FormFields) => {
    await createContract({
      amount: 30, // TODO: use user input
      expertId: expertRef.id,
      deelLegalEntityId: parseInt(deelLegalEntityId, 10),
      deelTeamId: parseInt(deelTeamId, 10),
    });
  }, [createContract, expertRef.id]);

  const deelTeamsCollectionRef = useDeelTeamsCollectionRef();
  const { data: deelTeamsSnap } = useFirestoreCollection(
    query(
      deelTeamsCollectionRef,
      where('organizationRef', '==', organizationRef),
    ),
  );

  const refreshTeams = useDeelRefreshTeams();
  useEffect(() => {
    refreshTeams({ organizationId: organizationRef.id });
  }, [organizationRef.id, refreshTeams]);

  const deelLegalEntitiesCollectionRef = useDeelLegalEntitiesCollectionRef();
  const { data: deelLegalEntitiesSnap } = useFirestoreCollection(
    query(
      deelLegalEntitiesCollectionRef,
      where('organizationRef', '==', organizationRef),
    ),
  );

  const refreshLegalEntities = useDeelRefreshLegalEntities();
  useEffect(() => {
    refreshLegalEntities({ organizationId: organizationRef.id });
  }, [organizationRef.id, refreshLegalEntities]);

  const [validateAll, setValidateAll] = useState(false);

  const schema = useMemo(() => yup.object().shape({
    deelLegalEntityId: yup.string().required(),
    deelTeamId: yup.string().required(),
  }), []);

  const userRef = useUserRef();
  const deelAccessTokensCollectionRef = useDeelAccessTokensCollectionRef();
  const { data: deelAccessTokensSnap } = useFirestoreCollection(
    query(
      deelAccessTokensCollectionRef,
      where('organizationRef', '==', organizationRef),
      where('userRef', '==', userRef),
      limit(1),
    ),
  );

  if (!deelAccessTokensSnap.docs.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch">
      <DeelLogo height="14px" />

      <PaddingBlock variant="outline">
        <Formik<FormFields>
          initialValues={{
            deelLegalEntityId: '',
            deelTeamId: '',
          }}
          onSubmit={handleCreate}
          validationSchema={schema}
          validateOnChange={validateAll}
          validateOnBlur={validateAll}
        >
          {({
            handleSubmit,
            isSubmitting,
            values,
            getFieldProps,
          }) => (
            <form
              onSubmit={(...props) => {
                setValidateAll(true);
                return handleSubmit(...props);
              }}
            >
              <HStack>
                <Select
                  h={9}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getFieldProps({ name: 'deelLegalEntityId' })}
                >
                  <option value="">
                    Please select...
                  </option>
                  {deelLegalEntitiesSnap.docs.map((deelLegalEntitySnap) => (
                    <option key={deelLegalEntitySnap.id} value={deelLegalEntitySnap.id}>
                      {deelLegalEntitySnap.data().name}
                    </option>
                  ))}
                </Select>

                <Select
                  h={9}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getFieldProps({ name: 'deelTeamId' })}
                >
                  <option value="">
                    Please select...
                  </option>
                  {deelTeamsSnap.docs.map((deelTeamSnap) => (
                    <option key={deelTeamSnap.id} value={deelTeamSnap.id}>
                      {deelTeamSnap.data().name}
                    </option>
                  ))}
                </Select>

                <IconButton
                  type="submit"
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  icon={<ArrowRightIcon />}
                  aria-label="Create contract"
                />
              </HStack>
            </form>
          )}
        </Formik>
      </PaddingBlock>
    </VStack>
  );
};

export const DeelContractCreateCatchFallback: React.FC = () => null;

export const DeelContractCreateSuspenseFallback: React.FC = () => (
  <VStack spacing={1} alignItems="stretch">
    <Skeleton pt="1px" pb="3px" height="20px" width="140px" />
  </VStack>
);

/* eslint-disable react/jsx-props-no-spreading */
const DeelContractCreate: React.FC = () => (
  <Catch fallback={<DeelContractCreateCatchFallback />}>
    <Suspense fallback={<DeelContractCreateSuspenseFallback />}>
      <DeelContractCreateMain />
    </Suspense>
  </Catch>
);

export default DeelContractCreate;
