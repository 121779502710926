import { HStack, Text, VStack } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import BriefcaseIcon from '../../icons/BriefcaseIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import FlagIcon from '../../icons/FlagIcon';
import MicOnIcon from '../../icons/MicOnIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import { OrganizationDoc } from '../../types/Organization';
import BlockList from '../BlockList';
import BlockListItem from '../BlockListItem';
import OrganizationAvatar from '../OrganizationAvatar';

export type Props = {
  organizationSnap: QueryDocumentSnapshot<OrganizationDoc>
};

const OrganizationItem: React.FC<Props> = ({ organizationSnap }) => {
  const organization = useMemo(() => organizationSnap.data(), [organizationSnap]);
  const navigate = useNavigate();

  const handleCandidatesClick = useCallback(() => {
    navigate(`/organizations/${organizationSnap.id}/candidates`);
  }, [navigate, organizationSnap.id]);

  const handleExpertsClick = useCallback(() => {
    navigate(`/organizations/${organizationSnap.id}/experts`);
  }, [navigate, organizationSnap.id]);

  const handleInterviewsClick = useCallback(() => {
    navigate(`/organizations/${organizationSnap.id}/interviews`);
  }, [navigate, organizationSnap.id]);

  const handleSkillsClick = useCallback(() => {
    navigate(`/organizations/${organizationSnap.id}/skills`);
  }, [navigate, organizationSnap.id]);

  const handleSettingsClick = useCallback(() => {
    navigate(`/organizations/${organizationSnap.id}/settings`);
  }, [navigate, organizationSnap.id]);

  return (
    <VStack alignItems="stretch">
      <HStack>
        <OrganizationAvatar size="sm" organizationSnap={organizationSnap} />
        <Text variant="labelMedium">
          {organization.name}
        </Text>
      </HStack>

      <BlockList variant="outline">
        <BlockListItem onClick={handleCandidatesClick}>
          <HStack>
            <BriefcaseIcon />
            <Text>
              Candidates
            </Text>
          </HStack>
        </BlockListItem>

        <BlockListItem onClick={handleExpertsClick}>
          <HStack>
            <MicOnIcon />
            <Text>
              Experts
            </Text>
          </HStack>
        </BlockListItem>

        <BlockListItem onClick={handleInterviewsClick}>
          <HStack>
            <CalendarIcon />
            <Text>
              Interviews
            </Text>
          </HStack>
        </BlockListItem>

        <BlockListItem onClick={handleSkillsClick}>
          <HStack>
            <FlagIcon />
            <Text>
              Skills
            </Text>
          </HStack>
        </BlockListItem>

        <BlockListItem onClick={handleSettingsClick}>
          <HStack>
            <SettingsIcon />
            <Text>
              Settings
            </Text>
          </HStack>
        </BlockListItem>
      </BlockList>
    </VStack>
  );
};

export default OrganizationItem;
