import {
  Button,
  Container,
  Grid,
  GridItem,
  HStack,
  Heading,
  VStack,
} from '@chakra-ui/react';
import {
  DocumentReference,
  WithFieldValue,
  doc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { Formik } from 'formik';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';
import Catch from '../../../components/Catch';
import ExpertsSelectorField from '../../../components/ExpertsSelectorField';
import ExternalExpertTiersField from '../../../components/ExternalExpertTiersField';
import InternalExpertTiersField from '../../../components/InternalExpertTiersField';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import PhoneNumberField from '../../../components/PhoneNumberField';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import StageRefField from '../../../components/StageRefField';
import TextField from '../../../components/TextField';
import { useUserRef } from '../../../components/UserRefContext';
import AddUserIcon from '../../../icons/AddUserIcon';
import Spinner from '../../../icons/Spinner';
import {
  CandidateDoc,
  CandidateStatus,
  useCandidatesCollectionRef,
} from '../../../types/Candidate';
import { ExpertDoc, ExpertTier } from '../../../types/Expert';
import { useMembersCollectionRef } from '../../../types/Member';
import { SkillDoc } from '../../../types/Skill';
import { StageDoc } from '../../../types/Stage';

export interface FormFields {
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  skillRefs: DocumentReference<SkillDoc>[];
  requiredExpertRefs: DocumentReference<ExpertDoc>[];
  optionalExpertRefs: DocumentReference<ExpertDoc>[];
  customerUrl: string;
  internalExpertTiers: ExpertTier[];
  externalExpertTiers: ExpertTier[];
  stageRef: DocumentReference<StageDoc> | null;
}

const schema = yup.object().shape({
  email: yup.string().email().label('Candidate email').required(),
  phoneNumber: yup.string().label('Candidate phone'),
  firstName: yup.string().label('First name').required(),
  lastName: yup.string().label('Last name').required(),
  skillRefs: yup.array().label('Skills').required(),
  requiredExpertRefs: yup.array().label('Required experts').required(),
  optionalExpertRefs: yup.array().label('Optional experts').required(),
  customerUrl: yup.string().url().label('RMS profile'),
  internalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  externalExpertTiers: yup.array(yup.string().oneOf([
    ExpertTier.LIEUTENANT,
    ExpertTier.COMMANDER,
    ExpertTier.CAPTAIN,
    ExpertTier.ADMIRAL,
  ])).required(),
  stageRef: yup.object().label('Stage').nullable(),
});

const CreateCandidateScreenMain: React.FC = () => {
  const organizationRef = useOrganizationRef();
  const userRef = useUserRef();

  const navigate = useNavigate();

  const { trackEvent } = useIntercom();

  const candidatesCollectionRef = useCandidatesCollectionRef();

  const membersCollectionRef = useMembersCollectionRef(organizationRef);
  const memberRef = useMemo(
    () => doc(membersCollectionRef, userRef.id),
    [membersCollectionRef, userRef.id],
  );

  const handleFormSubmit = useCallback(async ({
    email,
    firstName,
    lastName,
    skillRefs,
    requiredExpertRefs,
    optionalExpertRefs,
    phoneNumber,
    customerUrl,
    internalExpertTiers,
    externalExpertTiers,
    stageRef,
  }: FormFields) => {
    const candidateRef = doc(candidatesCollectionRef);

    const requestData: WithFieldValue<CandidateDoc> = {
      email,
      firstName,
      lastName,
      organizationRef,
      skillRefs,
      requiredExpertRefs,
      optionalExpertRefs,
      createdAt: serverTimestamp(),
      assigneeRefs: [memberRef],
      status: CandidateStatus.PAUSED,
      internalExpertTiers,
      externalExpertTiers,
      _v: 4,
    };

    if (stageRef !== null) {
      requestData.stageRef = stageRef;
    }

    if (phoneNumber !== '') {
      requestData.phoneNumber = phoneNumber;
    }

    if (customerUrl !== '') {
      requestData.customerUrl = customerUrl;
    }

    await setDoc(
      candidateRef,
      requestData,
    );

    trackEvent('clarwis-candidate-created', requestData);

    navigate(`../${candidateRef.id}`);
  }, [candidatesCollectionRef, memberRef, navigate, organizationRef, trackEvent]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        skillRefs: [],
        requiredExpertRefs: [],
        optionalExpertRefs: [],
        customerUrl: '',
        internalExpertTiers: [],
        externalExpertTiers: [],
        stageRef: null,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        values,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
            data-intercom-target="OrganizationCreateCandidatePage"
          >
            <GridItem py={4} borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Heading>
                  New candidate
                </Heading>
              </Container>
            </GridItem>

            <GridItem py={3} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={3}>
                  <HStack spacing={5} alignItems="start" className="candidate-name">
                    <TextField isRequired label="First name" name="firstName" placeholder="John" data-intercom-target="OrganizationCreateCandidateFormFirstName" />
                    <TextField isRequired label="Last name" name="lastName" placeholder="Doe" data-intercom-target="OrganizationCreateCandidateFormLastName" />
                  </HStack>

                  <TextField isRequired label="Candidate email" name="email" type="email" placeholder="candidate@email.com" data-intercom-target="OrganizationCreateCandidateFormEmail" />

                  <HStack spacing={5} alignItems="start">
                    <PhoneNumberField label="Candidate phone (optional)" name="phoneNumber" />
                    <TextField label="RMS profile (optional)" name="customerUrl" type="url" placeholder="https://example.com/profile/123" />
                  </HStack>

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                    data-intercom-target="OrganizationCreateCandidateFormSkills"
                    isRequired
                  />

                  <ExpertsSelectorField
                    name="requiredExpertRefs"
                    label="Required experts"
                  />

                  <HStack spacing={5}>
                    <InternalExpertTiersField
                      label="Expert ranks"
                      name="internalExpertTiers"
                    />

                    <ExternalExpertTiersField
                      label="Clarwis Expert ranks"
                      name="externalExpertTiers"
                    />
                  </HStack>

                  <ExpertsSelectorField
                    name="optionalExpertRefs"
                    label="Optional experts"
                  />

                  <StageRefField
                    name="stageRef"
                    label="Stage"
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Button
                  width="100%"
                  variant="solid"
                  type="submit"
                  leftIcon={<AddUserIcon />}
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Processing..."
                  className="cart-checkout"
                  data-intercom-target="OrganizationCreateCandidateFormSubmit"
                >
                  Invite candidate
                </Button>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const CreateCandidateScreenCatchFallback: React.FC = () => null;
export const CreateCandidateScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CreateCandidateScreen: React.FC = () => (
  <Catch fallback={<CreateCandidateScreenCatchFallback />}>
    <Suspense fallback={<CreateCandidateScreenSuspenseFallback />}>
      <CreateCandidateScreenMain />
    </Suspense>
  </Catch>
);

export default CreateCandidateScreen;
