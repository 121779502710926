import {
  BoxProps,
  HStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import TrashIcon from '../../icons/TrashIcon';
import { QuestionDoc } from '../../types/Question';
import BlockListItem from '../BlockListItem';
import DocumentLoader from '../DocumentLoader';
import QuestionInfo from '../QuestionInfo';
import QuestionRefProvider from '../QuestionRefContext';
import EditQuestionModal from './EditQuestionModal';

export type Props = BoxProps & {
  name: string;
  onUpClick?: (questionRef: DocumentReference<QuestionDoc>) => void;
  onRemoveClick: (questionRef: DocumentReference<QuestionDoc>) => void;
};

const QuestionField: React.FC<Props> = ({
  name,
  onUpClick,
  onRemoveClick,
  ...boxProps
}) => {
  const [field] = useField<DocumentReference<QuestionDoc>>(name);

  const handleUpClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!onUpClick) {
        return;
      }
      e.stopPropagation();
      onUpClick(field.value);
    },
    [field.value, onUpClick],
  );

  const handleRemoveClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!onRemoveClick) {
        return;
      }
      e.stopPropagation();
      onRemoveClick(field.value);
    },
    [field.value, onRemoveClick],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUpdate = useCallback(
    () => {
      onClose();
    },
    [onClose],
  );

  return (
    <BlockListItem onClick={onOpen}>
      <HStack>
        <DocumentLoader reference={field.value}>
          {(questionSnap) => (
            <QuestionInfo
              questionSnap={questionSnap}
              flexGrow={1}
              flexShrink={1}
              minW={0}
            />
          )}
        </DocumentLoader>

        <IconButton
          aria-label="Up"
          variant="outline"
          icon={<ArrowUpIcon />}
          onClick={handleUpClick}
          isDisabled={!onUpClick}
        />

        <IconButton
          aria-label="Remove"
          colorScheme="negative"
          icon={<TrashIcon />}
          onClick={handleRemoveClick}
        />
      </HStack>

      <QuestionRefProvider questionRef={field.value}>
        <EditQuestionModal
          isOpen={isOpen}
          onClose={onClose}
          onUpdate={handleUpdate}
        />
      </QuestionRefProvider>
    </BlockListItem>
  );
};

QuestionField.defaultProps = {
  onUpClick: undefined,
};

export default QuestionField;
