import {
  Alert,
  Button,
  Container,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Formik } from 'formik';
import React, { Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { useCandidateRef } from '../../../components/CandidateRefProvider';
import Catch from '../../../components/Catch';
import ExpertsSelectorField from '../../../components/ExpertsSelectorField';
import Loader from '../../../components/Loader';
import { useOrganizationRef } from '../../../components/OrganizationRefContext';
import SkillsSelectorField from '../../../components/SkillsSelectorField';
import useInterviewCreate from '../../../functions/useInterviewCreate';
import CalendarIcon from '../../../icons/CalendarIcon';
import Spinner from '../../../icons/Spinner';
import { ExpertDoc } from '../../../types/Expert';
import { SkillDoc } from '../../../types/Skill';
import Header from './Header';
import StartField from './StartField';

export interface FormFields {
  expertRefs: DocumentReference<ExpertDoc>[];
  skillRefs: DocumentReference<SkillDoc>[];
  start: number;
}

const schema = yup.object().shape({
  expertRefs: yup.array().label('Experts').min(1).required(),
  skillRefs: yup.array().label('Skills').min(1).required(),
  start: yup.mixed().label('Starts At').required(),
});

const CreateInterviewScreenMain: React.FC = () => {
  const candidateRef = useCandidateRef();
  const organizationRef = useOrganizationRef();

  const navigate = useNavigate();

  const create = useInterviewCreate();

  const handleFormSubmit = useCallback(async ({
    skillRefs,
    expertRefs,
    start,
  }: FormFields) => {
    const { data: interviewId } = await create({
      candidateId: candidateRef.id,
      expertIds: expertRefs.map((ref) => ref.id),
      start,
      skillIds: skillRefs.map((ref) => ref.id),
    });

    navigate(`/organizations/${organizationRef.id}/interviews/${interviewId}`);
  }, [candidateRef.id, create, navigate, organizationRef.id]);

  const [validateAll, setValidateAll] = useState(false);

  return (
    <Formik<FormFields>
      initialValues={{
        expertRefs: [],
        skillRefs: [],
        start: Math.round(Date.now() / (1000 * 60 * 15)) * (1000 * 60 * 15) + 1000 * 60 * 60 * 24,
      }}
      onSubmit={handleFormSubmit}
      validationSchema={schema}
      validateOnChange={validateAll}
      validateOnBlur={validateAll}
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
      }) => (
        <form
          style={{ height: '100%' }}
          noValidate
          onSubmit={(...props) => {
            setValidateAll(true);
            return handleSubmit(...props);
          }}
        >
          <Grid
            templateColumns="auto"
            templateRows="auto 1fr auto"
            height="100%"
            data-intercom-target="OrganizationCreateCandidatePage"
          >
            <GridItem borderBottomWidth={1} borderColor="cf.brdBlackAlpha12">
              <Header />
            </GridItem>

            <GridItem py={3} overflow="auto">
              <Container>
                <VStack alignItems="stretch" spacing={3}>
                  <Alert status="warning" borderRadius="md">
                    You don&apos;t have to create interviews manually,
                    Clarwis takes care of it for you automatically.
                    It respects the schedule of the candidate and your experts,
                    their timezones, skill set, etc.
                  </Alert>

                  <StartField name="start" label="Start" />

                  <ExpertsSelectorField
                    name="expertRefs"
                    label="Experts"
                  />

                  <SkillsSelectorField
                    name="skillRefs"
                    label="Skills"
                  />
                </VStack>
              </Container>
            </GridItem>

            <GridItem py={4} borderTopWidth={1} borderColor="cf.brdBlackAlpha12">
              <Container>
                <Button
                  width="100%"
                  variant="solid"
                  type="submit"
                  leftIcon={<CalendarIcon />}
                  isDisabled={!isValid}
                  isLoading={isSubmitting}
                  spinner={<Spinner />}
                  loadingText="Processing..."
                >
                  Schedule interview
                </Button>
              </Container>
            </GridItem>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export const CreateInterviewScreenCatchFallback: React.FC = () => null;
export const CreateInterviewScreenSuspenseFallback: React.FC = () => (<Loader />);

/* eslint-disable react/jsx-props-no-spreading */
const CreateInterviewScreen: React.FC = () => (
  <Catch fallback={<CreateInterviewScreenCatchFallback />}>
    <Suspense fallback={<CreateInterviewScreenSuspenseFallback />}>
      <CreateInterviewScreenMain />
    </Suspense>
  </Catch>
);

export default CreateInterviewScreen;
