import { Text, VStack } from '@chakra-ui/react';
import React, { Suspense, useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import BlockList from '../../components/BlockList';
import BlockListItem from '../../components/BlockListItem';
import Catch from '../../components/Catch';
import SkillShortInfo from '../../components/SkillShortInfo';
import { useUserRef } from '../../components/UserRefContext';
import { useUserInterviewerProfileDocRef } from '../../types/UserInterviewerProfile';

const SkillsMain: React.FC = () => {
  const userRef = useUserRef();
  const userInterviewerProfileRef = useUserInterviewerProfileDocRef(userRef);
  const { data: userInterviewerProfileSnap } = useFirestoreDoc(userInterviewerProfileRef);

  const userInterviewerProfile = useMemo(
    () => userInterviewerProfileSnap.data(),
    [userInterviewerProfileSnap],
  );

  if (!userInterviewerProfile?.skillRefs?.length) {
    return null;
  }

  return (
    <VStack spacing={1} alignItems="stretch">
      <Text pt="1px" pb="3px" color="cf.cntTertiary" fontSize="sm" lineHeight="short" fontWeight="medium">
        Active skills
      </Text>

      <BlockList variant="outline">
        {(userInterviewerProfile?.skillRefs || []).map((skillRef) => (
          <BlockListItem key={skillRef.id}>
            <SkillShortInfo skillRef={skillRef} />
          </BlockListItem>
        ))}
      </BlockList>
    </VStack>
  );
};

export const SkillsCatchFallback: React.FC = () => null;
export const SkillsSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Skills: React.FC = () => (
  <Catch fallback={<SkillsCatchFallback />}>
    <Suspense fallback={<SkillsSuspenseFallback />}>
      <SkillsMain />
    </Suspense>
  </Catch>
);

export default Skills;
