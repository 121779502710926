import _ from 'lodash';
import { useMemo } from 'react';
import { useFirestoreDoc } from 'reactfire';
import { useInterviewRef } from '../components/InterviewRefContext';
import { useUserRef } from '../components/UserRefContext';
import SnapNotFoundError from '../types/SnapshotNotFoundError';

export enum InterviewRole {
  INTERVIEWEE = 'INTERVIEWEE',
  INTERVIEWER = 'INTERVIEWER',
  VISITOR = 'VISITOR',
}

const useInterviewRole = (): InterviewRole => {
  const interviewRef = useInterviewRef();
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const interview = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const userRef = useUserRef();

  const role = useMemo<InterviewRole>(
    () => {
      if (interview.intervieweeRef?.id === userRef.id) {
        return InterviewRole.INTERVIEWEE;
      }
      if (_.some(interview.interviewerRefs, (ref) => ref.id === userRef.id)) {
        return InterviewRole.INTERVIEWER;
      }
      return InterviewRole.VISITOR;
    },
    [interview.intervieweeRef?.id, interview.interviewerRefs, userRef.id],
  );

  return role;
};

export default useInterviewRole;
