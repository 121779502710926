import { Center } from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useStorage } from 'reactfire';
import Spinner from '../../icons/Spinner';
import { TwilioCompositionDoc } from '../../types/TwilioComposition';
import { usePlayback } from './PlaybackScaleProvider';

export interface Props {
  twilioCompositionSnap: QueryDocumentSnapshot<TwilioCompositionDoc>;
}

const Composition: React.FC<Props> = ({
  twilioCompositionSnap,
}: Props) => {
  const twilioComposition = useMemo(() => twilioCompositionSnap.data(), [twilioCompositionSnap]);

  const videoRef = useRef<HTMLVideoElement>(null);

  const storage = useStorage();
  const [src, setSrc] = useState<string | undefined>();
  useEffect(
    () => {
      getDownloadURL(ref(storage, twilioComposition.ref))
        .then((res) => {
          setSrc(res);
        });
    },
    [storage, twilioComposition.ref],
  );

  const {
    isPlaying,
    interviewToPlaybackTime,
    onVideoPlaying,
    onVideoWaiting,
    playbackTime,
  } = usePlayback();

  const [start, end] = useMemo<[number, number]>(() => ([
    interviewToPlaybackTime(twilioComposition.start.toMillis()),
    interviewToPlaybackTime(twilioComposition.end.toMillis()),
  ]), [interviewToPlaybackTime, twilioComposition.end, twilioComposition.start]);

  const [duration, setDuration] = useState<number>(end - start);

  // useEffect(
  //   () => console.log('duration', duration, twilioCompositionSnap.id),
  //   [duration, twilioCompositionSnap.id],
  // );

  // useEffect(
  //   () => {
  //     if (
  //       isPlaying
  //       && videoRef.current
  //       && start <= playbackTime
  //       && playbackTime < start + duration
  //     ) {
  //       const videoTime = videoRef.current.currentTime * 1000 + start;
  //       const diff = videoTime - playbackTime;
  //       console.log('adjust time', diff);
  //       // if (Math.abs(diff) > 500) {
  //       // videoRef.current.currentTime += (playbackTime - start) / 1000;
  //       // }
  //     }
  //   },
  //   [duration, isPlaying, playbackTime, start],
  // );

  useEffect(
    () => {
      if (!videoRef.current) {
        return;
      }

      if (isPlaying) {
        const videoTime = (playbackTime - start) / 1000;
        const diff = videoTime - videoRef.current.currentTime;
        // console.log('diff', diff);
        if (Math.abs(diff) > 100) {
          videoRef.current.currentTime += diff;
        }

        if (videoRef.current.paused) {
          videoRef.current.play();
        }
      } else if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    },
    [isPlaying, playbackTime, start],
  );

  useEffect(
    () => {
      const el = videoRef.current;

      if (!el) {
        return () => { };
      }

      const waiting = (e: Event) => {
        onVideoWaiting();
      };

      el.addEventListener('waiting', waiting);

      return () => {
        el.removeEventListener('waiting', waiting);
      };
    },
    [onVideoWaiting],
  );

  useEffect(
    () => {
      const el = videoRef.current;

      if (!el) {
        return () => { };
      }

      const playing = (e: Event) => {
        onVideoPlaying();
      };

      el.addEventListener('playing', playing);

      return () => {
        el.removeEventListener('playing', playing);
      };
    },
    [onVideoPlaying],
  );

  useEffect(
    () => {
      const el = videoRef.current;

      if (!el) {
        return () => { };
      }

      const durationchange = (e: Event) => {
        setDuration(el.duration * 1000);
      };

      el.addEventListener('durationchange', durationchange);

      return () => {
        el.removeEventListener('durationchange', durationchange);
      };
    },
    [setDuration],
  );

  // useEffect(
  //   () => {
  //     const el = videoRef.current;

  //     if (!el) {
  //       return () => { };
  //     }

  //     const audioprocess = () => console.log(Date.now(), 'audioprocess');
  //     const canplay = () => console.log(Date.now(), 'canplay');
  //     const canplaythrough = () => console.log(Date.now(), 'canplaythrough');
  //     const complete = () => console.log(Date.now(), 'complete');
  //     const durationchange = () => console.log(Date.now(), 'durationchange');
  //     const emptied = () => console.log(Date.now(), 'emptied');
  //     const ended = () => console.log(Date.now(), 'ended');
  //     const error = () => console.log(Date.now(), 'error');
  //     const loadeddata = () => console.log(Date.now(), 'loadeddata');
  //     const loadedmetadata = () => console.log(Date.now(), 'loadedmetadata');
  //     const pause = () => console.log(Date.now(), 'pause');
  //     const play = () => console.log(Date.now(), 'play');
  //     const playing = () => console.log(Date.now(), 'playing');
  //     const progress = () => console.log(Date.now(), 'progress');
  //     const ratechange = () => console.log(Date.now(), 'ratechange');
  //     const seeked = () => console.log(Date.now(), 'seeked');
  //     const seeking = () => console.log(Date.now(), 'seeking');
  //     const stalled = () => console.log(Date.now(), 'stalled');
  //     const suspend = () => console.log(Date.now(), 'suspend');
  //     // const timeupdate = () => console.log(Date.now(), 'timeupdate');
  //     const volumechange = () => console.log(Date.now(), 'volumechange');
  //     const waiting = () => console.log(Date.now(), 'waiting');

  //     el.addEventListener('audioprocess', audioprocess);
  //     el.addEventListener('canplay', canplay);
  //     el.addEventListener('canplaythrough', canplaythrough);
  //     el.addEventListener('complete', complete);
  //     el.addEventListener('durationchange', durationchange);
  //     el.addEventListener('emptied', emptied);
  //     el.addEventListener('ended', ended);
  //     el.addEventListener('error', error);
  //     el.addEventListener('loadeddata', loadeddata);
  //     el.addEventListener('loadedmetadata', loadedmetadata);
  //     el.addEventListener('pause', pause);
  //     el.addEventListener('play', play);
  //     el.addEventListener('playing', playing);
  //     el.addEventListener('progress', progress);
  //     el.addEventListener('ratechange', ratechange);
  //     el.addEventListener('seeked', seeked);
  //     el.addEventListener('seeking', seeking);
  //     el.addEventListener('stalled', stalled);
  //     el.addEventListener('suspend', suspend);
  //     // el.addEventListener('timeupdate', timeupdate);
  //     el.addEventListener('volumechange', volumechange);
  //     el.addEventListener('waiting', waiting);

  //     return () => {
  //       el.removeEventListener('audioprocess', audioprocess);
  //       el.removeEventListener('canplay', canplay);
  //       el.removeEventListener('canplaythrough', canplaythrough);
  //       el.removeEventListener('complete', complete);
  //       el.removeEventListener('durationchange', durationchange);
  //       el.removeEventListener('emptied', emptied);
  //       el.removeEventListener('ended', ended);
  //       el.removeEventListener('error', error);
  //       el.removeEventListener('loadeddata', loadeddata);
  //       el.removeEventListener('loadedmetadata', loadedmetadata);
  //       el.removeEventListener('pause', pause);
  //       el.removeEventListener('play', play);
  //       el.removeEventListener('playing', playing);
  //       el.removeEventListener('progress', progress);
  //       el.removeEventListener('ratechange', ratechange);
  //       el.removeEventListener('seeked', seeked);
  //       el.removeEventListener('seeking', seeking);
  //       el.removeEventListener('stalled', stalled);
  //       el.removeEventListener('suspend', suspend);
  //       // el.removeEventListener('timeupdate', timeupdate);
  //       el.removeEventListener('volumechange', volumechange);
  //       el.removeEventListener('waiting', waiting);
  //     };
  //   },
  //   [],
  // );

  if (!(start <= playbackTime && playbackTime < start + duration)) {
    return null;
  }

  if (!src) {
    return (
      <Center h="100%">
        <Spinner color="cf.cntOnColor" />
      </Center>
    );
  }

  return (
    <video
      ref={videoRef}
      style={{
        zIndex: -1,
        height: '100%',
        width: '100%',
        objectFit: 'contain',
      }}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

export default Composition;
