import {
  AvatarGroup,
  Box,
  BoxProps,
  HStack,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot, doc } from 'firebase/firestore';
import React, { Suspense, useCallback, useMemo } from 'react';
import useExpertStatusColor from '../hooks/useExpertStatusColor';
import useExpertStatusLabel from '../hooks/useExpertStatusLabel';
import { ExpertDoc } from '../types/Expert';
import { useUsersCollectionRef } from '../types/User';
import Catch from './Catch';
import ExpertTierIcon from './ExpertTierIcon';
import MemberAvatar from './MemberAvatar';
import SkillLogosRow, { SkillLogosRowSuspenseFallback } from './SkillLogosRow';
import UserAvatar from './UserAvatar';

export type Props = {
  expertSnap: QueryDocumentSnapshot<ExpertDoc>;
} & BoxProps;

const ExpertInfoMain: React.FC<Props> = ({ expertSnap, ...boxProps }: Props) => {
  const expert = useMemo(() => expertSnap.data(), [expertSnap]);

  const formatDecimal = useCallback(
    (v: number) => new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(v),
    [],
  );

  const statusColor = useExpertStatusColor(expertSnap);
  const statusLabel = useExpertStatusLabel(expertSnap);

  const usersCollectionRef = useUsersCollectionRef();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <HStack spacing={3} flexGrow={1} {...boxProps}>
      <AvatarGroup>
        <MemberAvatar userRef={expert.userRef}>
          <ExpertTierIcon
            expertSnap={expertSnap}
            position="absolute"
            right={0}
            bottom={0}
          />
        </MemberAvatar>
      </AvatarGroup>

      <VStack alignItems="stretch" flexGrow={1} spacing={1} minW={0}>
        <HStack>
          <HStack spacing={1} flexGrow={1} minW={0}>
            <Text
              fontWeight="semibold"
              lineHeight="short"
              flexGrow={1}
              flexShrink={1}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {expert.firstName}
              {' '}
              {expert.lastName}
            </Text>
          </HStack>

          <Text variant="labelSmall" textAlign="right" color="cf.cntSecondary">
            &#9734;
            {formatDecimal(expert.rating * 4 + 1)}
            {' '}
            &middot;
            {' '}
            <Text as="span" color={statusColor}>
              {statusLabel}
            </Text>
          </Text>

          {expert.assigneeRefs?.length ? (
            <AvatarGroup size="xs" max={2}>
              {expert.assigneeRefs.map((assigneeRef) => (
                <UserAvatar key={assigneeRef.id} userRef={doc(usersCollectionRef, assigneeRef.id)} size="xs" my="-4px" />
              ))}
            </AvatarGroup>
          ) : null}
        </HStack>

        {expert.skillRefs.length ? (
          <Box
            backgroundImage="linear-gradient(45deg, rgb(139,55,153,0.1) 25%, transparent 25%, transparent 50%, rgb(139,55,153,0.1) 50%, rgb(139,55,153,0.1) 75%, transparent 75%, transparent 100%)"
            backgroundSize="8px 8px"
            overflow="hidden"
            h={5}
          >
            <SkillLogosRow skillRefs={expert.skillRefs} h={5} />
          </Box>
        ) : (
          <Box
            backgroundImage="linear-gradient(45deg, rgb(139,55,153,0.1) 25%, transparent 25%, transparent 50%, rgb(139,55,153,0.1) 50%, rgb(139,55,153,0.1) 75%, transparent 75%, transparent 100%)"
            backgroundSize="8px 8px"
            h={5}
          />
        )}
      </VStack>
    </HStack>
  );
};

export const ExpertInfoSuspenseFallback: React.FC = () => (
  <HStack spacing={3} flexGrow={1}>
    <SkeletonCircle boxSize="44px" />

    <VStack alignItems="stretch" flexGrow={1} spacing={1}>
      <HStack>
        <Box flexGrow={1}>
          <Skeleton h={5} w="140px" />
        </Box>

        <SkeletonCircle boxSize={5} my="-4px" />
      </HStack>

      <SkillLogosRowSuspenseFallback />
    </VStack>
  </HStack>
);

export const ExpertInfoCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertInfo: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertInfoCatchFallback />}>
    <Suspense fallback={<ExpertInfoSuspenseFallback />}>
      <ExpertInfoMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertInfo;
