import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const UploadIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11 4.57218V13.9999H9V4.57218L5.68956 7.72417L4.31044 6.27572L7.88823 2.86917C8.47262 2.28992 9.23794 2 10 2C10.7621 2 11.5274 2.28992 12.1118 2.86918L15.6896 6.27572L14.3104 7.72417L11 4.57218ZM5 10.9999V14.9999C5 15.5522 5.44772 15.9999 6 15.9999H14C14.5523 15.9999 15 15.5522 15 14.9999V10.9999H17V14.9999C17 16.6568 15.6569 17.9999 14 17.9999H6C4.34315 17.9999 3 16.6568 3 14.9999V10.9999H5Z" fill="currentColor" />
  </Icon>
);

export default UploadIcon;
