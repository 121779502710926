import { DocumentReference } from 'firebase/firestore';
import React, { PropsWithChildren, useContext } from 'react';
import { OrganizationDoc } from '../types/Organization';

const OrganizationRefContext = React
  .createContext<DocumentReference<OrganizationDoc> | undefined>(undefined);

export const useOrganizationRef = (): DocumentReference<OrganizationDoc> => {
  const organizationRef = useContext(OrganizationRefContext);
  if (!organizationRef) {
    throw new Error('useOrganizationRef needs to be wrapped with OrganizationRefProvider');
  }
  return organizationRef;
};

export type Props = {
  organizationRef: DocumentReference<OrganizationDoc>;
};

const OrganizationRefProvider: React.FC<PropsWithChildren<Props>> = ({
  organizationRef,
  children,
}) => (
  <OrganizationRefContext.Provider value={organizationRef} key={organizationRef.path}>
    {children}
  </OrganizationRefContext.Provider>
);

export default OrganizationRefProvider;
