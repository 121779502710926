import { Alert, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { Suspense, useCallback } from 'react';
import Catch from '../../../components/Catch';
import { Easing } from '../../../helpers/ease';
import DayInterviewCountScoreField from './DayInterviewCountScoreField';
import DayInterviewHoursScoreField from './DayInterviewHoursScoreField';
import DurationScoreField from './DurationScoreField';
import EarlinessScoreField from './EarlinessScoreField';
import RarenessScoreField from './RarenessScoreField';
import WeekInterviewCountScoreField from './WeekInterviewCountScoreField';
import WeekInterviewHoursScoreField from './WeekInterviewHoursScoreField';

export interface FormFields {
  earliness: {
    influence: number;
    easing: Easing;
  };
  rareness: {
    influence: number;
    easing: Easing;
  };
  weekInterviewHours: {
    influence: number;
    easing: Easing;
  };
  weekInterviewCount: {
    influence: number;
    easing: Easing;
  };
  dayInterviewHours: {
    influence: number;
    easing: Easing;
  };
  dayInterviewCount: {
    influence: number;
    easing: Easing;
  };
  duration: {
    influence: number;
    easing: Easing;
  };
}

const AutoSchedulerMain: React.FC = () => {
  const handleSubmit = useCallback(
    () => { },
    [],
  );

  return (
    <Formik<FormFields>
      initialValues={{
        earliness: {
          influence: 1,
          easing: Easing.EASE_IN_OUT_SINE,
        },
        rareness: {
          influence: 0.6,
          easing: Easing.EASE_IN_OUT_SINE,
        },
        weekInterviewHours: {
          influence: 0.1,
          easing: Easing.EASE_IN_OUT_SINE,
        },
        weekInterviewCount: {
          influence: 0.1,
          easing: Easing.EASE_IN_OUT_SINE,
        },
        dayInterviewHours: {
          influence: 0.2,
          easing: Easing.EASE_IN_OUT_SINE,
        },
        dayInterviewCount: {
          influence: 0.2,
          easing: Easing.EASE_IN_OUT_SINE,
        },
        duration: {
          influence: 0.8,
          easing: Easing.EASE_IN_OUT_SINE,
        },
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <VStack alignItems="stretch" spacing={8}>
          <Alert status="warning">
            This feature is under active development and is not fully available to the public.
          </Alert>
          <EarlinessScoreField />
          <DurationScoreField />
          <RarenessScoreField />
          <DayInterviewHoursScoreField />
          <DayInterviewCountScoreField />
          <WeekInterviewHoursScoreField />
          <WeekInterviewCountScoreField />
        </VStack>
      )}
    </Formik>
  );
};

const AutoSchedulerCatchFallback: React.FC = () => null;
const AutoSchedulerSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const AutoScheduler: React.FC = () => (
  <Catch fallback={<AutoSchedulerCatchFallback />}>
    <Suspense fallback={<AutoSchedulerSuspenseFallback />}>
      <AutoSchedulerMain />
    </Suspense>
  </Catch>
);

export default AutoScheduler;
