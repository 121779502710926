import {
  Box,
  BoxProps,
  HStack,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router';
import { useFirestoreDoc } from 'reactfire';
import BlockListItem from '../../../../components/BlockListItem';
import Catch from '../../../../components/Catch';
import ExpertInfo from '../../../../components/ExpertInfo';
import { ExpertDoc } from '../../../../types/Expert';
import SnapNotFoundError from '../../../../types/SnapshotNotFoundError';

export type Props = BoxProps & {
  expertRef: DocumentReference<ExpertDoc>;
};

const ExpertItemMain: React.FC<Props> = ({ expertRef }) => {
  const { data: expertSnap } = useFirestoreDoc(expertRef);

  if (!expertSnap.exists()) {
    throw new SnapNotFoundError(expertSnap);
  }

  const navigate = useNavigate();

  return (
    <BlockListItem
      data-intercom-target="ExpertRow"
      onClick={() => navigate(`../../../experts/${expertSnap.id}`)}
    >
      <ExpertInfo expertSnap={expertSnap} />
    </BlockListItem>
  );
};

export const ExpertItemSuspenseFallback: React.FC = () => (
  <HStack>
    <SkeletonCircle boxSize={5} />

    <Box flexGrow={1}>
      <Skeleton h={5} w="120px" />
    </Box>

    <Skeleton h={7} w="35px" borderRadius="md" />
  </HStack>
);

const ExpertItemCatchFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const ExpertItem: React.FC<Props> = (props) => (
  <Catch fallback={<ExpertItemCatchFallback />}>
    <Suspense fallback={<ExpertItemSuspenseFallback />}>
      <ExpertItemMain {...props} />
    </Suspense>
  </Catch>
);

export default ExpertItem;
