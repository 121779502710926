import { BoxProps, Grid, GridItem } from '@chakra-ui/react';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useFirestoreCollection, useFirestoreDoc } from 'reactfire';
import CallScheduleItem from '../../../components/CallScheduleItem';
import { useInterviewRef } from '../../../components/InterviewRefContext';
import {
  InterviewScheduleItemDoc,
  useInterviewScheduleItemsCollectionRef,
} from '../../../types/InterviewScheduleItem';
import { SkillDoc } from '../../../types/Skill';
import SnapNotFoundError from '../../../types/SnapshotNotFoundError';
import { usePlayback } from '../PlaybackScaleProvider';
import Section from './Section';

const Sections: React.FC<BoxProps> = (props) => {
  const interviewRef = useInterviewRef();
  const interviewScheduleItemsCollectionRef = useInterviewScheduleItemsCollectionRef(interviewRef);
  const { data: interviewSnap } = useFirestoreDoc(interviewRef);

  if (!interviewSnap.exists()) {
    throw new SnapNotFoundError(interviewSnap);
  }

  const { interviewToPlaybackTime, playbackLength, playbackTime } = usePlayback();

  const { data: scheduleItemsSnap } = useFirestoreCollection(
    query(
      interviewScheduleItemsCollectionRef,
      orderBy('startedAt', 'asc'),
    ),
  );

  const playbackSections = useMemo<[number, number][]>(
    () => {
      const res: [number, number][] = [];

      if (!scheduleItemsSnap.docs.length) {
        return res;
      }

      res.push([
        0,
        Math.round(
          interviewToPlaybackTime(scheduleItemsSnap.docs[0].data().startedAt?.toMillis() || 0),
        ),
      ]);

      scheduleItemsSnap.docs.forEach((scheduleItemSnap) => {
        const { startedAt, endedAt } = scheduleItemSnap.data();
        return res.push([
          Math.round(
            interviewToPlaybackTime(startedAt?.toMillis() || 0),
          ),
          Math.round(
            interviewToPlaybackTime(endedAt?.toMillis() || 0),
          ),
        ]);
      });

      res.push([
        Math.round(
          interviewToPlaybackTime(
            scheduleItemsSnap.docs[scheduleItemsSnap.docs.length - 1]?.data().endedAt?.toMillis()
            || 0,
          ),
        ),
        playbackLength,
      ]);

      return res;
    },
    [interviewToPlaybackTime, playbackLength, scheduleItemsSnap.docs],
  );

  // useEffect(() => console.log(playbackSections), [playbackSections]);

  const templateColumns = useMemo<string>(
    () => playbackSections.map(([start, end]) => (`${end - start}fr`)).join(' '),
    [playbackSections],
  );

  const skillBlocks = useMemo(
    () => _.reduce<
    QueryDocumentSnapshot<InterviewScheduleItemDoc>,
    {
      skillRef: DocumentReference<SkillDoc>,
      colSpan: number,
      duration: number,
      complete: number,
    }[]
    >(
      scheduleItemsSnap.docs,
      (res, scheduleItemSnap) => {
        const {
          skillRef, startedAt, endedAt,
        } = scheduleItemSnap.data();

        const e = endedAt
          ? interviewToPlaybackTime(endedAt.toMillis())
          : playbackLength;

        const duration = ((endedAt?.toMillis() || 0) - (startedAt?.toMillis() || 0)) / (1000 * 60);

        if (!res.length) {
          return [{
            skillRef,
            colSpan: 1,
            duration,
            complete: playbackTime > e ? 1 : 0,
          }];
        }

        const last = res[res.length - 1];

        if (last.skillRef.id !== skillRef.id) {
          return [
            ...res,
            {
              skillRef,
              colSpan: 1,
              duration,
              complete: playbackTime > e ? 1 : 0,
            },
          ];
        }

        last.colSpan += 1;
        last.duration += duration;
        last.complete += playbackTime > e ? 1 : 0;

        return res;
      },
      [],
    ),
    [interviewToPlaybackTime, playbackLength, playbackTime, scheduleItemsSnap.docs],
  );

  return (
    <Grid
      templateColumns={templateColumns}
      templateRows="36px 4px"
      columnGap={1}
      rowGap={1}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <GridItem />

      {skillBlocks.map(({
        skillRef,
        colSpan,
        duration,
        complete,
      }) => (
        <GridItem key={skillRef.id} colSpan={colSpan}>
          <CallScheduleItem
            skillRef={skillRef}
            duration={duration}
            total={colSpan}
            complete={complete}
          />
        </GridItem>
      ))}

      <GridItem />

      {playbackSections.map(([start, end], i) => (
        <Section
          key={String(start)}
          start={start}
          end={end}
          borderLeftRadius={i === 0 ? 'md' : undefined}
          borderRightRadius={i === playbackSections.length - 1 ? 'md' : undefined}
        />
      ))}
    </Grid>
  );
};

export default Sections;
