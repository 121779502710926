import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  collection,
  doc,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { CandidateDoc } from './Candidate';
import { SendGridEventsParentDoc } from './SendGridEvent';

export type CandidateInvitationDoc = {
  accepted: boolean;
  email: string;
  firstName: string;
  lastName: string;
} & SendGridEventsParentDoc;

export const isCandidateInvitationDoc = (
  data?: DocumentData,
): data is CandidateInvitationDoc => true;

export const isCandidateInvitationRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CandidateInvitationDoc> => ref.parent.id === 'invitations';

export const isCandidateInvitationSnap = (
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<CandidateInvitationDoc> => isCandidateInvitationRef(snap.ref);

export const useCandidateInvitationsCollectionRef = (candidateRef: DocumentReference<CandidateDoc>) => useMemo(() => collection(candidateRef, 'invitations') as CollectionReference<CandidateInvitationDoc>, [candidateRef]);

export const useCandidateInvitationDocRef = (
  candidateRef: DocumentReference<CandidateDoc>,
  docId: string,
) => {
  const collectionRef = useCandidateInvitationsCollectionRef(candidateRef);
  return useMemo(() => doc(collectionRef, docId), [collectionRef, docId]);
};
