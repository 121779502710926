import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

const SearchIcon: React.FC<IconProps> = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon boxSize={5} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9 4C6.23858 4 4 6.23858 4 9C4 11.7614 6.23858 14 9 14C10.381 14 11.6296 13.4415 12.5355 12.5355C13.4415 11.6296 14 10.381 14 9C14 6.23858 11.7614 4 9 4ZM2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.5719 15.481 12.0239 14.6063 13.1921L17.2071 15.7929L15.7929 17.2071L13.1921 14.6063C12.0239 15.481 10.5719 16 9 16C5.13401 16 2 12.866 2 9Z" fill="currentColor" />
  </Icon>
);

export default SearchIcon;
