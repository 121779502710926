import { Text, VStack } from '@chakra-ui/react';
import { query, where } from 'firebase/firestore';
import React, { Suspense } from 'react';
import { useFirestoreCollection } from 'reactfire';
import BlockList from '../../components/BlockList';
import BlockListItem from '../../components/BlockListItem';
import Catch from '../../components/Catch';
import { useOrganizationRef } from '../../components/OrganizationRefContext';
import PaddingBlock from '../../components/PaddingBlock';
import { useWebhooksCollectionRef } from '../../types/Webhook';
import WebhookCreateForm from './WebhookCreateForm';
import WebhookItem from './WebhookItem';

const WebhooksMain: React.FC = () => {
  const organizationRef = useOrganizationRef();

  const webhooksCollectionRef = useWebhooksCollectionRef();
  const { data: webhooksSnap } = useFirestoreCollection(
    query(
      webhooksCollectionRef,
      where('organizationRef', '==', organizationRef),
    ),
  );

  return (
    <VStack alignItems="stretch" spacing={8}>
      <VStack spacing={1} alignItems="stretch">
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
        >
          Webhooks
        </Text>

        {webhooksSnap.docs.length ? (
          <BlockList variant="outline">
            {webhooksSnap.docs.map((webhookSnap) => (
              <BlockListItem key={webhookSnap.id}>
                <WebhookItem webhookSnap={webhookSnap} />
              </BlockListItem>
            ))}
          </BlockList>
        ) : (
          <PaddingBlock>
            <Text as="i">No webhooks yet</Text>
          </PaddingBlock>
        )}
      </VStack>

      <VStack spacing={1} alignItems="stretch">
        <Text
          pt="1px"
          pb="3px"
          color="cf.cntTertiary"
          fontSize="sm"
          lineHeight="short"
          fontWeight="medium"
        >
          New Webhook
        </Text>

        <PaddingBlock>
          <WebhookCreateForm />
        </PaddingBlock>
      </VStack>
    </VStack>
  );
};

const WebhooksCatchFallback: React.FC = () => null;
const WebhooksSuspenseFallback: React.FC = () => null;

/* eslint-disable react/jsx-props-no-spreading */
const Webhooks: React.FC = () => (
  <Catch fallback={<WebhooksCatchFallback />}>
    <Suspense fallback={<WebhooksSuspenseFallback />}>
      <WebhooksMain />
    </Suspense>
  </Catch>
);

export default Webhooks;
