import { HttpsCallable, httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';
import { useFunctions } from 'reactfire';

export type RequestData = {
  organizationId: string;
  returnUrl: string;
  locale?: string;
  configuration?: string;
};

export type ResponseData = {
  url: string;
};

const useStripeCreatePortalLink = (): HttpsCallable<RequestData, ResponseData> => {
  const functions = useFunctions();

  return useMemo(
    () => httpsCallable<RequestData, ResponseData>(functions, 'stripe-createportallink'),
    [functions],
  );
};

export default useStripeCreatePortalLink;
